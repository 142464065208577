import React from 'react'
import { t } from '../utils/i18n'
import { Typography, Chip} from '@material-ui/core'

// Components
import Tags from "./Tags"

export default function CandidateTags({ candidate, edit = false }) {
  candidate.candidatetags = candidate.candidatetags || []
  return (
    <React.Fragment>
      { (! edit && ! candidate.candidatetags.length) ?
          <Typography variant="overline"> {t('no_tags_assigned')} </Typography>
        :
          edit ? 
            <Tags 
              tags={candidate.candidatetags}
              onChange={values => {
                candidate.candidatetags = values 
                candidate.tags = values.map(value => value._id)
              }}
            />
          :
            candidate.candidatetags.map(({label}) => { return <Chip label={label} key={label} /> })
      }
    </React.Fragment>         
  )
}