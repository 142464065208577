import React from 'react';
import { t } from '../utils/i18n';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Box,
  MenuList,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Button,
  TextField
} from '@material-ui/core';

// Services
import ScoreCardsService from '../services/scorecards.service';

const useStyles = makeStyles(() => ({
  root: {
    '& label': {
      color: '#606060',
      fontSize: 20,
      display: 'block'
    }
  },
  menuItem: {
    paddingLeft: 0,
    borderBottom: '1px solid #ccc'
  }
}));

export const fetchScoreCards = async filter => ScoreCardsService.list(false, 50, 0, '', filter);
export default function CandidateScoreCardSelect({data: {candidate, onSelected}}) {
  const classes = useStyles();
  candidate.scoreCards = candidate.scoreCards || []; 
  const [ scoreCards, setScoreCards ] = React.useState([]);
  const [ searchCards, setSearchCards ] = React.useState([]);
  const [ refresh, setRefresh ] = React.useState(true);

  React.useEffect(() => {
    const filter = candidate.scoreCards.length ? `_id!=${candidate.scoreCards.map(({_id}) => _id).toString()},` : '';
    if (! scoreCards.length) fetchScoreCards(filter).then(({records}) => {
      setScoreCards(records);
      setSearchCards(records);
    })
    // eslint-disable-next-line
  }, [])
  
  const isChecked = id => candidate.scoreCards.map(({_id}) => _id).includes(id);

  const toggleCard = ({_id: id, ...card}) => {
    if (isChecked(id)) {
      candidate.scoreCards = candidate.scoreCards.filter(({_id}) => _id !== id);
    } else {
      candidate.scoreCards.push({_id: id, scores: {}, added: true});
    }
    setRefresh(Math.random());
  }
  
  const search = search => scoreCards.filter(card => card.label.toLowerCase().includes(search.toLowerCase()));

  const addScoreCards = () => {
    const cards = candidate.scoreCards.reduce((memo, card) => { 
      const { added = false, ...scorecard } = card;
      if (!! added) {
        memo.push(scorecard);
      } 
      return memo;
    }, [])
    onSelected(cards)
    // add candidate scorecards
  }

  return <Box className={classes.root} style={{overflow: 'auto'}}> 
      <TextField 
        name="search" 
        type="text" 
        fullWidth
        placeholder={t('search')}
        variant="outlined"
        onChange={({currentTarget}) => setSearchCards(search(currentTarget.value))}
      />
      <MenuList>
        {refresh && searchCards.map(card => (
          <MenuItem key={card._id} value={card._id} onClick={() => toggleCard(card)} className={classes.menuItem}>
            <ListItemIcon>
              <Checkbox color="primary" checked={isChecked(card._id)} />
            </ListItemIcon>
            <ListItemText primary={card.label} />
          </MenuItem>
        ))}
      </MenuList>
      <Box>
        <Button onClick={addScoreCards}> { t('select') } </Button>
      </Box>
  </Box>
}
