import React from "react"
import { t } from '../utils/i18n'
import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE, ENTITY_TYPE } from "draftail"
import { AtomicBlockUtils, EditorState, CompositeDecorator, Modifier } from "draft-js"
import { convertToHTML, convertFromHTML } from "draft-convert"
import { Field } from 'formik'
import { FormHelperText } from '@material-ui/core'

import { composeDecorators } from 'draft-js-plugins-editor' 
import createResizeablePlugin from 'draft-js-resizeable-plugin'
import createFocusPlugin from 'draft-js-focus-plugin'
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';

// assets
import '../assets/scss/rich-editor.scss'
import 'draft-js-mention-plugin/lib/plugin.css';
import icons from '../assets/svg/editor.svg'

const focusPlugin = createFocusPlugin()
const resizeablePlugin = createResizeablePlugin({ horizontal: 'absolute', vertical: 'absolute' })
const decorator = composeDecorators(resizeablePlugin.decorator, focusPlugin.decorator)
const mentionPlugin = createMentionPlugin({mentionPrefix: '@'})
const { MentionSuggestions } = mentionPlugin


const Link = ({ entityKey, contentState, children }) => {
  let { url } = contentState.getEntity(entityKey).getData();
  return (
      <a
          style={{ color: "blue", fontStyle: "italic" }}
          href={url}
          target="_blank"
      >
          {children}
      </a>
  );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
      return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === "LINK"
      );
  }, callback);
};

export const createLinkDecorator = () =>
  new 
      CompositeDecorator
([
  {
      strategy: findLinkEntities,
      component: Link,
  },
]);


var resizeEventListener = false
function ImageSourceFn(props) {
  const [src, setSrc] = React.useState('')
  const [error, setError] = React.useState(false)
  const [position, setPosition] = React.useState({})
  
  React.useEffect(() => {
    if (! resizeEventListener) {
      resizeEventListener = true
      window.addEventListener('resize', calculatePosition);
    }
    return function cleanup() {
      window.removeEventListener('resize', calculatePosition)
      resizeEventListener = false
    }
  })

  React.useEffect(() => {
    calculatePosition()
  }, [])

  const addImage = event => {
    event.preventDefault()
    const { editorState, entityType, onComplete } = props
    setError(false)
    if (src) {
      const content = editorState.getCurrentContent()
      const contentWithEntity = content.createEntity(
        entityType.type,
        "IMMUTABLE",
        { src},
      )
      const entityKey = contentWithEntity.getLastCreatedEntityKey()
      const nextState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        " ",
      )
      onComplete(nextState)
    } else {
      setError(t('invalid_image_url'))
    }
  }

  const cancel = event => {
    event.preventDefault()
    const { editorState, onComplete } = props
    onComplete(editorState)
  }

  const calculatePosition = () => {
    const iconBtnPadding = 4
    const { 
      offsetLeft: iconBtnLeft, 
      offsetTop: iconBtnTop, 
      offsetHeight: iconBtnHeight 
    } = document.querySelector("#icon-image").parentElement
    
    const { offsetWidth: toolbarWidth } = document.querySelector(".Draftail-Toolbar")
    const { offsetWidth: imageSourceWidth } = document.querySelector(".DraftEditor-ImageSource")

    if (iconBtnLeft + imageSourceWidth > toolbarWidth) {
      setPosition({
        top: iconBtnTop + iconBtnHeight + iconBtnPadding,
        left: 'inherit',
        right: 0
      })
    } else {
      setPosition({
        top: iconBtnTop + iconBtnHeight + iconBtnPadding,
        left: iconBtnLeft,
        right: 'inherit'
      })
    }
  }

  return (
    <div className={`DraftEditor-ImageSource ${ error ? 'error' : ''}`} style={position}>
      { error && <div className="message">* {error}</div> }
      <Field 
        name="image-url" 
        label="Image url"
        onChange={ event => setSrc(event.target.value) }
        variant="outlined" 
        placeholder={t("image_url")}
        margin="normal"/>  
      <button onClick={ addImage }>add image</button>
      <button className="cancel" onClick={cancel}>X</button>
    </div>
  )
}


function LinkSourceFn(props) {
  const [url, setUrl] = React.useState('')
  const [text, setText] = React.useState(window.getSelection().toString())
  const [error, setError] = React.useState(false)
  const [position, setPosition] = React.useState({})
  
  React.useEffect(() => {
    if (! resizeEventListener) {
      resizeEventListener = true
      window.addEventListener('resize', calculatePosition);
    }
    return function cleanup() {
      window.removeEventListener('resize', calculatePosition)
      resizeEventListener = false
    }
  })

  React.useEffect(() => {
    calculatePosition()
  }, [])

  const addLink = event => {
    event.preventDefault()
    const { editorState, onComplete } = props
    setError(false)
    if (url && text) {
      const decorator = createLinkDecorator();
      const currentContent = editorState.getCurrentContent();
      const createEntity = currentContent.createEntity("LINK", "MUTABLE", {
          url,
          text
      });
      let entityKey = currentContent.getLastCreatedEntityKey();
      const selection = editorState.getSelection();
      const textWithEntity = Modifier.replaceText(
          currentContent,
          selection,
          text,
          null,
          entityKey
      );
      let newState = EditorState.createWithContent(textWithEntity, decorator);
            
      onComplete(newState)
    } else {
      setError(t('provide_text_and_link'))
    }
  }

  const cancel = event => {
    event.preventDefault()
    const { editorState, onComplete } = props
    onComplete(editorState)
  }

  const calculatePosition = () => {
    const iconBtnPadding = 4
    const { 
      offsetLeft: iconBtnLeft, 
      offsetTop: iconBtnTop, 
      offsetHeight: iconBtnHeight 
    } = document.querySelector("#icon-link").parentElement
    
    const { offsetWidth: toolbarWidth } = document.querySelector(".Draftail-Toolbar")
    const { offsetWidth: LinkSourceWidth } = document.querySelector(".DraftEditor-LinkSource")

    if (iconBtnLeft + LinkSourceWidth > toolbarWidth) {
      setPosition({
        top: iconBtnTop + iconBtnHeight + iconBtnPadding,
        left: 'inherit',
        right: 0
      })
    } else {
      setPosition({
        top: iconBtnTop + iconBtnHeight + iconBtnPadding,
        left: iconBtnLeft,
        right: 'inherit'
      })
    }
  }

  return (
    <div className={`DraftEditor-LinkSource ${ error ? 'error' : ''}`} style={position}>
      { error && <div className="message">* {error}</div> }
      <Field 
        name="link-url" 
        label="Link url"
        onChange={ event => setUrl(event.target.value) }
        variant="outlined" 
        placeholder={t("link")}
        margin="normal"/>
      <button onClick={ addLink }>add link</button>
      <button className="cancel" onClick={cancel}>X</button>
    </div>
  )
}

const exporterConfig = {
  styleToHTML: (style) => {
    switch(style) {
      case INLINE_STYLE.MARK:
        return <span style={{backgroundColor: 'yellow'}} />
      case INLINE_STYLE.BOLD:
        return <strong />
      case INLINE_STYLE.ITALIC:
        return <em />
      case INLINE_STYLE.UNDERLINE:
        return <u />
      case INLINE_STYLE.CODE:
        return <code />
      case INLINE_STYLE.STRIKETHROUGH:
        return <span style={{textDecoration: 'line-through'}} />
      default:
        return { start: '', end: '' }
    }
  },
  blockToHTML: (block, html) => {
    if (block.type === BLOCK_TYPE.BLOCKQUOTE) {
      return <blockquote />
    }
    // Discard atomic blocks, as they get converted based on their entity.
    if (block.type === BLOCK_TYPE.ATOMIC) {
      return { start: "", end: "" }
    }
    return null
  },
  entityToHTML: (entity, originalText) => {
      
    if (entity.type === 'mention') { return <span className="mention" id={entity.data.mention._id}>{originalText}</span> }

    if (entity.type === ENTITY_TYPE.LINK) { return <a href={entity.data.url}>{entity.data.text}</a> }

    if (entity.type === ENTITY_TYPE.IMAGE) {
      return <img src={entity.data.src} alt={entity.data.alt} width={entity.data.width} height={entity.data.height}/>
    }

    if (entity.type === ENTITY_TYPE.HORIZONTAL_RULE) {
      return <hr />
    }

    return originalText
  },
}

export const RichEditorHelpers = {
  convertToHtml: state => {
    return convertToHTML(exporterConfig)(state.getCurrentContent())
  },
  convertFromHtml: html => {
    const importerConfig = {
      htmlToEntity: (nodeName, node, createEntity) => {
        if (node.className === "mention") {
          return createEntity('mention', "SEGMENTED", {mention: {name: node.innerText, _id: node.id }})
        }
        if (nodeName === "a") {
          return createEntity("LINK", "MUTABLE", { url: node.href, text: node.innerText })
        }
        if (nodeName === "img") {
          return createEntity(ENTITY_TYPE.IMAGE, "IMMUTABLE", {
            src: node.src,
            width: node.width, 
            height: node.height
          })
        }
        if (nodeName === "hr") {
          return createEntity(ENTITY_TYPE.HORIZONTAL_RULE, "IMMUTABLE", {})
        }
        return null
      },  
      htmlToBlock: (nodeName) => {
        if (nodeName === "hr" || nodeName === "img" ) { return "atomic" }
        return null
      }
    }
    return EditorState.createWithContent(convertFromHTML(importerConfig)(html))
  },
  createEmpty: () => {
    return EditorState.createEmpty()
  },
  getPlainText: state => {
    return state.getCurrentContent().getPlainText()
  }
}

const SvgIcon = ({ icon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={`Draftail-ToolbarButton__label icon ${icon}`}
    id={icon}
  >
    <use xlinkHref={`${icons}#${icon}`} />
  </svg>
)

function ImageBlock({
  block, // eslint-disable-line no-unused-vars
  blockProps, // eslint-disable-line no-unused-vars
  customStyleMap, // eslint-disable-line no-unused-vars
  customStyleFn, // eslint-disable-line no-unused-vars
  decorator, // eslint-disable-line no-unused-vars
  forceSelection, // eslint-disable-line no-unused-vars
  offsetKey, // eslint-disable-line no-unused-vars
  selection, // eslint-disable-line no-unused-vars
  tree, // eslint-disable-line no-unused-vars
  contentState, // eslint-disable-line no-unused-vars
  style,
  blockStyleFn, // eslint-disable-line no-unused-vars
  preventScroll, // eslint-disable-line no-unused-vars
  ...elementProps
}) {
  if (! blockProps.entity) { return null }
  const { src, alt } = blockProps.entity.getData()
  return ( 
    <div {...elementProps} className="ImageBlockWrapper" style={{ ...style }}>
      <ul className="ImageCropArea">
        <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
      </ul>
      <img className="ImageBlock" src={src} alt={alt} style={{ ...style }} />
    </div>
  )
}

const createImageBlockPlugin = () => {
  const c = {
    component: decorator(ImageBlock),
    editable: false
  }
  return { 
    blockRendererFn: (block, { getEditorState }) => {
      if (block.getType() === 'atomic') {
        return { ...c }
      }
      return null
    } 
  } 
} 

const imageBlockPlugin = createImageBlockPlugin()
export default function RichEditor(props) {
  const {
    editorState,
    error,
    helperText,
    onChange,
    mentions = [],
    onAddMention
  } = props

  const [suggestions, setSuggestions] = React.useState(mentions)
  const onSearchChange = ({ value }) => { setSuggestions(defaultSuggestionsFilter(value, mentions)) }
  const addMention = mention => { if (onAddMention) { onAddMention(mention) }}

  return (
    <div className={`RichEditor-Wrapper ${error ? 'error' : ''}`}>
      <DraftailEditor
        editorState={editorState}
        stateSaveInterval={50}
        enableHorizontalRule
        enableLineBreak
        showUndoControl
        showRedoControl
        onChange={onChange}
        plugins={[resizeablePlugin, imageBlockPlugin, focusPlugin, mentionPlugin]}
        entityTypes={[
          {
            type: ENTITY_TYPE.LINK,
            source: LinkSourceFn,
            decorator: Link,
            icon: <SvgIcon icon="icon-link" />,
            attributes: ['url', 'text'],
            whitelist: {
                src: '^http',
            }
          },
          {
            type: ENTITY_TYPE.IMAGE,
            source: ImageSourceFn,
            block: ImageBlock,
            icon: <SvgIcon icon="icon-image" />,
            attributes: ['src', 'alt'],
            whitelist: {
                src: '^http',
            }
          }
        ]}
        blockTypes={[
          { type: BLOCK_TYPE.HEADER_ONE },
          { type: BLOCK_TYPE.HEADER_TWO },
          { type: BLOCK_TYPE.HEADER_THREE },
          { type: BLOCK_TYPE.HEADER_FOUR },
          { type: BLOCK_TYPE.HEADER_FIVE },
          { type: BLOCK_TYPE.UNORDERED_LIST_ITEM, icon: <SvgIcon icon="icon-list-ul" /> },
          { type: BLOCK_TYPE.ORDERED_LIST_ITEM, icon: <SvgIcon icon="icon-list-ol" /> },
          { type: BLOCK_TYPE.BLOCKQUOTE },
          { type: BLOCK_TYPE.CODE },
        ]}
        inlineStyles={[
          { type: INLINE_STYLE.BOLD, icon: <SvgIcon icon="icon-bold" /> },
          { type: INLINE_STYLE.ITALIC, icon: <SvgIcon icon="icon-italic" /> },
          { type: INLINE_STYLE.CODE },
          { type: INLINE_STYLE.UNDERLINE, icon: <SvgIcon icon="icon-underline" /> },
          { type: INLINE_STYLE.STRIKETHROUGH, icon: <SvgIcon icon="icon-strikethrough" /> },
          { type: INLINE_STYLE.MARK },
          { type: INLINE_STYLE.KEYBOARD },
          { type: INLINE_STYLE.SUPERSCRIPT, icon: <SvgIcon icon="icon-superscript" /> },
          { type: INLINE_STYLE.SUBSCRIPT, icon: <SvgIcon icon="icon-subscript" /> }
        ]} />

      <MentionSuggestions
          onSearchChange={onSearchChange}
          suggestions={suggestions}
          onAddMention={addMention} />

      { error && <FormHelperText error>{helperText}</FormHelperText> }
    </div>
  )
}
