import React, { useState } from 'react';
import { t } from '../../utils/i18n';
import { useHistory } from "react-router-dom";

import * as Yup from 'yup';

import countries from "../../assets/countries/CountryList";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextField } from 'formik-material-ui';
import MuiPhoneInput from "material-ui-phone-number";

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiTextField from '@material-ui/core/TextField';

// Services
import CandidatesService from "../../services/candidates.service";

// Context 
import { useAuth } from "../../context/auth";

// Candidate components
import CandidateSkills from "../CandidateSkills";

// App components
import Dropdown from "../Dropdown";
import ImageUpload from "../ImageUpload";
import AuthenticatedAvatar from "../AuthenticatedAvatar";

import { getLanguageList } from "../../assets/languages/LanguageList";
import TimeZoneList from "../../assets/timezones/TimeZoneList";

const useStyles = makeStyles(() => ({
  chip: {
    paddingRight: 15,
    cursor: 'pointer'
  },
  avatarWrapper: {
    position: 'relative',
    marginTop: 40,
    flex: 1
  },
  uploadIcon: {
    position: 'absolute',
    zIndex: 9,
    top: 10,
    width: 120
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': { float: 'left' }
  },
  name: {
    fontSize: 22,
    padding: '10px 10px 0',
    width: 'calc(100% - 250px)',
    '& > * > span': {
      fontSize: 17,
      display: 'block'
    }
  },
  avatar: {
    width: 120,
    height: 120
  },
  form: {
    width: '100%',
    backgroundColor: '#f0f3f5',
    '& .MuiFormControl-root': {
      backgroundColor: '#ebeced',
      marginBottom: 20,
      '& .Mui-error': {
        marginBottom: 0,
        backgroundColor: '#f0f3f5'
      }
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: 30
    }
  },
  sections: {
    marginTop: 30,
    '& > *': {
      marginLeft: '30px !important',
      color: '#ccc',
      fontWeight: 500,
      '&.-active': {
        color: '#2a7fd7',
        borderBottom: '3px solid #2a7fd7',
        paddingBottom: 5,
      }
    }
  },
  dropdown: {
    backgroundColor: 'transparent !important',
    width: '100%',
    '& > .MuiFormLabel-root': { top: 0, left: 0 },
    '& .MuiInputBase-formControl, & div .MuiSelect-root': {
      backgroundColor: '#ebeced !important',
      borderRadius: 0,
      padding: '5px 5px 7px'
    },
    '& .MuiInputBase-root > fieldset': { borderRadius: '4px' }
  },
  phone: { '& .MuiPhoneNumber-flagButton': { backgroundColor: 'transparent' } },
  error: {
    width: '100%',
    background: '#ef5350',
    color: '#fff',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    padding: '10px 50px',
    margin: 0,
    '& li > span': {
      fontSize: '15px',
      textTransform: 'uppercase',
      fontWeight: 600,
      marginRight: '5px'
    }
  }
}));

const PersonalSchema = Yup.object().shape({
  firstname: Yup.string().required(t('required')),
  name: Yup.string().required(t('required')),
  street: Yup.string().required(t('required')),
  number: Yup.string().required(t('required')),
  city: Yup.string().required(t('required')),
  postal: Yup.string().required(t('required')),
  country: Yup.string().required(t('required')),
  phone: Yup.string().required(t('required')),
  projectPreferences: Yup.string().required(t('required')),
  topProject: Yup.string().required(t('required')),
  yearsExperience: Yup.number().required(t('required')),
});

export default function CandidateDetails({ candidate = {} }) {
  const classes = useStyles();
  const history = useHistory();
  const { user: { user } } = useAuth();
  const [avatar, setAvatar] = React.useState(candidate.avatar);
  const languageList = getLanguageList();
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const canTranslate = () => candidate.userfunction && !['contentmarketer', 'Designer'].includes(candidate.userfunction[0].name);
  
  const uploadAvatar = file => {
    CandidatesService.upload(candidate._id, [file], true).then(response => {
      candidate.avatar = response.data.file._id;
      CandidatesService.patch(candidate._id, { avatar: response.data.file._id }).then(history.push('/complete', { candidate, step: 1 }));
    });
  };

  const {
    firstname,
    name,
    street = '',
    number = '',
    phone = '',
    city = '',
    postal = '',
    country = 'BE',
    skype = '',
    linkedin = '',
    website = '',
    publicEnabled = 'true',
    candidateInfo = {}
  } = candidate;
  const {
    projectPreferences = '',
    topProject = '',
    freelancer = '',
    skillSet = { yearsExperience: '', motherTongue: user.language, translationSkills: [] }
  } = candidateInfo;

  let { yearsExperience, motherTongue, translationSkills } = skillSet;

  translationSkills = translationSkills &&
    translationSkills.length ?
    translationSkills :
    [{ from: '', to: '', _id: Math.random().toString(16).substr(2, 8) }];

  return (
    candidate &&
    <Formik
      initialValues={{
        firstname,
        name,
        street,
        number,
        postal,
        city,
        country,
        phone,
        skype,
        linkedin,
        website,
        projectPreferences,
        topProject,
        freelancer: freelancer !== '' ? freelancer.toString() : '',
        publicEnabled: publicEnabled !== '' ? publicEnabled.toString() : 'true',
        yearsExperience,
        translationSkills,
        motherTongue
      }}
      validationSchema={PersonalSchema}
      onSubmit={({
        translationSkills,
        yearsExperience,
        motherTongue,
        freelancer,
        publicEnabled,
        topProject,
        projectPreferences,
        website,
        linkedin,
        skype,
        ...values
      }, { setSubmitting }) => {
        translationSkills = translationSkills.filter(({ from, to }) => !!from && !!to);
        const candidateInfo = { skillSet: { translationSkills, yearsExperience, motherTongue }, freelancer, topProject, projectPreferences };
        const data = { candidate: { ...candidate, publicEnabled, timezone: selectedTimezone, candidateInfo, links: { website, linkedin, skype }, ...values }, step: 2 };
        history.push('/complete', data);
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validate={async ({ freelancer, ...rest }) => {
        const errors = {};
        if (!candidate.skills || !candidate.skills.length) errors.skills = t('please_select_one_skill');
        if (freelancer !== 'false' && freelancer !== 'true') errors.freelancer = t('required');
        let hasErrors = Object.keys(errors).length;
        try {
          await PersonalSchema.validate(rest);
        } catch (e) {
          hasErrors = true;
        }
        if (hasErrors) {
          setTimeout(() => document.body.scrollIntoView({ behavior: 'smooth', block: 'start' }), 400);
        }
        return hasErrors ? errors : false;
      }}
    >
      {({ submitForm, values, errors, isSubmitting, setFieldValue }) => {
        return (
          <>
            {!!Object.keys(errors).length &&
              <ul className={classes.error}>
                {
                  Object.keys(errors).map(key =>
                    <li key={key}>
                      <span>{t(key)}:</span> {errors[key]}
                    </li>
                  )
                }
              </ul>
            }
            <Form className={classes.form}
            // onBlur={submitForm}
            >
              <Grid container className={classes.sections}>
                <Typography className={'-active'}> {t('personal_details')} </Typography>
                <Typography style={{ flex: 'inherit' }}> {t('professional_details')} </Typography>
              </Grid>
              <Grid container>
                <Typography variant="h5" style={{ flex: 'inherit', margin: '80px 30px 0' }}> {t('profile_picture')} </Typography>
                <Grid className={classes.avatarWrapper} item xs={12}>
                  <ImageUpload onUpload={uploadAvatar} className={classes.uploadIcon} />
                  <AuthenticatedAvatar candidate={candidate} avatarId={avatar} />
                </Grid>
              </Grid>
              <Grid container className={classes.container}>
                <Grid container>
                  <Typography variant="h5" style={{ marginBottom: 20 }}> {t('personal_data')} </Typography>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      style={{ marginBottom: 20 }}
                      variant="outlined"
                      name="firstname"
                      type="text"
                      label={t("firstname")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      style={{ marginBottom: 20 }}
                      variant="outlined"
                      name="name"
                      type="text"
                      label={t("lastname")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      style={{ marginBottom: 20, width: '70%' }}
                      variant="outlined"
                      name="street"
                      type="text"
                      label={t("street")}
                    />
                    <Field
                      component={TextField}
                      style={{ marginBottom: 20, width: '28%', marginLeft: '2%' }}
                      variant="outlined"
                      name="number"
                      type="text"
                      label={t("number")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      style={{ marginBottom: 20, width: '70%' }}
                      variant="outlined"
                      name="city"
                      type="text"
                      label={t("city")}
                    />
                    <Field
                      component={TextField}
                      style={{ marginBottom: 20, width: '28%', marginLeft: '2%' }}
                      variant="outlined"
                      name="postal"
                      type="text"
                      label={t("postal")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Dropdown}
                      items={countries.map(({ label, code }) => ({ value: code, label: t(label) }))}
                      name="country"
                      label={t("country")}
                      className={classes.dropdown}
                      value={values.country}
                      onChange={value => setFieldValue('country', value)}
                      style={{ marginBottom: 20 }}
                      fullWidth />
                  </Grid>
                </Grid>
                <Grid container style={{ display: 'block' }}>
                  <Typography variant="h5" style={{ marginBottom: 20 }}> {t('contact')} </Typography>
                  <Grid item xs={12}>
                    <MuiPhoneInput
                      style={{ marginTop: 10, marginBottom: 25 }}
                      defaultCountry='be'
                      disableAreaCodes={true}
                      value={values.phone}
                      error={!!errors['phone']}
                      helperText={errors['phone']}
                      onChange={value => setFieldValue('phone', value)}
                      className={classes.phone}
                      variant="outlined"
                      fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      style={{ marginBottom: 30 }}
                      variant="outlined"
                      name="skype"
                      type="text"
                      label={t("skype")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      style={{ marginBottom: 25 }}
                      variant="outlined"
                      name="linkedin"
                      type="text"
                      label={t("linkedin")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      style={{ marginBottom: 25 }}
                      variant="outlined"
                      name="website"
                      type="text"
                      label={t("website")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={TimeZoneList}
                      getOptionLabel={timezone => timezone.text}
                      style={{ marginBottom: 10 }}
                      value={TimeZoneList.find(tz => tz.utc.includes(selectedTimezone) || tz.value === selectedTimezone) || null}
                      onChange={(event, input) => {
                        if (input.value) {
                          setFieldValue('timezone', input.value);
                          setSelectedTimezone(input.value);
                        }
                      }}
                      renderInput={(params) => (
                        <MuiTextField {...params} label={t('timezone')}
                          variant="outlined" fullWidth />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.container}>
                <Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" style={{ marginBottom: 20 }}> {t('skills')} </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      style={{ marginBottom: 20 }}
                      variant="outlined"
                      name="yearsExperience"
                      type="number"
                      label={t("years_experience")}
                    />
                  </Grid>
                  <Grid item style={{ marginBottom: 40 }}>
                    <CandidateSkills
                      candidate={candidate}
                      edit={true}
                      error={!!errors['skills']}
                      helperText={errors['skills']}
                      onClose={() => history.push('/complete', { candidate, step: 1 })}
                    />
                  </Grid>
                  {canTranslate() &&
                    <Grid>
                      <Grid item xs={6}>
                        <Typography style={{ marginBottom: 20 }}> {t('translations')} </Typography>
                      </Grid>
                      <Grid style={{ maxWidth: 'calc(100vw - 30px)' }}>
                        {values.translationSkills.map(({ from, to, _id }) => {
                          return <Grid item xs={12} key={_id}>
                            <Autocomplete
                              options={languageList}
                              getOptionLabel={language => language.name}
                              value={languageList.find(l => l.code === from) || null}
                              getOptionSelected={(option, value) => option.value === value.code}
                              onChange={(event, input) => {
                                setFieldValue(
                                  'translationSkills',
                                  values.translationSkills.map(skill => {
                                    if (skill._id === _id) skill.from = input ? input.code : '';
                                    return skill;
                                  })
                                );
                              }}
                              renderInput={(params) => (
                                <MuiTextField {...params} label={t('translate_from')} variant="outlined" fullWidth />
                              )}
                            />
                            <Autocomplete
                              options={languageList}
                              getOptionLabel={language => language.name}
                              value={languageList.find(l => l.code === to) || null}
                              getOptionSelected={(option, value) => option.value === value.code}
                              onChange={(event, input) => {
                                setFieldValue(
                                  'translationSkills',
                                  values.translationSkills.map(skill => {
                                    if (skill._id === _id) skill.to = input ? input.code : '';
                                    return skill;
                                  })
                                );
                              }}
                              renderInput={(params) => (
                                <MuiTextField {...params} label={t('translate_to')} variant="outlined" fullWidth />
                              )}
                            />
                          </Grid>;
                        })}
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{ marginLeft: 10, cursor: 'pointer', display: 'inline-block' }}
                          onClick={() => setFieldValue(
                            'translationSkills',
                            [...values.translationSkills, { from: '', to: '', _id: Math.random().toString(16).substr(2, 8) }]
                          )}>
                          + {t('add_language')}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                </Grid>
                <Grid container style={{ display: 'block' }}>
                  <Typography variant="h5" style={{ marginBottom: 20 }}> {t('projects')} </Typography>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      multiline
                      rows={10}
                      style={{ marginBottom: 20 }}
                      variant="outlined"
                      name="projectPreferences"
                      type="text"
                      label={t("project_preferences")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      multiline
                      rows={10}
                      style={{ marginBottom: 20 }}
                      variant="outlined"
                      name="topProject"
                      type="text"
                      label={t("top_project")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {t("work_as_freelancer")}
                    <Field
                      name="freelancer"
                      type="radio"
                      value='true'
                    /> {t('yes')}
                    <Field
                      name="freelancer"
                      type="radio"
                      value='false'
                    /> {t('no')}
                    <ErrorMessage
                      name="freelancer"
                      render={msg => <p className='MuiFormHelperText-root MuiFormHelperText-contained Mui-error'>{msg}</p>}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {t("public_enabled")}
                    <Field
                      name="publicEnabled"
                      type="radio"
                      value='true'
                    /> {t('yes')}
                    <Field
                      name="publicEnabled"
                      type="radio"
                      value='false'
                    /> {t('no')}
                  </Grid>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#0df394',
                  color: '#041e7a',
                  float: 'right',
                  margin: 20
                }}
                size='large'
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {t("next")} &gt;
              </Button>
            </Form>
          </>
        );
      }}
    </Formik >
  );
}