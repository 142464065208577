import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, Link } from '@material-ui/core'
import {
  // Assignment as AssignmentIcon,
  Mail as MailIcon,
  Phone as PhoneIcon,
  PinDrop as PinDropIcon,
  CalendarToday as CalendarTodayIcon,
  LinkedIn as LinkedInIcon
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: { padding: '5px 0', overflow: 'hidden' },
  item: {
    float: 'left',
    borderRadius: 17,
    backgroundColor: theme.palette.mediaforta.superlight,
    margin: '0 4px 0 0',
    width: 40,
    height: 40,
    padding: '0 10px',
    '& > svg': { width: 20, color: theme.palette.primary.dark }
  }
}))

export default function CandidateIconMenu({candidate = {}}) {
  const classes = useStyles()
  const { postal, street, number, city, country } = candidate
  return ( 
    <List className={classes.root}>
      {/* <ListItem component={Link} className={classes.item} href="#" target="_blank">
        <AssignmentIcon />
      </ListItem> */}
      <ListItem component={Link} className={classes.item} href={`mailto:${candidate.email}`} target="_blank">
        <MailIcon />
      </ListItem>
      <ListItem component={Link} className={classes.item} href={`tel:${candidate.phone}`} target="_blank">
        <PhoneIcon />
      </ListItem>
      <ListItem component={Link} className={classes.item} href={`https://maps.google.com/?q=${street} ${number}, ${postal} ${city}, ${country}`} target="_blank">
        <PinDropIcon />
      </ListItem>
      <ListItem component={Link} className={classes.item} href={`/agenda/${candidate._id}`}>
        <CalendarTodayIcon />
      </ListItem>
      {
        !! candidate.links && !! candidate.links.linkedin && 
        <ListItem component={Link} className={classes.item} href={candidate.links.linkedin} target="_blank">
          <LinkedInIcon />
        </ListItem>
      }
    </List>
  )
}