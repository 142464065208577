import React from 'react'
import { t } from '../utils/i18n'

import { Add as AddIcon } from '@material-ui/icons'
import { Typography, Container, CssBaseline, IconButton, Box, Grid } from '@material-ui/core'

// Context
import { useUser } from '../context/user'

// Components
import InterviewTemplatesList from '../components/InterviewTemplatesList'
import { InterviewTemplateForm } from '../components/InterviewForm'

// Services
import interviewsService from '../services/interviews.service'

export const fetchInterviewTemplates = async () => interviewsService.templates()

export default function InterviewTemplates() {
  const { isRecruiter } = useUser()
  const [ template, setTemplate ] = React.useState(false)
  const [ templateId, setTemplateId] = React.useState(false)
  const [ templates, setTemplates ] = React.useState([])
  const [ refresh, setRefresh ] = React.useState(false)
  
  React.useEffect(() => {
    if (! templates.length || templateId || refresh) fetchInterviewTemplates().then(response => {
      const templates = response.records
      setTemplates(templates)
      if(refresh) setRefresh(false)
    })
    // eslint-disable-next-line
  }, [templateId, refresh])


  return (
    isRecruiter() &&
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
      	{t('Interviews')}
        <IconButton aria-label={t('add_template')} onClick={() => setTemplate({})}>
          <AddIcon />
        </IconButton>
        { template &&
          <Box className="form-wrapper -full">  
            <h2>
              { template._id ? t('edit_interview') : t('new_interview') }
            </h2>
            <div>
              <InterviewTemplateForm onClose={templateId => {
                  setTemplate(false)
                  setTemplateId(templateId)
                  setRefresh(true)
                }} 
                template={template}
              />
              <IconButton aria-label={t('cancel')} onClick={() => setTemplate(false)}> x </IconButton>
            </div>
          </Box> 
        }
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <InterviewTemplatesList templateId={templateId} templates={templates} setRefresh={setRefresh}/>   
        </Grid>
      </Grid>
    </Container>
  )
}