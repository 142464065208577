// Services
import ApiService from "../services/api.service"

const model = 'calendar'

export default class CalendarService {
	static list({ start, end }, assignedTo) {
    let query = `startDate>=${start}&endDate<=${end}`
    if (assignedTo) query = `${query}&assignedTo=${assignedTo}`
     
    return ApiService.get(model, query).then(response => response.data)
  }

  static post(data = {}) {
    return ApiService.post(model, data).then(response => response)
  }

  static patch(calendarId, patch = {}) {
    return ApiService.patch(`${model}/${calendarId}`, patch).then(response => response) 
  }

  static delete(calendarId) {
    return ApiService.delete(`${model}/${calendarId}`).then(response => response) 
  }
}