import React from 'react'
import { t, getLanguage } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button, Box, IconButton } from '@material-ui/core'

// Services
import skillsService from '../services/skills.service'

// Components
import CandidateSkillsForm from './CandidateSkillsForm'
import SkillStars from './SkillStars'

import '../assets/scss/form-wrapper.scss'

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: 'capitalize',
    overflow: 'hidden',
    marginBottom: 10,
    minWidth: 500,
    '& h5': { fontSize: 20 },
    '& h6': { fontSize: 18, fontWeight: 300 },
    '& > div': {
      float: 'left',
      marginRight: 20,
      marginTop: 10,
      marginBottom: 20,
      display: 'flex',
    }
  },
  skillSet: {
    width: '100%',
    flexDirection: 'column',
    '& > *': { width: '100%' }
  },
  skill: {
    display: 'flex',
    '& > *': { flex: 1 },
    '& > h6': { flex: 2 },
  },
  error: { 
    color: '#eb313c',
    fontSize: 12,
    marginLeft: '15px !important'
  },
  errorBtn: { backgroundColor: '#eb313c' }
}))

export const fetchSkills = async userfunctions => skillsService.list({filter: `filter=${JSON.stringify({$or:[{userfunctions},{'userfunctions.0':{$exists:false}}]})}`})
export default function CandidateSkills({ candidate = {}, onClose, edit, error = false, helperText }) {
  candidate.skills = candidate.skills || []
  const classes = useStyles()
  const language = getLanguage()
  const [ skills, setSkills ] = React.useState(false)
  const [ skillForm, setSkillForm ] = React.useState(false)

  React.useEffect(() => {
    fetchSkills(candidate.userfunction[0]._id).then(response => {
      const skills = response.records.map(skill => {
        skill.skills = skill.skills.filter(item => (item.state !== 'pending') || (item.createdBy === candidate.userId))
        return skill
      })
      setSkills(skills)
    })
    // eslint-disable-next-line
  }, [candidate.userfunction])

  const refreshSkills = () => fetchSkills().then(response => setSkills(response.records))

  const getFilteredSkills = () => {
    const copy = JSON.parse(JSON.stringify(skills))
    return copy.map(skill => {
      skill.skills = skill.skills.filter(x => {
        return candidate.skills.map(cs => cs.skillId).includes(x._id)
      })
      return skill
    })
  }
  
  return (
     !! skills &&
    <>
      <Grid className={classes.root}>
        { !! candidate.skills.length ?
          getFilteredSkills().map(skill => {
            return !! skill.skills.length && <Grid key={skill._id} className={classes.skillSet}>
              <Typography variant='h5'>{skill.label[language]}</Typography>
              {
                skill.skills.map(item => {
                  return <Grid key={item._id}  className={classes.skill}>
                    <Typography variant='h6'>{item.label[language]}</Typography>
                    <SkillStars skill={candidate.skills.find(skill => skill.skillId === item._id)}  />
                  </Grid>
                })
              }
            </Grid>        
          })
          :
          <Typography variant="overline"> {t('no_skillset')}</Typography>
        }
      </Grid>
      {!! edit && 
        <Button color="primary" className={error ? classes.errorBtn : ''} onClick={() => setSkillForm(true)}> 
          { !! candidate.skills.length ? t('manage_skills') : t('add_skills')} 
        </Button>
      }
      { !! helperText &&
        <Typography className={classes.error}>{helperText}</Typography>
      }
      { skillForm &&
        <Box className="form-wrapper" style={{width: 'calc(100vw - 130px)'}}>  
          <h2> {t('skills') } </h2>
          <div>
            <CandidateSkillsForm 
              onClose={skills => {
                setSkillForm(false)
                if (onClose) onClose(skills)
              }} 
              skills={skills} 
              refreshSkills={refreshSkills} 
              candidate={candidate}
            />
            <IconButton aria-label="cancel" onClick={() => setSkillForm(false)}> x </IconButton>
          </div>
        </Box>
      }
    </>
  )
}