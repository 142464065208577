import React, {createRef} from 'react'

// Services
import AuthService from "../services/auth.service"

export default function AuthenticatedLink({ url, filename, children }) {
  const link = createRef()
  const user = AuthService.getCurrentUser() || {}

  const handleAction = async (e) => {
    e.stopPropagation()
    
    if (link.current.href) { return }
    
    const result = await fetch(url, {  
      headers: {
         Authorization: "Bearer " + user.token,
         Prefer: 'count=exact'
      }
    })
    
    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)
    
    link.current.download = filename
    link.current.href = href
    link.current.click()
  }

  return (
    <>
      {/* eslint-disable-next-line*/}
      <a role='button' ref={link} onClick={handleAction} style={{textDecoration: 'none'}}>
        {children}
      </a>
    </>
  )
}