import * as React from 'react'
import { t } from '../utils/i18n'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { AppointmentForm, AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui'

// Services
import CandidatesService from "../services/candidates.service"

const CalendarLayouts = {}
const fetchCandidates = async search => CandidatesService.list(search, 4, 0, '_id, firstname, name')
const fetchCandidate = async (id) => CandidatesService.get(id)

const CalendarFormLayout = function({ candidates, assignedTo, onFieldChange, appointmentData, ...restProps }) { 
  const [candidatesList, setCandidatesList] = React.useState(candidates)
  const [candidate, setCandidate] = React.useState(null)
 
  React.useEffect(() => {
    if (! candidate && assignedTo) {
      fetchCandidate(assignedTo)
        .then(candidate => {
          const c = { _id: candidate._id, name: `${candidate.firstname} ${candidate.name}` }
          setCandidate(c)
          if (! appointmentData.assignedTo) onFieldChange({ assignedTo: c })
        })
        .catch()
    }
  })

  const findCandidates = (search = false) => {
    fetchCandidates(search)
    .then(data => {
      const list = data.records.map(r => { return { _id: r._id, name: `${r.firstname} ${r.name}`} })
      return setCandidatesList(list)
    })
    .catch()
  }

  return (
    <AppointmentForm.BasicLayout appointmentData={appointmentData} onFieldChange={onFieldChange} {...restProps} >
      <AppointmentForm.Label text={t("assigned_to")} type="title" />
      <Autocomplete
        id="assigned-to"
        options={candidatesList || []}
        getOptionLabel={(option) => option.name || ''}
        value={appointmentData.assignedTo || candidate || null}
        onChange={(event, value) => onFieldChange({ assignedTo: value })}
        onInputChange={(event, value) => findCandidates(value)}
        disabled={!! assignedTo}
        renderInput={(params) => <TextField {...params} placeholder={t("assigned_to")} variant="outlined" />}
      />
    </AppointmentForm.BasicLayout>
  );
};

const CalendarCommandLayout = function({onCommitButtonClick, onSave, ...restProps}) {
  return (
    <AppointmentForm.CommandLayout onCommitButtonClick={onSave} {...restProps} />
  );
};


const CalendarTooltipLayout = function({onDeleteAppointment, appointmentMeta, onDeleteButtonClick, ...restProps}) {
  return (
    <AppointmentTooltip.Layout
      onDeleteButtonClick={() => onDeleteAppointment(appointmentMeta.data._id)}
      appointmentMeta={appointmentMeta}
      {...restProps}
    />
  );
};

CalendarLayouts.Form = CalendarFormLayout
CalendarLayouts.Tooltip = CalendarTooltipLayout
CalendarLayouts.Command = CalendarCommandLayout

export default CalendarLayouts