// Services
import ApiService from "../services/api.service";

const model = "scorecards";
const categoriesModel = "scorecardcategories";

export default class ScoreCardsService {
  static list(
    search = false,
    limit = 10,
    skip = 0,
    fields = "",
    filter = false
  ) {
    return ApiService.list(model, { search, filter, limit, skip, fields }).then(
      (response) => response.data
    );
  }

  static get(id) {
    return ApiService.get(`${model}/${id}`).then((response) => response.data);
  }

  static patch(id, patch) {
    return ApiService.patch(`${model}/${id}`, patch).then(
      (response) => response.data
    );
  }

  static post(data = {}) {
    return ApiService.post(model, data).then((response) => response);
  }

  static delete(scoreCardId) {
    return ApiService.delete(`${model}/${scoreCardId}`).then(
      (response) => response
    );
  }

  static listCategories(
    search = false,
    limit = 10,
    skip = 0,
    fields = "",
    filter = false
  ) {
    return ApiService.list(categoriesModel, {
      search,
      filter,
      limit,
      skip,
      fields,
    }).then((response) => response.data);
  }

  static addCategory(data) {
    return ApiService.post(categoriesModel, data).then((response) => response);
  }

  static addCategoryItem(id, item) {
    return ApiService.post(`${categoriesModel}/${id}`, { item }).then(
      (response) => response
    );
  }

  static patchScoreCardCategories(scoreCardId, categories = []) {
    return ApiService.patch(`${model}/${scoreCardId}/categories`, {
      categories,
    }).then((response) => response);
  }

  static patchCategory(id, patch) {
    return ApiService.patch(`${categoriesModel}/${id}`, patch).then(
      (response) => response.data
    );
  }

  static patchCardItemWeight(id, itemId, weight) {
    return ApiService.patch(`${model}/${id}/${itemId}/weight`, { weight }).then(
      (response) => response.data
    );
  }
}
