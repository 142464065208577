import React from 'react'
import { t } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Chip, TextField } from '@material-ui/core'
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete'

// Services
import CandidatesService from "../services/candidates.service"

const useStyles = makeStyles(() => ({ 
  autocomplete: { minWidth: 400 } 
}))

const sourceFilter = createFilterOptions()

export default function CandidateSource({ candidate = {}, edit = false }) {
  candidate.candidatesources = candidate.candidatesources || []  
  const classes = useStyles()
  const [ sources, setSources ] = React.useState([])

  const [ source, setSource ] = React.useState(candidate.candidatesources.length ? candidate.candidatesources[0].label : '')

  React.useEffect(() => {
    if (! sources.length && candidate._id) CandidatesService.listSources().then(data => setSources(data.records))
  // eslint-disable-next-line
  }, [])

  const selectSource = label => {
    candidate.candidatesources = label ? [{ label }] : []
    if (! label) candidate.sourceId = null
    else {
      const s = sources.find(source => source.label === label)
      if (s) candidate.sourceId = s._id
      else candidate.sourceId = label
    } 
    setSource(label)
  }

  // if (! sources.length) return null
  return (
    <React.Fragment>
    { ! edit && ! candidate.candidatesources.length ?
        <Typography  variant="overline"> {t('no_source_assigned')} </Typography>
      :
        edit ? 
          <Autocomplete
            name="sourceId"
            id="sourceId"
            className={classes.autocomplete}
            options={sources.map(s => s.label)}
            margin="normal"
            value={source}
            filterOptions={(options, params) => {
              const filtered = sourceFilter(options, params);
              if (params.inputValue !== '') filtered.push(params.inputValue);
              return filtered;
            }}
            renderInput={(params) => (
              <TextField 
                {...params}
                onBlur={e => selectSource(e.currentTarget.value)}
                placeholder={t("add_source")}
                variant="outlined" 
                name="sourceId" />
            )}
          />
        :
          !! candidate.candidatesources.length && <Chip label={candidate.candidatesources[0].label} />
      }
    </React.Fragment>       
  )
}