import React from 'react';
import { useHistory } from "react-router-dom";
import { t } from '../utils/i18n';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiTextField from '@material-ui/core/TextField';

// Services
import UsersService from "../services/users.service";
import CandidatesService from "../services/candidates.service";

// App components
import Alert from "./Alert";
import Dropdown from "./Dropdown";

import { getLanguageList } from "../assets/languages/LanguageList";

const useStyles = makeStyles(theme => ({
  form: { marginTop: theme.spacing(2) },
  dropdown: {
    backgroundColor: 'transparent !important',
    width: '100%',
    '& > .MuiFormLabel-root': { top: 0, left: 0 },
    '& .MuiInputBase-formControl, & div .MuiSelect-root': {
      backgroundColor: '#ebeced !important',
      borderRadius: 0,
      padding: '7px 5px 7px'
    },
    '& .MuiInputBase-root > fieldset': { borderRadius: '4px' }
  },
  error: { marginBottom: 30 },
  errorLabel: {
    color: `${theme.palette.mediaforta.error} !important`,
    '& svg': { fill: theme.palette.mediaforta.error }
  },
  checkboxControl: { '& svg': { fill: theme.palette.mediaforta.dark } }
}));

const tRequired = t('required');
const RegisterSchema = Yup.object().shape({
  function: Yup.string()
    .required(tRequired),
  language: Yup.string()
    .required(tRequired),
  email: Yup.string()
    .required(tRequired),
  password: Yup.string()
    .required(tRequired),
  confirmpassword: Yup.string()
    .required(tRequired),
  privacy: Yup.boolean()
    .required(tRequired),
  newsletter: Yup.boolean()
});

export default function RegisterForm({ user = {}, jobId = null, campaignId = null }) {
  const classes = useStyles();
  const history = useHistory();
  const { firstname, name, email, password, confirmpassword, language } = user;
  const [error, setError] = React.useState(false);
  const [userFunctions, setUserFunctions] = React.useState(null);
  const languageList = getLanguageList();

  React.useEffect(() => {
    if (!userFunctions) {
      UsersService.listUserFunctions()
        .then(data => setUserFunctions(data.records.map(({ _id: value, name: label }) => ({ value, label: t(label) }))));
    }
  });

  return (
    <Formik
      initialValues={{
        email,
        password,
        confirmpassword,
        function: user.function || '',
        language,
        privacy: false,
        newsletter: false
      }}
      validationSchema={RegisterSchema}
      onSubmit={(values, { setSubmitting }) => {
        const user = { firstname, name, jobId, campaignId, ...values };
        CandidatesService.post(user)
          .then(() => history.push('/register', { user, jobId, campaignId, step: 2 }))
          .catch(e => {
            setError(e.response.data.message);
            setSubmitting(false);
          });
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validate={({ password, confirmpassword, privacy }) => {
        const errors = {};
        if (password !== confirmpassword) { errors.confirmpassword = t('passwords_no_match'); }
        if (!privacy) { errors.privacy = tRequired; }
        return Object.keys(errors).length ? errors : false;
      }}
    >
      {({ submitForm, values, errors, isSubmitting, setFieldValue }) => (
        <Form className={classes.form}>
          {error && <Alert severity="error" className={classes.error}>{error}</Alert>}
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <Field
                component={Dropdown}
                items={userFunctions}
                name="function"
                label={t("i_am")}
                className={classes.dropdown}
                value={values.function}
                onChange={value => setFieldValue('function', value)}
                error={!!errors.function}
                helperText={errors.function}
                style={{ marginBottom: 20 }}
                fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={languageList}
                getOptionLabel={language => language.name}
                value={values.language}
                getOptionSelected={(option, value) => option.value === value.code}
                disableClearable
                onChange={(event, input) => setFieldValue('language', input.code)}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={t('my_lang')}
                    variant="outlined"
                    fullWidth
                    error={!!errors.language}
                    helperText={errors.language}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                style={{ marginBottom: 20 }}
                variant="outlined"
                name="email"
                type="email"
                label={t("email")}
                InputProps={{ autoComplete: 'off' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                style={{ marginBottom: 20 }}
                variant="outlined"
                name="password"
                type="password"
                label={t("password")}
                InputProps={{ autoComplete: 'new-password' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                style={{ marginBottom: 20 }}
                variant="outlined"
                name="confirmpassword"
                type="password"
                label={t("confirm_password")}
                InputProps={{ autoComplete: 'new-password' }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                className={!!errors['newsletter'] ? classes.errorLabel : classes.checkboxControl}
                control={
                  <Checkbox
                    name="newsletter"
                    variant="outlined"
                    onChange={({ currentTarget }) => setFieldValue('newsletter', currentTarget.checked)} />
                }
                label={t("newsletter_check", true)}
              />
            </Grid><Grid item xs={12}>
              <FormControlLabel
                className={!!errors['privacy'] ? classes.errorLabel : classes.checkboxControl}
                control={
                  <Checkbox
                    name="privacy"
                    variant="outlined"
                    onChange={({ currentTarget }) => setFieldValue('privacy', currentTarget.checked)} />
                }
                label={t("privacy_check", true)}
              />
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{errors['privacy']}</p>
            </Grid>
          </Grid>
          <br />
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#0df394', color: '#041e7a' }}
            size='large'
            disabled={isSubmitting}
            onClick={submitForm}
          >
            {t("lets_go")} &gt;
          </Button>
        </Form>
      )}
    </Formik>
  );
}