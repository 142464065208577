import React from "react";
import { v4 as uuidv4 } from "uuid";
import { t, getLanguage } from "../utils/i18n";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Input,
  Button,
  Chip,
  Collapse,
  Box,
} from "@material-ui/core";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@material-ui/icons";

// Context
import { useUser } from "../context/user";

// Services
import skillsService from "../services/skills.service";
import candidatesService from "../services/candidates.service";

// Components
import SkillStars from "./SkillStars";

const filter = createFilterOptions();
const useStyles = makeStyles(() => ({
  root: { padding: "20px 40px 100px" },
  steps: {
    display: "flex",
    margin: "auto",
    width: 200,
    "& > div": {
      flex: 1,
      border: "2px solid #ddd",
      margin: "0px 10px 20px",
      "&.active": { borderColor: "#48f393" },
    },
  },
  skill: {
    marginBottom: 10,
    borderBottom: "1px solid #e2e2e2",
  },
  category: {
    cursor: "pointer",
    position: "relative",
    "& h5": { display: "inline-block" },
    "& svg": { float: "right" },
  },
  max5: {
    margin: "0 35%",
    color: "#fff !important",
    backgroundColor: "red",
    fontWeight: 500,
    borderRadius: 4,
  },
  skillitems: { padding: 10 },
  chip: {
    cursor: "pointer",
    marginBottom: 10,
    "&.selected": {
      background: "#041e7a",
      color: "#fff",
    },
  },
  newSkill: {
    textTransform: "none",
    width: "100%",
    padding: "10px 0",
  },
  input: {
    marginTop: -20,
    marginLeft: 10,
  },
  title: {
    textAlign: "center",
    "& h6": {
      color: "#606060",
      fontSize: "14px",
      padding: 5,
      fontWeight: 600,
    },
    "& h5": { marginBottom: 5 },
  },
  autocomplete: { margin: 10 },
  button: {
    float: "right",
    backgroundColor: "#48f393",
    marginTop: -150,
    color: "#041e7a",
    "&:hover": {
      backgroundColor: "#48f393",
      color: "#fff",
    },
  },
  skillItem: {
    paddingTop: 10,
    borderBottom: "1px solid #ddd",
    "& > div": {
      display: "flex",
      "& h5": {
        fontSize: 18,
        marginRight: 20,
      },
    },
  },
  skillCategory: {
    borderBottom: "1px solid #ddd",
    paddingBottom: 10,
    marginBottom: 0,
    fontWeight: 500,
  },
  addRemark: {
    flex: "auto",
    textAlign: "right",
    "& a": { cursor: "pointer" },
  },
  remark: {
    marginBottom: 20,
    marginTop: 10,
    backgroundColor: "#dfe1e4",
    borderRadius: 17,
    width: "50%",
    "& label": { top: "-6px !important" },
    "& input": {
      padding: 10,
      "&.MuiAutocomplete-input": {
        padding: "2px !important",
      },
    },
    "&:before, &:after": { border: "0 !important" },
    "& fieldset": { border: 0 },
  },
  selectedSkills: {
    textAlign: "center",
    "& h5": { fontSize: 15 },
    "& > div": { fontSize: 12, padding: 5, marginRight: 2 },
  },
}));

const SelectedSkillItem = ({
  skillItem,
  candidateSkill,
  updateCandidateSkills,
  language,
}) => {
  const classes = useStyles();
  const [showRemark, setShowRemark] = React.useState(false);
  const [skill, setSkill] = React.useState(candidateSkill);

  const updateCandidateSkill = (skill) => {
    updateCandidateSkills(skill);
    setSkill(skill);
  };

  return (
    <Box key={skillItem._id} className={classes.skillItem}>
      <Box>
        <Typography variant="h5"> {skillItem.label[language]} </Typography>
        <SkillStars skill={skill} setSkill={setSkill} />
        <div className={classes.addRemark}>
          <a onClick={() => setShowRemark(skillItem._id)}>
            {" "}
            + {t("add_remark")}{" "}
          </a>
        </div>
      </Box>
      {(showRemark || candidateSkill.remark) && (
        <Box>
          <Input
            defaultValue={candidateSkill.remark || ""}
            placeholder={t("remark")}
            onChange={(e) =>
              updateCandidateSkill({ ...skill, remark: e.currentTarget.value })
            }
            className={classes.remark}
            inputProps={{ "aria-label": "remark" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default function CandidateSkillsForm({
  candidate = {},
  skills = [],
  refreshSkills,
  onClose,
}) {
  const classes = useStyles();
  const language = getLanguage();
  const { isRecruiter = () => false } = useUser() || {};
  const [candidateSkills, setCandidateSkills] = React.useState(
    candidate.skills || []
  );
  const [max5, setMax5] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [open, setOpen] = React.useState(false);

  const getOptions = () => {
    return skills.reduce((memo, skill) => {
      memo = [
        ...memo,
        ...skill.skills.map((s) => ({
          label: `${skill.label[language]} : ${s.label[language]}`,
          _id: s._id,
        })),
      ];
      return memo;
    }, []);
  };

  const isOpen = (skillId) => open === skillId;
  const hasSkill = (skillId) =>
    candidateSkills.filter((skill) => skillId === skill.skillId).length;

  const toggleSkill = (skillId) => {
    if (hasSkill(skillId)) {
      if (candidateSkills.length === 1) setStep(1);
      return setCandidateSkills(
        candidateSkills.filter((skill) => skill.skillId !== skillId)
      );
    }
    if (candidateSkills.length === 5) {
      setMax5(true);
      setTimeout(() => setMax5(false), 5000);
      return false;
    }
    setCandidateSkills([...candidateSkills, { skillId, level: 0 }]);
  };

  const getNewSkill = (label) => {
    return {
      _id: uuidv4(),
      label: { NL: label, FR: label, EN: label },
      state: isRecruiter() ? "approved" : "pending",
    };
  };

  const addSkill = async (skillId, label) => {
    const skill = getNewSkill(label);
    await skillsService.addSkill(skillId, skill).then(() => {
      setCandidateSkills([
        ...candidateSkills,
        { skillId: skill._id, level: 0 },
      ]);
      refreshSkills();
    });
  };

  const getFilteredSkills = () => {
    const copy = JSON.parse(JSON.stringify(skills));
    const filtered = copy.map((skill) => {
      skill.skills = skill.skills.filter((x) => {
        return candidateSkills.map((cs) => cs.skillId).includes(x._id);
      });
      return skill;
    });
    return filtered;
  };

  const updateCandidate = () => {
    candidate.skills = candidateSkills;
    if (candidate._id)
      candidatesService.patch(candidate._id, candidate).then(() => onClose());
    else onClose(candidateSkills);
  };

  const writesContent = () =>
    candidate.userfunction &&
    !!["copywriter", "translator", "contentmarketer"].includes(
      candidate.userfunction[0].name
    );

  return (
    <Grid className={classes.root}>
      <div className={classes.steps}>
        <div className="active"></div>
        <div className={step === 2 ? "active" : ""}></div>
      </div>
      <div className={classes.title}>
        <Typography variant="h5">
          {" "}
          {writesContent() ? t("which_writer_skills") : t("which_skills")}{" "}
        </Typography>
        <Typography variant="h6" className={max5 ? classes.max5 : ""}>
          {" "}
          {step === 2
            ? t("skills_stars")
            : writesContent()
            ? t("max_5_writer_skills")
            : t("max_5_skills")}{" "}
        </Typography>
        <Autocomplete
          className={classes.autocomplete}
          onChange={(event, option) => {
            if (!option) return;
            if (typeof option === "string") return addSkill(option._id, option);
            else if (option.inputValue)
              return addSkill(option._id, option.inputValue);
            else if (option._id) toggleSkill(option._id);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            // if (params.inputValue !== '' && (isRecruiter() || candidate._id)) {
            //   filtered.push({
            //     inputValue: params.inputValue,
            //     label: `${t('add_new_skill')} "${params.inputValue}"`,
            //   });
            // }
            return filtered;
          }}
          options={getOptions()}
          getOptionLabel={(option) => {
            if (typeof option === "string") return option;
            // if (option.inputValue) return option.inputValue;
            return option.label;
          }}
          renderOption={(option) => option.label}
          // freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("find_skill")}
              variant="outlined"
              className={classes.remark}
            />
          )}
        />
      </div>
      {!!candidateSkills.length && (
        <div className={classes.selectedSkills}>
          <Typography variant="h5"> {t("selected_skills")} </Typography>
          {getFilteredSkills().map((skill) => {
            return skill.skills.map((item) => (
              <Chip
                key={item._id}
                onClick={() => toggleSkill(item._id)}
                label={`${item.label[language]}  X`}
                className={`${classes.chip} selected`}
              />
            ));
          })}
        </div>
      )}
      {((step === 1 && !!skills) || !candidateSkills.length) && (
        <React.Fragment>
          {!!candidateSkills.length && (
            <Button
              color="primary"
              className={classes.button}
              onClick={() => (!!candidateSkills.length ? setStep(2) : false)}
            >
              {t("next")}
            </Button>
          )}
          {skills.map((skill) => {
            return (
              <Grid key={skill._id} className={classes.skill}>
                <Box
                  onClick={() => setOpen(skill._id)}
                  className={classes.category}
                >
                  <Typography variant="h5" onClick={() => setOpen(skill._id)}>
                    {" "}
                    {skill.label[language]}{" "}
                  </Typography>
                  {isOpen(skill._id) ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </Box>
                <Collapse
                  in={isOpen(skill._id)}
                  timeout={200}
                  unmountOnExit
                  style={{ padding: 10 }}
                >
                  <Grid className={classes.skillitems} open={isOpen(skill._id)}>
                    {skill.skills.map((item) => (
                      <Chip
                        key={item._id}
                        onClick={() => toggleSkill(item._id)}
                        label={item.label[language]}
                        className={`${classes.chip} ${
                          hasSkill(item._id) ? "selected" : ""
                        }`}
                      />
                    ))}
                  </Grid>
                </Collapse>
              </Grid>
            );
          })}
          {!!candidateSkills.length && (
            <Button
              color="primary"
              className={classes.button}
              style={{ marginTop: 30 }}
              onClick={() => (!!candidateSkills.length ? setStep(2) : false)}
            >
              {t("next")}
            </Button>
          )}
        </React.Fragment>
      )}
      {step === 2 && !!candidateSkills.length && (
        <React.Fragment>
          <Button
            color="primary"
            className={classes.button}
            style={{ float: "left" }}
            onClick={() => setStep(1)}
          >
            {t("previous")}
          </Button>
          <Button
            color="primary"
            className={classes.button}
            onClick={() => updateCandidate()}
          >
            {t("add_skills")}
          </Button>
          {getFilteredSkills().map((skill) => {
            if (skill.skills.length) {
              return (
                <Grid key={skill._id} style={{ marginBottom: 40 }}>
                  <Box>
                    <Typography variant="h5" className={classes.skillCategory}>
                      {" "}
                      {skill.label[language]}{" "}
                    </Typography>
                    <Grid>
                      {skill.skills.map((skillItem) => (
                        <SelectedSkillItem
                          key={skillItem._id}
                          candidate={candidate}
                          skillItem={skillItem}
                          candidateSkill={candidateSkills.find(
                            (skill) => skill.skillId === skillItem._id
                          )}
                          updateCandidateSkills={(skill) =>
                            setCandidateSkills(
                              candidateSkills.map((s) => {
                                if (s.skillId === skill.skillId) return skill;
                                return s;
                              })
                            )
                          }
                          language={language}
                        />
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              );
            }
            return null;
          })}
          <Button
            color="primary"
            className={classes.button}
            style={{ float: "left", marginTop: 30 }}
            onClick={() => setStep(1)}
          >
            {t("previous")}
          </Button>
          <Button
            color="primary"
            className={classes.button}
            style={{ marginTop: 30 }}
            onClick={() => updateCandidate()}
          >
            {t("add_skills")}
          </Button>
        </React.Fragment>
      )}
    </Grid>
  );
}
