import React from 'react'
import { t } from '../utils/i18n'

import { Add as AddIcon } from '@material-ui/icons'
import { Typography, Container, CssBaseline, IconButton, Box, Grid } from '@material-ui/core'

// Context
import { useUser } from '../context/user'

// Services
import usersService from '../services/users.service';

// Components
import SpecialisationsList from '../components/SpecialisationsList'
import { SkillsForm } from '../components/SkillsForm'

export const listUserFunctions = async () => usersService.listUserFunctions()
export default function Specialisations() {
  const { isRecruiter } = useUser()
  const [ userFunctions, setUserFunctions ] = React.useState([])
  const [specialisation, setSpecialisation] = React.useState(false)
  const [skillId, setSkillId] = React.useState(false)

  React.useEffect(() => {
    if (! userFunctions.length ) listUserFunctions().then(response => setUserFunctions(response.records))
    // eslint-disable-next-line
  }, [])

  return (
    isRecruiter() &&
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
      	{t('specialisations')}
        <IconButton aria-label={t('add_specialisation')} onClick={() => setSpecialisation({})}>
          <AddIcon />
        </IconButton>
        { specialisation &&
          <Box className="form-wrapper -full">  
            <h2>
              { specialisation && specialisation._id ? t('edit_specialisation_category') : t('new_specialisation_category') }
            </h2>
            <div>
              <SkillsForm onClose={skillId => {
                  setSpecialisation(false)
                  setSkillId(skillId)
                }} 
                userFunctions={userFunctions}
                specialisation={specialisation}
              />
              <IconButton aria-label={t('cancel')} onClick={() => setSpecialisation(false)}> x </IconButton>
            </div>
          </Box> 
        }
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <SpecialisationsList skillId={skillId} userFunctions={userFunctions} setSpecialisation={setSpecialisation}/>   
        </Grid>
      </Grid>
    </Container>
  )
}