import React from 'react'
import { t, getLanguage } from '../../utils/i18n'

import { makeStyles } from '@material-ui/core/styles'
import { Container, Box, CssBaseline, Typography, Link as MuiLink } from '@material-ui/core'

// Pages 
import Home from '../../pages/Home'

const useStyles = makeStyles(() => ({
  root: { 
    maxWidth: 1605,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  logo: {
    width: 90,
    height: 90,
    marginBottom: 30,
    backgroundImage: 'url(/mediaforta-logo.svg)',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat'
  },
  box: {
    width: 800,
    flexDirection: 'column',
    '& > *': { 
      padding: 10,
      margin: '20px 0 0',
    }
  },
  register: { 
    backgroundColor: '#f0f3f5',
    padding: 50,
    marginTop: 40,
    '& img': { height: 220 },
    '& h1': { textAlign: 'left' }
  },
  title: { 
    textAlign:'center',
    marginBottom: 15,
    color: '#17285d'
  },
  link: {
    display: 'block',
    color: '#504fff',
    textAlign: 'left'
  }
}))

export default function CandidateCompleted({ candidate }) {
  const classes = useStyles()
  const language = getLanguage()
  
  if (! candidate) return <Home />

  return (
    <Container component="main" maxWidth={false} className={classes.root}>
      <CssBaseline />
      <Box display="flex" className={classes.box}>
        <Container component="main" maxWidth={false}>
          <Box>
            <Typography className={classes.title} component="h1" variant="h4"> {t('registration_completed_title')} </Typography>
          </Box>
        </Container>
        <Container component="main" maxWidth={false} style={{margin: 0}}>
          <Box>
            <Typography className={classes.title} component="h1" variant="h5" style={{fontSize: 18}}> 
              {t('registration_completed_sub', true)}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box className={classes.register}>
        <Typography className={classes.title} component="h1" variant="h4" style={{marginBottom: 40, textAlign: 'center'}}> {t('read_more')} </Typography>
        <Box display="flex">
          <Container component="main" maxWidth={false}>
            <Box>
              <Typography className={classes.title} component="h1" variant="h5"> {t('vacancies')} </Typography>
              <img alt="vacancies" src="https://blog.mediaforta.com/wp-content/uploads/2021/05/VoorbereidenGooglePageExperienceUpdate-635x423.jpg" />
              <MuiLink className={classes.link} href={`https://www.mediaforta.com/${language.toLowerCase()}/jobs/`} target="_blank"> {t('goto_vacancies')} &gt;</MuiLink>
            </Box>
          </Container>
          <Container component="main" maxWidth={false}>
            <Box>
              <Typography className={classes.title} component="h1" variant="h5"> {t('blogposts')} </Typography>
              <img alt="blog" src="https://blog.mediaforta.com/wp-content/uploads/2021/05/shutterstock_1298785111-635x424.jpg" />
              <MuiLink className={classes.link} href="https://blog.mediaforta.com/" target="_blank"> {t('goto_blogposts')} &gt;</MuiLink>
            </Box>
          </Container>
          <Container component="main" maxWidth={false}>
            <Box>
              <Typography className={classes.title} component="h1" variant="h5"> {t('your_profile')} </Typography>
              <img alt="profile" src="https://blog.mediaforta.com/wp-content/uploads/2021/04/Chatbots-in-B2B-bedrijf-635x424.jpg" />
              <MuiLink className={classes.link} href="/profile"> {t('goto_your_profile')} &gt;</MuiLink>
            </Box>
          </Container>
        </Box>
      </Box>
    </Container>
  )
}