import React from 'react'
import { t } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField, Button } from '@material-ui/core'

// Services 
import CandidatesService from "../services/candidates.service"

const links = ['angellist', 'behance', 'dribbble', 'facebook', 'github', 'linkedin', 'twitter']
const useStyles = makeStyles(() => ({
  root: {
    padding: '0 30px',
    '& .MuiTextField-root': {
      width: '100%',
      marginBottom: 10
    }
  },
  button: {
    textAlign: 'right',
    marginTop: 20
  }
}))

export default function CandidateLinkForm({ data, onClose }) {
  const classes = useStyles()
  const [ name, setName ] = React.useState('')
  const [ url, setUrl ] = React.useState('')
  const [ errors, setErrors ] = React.useState({})

  const validate = () => {
    const errors = {}
    if (! name) errors.name = t('required')
    if (! url) errors.url = t('required')
    setErrors(errors)
    return errors
  }

  const addCandidateLink = () => {
    const isValid = ! Object.keys(validate()).length
    if (! isValid) return false

    const candidate = data
    candidate.links = candidate.links || {}
    candidate.links[name] = url
    CandidatesService.patch(candidate._id, candidate).then(onClose) 
  }

  return (
    <form className={classes.root}>
      <Grid>
        <Grid item xs={12}>
          <Autocomplete
            freeSolo
            options={links}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label={t("name")} 
                error={!! errors.name} 
                helperText={errors.name} 
                onBlur={event => setName(event.currentTarget.value)} 
                margin="normal" 
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            name='url' 
            type="text" 
            label="Url"
            error={!! errors.url}
            helperText={errors.url}
            defaultValue={url}
            onChange={event => setUrl(event.currentTarget.value)}  
          />
        </Grid>
      </Grid>
      <Grid className={classes.button}>
        <Grid item xs={12}>
          <Button onClick={() => addCandidateLink()} color="primary"> {t('add_link')} </Button>
        </Grid>
      </Grid>
    </form>
  )
}