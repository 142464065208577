import React from 'react';
import config from '../config';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Services
import AuthService from "../services/auth.service";

const { API_URL } = config.mediaforta;

const useStyles = makeStyles(() => ({
  avatar: { width: 110, height: 110 }
}));

export default function AuthenticatedAvatar({ candidate, avatarId, classes = {} }) {
  const styles = useStyles();
  classes = { ...styles, ...classes };
  const user = AuthService.getCurrentUser() || {};
  const [avatar, setAvatar] = React.useState(false);

  React.useEffect(() => {
    if (avatarId || (candidate)) { handleAction(); }
    // eslint-disable-next-line
  }, [avatarId, candidate]);

  const handleAction = async () => {
    if (!candidate.avatar) return setAvatar(false);
    const result = await fetch(`${API_URL}candidates/${candidate._id}/file/${candidate.avatar}`, {
      headers: {
        Authorization: "Bearer " + user.token,
        Prefer: 'count=exact'
      }
    });

    if (result.status !== 200) return;
    const blob = await result.blob();
    setAvatar(window.URL.createObjectURL(blob));
  };

  return (
    <>
      {/* eslint-disable-next-line*/}
      <Avatar src={avatar ? avatar : ''} className={classes.avatar}> U </Avatar>
    </>
  );
}