import React from 'react'
import { t } from '../utils/i18n'

import { Add as AddIcon } from '@material-ui/icons'
import { Typography, Container, CssBaseline, IconButton, Box, Grid } from '@material-ui/core'

// Context
import { useUser } from '../context/user'

// Components
import RecruitersList from '../components/RecruitersList';

// Services
import usersService from '../services/users.service'
import CandidatesList from '../components/CandidatesList';

export const fetchRecruiters = async () => usersService.listRecruiters();
export const patchRole = async patch => usersService.patchRole(patch);

export default function Recruiters() {
  const { isRecruiter } = useUser()
  const [ recruiter, setRecruiter ] = React.useState(false)
  const [ recruiters, setRecruiters ] = React.useState([])
  const [ refresh, setRefresh ] = React.useState(false)
  
  React.useEffect(() => {
    if (! recruiters.length || refresh) fetchRecruiters().then(({recruiters}) => {
      setRecruiters(recruiters);
      if(refresh) setRefresh(false)
    })
    // eslint-disable-next-line
  }, [refresh])
  
  const addRecruiterRole = ({userId}) => {
    if (userId) {
      patchRole({userId, role: 'recruiter'}).then(() => {
        setRefresh(true);
        setRecruiter(false);
      })
    }
  };

  return (
    isRecruiter() &&
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
      	{t('Recruiters')}
        <IconButton aria-label={t('add_recruiter')} onClick={() => setRecruiter({})}>
          <AddIcon />
        </IconButton>
        { recruiter &&
          <Box className="form-wrapper -full">  
            <h2> { t('new_recruiter') } </h2>
            <div>
              <CandidatesList minimal={true} onClick={addRecruiterRole}/>
              <IconButton aria-label={t('cancel')} onClick={() => setRecruiter(false)}> x </IconButton>
            </div>
          </Box> 
        }
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <RecruitersList recruiters={recruiters} onRemove={() => setRefresh(true)} />
        </Grid>
      </Grid>
    </Container>
  )
}