import React from 'react'
import { t } from '../utils/i18n'
import { debounce } from 'lodash'

import { Add as AddIcon } from '@material-ui/icons'
import { Typography, Container, CssBaseline, IconButton, Box, Grid, TablePagination } from '@material-ui/core'

// Context
import { useUser } from '../context/user'

// Components
import MediumsList from '../components/MediumsList';

// Services
import JobsService from '../services/jobs.service';
import MediumForm from '../components/MediumForm';
import SearchFilter from "../components/SearchFilter";

export const fetchMediums = async (search, rowsPerPage, page) => JobsService.listMediums(search, rowsPerPage, page);
export const patchMedium = async (mediumId, patch) => JobsService.patchMedium(mediumId, patch);

export default function Mediums() {
  const { isRecruiter } = useUser()
  const [ medium, setMedium ] = React.useState(false)
  const [ mediums, setMediums ] = React.useState([])
  const [ refresh, setRefresh ] = React.useState(false)
  const [ totalCount, setTotalCount ] = React.useState(0)
  const [ searchInput, setSearchInput ] = React.useState(false);
  const search = event => debounceSearch(event.target.value)
  const debounceSearch = debounce(q => setSearchInput(q), 300);
  const [ page, setPage ] = React.useState(0);
  const [ rowsPerPage, setRowsPerPage ] = React.useState(10);
  
  const handleChangePage = (event, newPage) => setPage(parseInt(newPage))
  const handleChangeRowsPerPage = (event) => {
    const rows = parseInt(event.target.value, 10)
    setRowsPerPage(rows)
    setPage(0)
  }


  React.useEffect(() => {
    if (! mediums.length || refresh || searchInput || rowsPerPage || page) fetchMediums(searchInput, rowsPerPage, page).then(({records, totalCount}) => {
      setMediums(records);
      setTotalCount(totalCount)
      if(refresh) setRefresh(false)
    })
    // eslint-disable-next-line
  }, [refresh, searchInput, rowsPerPage, page])
  
  return (
    isRecruiter() &&
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
      	{t('Mediums')}
        <IconButton aria-label={t('add_medium')} onClick={() => setMedium({})}>
          <AddIcon />
        </IconButton>
        { medium &&
          <Box className="form-wrapper -full">  
            <h2> { t('new medium') } </h2>
            <div>
              <MediumForm edit={true} onClose={() => {
                setRefresh(true);
                setMedium(false);
              }} onUpdate={(medium, cb) => patchMedium(medium._id, medium)}/>
              <IconButton aria-label={t('cancel')} onClick={() => setMedium(false)}> x </IconButton>
            </div>
          </Box> 
        }
      </Typography>
      <Grid container>
        <Grid item xs={12}>
        <SearchFilter 
          onChange={search} 
          value={searchInput} 
          placeholder={t("search mediums")}
        />
          <MediumsList mediums={mediums} setRefresh={() => setRefresh(true)} onRemove={() => setRefresh(true)} />
        { !! mediums.length &&
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
        </Grid>
      </Grid>
    </Container>
  )
}