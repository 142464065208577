import * as React from 'react'
import { t } from '../utils/i18n'
import moment from 'moment'
import { debounce } from 'lodash'
import { Paper, Typography, Container, CssBaseline } from '@material-ui/core'
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler'
import {
  Scheduler,
  WeekView,
  MonthView,
  DayView,
  Appointments,
  AppointmentForm,
  Toolbar,
  DateNavigator,
  TodayButton,
  ViewSwitcher,
  AllDayPanel,
  AppointmentTooltip
} from '@devexpress/dx-react-scheduler-material-ui'

// Context 
import { useApp } from "../context/app"

// Services
import CalendarService from "../services/calendar.service"
import CandidatesService from "../services/candidates.service"

// App components
import CalendarLayouts from "../components/CalendarLayouts"

// assets
import '../assets/scss/scheduler.scss'

let appointment = {}
const fetchCandidates = async search => CandidatesService.list(search, 4, 0, '_id, firstname, name')
export default function Agenda(props) {
  const { candidateId } = props.match.params
  const { setDialog, setResponseDialog } = useApp()
  const [ candidates, setCandidates ] = React.useState(false)

  const [ currentDate, setCurrentDate ] = React.useState(moment().format('YYYY-MM-DD'))
  const [ currentViewName, setCurrentViewName ] = React.useState('week')
  const [ schedulerData, setSchedulerData ] = React.useState(false)
  const [ formVisible, setFormVisible ] = React.useState(false)
  const [ tooltipVisible, setTooltipVisible ] = React.useState(false)
  
  const makeQueryString = () => {
    const format = 'YYYY-MM-DDTHH:mm:ss'
    const start = moment(currentDate).startOf(currentViewName.toLowerCase())
    const end = start.clone().endOf(currentViewName.toLowerCase())
    return { start: start.format(format), end: end.format(format) }
  };

  const fetchData = (mounted = true) => {
    return CalendarService.list(makeQueryString(), candidateId)
      .then(data => {
        if (mounted) {
          setSchedulerData(data.map(item => {
            item.startDate = new Date(item.startDate)
            item.endDate = new Date(item.endDate)
            return item
          }))
        }
      })
  }
  
  React.useEffect(() => {
    let mounted = true
    fetchData(mounted)
    return function cleanup() {
      mounted = false
    } 
    // eslint-disable-next-line
  }, [currentDate, currentViewName])

  const setError = () => {
    return setDialog({
      error: true,
      title: t('missing_field'),
      message: t('field_required', false, {field: 'Title'})
    })
  }

  React.useEffect(() => { 
    let mounted = true
    if (! candidates) {
      fetchCandidates()
        .then(data => {
          if (mounted) {
            const list = data.records.map(r => { return { _id: r._id, name: `${r.firstname} ${r.name}` }})
            return setCandidates(list)
          }
        })
    }
    return function cleanup() {
      mounted = false
    } 
    // eslint-disable-next-line
  }, [])

  const setAppointment = debounce(fields => {
    appointment = {...appointment, ...fields}
  }, 500);

  const deleteAppointment = appointmentId => {
    setTooltipVisible(false)
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('confirm_remove_calendar_item'),
      onConfirm: () => {
        CalendarService.delete(appointmentId)
          .then(fetchData)
      }
    })
  } 

  const refresh = () => {
    appointment = {}
    setFormVisible(false)
    fetchData()
  }

  const onSave = () => {
    if (! appointment.title) return setError()
    if (appointment.assignedTo) appointment.assignedTo = appointment.assignedTo._id

    if (! appointment._id) {
      CalendarService.post(appointment)
      .then(() => {
        setResponseDialog({
          title: t('great_success'),
          message: t('calendar_item_added'),
          onClose: refresh
        })
      })
    } else {
      CalendarService.patch(appointment._id, appointment)
      .then(() => {
        setResponseDialog({
          title: t('great_success'),
          message: t('calendar_item_updated'),
          onClose: refresh
        })
      })
    }
  }

  return (
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
        Agenda
      </Typography>
      <Paper className={`Scheduler ${currentViewName}`}>
        <Scheduler
          data={schedulerData || []}
          locale="nl-BE"
        >
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={setCurrentDate}
            currentViewName={currentViewName}
            onCurrentViewNameChange={setCurrentViewName} />
          <EditingState 
            onEditingAppointmentChange={setAppointment}
            onAddedAppointmentChange={setAppointment}
            onAppointmentChangesChange={setAppointment}
          />
          <WeekView
            name="week"
            startDayHour={9}
            endDayHour={24} />
          <MonthView name="month"/>
          <DayView name="day"/>
          <Toolbar />
          <ViewSwitcher />
          <DateNavigator />
          <TodayButton />
          <Appointments />
          <AllDayPanel />
          <AppointmentTooltip
            showOpenButton
            showDeleteButton
            visible={tooltipVisible}
            onVisibilityChange={setTooltipVisible}
            layoutComponent={props => <CalendarLayouts.Tooltip onDeleteAppointment={deleteAppointment} {...props}/>}
          />
          <AppointmentForm 
            visible={formVisible} 
            basicLayoutComponent={props => <CalendarLayouts.Form candidates={candidates} assignedTo={candidateId} {...props}/>}
            commandLayoutComponent={props => <CalendarLayouts.Command onSave={onSave} {...props}/>}
            onVisibilityChange={setFormVisible} 
          />
        </Scheduler>
      </Paper>  
    </Container>
  )
}