// Services
import ApiService from "../services/api.service"

const model = 'jobs';
const applicantLevelsModel = 'applicantlevels';
const employmentTypesModel = 'employmenttypes';
const mediumsModel = 'mediums';
const campaignsModel = 'campaigns';
const fileModel = 'files';


export default class JobsService {
  static list(search = false, limit = 10, skip = 0, fields = '', filter = false) {
    if (search && search !== '') search = `{"$or":[{"title": "regex:${search}"},{"client":"regex:${search}"}]}`  
    return ApiService.list(model, { search, filter, limit, skip, fields}).then(response => response.data)
  }

  static get(jobId) {
    return ApiService.get(`${model}/${jobId}`).then(response => response.data)
  }
  
  static post(data = {}) {
    return ApiService.post(model, data).then(response => response)
  }
  
  static patch(jobId, patch = {}) {
    return ApiService.patch(`${model}/${jobId}`, patch).then(response => response) 
  }
  
  static delete(jobId) {
    return ApiService.delete(`${model}/${jobId}`).then(response => response) 
  }

  static listApplicantLevels() {
    return ApiService.get(applicantLevelsModel).then(response => response.data)
  }
  
  static listEmploymentTypes() {
    return ApiService.get(employmentTypesModel).then(response => response.data)
  }

  static apply(data = {}) {
    return ApiService.post(`${model}/apply`, data).then(response => response.data)
  }

  static getMedium(mediumId) {
    return ApiService.get(`${mediumsModel}/${mediumId}`).then(response => response.data)
  }
  
  static listMediums(search = false, limit = 10, skip = 0, fields = '', filter = false) {
    if (search && search !== '') search = `{"$or":[{"name": "regex:${search}"},{"client":"regex:${search}"}]}`  
    return ApiService.list(mediumsModel, { search, filter, limit, skip, fields, sort: '-createdAt'}).then(response => response.data)
  }

  static postMedium(data = {}) {
    return ApiService.post(mediumsModel, data).then(response => response)
  }
  
  static patchMedium(mediumId, patch = {}) {
    return ApiService.patch(`${mediumsModel}/${mediumId}`, patch).then(response => response) 
  }
  
  static deleteMedium(mediumId) {
    return ApiService.delete(`${mediumsModel}/${mediumId}`).then(response => response) 
  }
  
  static upload(mediumId, files = [], avatar = false) {
    if (! files.length) return
    
    let formData = new FormData();
    if (files.length > 1) files.forEach(file => formData.append('files', file))
    else formData.append('file', files[0])

    formData.append('mediumId', mediumId)

    const path = avatar ? 'avatar' : files.length > 1 ? 'files' : 'file'
    return ApiService.post(`${mediumsModel}/${mediumId}/${path}`, formData, { 'Content-Type': 'multipart/form-data' }).then(response => response)
  }

  static files(mediumId) { 
    return ApiService.get(fileModel, `filter={"mediumId":"${mediumId}","hidden":false}`).then(response => response.data) 
  }

  static getCampaignJob(campaigmId, jobId) {
    return ApiService.get(`${campaignsModel}/apply/${campaigmId}/job/${jobId}`).then(response => response.data)
  }
  
  static listCampaigns(mediumId, search = false, limit = 10, skip = 0, fields = '', filter = false) {
    if (search && search !== '') search = `{"$or":[{"title": "regex:${search}"},{"client":"regex:${search}"}]}`  
    return ApiService.list(`${mediumsModel}/${mediumId}/campaigns`, { search, filter, limit, skip, fields}).then(response => response.data)
  }

  static listCampaignJobs(campaignId, search = false, limit = 10, skip = 0, fields = '', filter = false) {
    if (search && search !== '') search = `{"$or":[{"title": "regex:${search}"},{"client":"regex:${search}"}]}`  
    return ApiService.list(`${campaignsModel}/${campaignId}/jobs`, { search, filter, limit, skip, fields}).then(response => response.data)
  }
  
  static postCampaign(data = {}) {
    return ApiService.post(campaignsModel, data).then(response => response)
  }
  
  static patchCampaign(campaignId, patch = {}) {
    return ApiService.patch(`${campaignsModel}/${campaignId}`, patch).then(response => response) 
  }
  
  static deleteCampaign(campaignId) {
    return ApiService.delete(`${campaignsModel}/${campaignId}`).then(response => response) 
  }

}