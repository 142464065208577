import axios from "axios"
import moment from "moment"
import i18n from "i18next"
import config from '../config'

const { AUTH_URL, USER_URL } = config.mediaforta 


export default class AuthService {
  static getToken() {
    const user = AuthService.getCurrentUser()
    return user ? user.token : false
  }

  static setUserStorage({token, user}, cb) {
    if (! token || ! user) return false
    const { password, ...userData }  = user
    const data = {
      user: userData,
      token,
      expiry: moment().add(user.tokenExpiresMinutes, 'minutes').toDate()
    }
    localStorage.setItem("mediaforta-user", JSON.stringify(data))
    if (cb) cb(data)
    return data
  }

  static login({email, password}) {
    return axios.post(AUTH_URL + "login", {
        email,
        password
      }).then(({ data: {token, user } }) => {
        if (user && user.twofactorEnabled) {
          return {token, user}
        } else {
          return AuthService.setUserStorage({token, user})
        }
      })
  }

  static verify2FA({auth, code, time}) {
    return axios.post(USER_URL + "verify2fa", {
        userId: auth.user._id,
        code, 
        time
      }).then((response) => {
        if (!! response.status && response.status === 200) {
          return AuthService.setUserStorage({token: auth.token, user: auth.user})
        }
      })
  }

  static removeJobId() {
    const currentUser = AuthService.getCurrentUser()
    if (currentUser) {
      const { user: {jobId, ...data} } = currentUser
      currentUser.user = data
      localStorage.setItem("mediaforta-user", JSON.stringify(currentUser))
      return true
    }
    return false
  }

  static updateUserLanguage(language) {
    const currentUser = AuthService.getCurrentUser()
    if (currentUser) {
      currentUser.user.language = language
      localStorage.setItem("mediaforta-user", JSON.stringify(currentUser))
      
      const userLang = ['nl', 'fr', 'en'].includes(language.toLowerCase()) ? language : 'en'
      if (userLang !== i18n.language.toLowerCase()) i18n.changeLanguage(userLang.toUpperCase())
      
      return true
    }
    return false
  }

  static logout() { 
    return localStorage.removeItem("mediaforta-user") 
  }

  static getCurrentUser() {
    const item = JSON.parse(localStorage.getItem('mediaforta-user'))
    if (! item) return false
    if (moment(item.expiry).isBefore(moment())) return this.logout()
    return JSON.parse(localStorage.getItem('mediaforta-user')) || false
  }
}
