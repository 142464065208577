import React from 'react'
import { t } from '../utils/i18n'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, TextField, Button, Slider, Typography } from '@material-ui/core'

// Services
import ScoresService from "../services/tests.service"

const useStyles = makeStyles(theme => ({
  root: {
    textAlign:'left',
    padding: '0 30px',
    '& .MuiTextField-root': {
      width: '100%',
      marginBottom: 10
    },
    '& .MuiSlider-valueLabel > span': {
      background: 'transparent',
      marginTop: 10,
      '& > span': {
        color: theme.palette.mediaforta.main,
        fontWeight: 500,
        fontSize: 15
      }
    },
    '& .MuiOutlinedInput-multiline': {
      backgroundColor: '#fff'
    }
  },
  label: {
    marginBottom: 20,
    marginLeft: -5
  },
  button: {
    textAlign: 'right',
    marginTop: 20
  },
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  }
}))

const marks = [
  { value: .5 }, { value: 1 }, { value: 1.5 },
  { value: 2, label: '2 / 10' },
  { value: 2.5 }, { value: 3 }, { value: 3.5 },
  { value: 4, label: '4 / 10' },
  { value: 4.5 }, { value: 5 }, { value: 5.5 },
  { value: 6, label: '6 / 10' },
  { value: 6.5 }, { value: 7 }, { value: 7.5 },
  { value: 8, label: '8 / 10' },
  { value: 8.5 }, { value: 9 }, { value: 9.5 },
];

export default function TestScoreForm({ data, score: testScore = {}, index = 0, onClose }) {
  const classes = useStyles()
  const [ score, setScore ] = React.useState(testScore.score || 0)
  const [ remark, setRemark ] = React.useState(testScore.remark || '')
  const [ errors, setErrors ] = React.useState({})

  const validate = () => {
    const errors = {}
    if (! remark) errors.remark = t('remark_required')
    setErrors(errors)
    return errors
  }

  const addTestScore = () => {
    const isValid = ! Object.keys(validate()).length
    if (! isValid) { return false }    
    data.scores = data.scores || []
    data.scores.push({score, remark})
    ScoresService.patch(data._id, data).then(onClose) 
  }

  const editTestScore = () => {
    const isValid = ! Object.keys(validate()).length
    if (! isValid) { return false }        
    data.scores[index] = {score, remark}
    ScoresService.patch(data._id, data).then(onClose) 
  }
  
  return (
    <form className={classes.root}>
       <Grid container style={{marginBottom: 40}}>
          <Grid item xs>
            <Typography id="score-slider" className={classes.label}>Score</Typography>
            <Slider
              value={typeof score === 'number' ? score : 0}
              onChange={(event, value) => { setScore(value) }}
              aria-labelledby="score-slider"
              marks={marks}
              min={0}
              max={10}
              step={.5}
              valueLabelDisplay="on"
            />
          </Grid>
        </Grid>
      <Grid container spacing={2}>
        <Grid item xs style={{padding: 0}}>
          <TextField 
            name='remark' 
            type="text" 
            label={t('remark')}
            multiline
            rows={5}
            variant="outlined"
            error={errors.remark}
            helperText={errors.remark}
            defaultValue={remark}
            onChange={event => setRemark(event.currentTarget.value)}  
          />
        </Grid>
      </Grid>
      <Grid className={classes.button}>
        <Grid item xs={12}>
          { testScore && testScore.score ?
            <Button onClick={() => editTestScore()} color="primary"> {t('edit_score')} </Button>
            :
            <Button onClick={() => addTestScore()} color="primary"> {t('add_score')} </Button>
          }
        </Grid>
      </Grid>
    </form>
  )
}