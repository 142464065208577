import React from 'react'
import { t } from '../utils/i18n'
import { IconButton, Box } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

// Model components
import TasksList from './TasksList'
import TaskForm from './TaskForm'

// assets
import '../assets/scss/form-wrapper.scss'

const filters = [
  { label: t('all'), value: ''},
  { label: t('unfinished'), value: `completed!=true` },
  { label: t('finished'), value: 'completed=true' },
]

export default function CandidateTasks({candidate = {}, className}) {
  const [task, setTask] = React.useState(false)

  return ( 
   	<div className={className}>
      <IconButton aria-label="add task" onClick={() => setTask({})}> <AddIcon /> {t('new_task')} </IconButton>
      { task &&
        <Box className="form-wrapper">  
          <h2> { task._id ? t('edit task'):  t('new_task') } </h2>
          <div>
            <TaskForm candidate={candidate} onClose={() => setTask(false)} task={task}/>
            <IconButton aria-label={t("cancel")} onClick={() => setTask(false)}> x </IconButton>
          </div>
        </Box> 
      }
      {
        candidate && candidate._id &&
        <TasksList fullwidth filters={filters} candidate={candidate} task={task} selectTask={task => setTask(task) }/>
      }
    </div>
  )
}