import ApiService from "../services/api.service"

const model = 'interviews'
const templateModel = 'interviewtemplates'

export default class InterviewsService {
  static get(interviewId) {
    return ApiService.get(`${model}/${interviewId}`).then(response => response.data)
  }
  
  static post(data = {}) {
    return ApiService.post(model, data).then(response => response)
  }
  
  static patch(interviewId, patch = {}) {
    return ApiService.patch(`${model}/${interviewId}`, patch).then(response => response) 
  }
  
  static delete(interviewId) {
    return ApiService.delete(`${model}/${interviewId}`).then(response => response) 
  }
  
  static templates() {
    return ApiService.get(`${model}/templates`).then(response => response.data)
  }

  static postTemplate(data = {}) {
    return ApiService.post(templateModel, data).then(response => response)
  }

  static deleteTemplate(templateId) {
    return ApiService.delete(`${templateModel}/${templateId}`).then(response => response)
  }

  static addQuestion(templateId, data = {}) {
    return ApiService.patch(`${templateModel}/${templateId}/question`, data).then(response => response)
  }

  static patchTemplate(templateId, data = {}) {
    return ApiService.patch(`${templateModel}/${templateId}`, data).then(response => response)
  }
}