import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, IconButton } from '@material-ui/core'
import { ThumbDownAlt as ThumbDownAltIcon, ThumbUpAlt as ThumbUpAltIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    '& > button': {
      '&.selected': { cursor: 'default' },
      '&.selected': { backgroundColor: theme.palette.error.main },
      '&:hover:not(.selected)': { backgroundColor: '#d9def2' },
      height: 50,
      width: 50,
      marginRight: 15,
      position: 'relative',
      backgroundColor: theme.palette.mediaforta.disabled,
      color: '#fff',
      '& > span': {
        width: 22,
        display: 'inline-block',
        '& svg': {
          transform: 'scaleX(-1)',
          fontSize: 22,
          float: 'left',
          position: 'relative',
          '&:nth-child(2)': {
            position: 'absolute',
            left: 17
          }
        }
      },
      '&.success': {
        '&.selected': { backgroundColor: theme.palette.success.main },
        '& svg': { transform: 'none' }
      }
    }
  },
  small: {
    '& > button': {
      height: 40,
      width: 40,
      '& > span svg:nth-child(2)': { left: 12 }
    }
  }
}))

export default function NoteScores({ setFieldValue, values, note, size }) {
  const classes = useStyles();
  const className = size ? `${classes.root} ${classes[size]}` : classes.root;

  const setScore = score => {
    if (values.score === score) {
      return setFieldValue('score', -1);
    }
    return setFieldValue('score', score);
  }

  const renderScores = () => {
    switch(note && note.score) {
      case -1:
        return (
          <Box margin={1} className={className}></Box>
        )
      case 0:
        return (
          <Box margin={1} className={className}>
            <IconButton className="selected">
              <ThumbDownAltIcon style={{left: -3}}/>
              <ThumbDownAltIcon />
            </IconButton>
          </Box>
        )
      case 1:
        return (
          <Box margin={1} className={className}>
            <IconButton className="selected">
              <ThumbDownAltIcon />
            </IconButton>
          </Box>
        )
      case 2:
        return (
          <Box margin={1} className={className}>
            <IconButton className="success selected">
              <ThumbUpAltIcon />
            </IconButton>
          </Box>
        )
      case 3:
        return (
          <Box margin={1} className={className}>
            <IconButton className="success selected">
              <ThumbUpAltIcon style={{left: -3}}/>
              <ThumbUpAltIcon />
            </IconButton>
          </Box>
        )
      default:
        return (
          <Box margin={1} className={className}>
            <IconButton onClick={() => setScore(3)} className={`success ${values.score === 3 ? 'selected' : ''}`}>
              <ThumbUpAltIcon style={{left: -3}}/>
              <ThumbUpAltIcon />
            </IconButton>
            <IconButton onClick={() => setScore(2)} className={`success ${values.score === 2 ? 'selected' : ''}`}>
              <ThumbUpAltIcon />
            </IconButton>
            <IconButton onClick={() => setScore(1)} className={`${values.score === 1 ? 'selected' : ''}`}>
              <ThumbDownAltIcon />
            </IconButton>
            <IconButton onClick={() => setScore(0)} className={`${values.score === 0 ? 'selected' : ''}`}>
              <ThumbDownAltIcon style={{left: -3}}/>
              <ThumbDownAltIcon />
            </IconButton>
          </Box>
        )
      }
  }

  return ( 
    <Box>
      { renderScores }
    </Box> 
  )
}