import React from 'react';
import { t } from '../utils/i18n';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, IconButton } from '@material-ui/core';

// Candidate components
import CandidateNotes from "./CandidateNotes";
import CandidateInterviews from "./CandidateInterviews";
import CandidateTests from "./CandidateTests";
import CandidateEmails from "./CandidateEmails";
import CandidateTasks from "./CandidateTasks";
import CandidateScoreCards from "./CandidateScoreCards";

// App components
import TabList from "./TabList";

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative'
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 5,
    zIndex: 9,
    fontSize: 20,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

const tabItems = [
  { primary: t('interview'), id: 'interview', component: CandidateInterviews, selected: true },
  { primary: t('scorecards'), id: 'scorecards', component: CandidateScoreCards },
  { primary: t('notes'), id: 'notes', component: CandidateNotes },
  { primary: t('files'), id: 'files', component: CandidateTests },
  { primary: t('mail'), id: 'email', component: CandidateEmails },
  { primary: t('tasks'), id: 'tasks', component: CandidateTasks }
];

export default function CandidateCard({
  candidate = {},
  card = false,
  hideTabs,
  onClose,
  onChange,
  refresh,
  className,
  onUpdate,
  edit = false
}) {
  const classes = useStyles();
  const selectedItem = tabItems.find(i => i.selected);
  const [selected, setSelected] = React.useState(card ? card : selectedItem || false);

  return (
    <Card>
      <CardContent className={classes.content}>
        {!!onClose && <IconButton aria-label="close" className={classes.close} onClick={() => onClose()}> x </IconButton>}
        {!hideTabs && <TabList items={tabItems} onClick={item => setSelected(item)} />}
        {selected && selected.component &&
          React.createElement(selected.component, {
            key: selected.id,
            candidate: candidate,
            edit,
            className: className,
            onChange: onChange,
            refresh: refresh,
            onUpdate: onUpdate
          })
        }
      </CardContent>
    </Card>
  );
}