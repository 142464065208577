import React from 'react'
import { t } from '../utils/i18n'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TablePagination,
  Box,
  ThemeProvider,
  IconButton,
  Button,
  Typography
} from '@material-ui/core'
import { 
  Check as CheckIcon, 
  Close as CloseIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon 
} from '@material-ui/icons'

// Context
import { useApp } from "../context/app"

// Services
import JobApplicationsService from "../services/jobapplications.service"
import JobsService from "../services/jobs.service"
import CandidatesService from "../services/candidates.service"

// Components
import JobApplicationCard from "./JobApplicationCard"
import CandidateCard from "./CandidateCard"
import CandidateDetailsOverview from "./CandidateDetailsOverview"
import JobApplicationMail from './JobApplicationMail'
import Dropdown from "./Dropdown"

// themes
import tableThema from '../themes/table'

const useStyles = makeStyles((theme) => ({
  row: {
  	cursor: 'pointer',
  	'& th label, & td label': { cursor: 'pointer' }
  },
  rejected: { color: 'red !important' },
  successful: { color: '#43e68e !important' },
  actions: {
    width: 110,
    '& .Mui-disabled': { 
      opacity: .5, 
      pointerEvents: 'all'
    }
  },
  button: { marginRight: 10 }
}))

const filters = [
  { label: t('all'), value: ''},
  { label: t('pending'), value: 'status=pending' },
  { label: t('rejected'), value: 'status=rejected' },
  { label: t('successful'), value: 'status=successful' }
]

export const fetchJobApplications = async (search, limit, offset, filter, candidateId) => JobApplicationsService.list(search, limit, offset, '', filter, candidateId)
export const getJob = async jobId => JobsService.get(jobId)
export default function JobApplicationList({candidate = {}, jobId, hideFilter}) {
  const classes = useStyles()
  const { error, setResponseDialog, languages } = useApp()
  const [ jobApplications, setJobApplications ] = React.useState({})
  const [ filter, setFilter ] = React.useState('')
  const [ page, setPage ] = React.useState(0)
  const [ rowsPerPage, setRowsPerPage ] = React.useState(10)
  const [ jobApplication, setJobApplication ] = React.useState(false)
  const [ job, setJob ] = React.useState(false)
  const [ selectedCandidate, setSelectedCandidate ] = React.useState(false)
  const [ selectedApplications, setSelectedApplications ] = React.useState([])
  const [ emailList, setEmailList ] = React.useState([])
  const [ refresh, setRefresh ] = React.useState(0)
  
  const getLanguage = languageId => {
    const language = languages.find(lang => lang._id === languageId)
    return language ? t(language.label) : ''
  }
  const setError = error => setResponseDialog({error})

  React.useEffect(() => {
    setJobApplications(false)
    if (! error && ! candidate._id) fetchJobApplications(
      false, 
      rowsPerPage, 
      page*rowsPerPage, 
      jobId ? `jobId=${jobId}&${filter}` : filter
    ).then(setJobApplications).catch(setError)
  // eslint-disable-next-line
  }, [ rowsPerPage, page, filter, refresh ]) 

  React.useEffect(() => {
    if (! candidate._id) return
    setJobApplications(false)
    if (! error) fetchJobApplications(
      false, 
      rowsPerPage, 
      page*rowsPerPage, 
      jobId ? `jobId=${jobId}&${filter}` : filter, 
      candidate._id
    ).then(setJobApplications).catch(setError)
  // eslint-disable-next-line
  }, [ candidate, refresh ]) 


  React.useEffect(() => {
    if (jobApplication && (! job || job._id !== jobApplication.job[0]._id)) getJob(jobApplication.job[0]._id).then(setJob).catch(setError)
  // eslint-disable-next-line
  }, [ jobApplication ]) 

	const handleChangePage = (event, newPage) => setPage(parseInt(newPage))
	const handleChangeRowsPerPage = (event) => {
		const rows = parseInt(event.target.value, 10)
		setRowsPerPage(rows)
    setPage(0)
  }

  const setCandidate = candidateId => CandidatesService.get(candidateId).then(setSelectedCandidate)
  
  const toggleApplication = (event, applicationId) => {
    event.stopPropagation()
    if (selectedApplications.includes(applicationId)) setSelectedApplications(selectedApplications.filter(id => id !== applicationId))  
    else setSelectedApplications([...selectedApplications, applicationId])
  }

  const approve = (event, jobApplication = false) => {
    event.stopPropagation()
    JobApplicationsService.approve(jobApplication ? [jobApplication._id] : selectedApplications).then(() => setRefresh(refresh + 1))
  }

  const setDeclineList = (event, jobApplication) => {
    event.stopPropagation()
    setEmailList(jobApplication ? [jobApplication._id] : selectedApplications)
  }

  const decline = mail => {
    JobApplicationsService.decline(emailList, mail).then(() => {
      setRefresh(refresh + 1)
      setEmailList([])
      setSelectedApplications([])
    })
  }

  const removeApplication = (event, jobApplication) => {
    event.stopPropagation()
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('remove_job_application'),
      onConfirm: () => {
        JobApplicationsService.delete(jobApplication._id).then(() => {
          setRefresh(refresh + 1)
        })
      }
    })
  }

  return ( 
  	<React.Fragment>
      <Box display="flex">
        <div style={{flex: 2}}>
          {! hideFilter && 
            <Dropdown 
              label={'Filter'} 
              value={filter} 
              items={filters} 
              onChange={filter => {
                setJobApplication(false)
                setJob(false)
                setFilter(filter)
              }}/>
          }
            { !! selectedApplications.length &&
              <>
                <Button className={classes.button} variant="contained" color="primary" onClick={approve}> {t('approve')} </Button>
                <Button variant="contained" color="secondary" onClick={setDeclineList}> {t('decline')} </Button>
              </>
            }
            {
              jobApplications && jobApplications.records && !! jobApplications.records.length ?
              <ThemeProvider theme={ tableThema }>
                <TableContainer className={classes.tablecontainer}>
      		    		<React.Fragment>
      						  <Table aria-label="simple table">
      						    <TableHead>
      						      <TableRow>
                          { ! jobId ?
                            <>
                              <TableCell>{t('employer')}</TableCell>
                              <TableCell>{t('title')}</TableCell>
                              <TableCell>{t('type')}</TableCell>
                              <TableCell>{t('location')}</TableCell>
                            </>
                            :
                            <>
                              <TableCell></TableCell>
                              <TableCell>{t('candidate')}</TableCell>
                            </>
                          }
      						        <TableCell style={{width: 200}}>{t('language')}</TableCell>
      						        <TableCell style={{width: 200}}>{t('application_date')}</TableCell>
      						        <TableCell style={{width: 200}}>{t('status')}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
      						    </TableHead>
      						    <TableBody>
      						      { jobApplications.records && jobApplications.records.map((row) => (
      						        <TableRow key={row._id} className={classes.row} onClick={() => jobId && row.candidate[0] ? setCandidate(row.candidate[0]._id) : setJobApplication(row)}>
                            { ! jobId ?
                              <>
                                <TableCell>{row.job[0].client}</TableCell>
                                <TableCell>{row.job[0].title}</TableCell>
                                <TableCell>{row.job[0].function}</TableCell>
                                <TableCell>{row.job[0].location}</TableCell>
                              </>
                              :
                              <>
                                <TableCell style={{width: 50}} onClick={event => toggleApplication(event, row._id)}>
                                  { selectedApplications.includes(row._id) ?
                                    <CheckBoxIcon/>
                                    :
                                    <CheckBoxOutlineBlankIcon />
                                  }
                                </TableCell>
                                <TableCell>  
                                  {
                                    row.candidate[0] &&
                                    `${row.candidate[0].firstname} ${row.candidate[0].name}` 
                                  }
                                </TableCell>
                              </>
                            }
                            <TableCell>{t(getLanguage(row.job[0].languageId))}</TableCell>
      						          <TableCell>{moment(row.createdAt).format('DD.MM.YYYY')}</TableCell>
                            <TableCell className={classes[row.status]}>{row.status}</TableCell>
                            {!! jobId ? 
                              <TableCell style={{width: 110}} className={classes.actions}>
                                <IconButton onClick={event => approve(event, row)} disabled={row.status === 'successful'}>
                                  <CheckIcon />
                                </IconButton>
                                <IconButton onClick={event => setDeclineList(event, row)} disabled={row.status === 'rejected'}>
                                  <CloseIcon />
                                </IconButton>
                              </TableCell>
                              :
                              <TableCell style={{width: 50}} className={classes.actions}>
                                <IconButton onClick={event => removeApplication(event, row)} disabled={row.status !== 'pending'}>
                                  <CloseIcon />
                                </IconButton>
                              </TableCell>
                            }
      						        </TableRow>
      						      ))}
      						    </TableBody>
      						  </Table>
      					    <TablePagination
      			          rowsPerPageOptions={[5, 10, 25]}
      			          component="div"
      			          count={jobApplications.totalCount || 0}
      			          rowsPerPage={rowsPerPage}
      			          page={page}
      			          onChangePage={handleChangePage}
      			          onChangeRowsPerPage={handleChangeRowsPerPage} />
      		      </React.Fragment>
              </TableContainer>  
              </ThemeProvider>
            :
            <Typography variant="overline"> {t('no_job_applications_assigned')} </Typography>
          }
          </div> 
          { jobApplication && !! job && 
            <div style={{flex: 1, marginLeft: 40, marginBottom: 20, zIndex: 9, minWidth: '30vw'}}>
              <JobApplicationCard candidate={candidate} job={job} language={getLanguage(jobApplication.job[0].languageId)}jobApplication={jobApplication} onClose={() => setJobApplication(false)}/>
            </div>
          }
          { selectedCandidate && 
            <div style={{flex: 1, marginLeft: 40, marginBottom: 20}}>
              <CandidateCard candidate={selectedCandidate} card={{ id: 'details', component: CandidateDetailsOverview }} hideTabs={true} onClose={() => setSelectedCandidate(false)}/>
            </div>
          }
          { !! emailList.length &&
            <Box className="form-wrapper">
              <h2> { t('jobapplication_popup_title') } </h2>
              <div>
                <JobApplicationMail onClose={() => setEmailList([])} onSend={decline} />
                <IconButton aria-label={t("cancel")} onClick={() => setEmailList([])}> x </IconButton>
              </div>
            </Box>
          }
        </Box>
		</React.Fragment>    
  )
}