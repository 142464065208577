import React from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import Dropdown from "../Dropdown";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiTextField from '@material-ui/core/TextField';

import { Box, Typography } from '@material-ui/core';
import CandidatesService from "../../services/candidates.service";
import { getLanguageList } from "../../assets/languages/LanguageList";
import SearchFilter from "../SearchFilter";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  ArcElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Context
import { useUser } from '../../context/user';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  ArcElement
);


const useStyles = makeStyles((theme) => ({
  filters: {
    // display: 'flex',
    width: '100%',
    textAlign: 'center',
    marginBottom: 20,
    '& > div': {
      // flexDirection: 'column',
      width: '32%',
      margin: 'auto',
      paddingLeft: 5,
      paddingRight: 5,
      boxShadow: 'none'
    },
    '& input': {
      textAlign: 'center !important'
    }
  },
  date: {
    // width: 'calc(100% - 70px)',
    border: `1px solid ${theme.palette.mediaforta.light}`,
    borderRadius: 6,
    marginTop: 0,
    marginLeft: 8,
    '& > label': {
      top: -8,
      left: 15,
      '&.MuiFormLabel-filled, &.Mui-focused': { display: 'none' }
    },
    '& > div': {
      marginTop: 10,
      '&:before': { border: 0 }
    },
    '& input': { padding: '4px 15px 12px !important' },
  },
  charts: {
    width: '70%',
    margin: '20px auto'
  }
}));


const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
  },
  scales: { y: { ticks: { stepSize: 5 } } }
};

const formFilters = [
  { label: 'firstname', name: 'firstname', filter: `firstname=` },
  { label: 'name', name: 'name', filter: `name=` },
];


export const fetchCandidateRegistrations = async filter => CandidatesService.registrations(filter);
export default function Registrations() {
  const { isRecruiter } = useUser();
  const classes = useStyles();
  const languageList = getLanguageList();

  const [searchFilters, setSearchFilters] = React.useState([]);

  const [filter, setFilter] = React.useState('year');
  const [date, setDate] = React.useState(moment());
  const [endDate, setEndDate] = React.useState(moment());
  const [stats, setStats] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [postalCodes, setPostalCodes] = React.useState([]);
  const [postal, setPostal] = React.useState(false);
  const [city, setCity] = React.useState(false);
  const [country, setCountry] = React.useState(false);
  const [motherTongue, setMotherTongue] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState(false);


  const filters = [
    { label: 'year', value: 'year' },
    { label: 'month', value: 'month' },
    { label: 'week', value: 'week' },
    { label: 'day', value: 'day' },
    { label: 'range', value: 'range' },
  ];

  React.useEffect(() => {
    let q = `createdAt.from=${moment(date).format('YYYY-MM-DD')}&groupBy=${filter}`;

    if (filter === 'range') q += `&createdAt.to=${moment(endDate).format('YYYY-MM-DD')}`;
    if (postal) q += `&postal=${postal}`;
    if (city) q += `&city=${city}`;
    if (country) q += `&country=${country}`;
    if (motherTongue) q += `&candidateInfo.skillSet.motherTongue=${motherTongue}`;

    fetchCandidateRegistrations(q).then(({ total, stats }) => {
      setStats(stats);
      setTotal(total);
    }).catch(e => { });

    // eslint-disable-next-line
  }, [filter, date, endDate, postal, motherTongue, city]);

  React.useEffect(() => {
    fetchCandidateRegistrations('group=postal').then(({ stats: r }) => {
      setPostalCodes(
        r.filter(
          s => !!s._id.postal && s._id.postal !== '/'
        ).map(p => (
          { label: `${p._id.postal} (total: ${p.total})`, value: p._id.postal }
        ))
      );
    }).catch(e => { });
    // eslint-disable-next-line
  }, []);

  const data = {
    labels: stats.map(s => {
      if (filter === 'year') {
        return moment(`${s._id.year}-${s._id.month}-1`).format('YYYY-MM');
      }
      return moment(`${s._id.year}-${s._id.month}-${s._id.day}`).format('YYYY-MM-DD');
    }),
    datasets: [
      {
        label: `candidates`,
        data: stats.map(s => s.total),
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ],
  };

  const search = event => debounceSearch(event.target.value);
  const debounceSearch = debounce(q => {
    setSearchInput(q);
  }, 300);


  return (
    isRecruiter() &&
    <>
      <Typography style={{ flex: 1, textAlign: 'center', marginBottom: 40 }}>
        {total} Registration(s)
      </Typography>

      <Box className={classes.filters}>
        <Dropdown
          label={'Date Filter'}
          value={filter}
          items={filters}
          onChange={filter => {
            setFilter(filter);
          }} />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <>
            <DatePicker
              name="start"
              ampm={false}
              label="date"
              className={classes.date}
              variant="outlined"
              format="DD-MM-YYYY"
              views={['year', 'month', 'date']}
              value={date}
              onChange={setDate}
            />
            {filter === 'range' &&
              <DatePicker
                name="end"
                ampm={false}
                label="end date"
                className={classes.date}
                variant="outlined"
                format="DD-MM-YYYY"
                views={['year', 'month', 'date']}
                value={endDate}
                onChange={setEndDate}
              />
            }
          </>
        </MuiPickersUtilsProvider>
      </Box>
      <Box className={classes.filters}>
        <SearchFilter
          onChange={search}
          formFilters={formFilters}
          onFilter={setFilter}
          disableSearch={true}
          fixedFilters={true}
        />

        {/* <Autocomplete
          options={postalCodes}
          getOptionLabel={postal => postal.label}
          // style={{ float: 'left' }}
          onChange={(event, input) => setPostal(input ? input.value : false)}
          renderInput={(params) => (
            <MuiTextField {...params} label='postalcode' variant="outlined" fullWidth />
          )}
        />
        <MuiTextField 
          label='city' 
          variant="outlined" 
          fullWidth 
          onChange={({currentTarget: {value}}) => setCity(value)}
        />    */}
        {/* <Autocomplete
          options={languageList}
          getOptionLabel={language => language.name}
          value={languageList.find(l => l.code === motherTongue)}
          getOptionSelected={(option, value) => option.value === value.code}
          // style={{paddingRight: '3%'}}
          onChange={(event, input) => setMotherTongue(input ? input.code : false)}
          renderInput={(params) => (
            <MuiTextField {...params} label={'mother tongue'}  variant="outlined" fullWidth />
          )}
        /> */}
      </Box>
      <Box display='flex' className={classes.charts}>
        {!!stats.length ?
          <Bar options={options} data={data} />
          :
          <Typography style={{ flex: 1, textAlign: 'center' }}>
            no registrations
          </Typography>
        }
      </Box>
    </>
  );
}