import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Container, CssBaseline, Box } from '@material-ui/core';

// Context
import { useUser } from '../context/user';
import Registrations from '../components/Stats/Registrations';

const useStyles = makeStyles((theme) => ({
  stats: {
    width: '80%',
    margin: '40px auto'
  }
}));


export default function DataStats() {
  const { isRecruiter } = useUser();
  const classes = useStyles();  
  return (
    isRecruiter() &&
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Box className={classes.stats}>
        <Registrations />
      </Box>
    </Container>
  )
}