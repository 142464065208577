// Services
import ApiService from "../services/api.service"

const languagesModel = 'languages'

export default class AppService {
  static languages() {
    return ApiService.get(languagesModel).then(response => response.data)
  }
}
