import * as React from 'react'
import { t } from '../utils/i18n'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import RichEditor, { RichEditorHelpers } from './RichEditor'
import MuiTextField from '@material-ui/core/TextField'
import { Autocomplete } from 'formik-material-ui-lab'
import DatePicker from './DatePicker'
import { TextField } from 'formik-material-ui'
import { Button, Box } from '@material-ui/core'

// Context
import { useApp } from "../context/app"
import { useAuth } from "../context/auth"

// Services
import EmailsService from "../services/emails.service"
import CandidatesService from "../services/candidates.service"

const useStyles = makeStyles(theme => ({
  message: {
    '& .DraftEditor-editorContainer > div': { minHeight: 150 }
  },
  button: {
    float: 'right',
    margin: '20px 10px'
  },
  form: {
    '& .DatePicker > label:not(.MuiFormLabel-filled)': {
      transform: 'none',
      padding: 0,
      left: 15,
      top: 0,
      bottom: 0,
      zIndex: 0,
      margin: 'auto',
      width: 140,
      height: 17
    }
  }
}))

const validEmailObject = Yup.object().shape({
  email: Yup.string().email(t('invalid_email')).required(t('required')),
  name: Yup.string().required(t('required'))
})

const EmailSchema = Yup.object().shape({
  from: validEmailObject.required(t('required')),
  to: validEmailObject.required(t('required')),
  cc: Yup.array().of(validEmailObject),
  bcc:Yup.array().of(validEmailObject),
  subject: Yup.string().required(t('required')),
  scheduledAt: Yup.string(),
  message: Yup.string()
    .min(2, t('message_to_short'))
   .required(t('required'))
})

const fetchCandidates = async search => CandidatesService.list(search, 4, 0, '_id, email, firstname, name')
export default function EmailForm({ candidate = {}, email, onClose = false }) {
  const classes = useStyles()
  const { user } = useAuth()
  const { setResponseDialog } = useApp()
  const [candidates, setCandidates] = React.useState({ to: false, cc: false, bcc: false })
  
  const from = {email: user.user.email, name: `${user.user.firstname} ${user.user.name}`}
  const [to, setTo] = React.useState('')
  const [cc, setCc] = React.useState('')
  const [bcc, setBcc] = React.useState('')
  const [scheduledAt, setScheduledAt] = React.useState(null)
  
 	const today = moment()
  const [ message, setMessage ] = React.useState(
    email && email.message ? 
      RichEditorHelpers.convertFromHtml(email.message) 
    : 
      RichEditorHelpers.createEmpty()
  )

  const findCandidates = (search = false, type) => {
    // TODO CHECK IF SEARCH VALUE IS IN LIST => LESS API CALLS
    fetchCandidates(search)
    .then(data => {
      const list = data.records.map(r => {
        return { _id: r._id, email: r.email, name: `${r.firstname} ${r.name}` }
      })
      if (! type) return setCandidates({ to: list, cc: list, bcc: list })
      return setCandidates({ ...candidates, [type]: list })
    })
  }

  React.useEffect(() => {
    if (! candidates.to && ! candidates.cc && ! candidates.bcc) findCandidates()
  })

  const setDialog = message => {
    return setResponseDialog({
      title: t('great_success'),
      message,
      onClose,
      onConfirm: onClose
    })
  }

  const getFormData = ({message, values}) => {
    return { 
      candidateId: candidate._id, 
      ...from && {from: { name: from.name, email: from.email }}, 
      ...to && {to: { name: to.name, email: to.email }},
      ...cc && {cc: [{ name: cc.name, email: cc.email }]},
      ...bcc && {bcc: [{ name: bcc.name, email: bcc.email }]},
      ...scheduledAt && {scheduledAt: scheduledAt.toISOString()},
      message, 
      ...values 
    }
  }

	return (
  	<Formik
	    initialValues={{ subject: email && email.subject ? email.subject : '' }}
	    validateOnChange={false}
	    validateOnBlur={false}
	    validate={async values => {
        try { 
          await EmailSchema.validate(getFormData({message: RichEditorHelpers.getPlainText(message), values})) 
        } catch (err) { 
          return { [err.path.split('[0]')[0].split('.')[0]]: err.message } 
        }
        return {}
      }}
	    onSubmit={(values, { setSubmitting }) => {
        EmailsService.post(getFormData({message: RichEditorHelpers.convertToHtml(message), values}))
        .then(setDialog(t('email_sent_title')))
        .catch(error => setResponseDialog({error}))        
	      setSubmitting(false)
	    }}
    >
	    {({ submitForm, isSubmitting, errors, validateField }) => 
	    {
        return (
	        <Form
            className={classes.form}
	    			onBlur = {e => {
	    				const fieldName = e.target.name
	    				fieldName && fieldName !== 'image-url' && validateField(fieldName)
				    }}
	        >
	          <Box display="flex" margin={2}>
              <Field
                name="from"
                id="from"
                component={TextField}
                defaultValue={from.name}
                variant="outlined" 
                disabled
                style={{width: '100%', marginRight: 5}}
              />
              <Field
                name="to"
                id="to"
                component={Autocomplete}
                options={candidates.to ? candidates.to : to ? [to] : []}
                getOptionLabel={ option => option.name || '' }
                value={to || null}
                onChange={(event, value) => setTo(value)}
                onInputChange={(event, value) => findCandidates(value, 'to')}
                noOptionsText={t("no_candidate_found")}
                style={{width: '100%', marginRight: 5}}
                renderInput={(params) => (
                  <Field 
                    {...params} 
                    component={MuiTextField} 
                    error={!! errors['to']} 
                    helperText={errors['to']} 
                    label={t("to")} 
                    variant="outlined" 
                    name="to" />
                )}
              />
              <Field
                name="cc"
                id="cc"
                component={Autocomplete}
                options={candidates.cc ? candidates.cc : cc ? [cc] : []}
                getOptionLabel={ option => option.name || '' }
                value={cc || null}
                onChange={(event, value) => setCc(value)}
                onInputChange={(event, value) => findCandidates(value, 'cc')}
                style={{width: '100%'}}
                renderInput={(params) => (
                  <Field 
                    {...params} 
                    component={MuiTextField} 
                    error={!! errors['cc']} 
                    helperText={errors['cc']} 
                    label="Cc" 
                    variant="outlined" 
                    name="cc" />
                )}
              />
	          </Box>
            <Box display="flex" margin={2}>
             <Field
                name="bcc"
                id="bcc"
                component={Autocomplete}
                options={candidates.bcc ? candidates.bcc : bcc ? [bcc] : []}
                getOptionLabel={ option => option.name || '' }
                value={bcc || null}
                onChange={(event, value) => setBcc(value)}
                onInputChange={(event, value) => findCandidates(value, 'bcc')}
                style={{width: '50%', marginRight: 5}}
                renderInput={(params) => (
                  <Field 
                    {...params} 
                    component={MuiTextField} 
                    error={!! errors['bcc']} 
                    helperText={errors['bcc']} 
                    label="Bcc" 
                    variant="outlined" 
                    name="bcc" />
                )}
              />
              <Box style={{position: 'relative', width: '100%'}}>
                <DatePicker 
                  name="scheduledAt"
                  disabled={isSubmitting}
                  ampm={false}
                  minDate={today}
                  label={t("scheduled_send")}
                  style={{width: '100%', marginRight: 10}}
                  variant="outlined"
                  component={TextField}
                  format="DD.MM.YYYY HH:mm"
                  error={!! errors['scheduledAt']} 
                  helperText={errors['scheduledAt']} 
                  value={scheduledAt}
                  onChange={setScheduledAt}
                />
                <span 
                  style={{position: 'absolute', top: 0, right: 0, padding: 18, height: '100%', fontWeight: 300, cursor: 'pointer'}} 
                  onClick={() => setScheduledAt(null)}
                >
                  X
                </span>
              </Box>
            </Box>
            <Box display="flex" margin={2}>
              <Field 
                name="subject" 
                component={TextField} 
                label={t("subject")}
                variant="outlined" 
                style={{width: '100%'}} />
            </Box>
	          <Box margin={2} className={classes.message}>
              <Field 
                name="message" 
                component={RichEditor} 
                label={t("message")}
                editorState={message}
                onChange={setMessage}
                variant="outlined" 
                defaultValue={message}
                error={!! errors['message']} 
                helperText={errors['message']}
                style={{width: '100%'}} />
	          </Box>
	          <Box margin={1} className={classes.button}>
	            <Button
	              variant="contained"
	              color="primary"
	              disabled={isSubmitting}
	              onClick={submitForm}
	            >
                {t('send')}
	            </Button>
	          </Box>
	        </Form>
	    )}}
  	</Formik>
  )
}