import * as React from 'react';
import { t } from '../utils/i18n';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import DatePicker from './DatePicker';
import moment from 'moment';
import {
	Button,
	Box,
	TextField as MuiTextField,
	Paper,
	FormControl,
	InputLabel,
} from '@material-ui/core';

// Context
import { useApp } from "../context/app";

// Services
import jobsService from '../services/jobs.service';

// Components
import RichEditor, { RichEditorHelpers } from './RichEditor';

const useStyles = makeStyles(theme => ({
	disabled: {
		'& .Mui-disabled': {
			color: theme.palette.mediaforta.light,
			fontWeight: 400
		},
		'& input.Mui-disabled, textarea.Mui-disabled': {
			color: theme.palette.mediaforta.dark,
			fontWeight: 500,
		},
		'& .MuiInput-underline:before': {
			borderBottom: '1px dotted rgba(0, 0, 0, 0.2)',
			marginRight: 30
		},
		'& .MuiPaper-root': {
			marginRight: 30,
			borderTop: 0,
			marginTop: 20,
			padding: '0 0 5px 0'
		},
		'& .MuiAutocomplete-endAdornment': { display: 'none' }
	},
	formControl: {
		width: '100%',
		marginTop: 16,
		'& .RichEditor-Wrapper ': {
			marginTop: 24
		}
	},
	tagForm: {
		'& > div': {
			'& > div': {
				width: 'calc(50% - 8px)',
				minWidth: 'auto'
			},
			'& > label': {
				marginTop: -8,
				marginLeft: 14,
				backgroundColor: '#fff',
				padding: '0 2px',
				zIndex: 1
			}
		}
	},
	tags: {
		margin: '16px 8px',
		'& > div': {
			paddingTop: 25,
			flexDirection: 'row'
		}
	},
	textField: {
		marginTop: 0,
		'&> label': {
			padding: '5px 0'
		},
		'&> div': {
			height: 56
		}
	}
}));

const CampaignSchema = Yup.object().shape({
	title: Yup.string().required(t('required')),
	budget: Yup.string()
});

export default function CampaignForm({ onClose, campaign = {}, edit, mediumId, onUpdate }) {
	const classes = useStyles();
	const { setResponseDialog } = useApp();
	const [start, setStart] = React.useState(moment());
	const [end, setEnd] = React.useState(moment());
	const [remarks, setRemarks] = React.useState(
		campaign && campaign.remarks ?
			RichEditorHelpers.convertFromHtml(campaign.remarks) :
			RichEditorHelpers.createEmpty()
	);

	const variant = edit ? 'outlined' : 'standard';
	const today = moment();

	return (
		<>
		<Formik
			initialValues={{
				title: campaign.title || '',
				budget: campaign.budget || ''
			}}
			validateOnChange={false}
			validateOnBlur={false}
			validationSchema={CampaignSchema}
			validate={() => {
				const errors = {};
				if (RichEditorHelpers.getPlainText(remarks).length < 2) { errors.remarks = t('min_chars', false, { amount: 2 }); }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				const data = {
					start: moment(start.format('YYYY-MM-DD')).toDate(),
					end: moment(end.format('YYYY-MM-DD')).toDate(),
					remarks: RichEditorHelpers.convertToHtml(remarks),
					mediumId,
					...values
				};

				if (campaign && campaign._id) {
					jobsService.patchCampaign(campaign._id, data)
						.then(() => onClose())
						.catch(error => setResponseDialog({ error }));
				} else {
					jobsService.postCampaign(data)
						.then(() => onClose())
						.catch(error => setResponseDialog({ error }));
				}
				setSubmitting(false);
			}}
		>
			{({ submitForm, isSubmitting, errors, validateForm }) => {
				return (
					<Form className={!edit ? classes.disabled : ''}>
						<Box display="flex" margin={1}>
							<Field
								name="title"
								component={TextField}
								label={t("title")}
								variant={variant}
								className={classes.textField}
								disabled={!edit}
								style={{ width: '100%', marginTop: '35px'}}
								margin="normal" />
            </Box>
            <Box  display="flex" margin={1}>
							<Field
								name="budget"
								component={TextField}
								label={t("budget")}
								variant={variant}
								disabled={!edit}
								className={classes.textField}
								style={{ width: '50%', marginRight: 10  }}
								margin="normal" />
							<DatePicker 
								name="start"
								disabled={isSubmitting}
								ampm={false}
								minDate={today}
								label={t("start")}
								style={{width: '100%', marginRight: 10}}
								variant="outlined"
								component={TextField}
								format="DD.MM.YYYY"
								error={!! errors['start']} 
								helperText={errors['start']} 
								value={start}
								onChange={setStart}
							/>
							<DatePicker 
								name="end"
								disabled={isSubmitting}
								ampm={false}
								minDate={today}
								label={t("end")}
								style={{width: '100%'}}
								variant="outlined"
								component={TextField}
								format="DD.MM.YYYY"
								error={!! errors['end']} 
								helperText={errors['end']} 
								value={end}
								onChange={setEnd}
							/>
            </Box>
           	
						<Box display="flex" margin={1}>
							<FormControl className={classes.formControl}>
								<InputLabel shrink > {t('remarks')} </InputLabel>
								{edit ?
									<Field
										name="remarks"
										component={RichEditor}
										editorState={remarks}
										onChange={setRemarks}
										error={!!errors['remarks']}
										helperText={errors['remarks']}
										variant={variant}
										margin="normal"
									/>
									:
									<Paper elevation={2} className={classes.paper}>
										<div dangerouslySetInnerHTML={{ __html: campaign.remarks }} />
									</Paper>
								}
							</FormControl>
						</Box>
						
						{edit &&
							<Box margin={1}>
								<Button
									variant="contained"
									color="primary"
									disabled={isSubmitting}
									onClick={submitForm}
								>
									{t('save')}
								</Button>
							</Box>
						}
					</Form>
				);
			}}
		</Formik>
		</>
	);
}