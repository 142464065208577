import React from 'react'
import { t } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

// Services
import RecruitersService from "../services/recruiters.service"

const useStyles = makeStyles(() => ({
  root: { 
    minWidth: 200,
    '& input': { width: 'fit-content !important' } 
  }
}))

export default function RecruiterSelect({ onChange, recruiter = null }) {
  const classes = useStyles()
  const [ recruiters, setRecruiters ] = React.useState(false)
  const [ selected, setSelected] = React.useState(recruiter)

  React.useEffect(() => {
    if (! recruiters) {
      RecruitersService.list()
      .then(data => setRecruiters(data.records.map(r => {
        return { _id: r._id, name: `${r.firstname} ${r.name}` }
      })))
    }
  })

  const update = recruiter => {
    setSelected(recruiter)
    if (onChange) onChange(recruiter || null)
  }

  return ( 
    <div className={classes.root}>
      <Autocomplete
        name="recruiter"
        id="recruiter"
        options={recruiters ? recruiters : recruiter ? [recruiter] : []}
        getOptionLabel={ option => option.name || '' }
        value={selected || null}
        onChange={(event, recruiter) => update(recruiter) }
        margin="normal"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t("recruiter")}
            inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
          />
        )}
      />
    </div>
  )
}