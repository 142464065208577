import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Select, FormControl, InputLabel, MenuItem, FormHelperText } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
  	position: 'relative',
    paddingLeft: 2,
    minWidth: 250,
    marginRight: 10,
    '& label': {
	    color: '#999fae',
	    left: 1
    },
    '& div': {
    	backgroundColor: '#fff !important',
	    borderColor: theme.palette.mediaforta.light,
    	borderRadius: 5,
    	'& .MuiSelect-root': {
	    	backgroundColor: '#fff !important',
	    	padding: 14,
        paddingLeft: 14,
        color: theme.palette.mediaforta.dark,
	    },
	    '& fieldset': { borderColor: theme.palette.mediaforta.light }
    },
  },
  blue: {
    '& label': { color: '#fff' },
    '& div': {
      backgroundColor: theme.palette.info.dark + ' !important',
      borderColor: 'transparent',
      '& .MuiSelect-root': {
        backgroundColor: 'transparent !important',
        borderColor: 'transparent',
        color: '#fff',
        '& option': { color: theme.palette.primary.dark }
      },
      '& fieldset': { borderColor: 'transparent' },
      '& svg': { color: '#fff' }
    }
  },
  light: {
    '& label': { color: theme.palette.primary.dark },
    '& div': {
      backgroundColor: theme.palette.mediaforta.superlight + ' !important',
      '& .MuiSelect-root': { color: theme.palette.primary.dark, },
      '& svg': { color: theme.palette.primary.dark }
    }
  }
}))

export default function Dropdown({ 
  value, 
  onChange, 
  items = [], 
  blue = '', 
  light = '', 
  label, 
  name, 
  className = '', 
  error = false, 
  helperText = ''
}) {
  const classes = useStyles()
  return ( 
    <FormControl 
      variant="outlined" 
      className={`${classes.root} ${(blue || light) && classes.blue} ${light && classes.light} ${className}`} 
      error={error}
    >
      <InputLabel htmlFor="outlined-filter-native-simple">{label}</InputLabel>
      <Select
        value={value}
        onChange={event => onChange(event.target.value)}
        label={label}
        error={error}
        inputProps={{ name, id: label, 'data-value': value }}
      >
        { items && 
          items.map(item => <MenuItem key={item.label} value={item.value}> {item.label} </MenuItem>) 
        }
      </Select>
      {!! helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>  
  )
}