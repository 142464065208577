// Services
import ApiService from "../services/api.service"

const model = 'tests'

export default class TestsService {
  static list(search = false, limit = 10, skip = 0, fields = '', filter = false) {
    if (search && search !== '') search = `{"description": "regex:${search}"}`
    return ApiService.list(model, { search, filter, limit, skip, fields }).then(response => response.data)
  }

  static post(data = {}) {
    return ApiService.post(model, data).then(response => response)
  }
  
  static patch(testId, patch = {}) {
    return ApiService.patch(`${model}/${testId}`, patch).then(response => response) 
  }
  
  static delete(testId) {
    return ApiService.delete(`${model}/${testId}`).then(response => response) 
  } 
}