import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Paper,
  IconButton,
  TextField,
  Button,
} from "@material-ui/core";
import {
  ThumbUpAlt as ThumbUpAltIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@material-ui/icons";

// Services
import CandidatesService from "../services/candidates.service";
import ScoreCardsService from "../services/scorecards.service";

const useStyles = makeStyles((theme) => ({
  description: { "& > div > *": { margin: "5px 0" } },
  header: {
    marginTop: 4,
    backgroundColor: "#2a7fd7",
    cursor: "pointer",
    color: "#fff",
    padding: 10,
    borderRadius: "5px 5px 0 0",
    "& h2": { margin: 0, flex: 1 },
  },
  scoreCard: {
    "& .success": {
      float: "right",
      "&:hover, &:hover ~ button, &.selected ~ button, &.selected": {
        color: theme.palette.success.main,
      },
      "& svg": { transform: "none" },
    },
  },
  actions: {
    marginTop: 25,
    float: "right",
    "& > *": {
      padding: 5,
      float: "left",
    },
    "& svg": { fontSize: 20 },
  },
  button: {
    float: "right",
    marginTop: -10,
  },
}));

export const updateScoreCards = async (candidateId, scoreCards) =>
  CandidatesService.updateScoreCards(candidateId, scoreCards);
export const fetchScoreCards = async (filter) =>
  ScoreCardsService.list(false, 50, 0, "", filter);

export default function CandidateScoreCardList({
  candidate,
  scoreCard,
  setScoreCard,
  fullwidth,
  setRefresh,
}) {
  const classes = useStyles();
  const [candidateScoreCards, setCandidateScoreCards] = React.useState([]);
  const [comment, setComment] = React.useState("");

  React.useEffect(() => {
    if (!candidate.scoreCards || !candidate.scoreCards.length) return;
    const filter = `_id=${candidate.scoreCards
      .map(({ _id }) => _id)
      .toString()},`;
    fetchScoreCards(filter).then(({ records }) => {
      setCandidateScoreCards(records);
    });
    // eslint-disable-next-line
  }, [candidate.scoreCards]);

  const getCandidateCard = (cardId) => {
    return (
      candidate.scoreCards &&
      candidate.scoreCards.find(({ _id }) => _id === cardId)
    );
  };

  const getScore = (cardId, categoryId, itemId) => {
    const card = getCandidateCard(cardId);
    if (!card || !card.scores) {
      return 0;
    }
    return card.scores[`${categoryId}-${itemId}`] || 0;
  };

  const getTotalScore = (cardId, categoryId, itemId) => {
    const card = getCandidateCard(cardId);
    if (!card || !card.scores) {
      return 0;
    }

    const total = candidateScoreCards.reduce(
      (memo, category) => {
        category.selectedCategories.forEach((c) => {
          c.items.forEach((item) => {
            memo.total = memo.total + (item.weight || 1) * 5;
            memo.score =
              memo.score +
              ((item.weight || 1) * card.scores[`${c._id}-${item._id}`] || 0);
          });
        });
        return memo;
      },
      { total: 0, score: 0 }
    );

    return ((total.score / total.total) * 100).toFixed(2);
  };

  const getComment = (cardId) => {
    const card = getCandidateCard(cardId);
    if (!card) {
      return "";
    }
    return card.comment || "";
  };

  const setScore = (cardId, categoryId, itemId, score) => {
    const scoreCards = candidate.scoreCards.map((card) => {
      if (card._id === cardId) {
        card.scores[`${categoryId}-${itemId}`] = score;
      }
      return card;
    });
    updateScoreCards(candidate._id, scoreCards).then(() =>
      setRefresh(Math.random())
    );
  };

  const renderIconButton = (cardId, categoryId, itemId, score) => {
    return (
      <IconButton
        onClick={() => setScore(cardId, categoryId, itemId, score)}
        className={`success ${
          getScore(cardId, categoryId, itemId) === score ? "selected" : ""
        }`}
      >
        <ThumbUpAltIcon />
      </IconButton>
    );
  };

  const updateComment = () => {
    const scoreCards = candidate.scoreCards.map((card) => {
      if (card._id === scoreCard._id) {
        card.comment = comment;
      }
      return card;
    });
    updateScoreCards(candidate._id, scoreCards).then(() =>
      setRefresh(Math.random())
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {!!candidateScoreCards.length &&
          candidateScoreCards.map((card) => {
            return (
              <Grid key={card._id} item xs={fullwidth ? 12 : 6}>
                <Paper className={classes.scoreCard}>
                  <Box
                    display="flex"
                    className={classes.header}
                    onClick={() =>
                      setScoreCard(
                        scoreCard && scoreCard._id === card._id ? false : card
                      )
                    }
                  >
                    <h2>
                      {" "}
                      {card.label}{" "}
                      <span style={{ float: "right" }}>
                        {getTotalScore(card._id)}%
                      </span>
                    </h2>
                    {scoreCard && scoreCard._id === card._id ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </Box>
                  {scoreCard && scoreCard._id === card._id && (
                    <Box style={{ paddingBottom: 20, paddingTop: 20 }}>
                      <TextField
                        fullWidth
                        multiline
                        rows={5}
                        style={{ marginBottom: 20 }}
                        variant="outlined"
                        name="comment"
                        type="text"
                        label="comment"
                        defaultValue={getComment(card._id)}
                        onChange={({ currentTarget: { value } }) =>
                          setComment(value)
                        }
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={updateComment}
                      >
                        save comment
                      </Button>
                      {card.selectedCategories.map((category) => {
                        return (
                          <Box key={category._id}>
                            <h2 style={{ marginLeft: 20 }}>{category.label}</h2>
                            {category.selectedItems.map((item) => {
                              return (
                                <Box
                                  display="flex"
                                  key={item._id}
                                  style={{ marginLeft: 40 }}
                                >
                                  <h2
                                    style={{
                                      fontWeight: 200,
                                      fontSize: 18,
                                      minWidth: "30%",
                                      margin: "auto 0",
                                    }}
                                  >
                                    {item.label}
                                  </h2>

                                  <Box margin={0}>
                                    {renderIconButton(
                                      card._id,
                                      category._id,
                                      item._id,
                                      5
                                    )}
                                    {renderIconButton(
                                      card._id,
                                      category._id,
                                      item._id,
                                      4
                                    )}
                                    {renderIconButton(
                                      card._id,
                                      category._id,
                                      item._id,
                                      3
                                    )}
                                    {renderIconButton(
                                      card._id,
                                      category._id,
                                      item._id,
                                      2
                                    )}
                                    {renderIconButton(
                                      card._id,
                                      category._id,
                                      item._id,
                                      1
                                    )}
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Paper>
              </Grid>
            );
          })}
      </Grid>
    </React.Fragment>
  );
}
