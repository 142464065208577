import React from 'react'
import { t } from '../utils/i18n'
import { debounce } from 'lodash'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Paper, Typography, Collapse } from '@material-ui/core'
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowRight as KeyboardArrowRightIcon } from '@material-ui/icons'

// Context
import { useApp } from "../context/app"

// Services 
import InterviewsService from "../services/interviews.service"

// Candidate components
import CandidateInterviewForm from "./CandidateInterviewForm"

// App components
import SearchFilter from "./SearchFilter"

const useStyles = makeStyles(theme => ({
  interview: {
    marginTop: 20,
    '& h2, & h3, & p': { margin: 5, display: 'flex'},
    '& > div > div': { marginTop: 5 },
    '& > div > svg': {
      position: 'absolute',
      right: 10,
      top: 10
    },
    '& a': { 
      position: 'relative',
      '& > svg': {
        position: 'absolute', fontSize: 20, left: 10 
      }
    }
  },
  description: {
    '& > div > *': { margin: '5px 0' }
  },
  header: {
    marginTop: 4,
    '& > *': { flex: 1 },
    '& h2': { margin: '5px 10px' }
  },
  actions: {
    marginTop: 25,
    float: 'right',
    '& > *': {
      padding: 5,
      float: 'left'
    },
    '& svg': { fontSize: 20 }
  }
}))

export const fetchInterviewTemplates= async () => InterviewsService.templates()
export default function CandidateInterviewsList({ candidate }) {
  const classes = useStyles()
  const { setResponseDialog } = useApp()

  const [ filter, setFilter ] = React.useState('')
  const [ filters, setFilters ] = React.useState(false)
  const [ searchInput, setSearchInput ] = React.useState(false)
  const [ open, setOpen ] = React.useState('')
  
  const search = event => debounceSearch(event.target.value)
  const debounceSearch = debounce(q => setSearchInput(q), 300);

  const setError = (error) => setResponseDialog({error})

  const renderInterviewDetails = interview => {
    return <Box display="flex">
      <h3>{`${t('interview_by')} ${interview.user[0].firstname} ${interview.user[0].name}`}</h3>
      <Typography>{ moment(interview.createdAt).format('DD/MM/YYYY') }</Typography>
    </Box>
  }

  const isOpen = interview => { return open === interview._id }
  const toggleOpen = interview => { isOpen(interview) ? setOpen(false) : setOpen(interview._id) }

  React.useEffect(() => {
    if (! filters) fetchInterviewTemplates().then(response => setFilters(response.records)).catch(setError)
    // eslint-disable-next-line
  })  

  if (! filters || ! filters.length) return null
    
  return ( 
    <React.Fragment>
      <SearchFilter 
        onChange={search} 
        value={searchInput} 
        placeholder={t("search_interviews")}
        filter={filter}
        filterLabel={t("start_interview")}
        filters={filters.map(interview => { return {label: interview.type, value: interview._id }})}
        setFilter={setFilter}
      />
      <Grid container spacing={2}>
        { filter && 
          <Grid item xs={12}>
            <Paper variant="outlined" className={classes.note}>
              <Box display="flex">
                <h2>{t('interview')}</h2>
              </Box>
              <CandidateInterviewForm candidate={candidate} type={filter} interview={filters.find(f => f._id === filter)} close={() => setFilter('')}/>
            </Paper>
          </Grid>
        }
      </Grid>
      { !! candidate.candidateinterviews && !! candidate.candidateinterviews.length &&
        candidate.candidateinterviews.map(i => {
          return <Grid key={i._id} open={isOpen(i)} item xs={12}>
            <Paper variant="outlined" className={classes.interview}>
              <Box display="flex" onClick={() => toggleOpen(i) } style={{cursor: 'pointer', position: 'relative'}}>
                { renderInterviewDetails(i) }
                { isOpen(i) ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
              </Box>
              <Collapse in={isOpen(i)} timeout={200} unmountOnExit>
                <CandidateInterviewForm candidate={candidate} type={filter} interview={i}/>
              </Collapse>
            </Paper>
          </Grid>
        })
      }
    </React.Fragment>    
  )
}