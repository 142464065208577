import * as React from 'react'
import { t } from '../utils/i18n'

import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import TestsService from "../services/tests.service"
import CandidatesService from "../services/candidates.service"
import { DropzoneDialog } from 'material-ui-dropzone'
import { useApp } from "../context/app"
import AddIcon from "./AddIcon"
import { Button, Box } from '@material-ui/core'
import { AttachFile as AttachFileIcon } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  file: {
    display: 'flex',
    position: 'relative',
    width: 'fit-content',
    minWidth: 155,
    padding: 10,
    marginTop: 15
  },
  button: { float: 'right' }
}))

const TestSchema = Yup.object().shape({
	name: Yup.string().required(t('required'))
})

export default function TestForm(props) {
  const { test, candidate } = props
  const classes = useStyles()
	const { setResponseDialog } = useApp()
  const [ dropzoneDialog, setDropzoneDialog ] = React.useState(false)
  const [ file, setFile ] = React.useState(test ? test.fileId : false)

  const setDialog = message => {
    return setResponseDialog({
      title: t('great_success'),
      message,
      onClose: props.onClose || false 
    })
  }

	return (
    <React.Fragment>
    	<Formik
  	    initialValues={{ name: test && test.name ? test.name : '' }}
        validateOnChange={false}
  	    validationSchema={TestSchema}
        onSubmit={(values, { setSubmitting }) => {
          const data = { candidateId: candidate._id, ...values }
          if (file) { data.fileId = file._id }
          if (test && test._id) {
            TestsService.patch(test._id, data).then(() => {
              setDialog(t('test_updated'))
            }).catch(error => setResponseDialog({error, onClose: props.onClose }))
          } else {
            TestsService.post(data).then(() => {
              setDialog(t('test_created'))
          	}).catch(error => setResponseDialog({error, onClose: props.onClose }))        
          }
  	      setSubmitting(false)
  	    }}
      >
        { ({ submitForm, isSubmitting, errors }) => {
          return (
            <Form style={{margin: 5}}>
              <Box margin={1}>
                <Field 
                  name="name" 
                  label={t('name')}
                  variant="outlined" 
                  style={{width: '100%'}}
                  error={!! errors['name']} 
                  helperText={errors['name']}
                  component={TextField} />
              </Box>
              <Box margin={1} className={classes.file}>
                { file ?
                    <> <AttachFileIcon /> {file.originalname} </>
                  :
                    <> <AddIcon onClick={() => setDropzoneDialog(true) } /> {t('add_file')} </>
                }
              </Box>
              <Box margin={1}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  { test && test._id ? t('edit_test') : t('add_test') }
                </Button>
              </Box>
            </Form>
          )
        }}
    	</Formik>
      <DropzoneDialog
        open={dropzoneDialog}
        acceptedFiles={['image/jpeg', 'image/png', 'application/pdf', '.docx', '.doc', '.xls', '.xlsx']}
        onSave={fs => CandidatesService.upload(candidate._id, fs, true).then(({ data }) => {
          setFile(data.file || data.files[0])
          setDropzoneDialog(false)
        })}
        showPreviews={true}
        filesLimit={1}
        maxFileSize={10000000}
        maxWidth="md"
        className={classes.dialog}
        onClose={() => setDropzoneDialog(false)}
      />
    </React.Fragment>
  )
}