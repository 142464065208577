import React from 'react'
import { t } from '../utils/i18n'
import moment from 'moment'
import countries from "../assets/countries/CountryList"
import { makeStyles } from '@material-ui/core/styles'
import { Box, TextField, IconButton } from '@material-ui/core'
import { School as SchoolIcon, Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'

// Context
import { useApp } from "../context/app"

// Services
import candidatesService from '../services/candidates.service'

const useStyles = makeStyles(theme => ({
  diploma: {
    '& > div:not(.MuiBox-root)': {
      marginRight: 20,
      width: '100%'
    },
    '& label': {
      color: '#b6c1e6 !important',
      fontWeight: 500 
    },
    '& input': {
      color: theme.palette.primary.dark,
      fontWeight: 500 ,
      textOverflow: 'ellipsis'
    },
    '& .MuiInputBase-root:before': {
      borderBottom: '1px solid #b6c1e6'
    }
  },
  date: {
    maxWidth: 70,
    minWidth: 70
  },
  icon: {
    marginRight: 20,
    '& svg': {
      padding: 15,
      width: 50,
      height: 50,
      borderRadius: '50%',
      background: '#efeffd'
    }
  },
  iconBox: {
    marginTop: 16,
    alignItems: 'center'
  }
}))

const inputProps = { type: 'text', disabled: true, margin: 'normal' }

export const fetchDiplomaList = async candidateId => candidatesService.listDiplomas(candidateId)
export default function CandidateDiplomaList({ diploma, candidate, setDiploma }) {
  const classes = useStyles()
  const { setResponseDialog } = useApp()
  
  const { _id: candidateId } = candidate
  const [ diplomas, setDiplomas ] = React.useState([])
  const [ removedDiploma, setRemovedDiploma ] = React.useState(0)

  const getCountry = countryCode => countries.find(c => c.code === countryCode).label
  
  const setError = (error) => {
    setDiplomas([])
    setResponseDialog({error})
  }
  
  React.useEffect(() => {
    if (! diploma || !! removedDiploma) fetchDiplomaList(candidateId).then(setDiplomas).catch(setError)
  // eslint-disable-next-line
  }, [ diploma, removedDiploma ])
  
  const removeDiploma = diploma => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure_remove_degree'),
      onConfirm: () => candidatesService.deleteDiploma(diploma._id).then(() => setRemovedDiploma(diploma._id))
    })
  }
  
  const renderDiploma = diploma => {
    return (
      <Box className={classes.diploma} key={diploma._id} display="flex" style={{margin: '20px 10px 30px'}}>
        <Box className={`${classes.icon} ${classes.iconBox}`} display="flex"> <SchoolIcon/> </Box>
        <TextField 
          name="degree" 
          label={t("degree")} 
          value={diploma.degree}
          {...inputProps}/>
        <TextField 
          name="institute" 
          label={t("university")}
          value={diploma.institute} 
          {...inputProps}/>
        <TextField 
          name="from" 
          label={t("from")}
          className={classes.date}
          value={moment(diploma.from).format('YYYY')} 
          {...inputProps}/>
        <TextField 
          name="to" 
          label={t("to")}
          className={classes.date}
          value={moment(diploma.to).format('YYYY')} 
          {...inputProps}/>
        <TextField 
          name="country" 
          label={t("country")}
          value={getCountry(diploma.country)} 
          {...inputProps}/>
        <Box className={classes.iconBox} display="flex">
          {
            !! setDiploma && 
            <IconButton onClick={() => setDiploma(diploma)}> <EditIcon /> </IconButton>
          }
          <IconButton onClick={() => removeDiploma(diploma)}> <DeleteIcon /> </IconButton>
        </Box>
      </Box>
    )
  } 

  return <React.Fragment> { !! diplomas.length && diplomas.map(renderDiploma) } </React.Fragment>
}