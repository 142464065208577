import React from 'react'
import { t } from '../utils/i18n'
import { IconButton, Box } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

// Model components
import TestsList from './TestsList'
import TestForm from './TestForm'

// assets
import '../assets/scss/form-wrapper.scss'

export default function CandidateTests({ candidate = {}, className }) {
  const [test, setTest] = React.useState(false)

  return ( 
    <div className={className}>
      { ! test ? 
        <IconButton aria-label="add test" onClick={() => setTest({})}> <AddIcon /> {t('new_test')} </IconButton> 
        :
        <Box className="form-wrapper">
          <h2> { test._id ? t('edit_test') : t('new_test') } </h2>
          <div>
            <TestForm candidate={candidate} onClose={() => setTest(false)} test={test}/>
            <IconButton aria-label={t("cancel")} onClick={() => setTest(false)}> x </IconButton>
          </div>
        </Box>
      }
      { !! candidate && candidate._id &&
        <TestsList fullwidth candidate={candidate} test={test} selectTest={test => setTest(test) }/>
      }
    </div>
  )
}