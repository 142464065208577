import React from 'react'
import { t } from '../utils/i18n'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import { 
  Box, 
  Grid, 
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table 
} from '@material-ui/core'

// Components
import { fetchJobs } from "./JobsList"

const useStyles = makeStyles((theme) => ({
  root: {
    '& > .MuiGrid-root': {
      position: 'relative',
      paddingBottom: 25,
      minHeight: 40
    },
    '& ul.MuiList-padding': { padding: '5px 0' },
    '& .MuiGrid-root.MuiTypography-root': { minWidth: '40%' },
    '& h5': { fontSize: 18 }
  },
  tableHead: {
    '& > th': { color: theme.palette.mediaforta.light }
  },
  tableRow: {
    '& > td': { 
      color: theme.palette.mediaforta.light,
      cursor: 'pointer',
      '&:first-child': {
        color: theme.palette.mediaforta.dark,
        fontSize: 16,
        fontWeight: 500
      }
    }
  },
  selected: { backgroundColor: '#f0f2f9' }
}))

export default function CandidatesJobsFilter({onChange, refresh}) {
  const classes = useStyles()
  const today = moment(new Date(), 'DD.MM.YYYY')
  const [ jobs, setJobs ] = React.useState([])
  const [ selectedJobs, setSelectedJobs ] = React.useState([])
	  
  
  React.useEffect(() => {
    fetchJobs('', 100, 0, false, `to>=${today.format('YYYY-MM-DD')}&from<=${today.format('YYYY-MM-DD')}`)
      .then(({records = []}) => setJobs(records))
    // eslint-disable-next-line
  }, [refresh])

  const filter = ({ _id }) => {
    const selection = selectedJobs.includes(_id) ? 
      selectedJobs.filter(jobId => jobId !== _id) : 
      [...selectedJobs, _id]

    setSelectedJobs(selection)

    const jobapplications = jobs.reduce((memo, job) => {
      if (selection.includes(job._id)) memo.push(...job.jobapplications)
      return memo
    }, [])

    onChange({
      jobs: selection,
      candidates: jobapplications.map(application => application.candidateId)
    })
  }

  return ( 
    <Box className={classes.root}>
      <Grid container spacing={1}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tableHead}>
            <TableCell>{t('title')}</TableCell>
            <TableCell>{t('from')}</TableCell>
            <TableCell>{t('candidates')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        { jobs.length ? jobs.map(job => (
            <TableRow 
              key={job._id} 
              className={`${classes.tableRow} ${selectedJobs.includes(job._id) ? classes.selected : ''}`} 
              onClick={() => {
                filter(job)
              }}>
              <TableCell className={selectedJobs.includes(job._id) ? classes.selected : ''}>{job.title}</TableCell>
              <TableCell> {job.from && moment(job.from).format('DD.MM.YYYY')} </TableCell>
              <TableCell>{job.jobapplications.length}</TableCell>
            </TableRow>
          ))
          : 
          <TableRow>
            <TableCell>{t('no_jobs_found')}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        }
      </TableBody>
    </Table>
      </Grid>
    </Box>
  )
}