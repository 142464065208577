import React from 'react'
import { t } from '../utils/i18n'

import { Add as AddIcon } from '@material-ui/icons'
import { Typography, Container, CssBaseline, IconButton, Box, Grid } from '@material-ui/core'

// Context
import { useCandidate } from '../context/candidate'

// Components
import CandidateDiplomaList from '../components/CandidateDiplomaList'
import CandidateDiplomaForm from '../components/CandidateDiplomaForm'

export default function Diploma() {
  const [diploma, setDiploma] = React.useState(false)
  const { candidate } = useCandidate()
  
  return (
    candidate &&
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
      	{t('education')}
        <IconButton aria-label={t('add_education')} onClick={() => setDiploma({})}>
          <AddIcon />
        </IconButton>
        { diploma &&
          <Box className="form-wrapper -full">  
            <h2>
              { diploma && diploma._id ? t('edit_education') : t('new_education') }
            </h2>
            <div>
              <CandidateDiplomaForm candidateId={candidate._id} onClose={() => setDiploma(false)} diploma={diploma}/>
              <IconButton aria-label={t('cancel')} onClick={() => setDiploma(false)}> x </IconButton>
            </div>
          </Box> 
        }
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <CandidateDiplomaList candidate={candidate} diploma={diploma} setDiploma={setDiploma}/>   
        </Grid>
      </Grid>
    </Container>
  )
}