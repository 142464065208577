import React from 'react'
import moment from 'moment'
import { t, getLanguage } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box,
  Typography,
  Collapse,
  IconButton
} from '@material-ui/core'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,  
  KeyboardArrowRight as KeyboardArrowRightIcon 
} from '@material-ui/icons'

// Context 
import { useApp } from "../context/app";

// Services
import skillsService from '../services/skills.service';

// App components
import TabList from "./TabList";
import { SkillItemForm } from '../components/SkillsForm';

const tabItems = [
  { primary: t('existing'), id: 'approved', selected: true },
  { primary: t('for_validation'), id: 'pending' }
]

const useStyles = makeStyles(theme => ({
  tab: { width: 400 },
  category: {
    cursor: 'pointer',
    display: 'flex', 
    padding: '30px 20px 0',
    '& h5': { 
      flex: 1,
      '& svg': {
        background: theme.palette.mediaforta.main,
        borderRadius: '50%',
        fontSize: 20,
        fill: '#fff'
      }
    }
  },
  actions: {
    width: 40,
    padding: 0,
    '& svg': { fontSize: 20 }
  },
  delete: {
    fill: '#2a7fd7 !important',
    backgroundColor: '#fff !important'
  }
}))

export const fetchSkills = async () => skillsService.list()
export const fetchCandidates = async userIds => skillsService.listCandidates({userIds})

export default function SpecialisationsList({skillId, userFunctions, setSpecialisation}) {
  const classes = useStyles()
  const { setResponseDialog } = useApp()
  const language = getLanguage()
  const [ skills, setSkills ] = React.useState([])
  const [ candidates, setCandidates ] = React.useState(false)
  const [ open, setOpen ] = React.useState(false)
  const [ tab, setTab ] = React.useState('approved')
  const [ skillItem, setSkillItem ] = React.useState(false)
  const [ refresh, setRefresh ] = React.useState(false)
  
  const isOpen = _id => open === _id
 
  React.useEffect(() => {
    if (! skills.length || skillId || refresh) fetchSkills().then(response => {
      const skills = response.records
      setSkills(skills)
      setOpen(skillId ? skillId : skills[0] ? skills[0]._id : false) 
      if(refresh) setRefresh(false)
    })
    // eslint-disable-next-line
  }, [skillId, refresh])

  const getFilteredSkills = () => {
    const copy = JSON.parse(JSON.stringify(skills))
    return copy.map(skill => {
      skill.skills = skill.skills.filter(x => {
        return x.state === tab
      })
      return skill
    })
  }

  React.useEffect(() => {
    if (skills.length && ! candidates && tab === 'pending') {
      const users = getFilteredSkills().map(s => s.skills.map(skill => skill.createdBy)).flat()
      fetchCandidates(users).then(response => setCandidates(response.records))
    }
    // eslint-disable-next-line
  }, [skills, tab])

  const renderUser = userId => {
    if (! candidates || ! candidates.length) return ''
    const candidate = candidates.find(candidate => candidate.userId === userId)
    if (! candidate) return ''
    return `${candidate.firstname} ${candidate.name}`
  }

  const approve = skillItem => setSkillItem({...skillItem, state: 'approved'})
  const decline = (skillId, skillItem) => {
    const skill = skills.find(skill => skill._id === skillId)
    skill.skills = skill.skills.filter(item => (item._id !== skillItem._id))
    skillsService.patch(skill._id, skill).then(() => setRefresh(true))
  }

  const removeSkillItem = (skillId, skillItem) => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('remove_skill_item'),
      onConfirm: () => {    
        decline(skillId, skillItem)
      }
    })
  }

  const removeSkill = skillId => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('remove_skill'),
      onConfirm: () => {    
        skillsService.delete(skillId).then(() => setRefresh(true))
      }
    })
  }

  return <React.Fragment>
      <Box display="flex">
        <div style={{flex: 2}}>
          <div className={classes.tab}>
            <TabList items={tabItems} onClick={selected => setTab(selected.id)}/>
          </div>
          { getFilteredSkills().map(skill => {
            return <div key={skill._id}>
              <Box onClick={() => setOpen(skill._id) } className={classes.category}>
                <Typography variant='h5' onClick={() => setOpen(skill._id)}>
                  <b> {skill.label[language]}</b>
                  <small><i>
                    { skill.userfunctions && skill.userfunctions.map(f => {
                      const userfunction = userFunctions.find(uf => uf._id === f);
                      return userfunction ? ' - ' + t(userfunction.name) : ''
                    }) } 
                  </i></small>
                  { tab !== 'pending' &&
                    <>
                      <IconButton aria-label={t('add_specialisation')} onClick={() => setSkillItem({})}>
                        <AddIcon />
                      </IconButton>
                      <IconButton aria-label={t('edit_specialisation')} onClick={() => setSpecialisation(skill)}>
                        <EditIcon className={classes.delete}/>
                      </IconButton>
                      <IconButton onClick={() => removeSkill(skill._id)}>
                        <DeleteIcon className={classes.delete}/>
                      </IconButton>
                    </>
                  }
                </Typography>
                { isOpen(skill._id) ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
              </Box>
              <Collapse in={isOpen(skill._id)} timeout={200} unmountOnExit style={{padding: 10}}>
                <TableContainer className={classes.tablecontainer}>
                  <Table aria-label="simple table">
                    <TableHead>
                      { tab === 'pending' && !! skill.skills.length &&
                        <TableRow>
                          <TableCell>{t('dutch')}</TableCell>
                          <TableCell>{t('english')}</TableCell>
                          <TableCell>{t('french')}</TableCell>
                          <TableCell>{t('submitted_on')}</TableCell>
                          <TableCell>{t('submitted_by')}</TableCell>
                          <TableCell>{t('validation')}</TableCell>
                        </TableRow>
                      }
                    </TableHead>
                    <TableBody>
                    { tab === 'approved' ? skill.skills.map(s => {
                      return <TableRow key={s._id}>
                          <TableCell>{s.label[language]}</TableCell>
                          <TableCell className={classes.actions}>
                            <IconButton onClick={() => setSkillItem(s)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => removeSkillItem(skill._id, s)}>
                              <DeleteIcon/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      })
                      :
                      ! skill.skills.length ?
                        <TableRow>
                          <TableCell>{t('no_pending_skills')}</TableCell>
                        </TableRow>
                      :
                      skill.skills.map(item => {
                        return <TableRow key={item._id}>
                            <TableCell>{item.label.NL}</TableCell>
                            <TableCell>{item.label.EN}</TableCell>
                            <TableCell>{item.label.FR}</TableCell>
                            <TableCell>{moment(item.createdAt).format('DD.MM.YYYY')}</TableCell>
                            <TableCell>{renderUser(item.createdBy)}</TableCell>
                            <TableCell className={classes.actions} style={{width: 80}}>
                              <IconButton onClick={() => approve(item)}>
                                <CheckIcon />
                              </IconButton>
                              <IconButton onClick={() => decline(skill._id, item)}>
                                <CloseIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        })
                    }
                    </TableBody>
                  </Table>
                </TableContainer>  
              </Collapse>
            </div>
          })
        }
        </div>
        { skillItem &&
          <Box className="form-wrapper -full">  
            <Typography variant='h2'>
              { skillItem && skillItem._id ? t('edit_specialisation') : t('new_specialisation') }
            </Typography>
            <div>
              <SkillItemForm 
                onClose={skillId => {
                  if (skillId) setRefresh(true)
                  setSkillItem(false)
                }} 
                skill={skills.find(skill => skill._id === open)} 
                skillItem={skillItem}
                skills={skills}
              />
              <IconButton aria-label={t('cancel')} onClick={() => setSkillItem(false)}> x </IconButton>
            </div>
          </Box> 
        } 
      </Box>
		</React.Fragment>    
}