import React from 'react'
import { t, getLanguage } from '../utils/i18n'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { TextField } from 'formik-material-ui'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Typography } from '@material-ui/core'

// Services
import SkillsService from '../services/skills.service'

// Components
import Dropdown from "./Dropdown";
import MultiSelect from './MultiSelect';

const useStyles = makeStyles((theme) => ({
  tab: { width: 400 },
  dropdown: { 
    width: '100%',
    '& > div': {
      borderRadius: '5px',
      paddingTop: 5,
      paddingBottom: 5
    }
  }
}))

const SkillsSchema = Yup.object().shape({
	label: Yup.object().shape({
    NL: Yup.string().required(t('required')),
    EN: Yup.string().required(t('required')),
    FR: Yup.string().required(t('required'))
  })
})

export function SkillsForm({onClose, userFunctions = [], specialisation = {label: {NL: '', EN: '', FR: ''}}}) {
  const classes = useStyles()
  const [ userFunction, setUserFunction ] = React.useState(
    specialisation.userfunctions && specialisation.userfunctions.length ?
    userFunctions.filter(uf => specialisation.userfunctions.includes(uf._id))
    : userFunctions
  );

  const mapUserFunctions = userfunctions => userfunctions.map(uf => ({...uf, label: t(uf.name), value: uf._id}));

  return <Formik
    initialValues={{ label: specialisation.label }}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={SkillsSchema}
    onSubmit={(values, { setSubmitting }) => {
      values.userfunctions = userFunction || [];
      if (! specialisation._id) {
        SkillsService.post(values).then(({data = {}}) => onClose(data._id))
      } else {
        SkillsService.patch(specialisation._id, values).then(() => onClose(Math.random()))
      }
      setSubmitting(false)
    }}
  >
    {({ submitForm, isSubmitting }) => 
    {
      return (
        <Form>
          <Box margin={1}>
            <Typography variant="h5"> {t('userfunctions')} </Typography>
            <MultiSelect 
              className={classes.dropdown} 
              options={mapUserFunctions(userFunctions)}
              values={userFunction.map(uf => uf._id)}
              onChange={ ids => setUserFunction(ids)}
            />
            <br/>
            <Typography variant="h5" style={{marginTop: 20}}> {t('label')} </Typography>
            <Field
              component={TextField}
              fullWidth
              style={{marginBottom: 20}}
              variant="outlined"
              name="label.NL"
              type="text"
              label={t('dutch')} />
            <Field
              component={TextField}
              fullWidth
              style={{marginBottom: 20}}
              variant="outlined"
              name="label.EN"
              type="text"
              label={t('english')} />
            <Field
              component={TextField}
              fullWidth
              style={{marginBottom: 20}}
              variant="outlined"
              name="label.FR"
              type="text"
              label={t('french')} />
          </Box>
          <Box margin={1} className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              { t('save') }
            </Button>
          </Box>
        </Form>
    )}}
  </Formik>
}

export function SkillItemForm({skill, skillItem, skills, onClose}) {
  const {label: {NL = '', FR= '', EN= '' } = {}} = skillItem
  const classes = useStyles()
  const language = getLanguage()
  const skillCategories = skills.map(({ _id: value, label }) => ({ value, label: label[language] }) )
  const [ category, setCategory ] = React.useState(skill._id)
  
  return <Formik
    initialValues={{ label: {NL, EN, FR} }}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={SkillsSchema}
    onSubmit={(values, { setSubmitting }) => {
      const data = {...values, state: skillItem.state || 'approved'}
      if (skillItem._id) {
        if (category !== skill._id){
          skill.skills = skill.skills.filter(item => item._id !== skillItem._id)
          SkillsService.addSkill(category, data).then(() => {
            SkillsService.patch(skill._id, skill).then(() => onClose(true))
          })
        } else {
          skill.skills = skill.skills.map(item => {
            if (item._id === skillItem._id) return {...skillItem, ...data}
            return item
          })
          SkillsService.patch(skill._id, skill).then(() => onClose(true))
        }
      } else {
        SkillsService.addSkill(category, data).then(() => onClose(true))
      }
      setSubmitting(false)
    }}
  >
    {({ submitForm, isSubmitting }) => 
    {
      return (
        <Form onClick={event => event.stopPropagation()}>
          <Box margin={1}>
            <Typography variant="h5"> {t('category')} </Typography>
            <br/>
            <Dropdown 
              items={skillCategories}
              name="skill"
              label={t('skill')} 
              value={category}
              className={classes.dropdown}
              onChange={ _id => setCategory(_id)} 
            />
            <br/><br/><br/>
            <Typography variant="h5"> {t('label')} </Typography>
            <br/>
            <Field
              component={TextField}
              fullWidth
              style={{marginBottom: 20}}
              variant="outlined"
              name="label.NL"
              type="text"
              label={t('dutch')} />
            <Field
              component={TextField}
              fullWidth
              style={{marginBottom: 20}}
              variant="outlined"
              name="label.EN"
              type="text"
              label={t('english')} />
            <Field
              component={TextField}
              fullWidth
              style={{marginBottom: 20}}
              variant="outlined"
              name="label.FR"
              type="text"
              label={t('french')} />
          </Box>
          <Box margin={1} className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              { t('save') }
            </Button>
          </Box>
        </Form>
    )}}
  </Formik>
}