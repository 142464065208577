// Services
import ApiService from "../services/api.service"

const model = 'files'

export default class FilesService {
  static post(files = []) {
  	const formData = new FormData();
  	files.forEach(file => formData.append('mediafortaFiles', file))
    return ApiService.post(model, formData, {'Content-Type': 'multipart/form-data'}).then(response => response)
  }

  static patch(fileId, patch) {
    return ApiService.patch(`${model}/${fileId}`, patch).then(
      (response) => response
    );
  }

  static delete(fileId) {
    return ApiService.delete(`${model}/${fileId}`).then(response => response) 
  }
}