import React from 'react';
import { t } from '../utils/i18n';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Modal, Box, Typography, TextField as MUITextField} from '@material-ui/core';

// Context
import { useAuth } from "../context/auth";

// Services
import AuthService from "../services/auth.service";

// App components
import Alert from "./Alert";
import Link from "./Link";

const useStyles = makeStyles(theme => ({
  form: { marginTop: theme.spacing(2) },
  link: { textDecoration: 'none' }
}));

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .required(t('required')),
  password: Yup.string()
    .required(t('required'))
});


export default function SigninForm() {
  const { setUser, user } = useAuth();
  const classes = useStyles();
  const [error, setError] = React.useState(false);
  const [twofactorModal, setTwofactorModal] = React.useState(false);
  const [verificationCode, setVerificationCode] = React.useState(false);
  
  const login = (values, { setSubmitting }) => {
    setError(false);
    AuthService.login(values).then((user) => {
      if (user.user && user.user.twofactorEnabled) {
        setTwofactorModal(user);
      } else {
        setUser(user);
      }
    })
    .catch(() => {
      setError(true);
      setSubmitting(false);
    });
  };

  const verify2FA = () => {
    setError(false);
    AuthService.verify2FA({auth: twofactorModal, code: verificationCode, time: new Date() / 1000}).then(() => {
      setUser(twofactorModal);
    })
    .catch(() => {
      setError(true);
    });
  };
  
  return (
    <>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={SigninSchema}
        onSubmit={login}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting }) => (
          <Form className={classes.form}>
            {error && <Alert severity="error">Gegevens niet correct.</Alert>}
            <Grid container className={classes.container}>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="email"
                  style={{ marginBottom: 20 }}
                  type="email"
                  autoComplete="email"
                  variant="outlined"
                  label={t('email')}
                  onKeyUp={event => event.keyCode === 13 && submitForm()}
                  fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  fullWidth
                  style={{ marginBottom: 20 }}
                  variant="outlined"
                  onKeyUp={event => event.keyCode === 13 && submitForm()}
                  name="password"
                  type="password"
                  label={t('password')}
                  autoComplete="current-password" />
              </Grid>
            </Grid>
            <br />
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#0df394', color: '#041e7a', marginRight: 20 }}
              size='large'
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {t('login')} &gt;
            </Button>
            <Link className={classes.link} href="/reset-password">{t('forgot_password')}</Link>
          </Form>
        )}
      </Formik>
        <Modal
        open={twofactorModal}
        onClose={() => setTwofactorModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{ 
          width: 500,
          background: '#fff',
          padding: 20,
          margin: 'auto',
          height: 'fit-content',
          top: 0,
          bottom: 0,
          position: 'absolute',
          left: 0,
          right: 0
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please fill your 2FA verification token
            {error && <Alert severity="error">Gegevens niet correct.</Alert>}
          </Typography>
          <MUITextField
              style={{ marginTop: 20, marginBottom: 20, width: 250, display: 'block' }}
              variant="outlined"
              name="2fa"
              onChange={({currentTarget: {value}}) => setVerificationCode(value)}
              type="text"
              label={t('Verification code')} />
          <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#0df394', color: '#041e7a', marginRight: 20 }}
              size='large'
              onClick={verify2FA}
            >
              {t('verify')} &gt;
            </Button>
        </Box>
      </Modal>
    </>
  );
}