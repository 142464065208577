import { createMuiTheme } from '@material-ui/core/styles'

import theme from './mediaforta'

const tableTheme = createMuiTheme({
  overrides: {
    MuiTable: {
    	root: {
		    borderRadius: 0,
		    '& tbody': {
		      borderRadius: 0,
          '& tr:hover': { backgroundColor: theme.palette.mediaforta.hover }
		    },
		    '& tr': {
		      borderBottom: '1px solid',
		      borderBottomColor: theme.palette.mediaforta.light
		    },
		    '& td, & th': {
		      padding: 10,
		      border: 0,
		      fontSize: 15,
          fontWeight: 500,
		    },
		    '& td': { color: theme.palette.mediaforta.light },
		    '& thead': {
		      '& th': {
		        color: theme.palette.mediaforta.light,
		        fontSize: 12,
		      }
		    }
    	}
    },
    MuiTableContainer: {
    	root: {
	     	position: 'relative',
		  	minHeight: 100,
		    overflow: 'hidden' 
    	}
    }
  }
}, theme)

export default tableTheme