import React from "react";
import { t, getLanguage } from "../utils/i18n";
import { debounce } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TablePagination,
  Box,
  ThemeProvider,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import MultiSelect from "./MultiSelect";

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Share as ShareIcon,
} from "@material-ui/icons";

// Context
import { useApp } from "../context/app";
import { JobContext } from "../context/job";

// Services
import CandidatesService from "../services/candidates.service";
import JobsService from "../services/jobs.service";
import AppService from "../services/app.service";
import UsersService from "../services/users.service";
import SkillsService from "../services/skills.service";

// Candidate components
import CandidateCard from "./CandidateCard";
import CandidateDetailsOverview from "./CandidateDetailsOverview";
import CandidatesJobsFilter from "./CandidatesJobsFilter";

// Components
import TabList from "./TabList";
import SearchFilter from "./SearchFilter";
import AuthenticatedAvatar from "./AuthenticatedAvatar";
import NoteScores from "./NoteScores";
import JobForm from "./JobForm";

// themes
import tableThema from "../themes/table";

import countries from "../assets/countries/CountryList";
import { getLanguageList } from "../assets/languages/LanguageList";

const useStyles = makeStyles((theme) => ({
  imagecell: {
    "& div": {
      marginRight: 10,
      float: "left",
      width: 40,
      height: 40,
    },
    "& label": {
      paddingTop: 9,
      fontWeight: 500,
      fontSize: 12,
      display: "inline-block",
      color: theme.palette.mediaforta.dark,
    },
  },
  up: { color: "#43e68e" },
  down: { color: "#f7313c" },
  evaluation: {
    width: 80,
    textAlign: "center",
    "& div": {
      margin: 0,
      float: "left",
      "& button": { marginRight: 2 },
    },
  },
  row: {
    cursor: "pointer",
    "& th, & td": { width: "fit-content", fontSize: 12 },
    "& th label, & td label": { cursor: "pointer" },
  },
  iconButton: {
    backgroundColor: theme.palette.mediaforta.main,
    width: 25,
    height: 25,
    marginLeft: 10,
    "&:hover": { backgroundColor: theme.palette.mediaforta.dark },
    "& svg": { fontSize: 12, color: "#fff" },
  },
}));

const filters = {
  active: { label: t("active"), filter: `archived!=true` },
  newapplicant: {
    label: t("new_applicant"),
    filter: `archived!=true&!assignedTo`,
  },
  recruiter: { label: t("recruiter"), filter: `archived!=true&assignedTo` },
  phoneinterview: {
    label: t("phone_interview"),
    filter: "archived!=true&status=phone%20interview",
  },
  test: { label: t("test"), filter: `archived!=true&status=test` },
  liveinterview: {
    label: t("live_interview"),
    filter: `archived!=true&status=live%20interview`,
  },
  offer: { label: t("offer"), filter: `archived!=true&status=offer` },
  archived: { label: t("archived"), filter: `archived=true` },
};

const CandidateRow = ({
  classes,
  languages,
  getCandidate,
  candidate,
  setRefreshCandidates,
  minimal = false,
  onClick = false,
}) => {
  candidate.language = candidate.language || "en";
  const { setResponseDialog } = useApp();
  const language = languages.find(
    (l) => l.code === candidate.language.toLowerCase()
  );
  const {
    _id,
    avatar,
    firstname,
    name,
    city,
    notes,
    status = "",
    candidatetags = [],
    userfunction = [],
    createdAt,
  } = candidate;

  const removeCandidate = (event) => {
    event.stopPropagation();
    setResponseDialog({
      title: t("are_you_sure"),
      message: t("sure_remove_candidate"),
      onConfirm: () => {
        CandidatesService.delete(_id).then(setRefreshCandidates(Math.random()));
      },
    });
  };

  return (
    <TableRow
      className={classes.row}
      onClick={() => (onClick ? onClick(candidate) : getCandidate(_id))}
    >
      <TableCell>
        <input
          type="checkbox"
          name="selectedCandidates"
          value={_id}
          onClick={(e) => e.stopPropagation()}
          style={{ width: 15, height: 15 }}
        />
      </TableCell>
      <TableCell
        data-label="name"
        scope="row"
        component="th"
        className={classes.imagecell}
        style={{ display: "flex" }}
      >
        <AuthenticatedAvatar candidate={candidate} avatarId={avatar} />
        <label>
          {firstname} {name}
        </label>
      </TableCell>
      <TableCell data-label="function">
        {userfunction[0] ? userfunction[0].name : candidate.function}
      </TableCell>
      <TableCell data-label="city">{city}</TableCell>
      <TableCell data-label="language">
        {language ? language.name : candidate.language}
      </TableCell>
      {!minimal && (
        <>
          <TableCell data-label="status">{status} </TableCell>
          <TableCell data-label="created">
            {createdAt ? moment(createdAt).format("DD-MM-YYYY") : ""}{" "}
          </TableCell>
          <TableCell data-label="tags">
            {candidatetags.map((tag) => tag.label).join(", ")}
          </TableCell>
          <TableCell
            className={classes.evaluation}
            style={{ width: notes ? notes.length * 65 : 0 }}
          >
            {!!notes &&
              notes.map((note) => (
                <NoteScores key={note._id} note={note} size="small" />
              ))}
          </TableCell>
          <TableCell style={{ display: "flex", marginTop: 10 }}>
            <DeleteIcon onClick={removeCandidate} style={{ width: 20 }} />
            {!!candidate.publicEnabled && (
              <a
                href={`/public/candidate/${candidate._id}`}
                style={{ width: 15, color: "#b5bede" }}
                target="_blank"
              >
                <ShareIcon />
              </a>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

const params = new URLSearchParams(window.location.search);

export const fetchCandidates = async (search, limit, offset, filter, sort) =>
  CandidatesService.list(search, limit, offset, "", filter, sort);
export default function CandidatesList({
  candidate: newCandidate,
  minimal = false,
  onClick = false,
}) {
  const classes = useStyles();
  const { error, setResponseDialog, setCandidatesFilterResult } = useApp();
  const [candidates, setCandidates] = React.useState({});
  const [filter, setFilter] = React.useState(window.location.search.slice(1));
  const [sort, setSort] = React.useState("-createdAt");
  const [searchInput, setSearchInput] = React.useState(
    params.get("search") || false
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [candidate, setCandidate] = React.useState(newCandidate);
  const [selectedJobs, setSelectedJobs] = React.useState([]);
  const [candidatesFilter, setCandidatesFilter] = React.useState([]);
  const [job, setJob] = React.useState(false);
  const [applicantLevels, setApplicantLevels] = React.useState(false);
  const [employmentTypes, setEmploymentTypes] = React.useState(false);
  const [jobLanguages, setJobLanguages] = React.useState(false);
  const [refreshJobs, setRefreshJobs] = React.useState(1);
  const [refreshCandidates, setRefreshCandidates] = React.useState(1);
  const [searchFilters, setSearchFilters] = React.useState([]);
  const [selectedFolders, setSelectedFolders] = React.useState([]);
  const [folders, setFolders] = React.useState(null);
  const languages = getLanguageList();
  const language = getLanguage();

  React.useEffect(() => {
    if (!folders) {
      CandidatesService.listFolders().then(({ records }) => {
        setFolders(records);
        formFilters.splice(8, 0, {
          label: t("folder"),
          name: "folders",
          filter: `folders=`,
          type: "autocomplete",
          options: records.map(({ _id, name, candidates }) => ({
            _id,
            label: name,
            code: _id,
          })),
        });
      });
    }
  }, [folders]);

  const addCandidatesToFolder = () => {
    var inputs = document.querySelectorAll(
      '[name="selectedCandidates"]:checked'
    );

    const ids = [];
    for (let i = 0; i < inputs.length; i++) {
      ids.push(inputs[i].value);
    }
    CandidatesService.addCandidatesToFolders({
      candidates: ids,
      folders: selectedFolders,
    }).then(({ records }) => {
      setResponseDialog({
        title: t("great_success"),
        message: t("candidates added"),
        onClose: () => {
          setRefreshCandidates(Math.random());
        },
      });
    });
  };

  const formFilters = [
    { label: "firstname", name: "firstname", filter: `firstname=` },
    { label: "name", name: "name", filter: `name=` },
    { label: "postal", name: "postal", filter: `postal=` },
    { label: "city", name: "city", filter: `city=` },
    {
      label: "country",
      name: "country",
      filter: `country=`,
      type: "autocomplete",
      options: countries,
    },
    {
      label: "native_language",
      name: "motherTongue",
      filter: `candidateInfo.skillSet.motherTongue=`,
      type: "autocomplete",
      options: getLanguageList(),
    },
    {
      label: "translate_from",
      name: "translate_from",
      filter: `candidateInfo.skillSet.translationSkills.from=`,
      type: "autocomplete",
      options: getLanguageList(),
    },
    {
      label: "translate_to",
      name: "translate_to",
      filter: `candidateInfo.skillSet.translationSkills.to=`,
      type: "autocomplete",
      options: getLanguageList(),
    },
    {
      label: "years_experience",
      name: "yearsExperience",
      filter: `candidateInfo.skillSet.yearsExperience=`,
      type: "text",
    },
    {
      label: "worked_as_freelancer",
      name: "freelancer",
      filter: `candidateInfo.freelancer=`,
      type: "checkbox",
    },
    {
      label: "available_from",
      name: "unavailableFrom",
      filter: `candidateInfo.unavailable.from=`,
      type: "date",
      format: "DD-MM-YYYY",
    },
    {
      label: "available_to",
      name: "unavailableTo",
      filter: `candidateInfo.unavailable.to=`,
      type: "date",
      format: "DD-MM-YYYY",
    },
    {
      label: "modifiedAt",
      name: "modifiedAt",
      filter: `modifiedAt=`,
      type: "date",
      format: "DD-MM-YYYY",
    },
    {
      label: "createdAt_from",
      name: "createdAt_from",
      filter: `createdAt.from=`,
      type: "date",
      format: "DD-MM-YYYY",
    },
    {
      label: "createdAt_to",
      name: "createdAt_to",
      filter: `createdAt.to=`,
      type: "date",
      format: "DD-MM-YYYY",
    },
    {
      label: "modifiedByRecruiterAt",
      name: "modifiedByRecruiterAt",
      filter: `modifiedByRecruiterAt=`,
      type: "date",
      format: "DD-MM-YYYY",
    },
  ];

  React.useEffect(() => {
    if (!searchFilters.length) {
      SkillsService.list().then(({ records }) => {
        if (!formFilters.find((f) => f.name === "skills")) {
          formFilters.splice(8, 0, {
            label: t("skills"),
            name: "skills",
            filter: `skills.skillId=`,
            type: "autocomplete",
            options: records
              .reduce((memo, record) => {
                const skills = record.skills.map(({ _id, label }) => ({
                  _id,
                  label: label[language],
                  code: _id,
                }));
                return [...memo, ...skills];
              }, [])
              .sort((a, b) =>
                a.label > b.label ? 1 : b.label > a.label ? -1 : 0
              ),
          });
          formFilters.splice(8, 0, {
            label: t("skills_categories"),
            name: "skillscategories",
            filter: `skills.skillId=`,
            type: "autocomplete",
            options: records
              .map(({ label, skills }) => {
                const code = skills
                  .map((s) => s._id)
                  .join(",")
                  .toString();
                return {
                  _id: code,
                  label: label[language],
                  code,
                };
              })
              .sort((a, b) =>
                a.label > b.label ? 1 : b.label > a.label ? -1 : 0
              ),
          });
        }
        CandidatesService.listTags().then(({ records }) => {
          formFilters.splice(8, 0, {
            label: t("tags"),
            name: "tags",
            filter: `tags=`,
            type: "autocomplete",
            multiple: true,
            options: records.map(({ _id, label }) => ({
              _id,
              label,
              code: _id,
            })),
          });

          UsersService.listUserFunctions().then(({ records }) => {
            formFilters.splice(8, 0, {
              label: t("userfunctions"),
              name: "userfunctions",
              filter: `function=`,
              type: "autocomplete",
              options: records.map(({ _id, name }) => ({
                _id,
                label: name,
                code: _id,
              })),
            });
            setSearchFilters(formFilters);
          });
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (newCandidate !== candidate) setCandidate(newCandidate);
    // eslint-disable-next-line
  }, [newCandidate]);

  React.useEffect(() => {
    if (!jobLanguages && !employmentTypes && !applicantLevels) {
      AppService.languages().then(setJobLanguages);
      JobsService.listEmploymentTypes().then(setEmploymentTypes);
      JobsService.listApplicantLevels().then(setApplicantLevels);
    }
    // eslint-disable-next-line
  }, []);

  const setError = (error) => setResponseDialog({ error });
  React.useEffect(() => {
    setCandidates(false);
    if (!error) {
      fetchCandidates(
        searchInput,
        rowsPerPage,
        page * rowsPerPage,
        filter.concat(
          selectedJobs.length
            ? `&_id=${
                candidatesFilter.length ? candidatesFilter.toString() : "null"
              }`
            : ""
        ),
        sort
      )
        .then((response) => {
          setCandidates(response);
          setCandidatesFilterResult(response.records.map((r) => r._id));
        })
        .catch(setError);
    }
    // eslint-disable-next-line
  }, [
    searchInput,
    rowsPerPage,
    page,
    filter,
    candidatesFilter,
    refreshCandidates,
    sort,
  ]);

  const search = (event) => debounceSearch(event.target.value);
  const debounceSearch = debounce((q) => {
    setSearchInput(q);
    setPage(0);
  }, 300);

  const handleChangePage = (event, newPage) => setPage(parseInt(newPage));
  const handleChangeRowsPerPage = (event) => {
    const rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPage(0);
  };

  const getCandidate = (_id) =>
    CandidatesService.get(_id).then(setCandidate).catch(setError);
  const getStats = () => {
    const stats = candidates.stats || {};
    return Object.keys(filters).map((stat) => {
      return {
        primary: stats[stat] || 0,
        id: stat,
        secondary: filters[stat].label,
        filter: filters[stat].filter,
      };
    });
  };

  const setStatFilter = (stat) => {
    setPage(0);
    if (filter === stat.filter) return setFilter("");
    setFilter(stat.filter);
  };

  const mapFolders = () =>
    folders ? folders.map((f) => ({ ...f, label: f.name, value: f._id })) : [];

  return (
    <React.Fragment>
      <Box display="flex">
        <div style={{ flex: 2 }}>
          <SearchFilter
            onChange={search}
            formFilters={searchFilters}
            onFilter={setFilter}
            value={searchInput}
            placeholder={t("search_candidates")}
          />
          {!minimal && (
            <TabList
              items={getStats()}
              onClick={(stat) => setStatFilter(stat)}
            />
          )}
          <MultiSelect
            options={mapFolders(folders)}
            values={folders ? folders.map((uf) => uf._id) : []}
            onChange={(ids) => setSelectedFolders(ids)}
          />
          {!!selectedFolders.length && (
            <Button
              color="primary"
              className={classes.save}
              onClick={() => addCandidatesToFolder()}
            >
              {" "}
              {t("Add selected candidates to folders")}{" "}
            </Button>
          )}
          <ThemeProvider theme={tableThema}>
            <form name="candidates">
              <TableContainer className={classes.tablecontainer}>
                {candidates &&
                  candidates.records &&
                  !!candidates.records.length && (
                    <React.Fragment>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{t("name")}</TableCell>
                            <TableCell>{t("function")}</TableCell>
                            <TableCell>{t("location")}</TableCell>
                            <TableCell>{t("language")}</TableCell>
                            {!minimal && (
                              <>
                                <TableCell>{t("status")}</TableCell>
                                <TableCell
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setSort(
                                      sort === "createdAt"
                                        ? "-createdAt"
                                        : "createdAt"
                                    )
                                  }
                                >
                                  {t("createdAt")}
                                </TableCell>
                                <TableCell style={{ width: 200 }}>
                                  {t("tags")}
                                </TableCell>
                                <TableCell>{t("evaluation")}</TableCell>
                                <TableCell></TableCell>
                              </>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {candidates.records.map((row) => (
                            <CandidateRow
                              key={row._id}
                              classes={classes}
                              getCandidate={getCandidate}
                              candidate={row}
                              languages={languages}
                              minimal={minimal}
                              onClick={onClick}
                              setRefreshCandidates={setRefreshCandidates}
                            />
                          ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={candidates.totalCount || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </React.Fragment>
                  )}
              </TableContainer>
            </form>
          </ThemeProvider>
        </div>
        {minimal ? (
          <></>
        ) : candidate ? (
          <div style={{ flex: 1, marginLeft: 40, marginBottom: 20 }}>
            <CandidateCard
              candidate={candidate}
              card={{ id: "details", component: CandidateDetailsOverview }}
              hideTabs={true}
              onClose={() => setCandidate(false)}
              onUpdate={() => setRefreshCandidates(refreshCandidates + 1)}
              edit={!candidate._id}
            />
          </div>
        ) : (
          <div style={{ flex: 1, marginLeft: 40, marginBottom: 20 }}>
            <Typography
              component="h1"
              variant="h5"
              style={{ fontWeight: 500, fontSize: 30, marginBottom: 20 }}
            >
              Jobs
              <IconButton
                aria-label={t("add_job")}
                onClick={() => setJob({})}
                className={classes.iconButton}
              >
                <AddIcon />
              </IconButton>
            </Typography>
            <CandidateCard
              card={{ id: "details", component: CandidatesJobsFilter }}
              onChange={({ jobs, candidates }) => {
                setSelectedJobs(jobs);
                setCandidatesFilter(candidates);
              }}
              refresh={refreshJobs}
              hideTabs={true}
            />
            {job && (
              <JobContext.Provider
                value={{
                  applicantLevels,
                  employmentTypes,
                  languages: jobLanguages,
                }}
              >
                <Box className={classes.wrapper + " form-wrapper"}>
                  <h2> {t("new_job")} </h2>
                  <div>
                    <JobForm
                      onClose={() => {
                        setJob(false);
                        setRefreshJobs(refreshJobs + 1);
                      }}
                      job={job}
                      edit={true}
                    />
                    <IconButton
                      aria-label={t("cancel")}
                      onClick={() => setJob(false)}
                    >
                      {" "}
                      x{" "}
                    </IconButton>
                  </div>
                </Box>
              </JobContext.Provider>
            )}
          </div>
        )}
      </Box>
    </React.Fragment>
  );
}
