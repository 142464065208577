import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { Star as StarIcon,  StarBorder as StarBorderIcon } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  stars: { cursor: 'pointer' }
}))

export default function SkillStars({ skill, setSkill }) {
  const classes = useStyles()
  const [ refresh, setRefresh ] = React.useState(0)
    
  const toggleStars = level => {
    if (! setSkill) return
    skill.level = level
    setSkill(skill)
    setRefresh(refresh + 1)
  }

  return (
    <Grid item className={setSkill ? classes.stars : ''}>
      {[...Array(skill.level)].map((star, n) => <StarIcon onClick={()=> toggleStars(n + 1)} key={n} />)}
      {[...Array(5 - (skill.level))].map((star, n) => <StarBorderIcon onClick={()=> toggleStars(n + skill.level + 1)} key={n} />)}
    </Grid>
  ) 
}