import * as React from 'react'
import { t } from '../utils/i18n'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import TasksService from "../services/tasks.service"
import CandidatesService from "../services/candidates.service"
import { useApp } from "../context/app"
import { TextField } from 'formik-material-ui'
import MuiTextField from '@material-ui/core/TextField'
import { Autocomplete } from 'formik-material-ui-lab'
import DatePicker from './DatePicker'
import RichEditor, { RichEditorHelpers } from './RichEditor'
import { Button, MenuItem, Box } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  description: { '& .DraftEditor-editorContainer > div': { minHeight: 250 } },
  button: { textAlign: 'right' }
}))

const types = [
  { value: 'todo', label: t('todo') },
  { value: 'call', label: t('call') }
]

const TaskSchema = Yup.object().shape({
	title: Yup.string()
  	.min(2, t('title_short'))
   .required(t('required')),
  type: Yup.string()
   .required(t('required'))
})

export default function TaskForm(props) {
  const { task } = props
  const classes = useStyles()
	const { setResponseDialog } = useApp()

	const today = moment()
	const [deadline, setDeadline] = React.useState(task && task.deadline ? moment(task.deadline) : today)
	const [candidates, setCandidates] = React.useState(false)
  const [assignedTo, setAssignedTo] = React.useState(null)

  React.useEffect(() => {
    if (task.assignedTo && ! assignedTo) {
      CandidatesService.get(task.assignedTo).then(candidate => setAssignedTo({ _id: candidate._id, name: `${candidate.firstname} ${candidate.name}` }))
    }
  })

  const [ description, setDescription ] = React.useState(
    task && task.description ? 
      RichEditorHelpers.convertFromHtml(task.description) : 
      RichEditorHelpers.createEmpty()
  )

  React.useEffect(() => {
    if (! candidates) CandidatesService.list().then(data => setCandidates(data.records.map(r => { return { _id: r._id, name: `${r.firstname} ${r.name}` } })))
  })

  const setDialog = message => {
    return setResponseDialog({
      title: t('great_success'),
      message,
      onClose: props.onClose || false 
    })
  }

	return (
  	<Formik
	    initialValues={{
	    	title: task && task.title ? task.title : '',
	    	type: task && task.type ? task.type : 'todo'
	    }}
	    validateOnChange={false}
	    validateOnBlur={false}
	    validationSchema={TaskSchema}
	    validate={() => {
        const errors =  {}
	    	if (! assignedTo) { errors.assignedTo = t('required') }
        if (RichEditorHelpers.getPlainText(description).length < 2) { errors.description = t('min_chars', false, {amount: 2}) }
	    	return Object.keys(errors).length ? errors : false
	    }}
	    onSubmit={(values, { setSubmitting }) => {
	    	const data = { assignedTo: assignedTo._id, deadline: deadline.toDate(), description: RichEditorHelpers.convertToHtml(description), ...values }
        if (props.candidate && props.candidate._id) {
          data.candidateId = props.candidate._id
        }
        if (task && task._id) {
          TasksService.patch(task._id, data).then(() => {
            setDialog(t('task_updated'))
          }).catch(error => setResponseDialog({error}))
        } else {
          TasksService.post(data).then(() => {
            setDialog(t('task_created'))
          }).catch(error => setResponseDialog({error}))        
        }
	      setSubmitting(false)
	    }}
    >
	    {({ submitForm, isSubmitting, errors, validateField }) => 
	    {
	    	return (
	        <Form
	    			onBlur = {e => {
	    				const fieldName = e.target.name
	    				fieldName && fieldName !== 'image-url' && validateField(fieldName)
				    }}
	        >
	        	<Box margin={1}>
              <Field 
		            name="title" 
		            component={TextField} 
		            label={t('title')}
		            variant="outlined" 
								style={{width: '100%'}}
		            margin="normal"/>
	          </Box>
	          <Box margin={1}>
	            <Field
	              name="assignedTo"
	              id="assignedTo"
	              component={Autocomplete}
	              options={candidates ? candidates : assignedTo ? [assignedTo] : []}
	              getOptionLabel={ option => option.name || '' }
	              value={assignedTo}
	              margin="normal"
	              onChange={(event, value) => setAssignedTo(value)}
	              renderInput={(params) => (
	              	<Field 
	              		{...params} 
	              		component={MuiTextField} 
	              		error={!! errors['assignedTo']} 
	              		helperText={errors['assignedTo']} 
	              		label={t('assign_to')} 
	              		variant="outlined" 
	              		name="assignedTo" />
	              )}
	            />
	          </Box>
	          <Box display="flex" margin={1}>  
							<Field
	              component={TextField}
	              type="text"
	              name="type"
	              label={t('type')}
	              select
	              style={{ width: '100%', marginRight: 10 }}
	              variant="outlined"
	              margin="normal"
	              InputLabelProps={{
	                shrink: true,
	              }}
	            >
	              {types.map(option => (
	                <MenuItem key={option.value} value={option.value}>
	                  {option.label}
	                </MenuItem>
	              ))}
	            </Field>
	            <DatePicker 
	          		name="deadline"
	              disabled={isSubmitting}
	              ampm={false}
	              minDate={today}
	              label={t('due_date')}
	              style={{width: '100%'}}
	              variant="outlined"
	              margin="normal"
	              format="DD.MM.YYYY HH:mm"
	              error={!! errors['deadline']} 
            		helperText={errors['deadline']} 
	              value={deadline}
	            	onChange={setDeadline}
	          	/>
	          </Box>
	          <Box margin={1} className={classes.description}>
              <Field 
                name="description" 
                component={RichEditor} 
                label={t('description')}
                editorState={description}
                onChange={setDescription}
                error={!! errors['description']} 
                helperText={errors['description']}
                variant="outlined" 
                style={{width: '100%'}}
                margin="normal"/>
	          </Box>
	          <Box margin={1} className={classes.button}>
	            <Button
	              variant="contained"
	              color="primary"
	              disabled={isSubmitting}
	              onClick={submitForm}
	            >
                { task && task._id ? t('edit_task') : t('add_task') }
	            </Button>
	          </Box>
	        </Form>

	    )}}
  	</Formik>
  )
}