import React from 'react';
import { t } from '../utils/i18n';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box,
  IconButton
} from '@material-ui/core';
import { Delete as DeleteIcon, Scanner as QrCodeIcon } from '@material-ui/icons';
// Context
import { useApp } from "../context/app";

// Services
import usersService from '../services/users.service';

const useStyles = makeStyles(theme => ({
  tab: { width: 400 },
  category: {
    cursor: 'pointer',
    display: 'flex',
    padding: '30px 20px 0',
    '& h5': {
      flex: 1,
      '& svg': {
        background: theme.palette.mediaforta.main,
        borderRadius: '50%',
        fontSize: 20,
        fill: '#fff'
      }
    }
  },
  actions: {
    padding: 0,
    margin: '0 0 0 10px',
    '& svg': { fontSize: 20 }
  }
}));

export const deleteRole = async (userId, role) => usersService.deleteRole(userId, role);
export const generateQr = async (userId) => usersService.generateQr(userId);

export default function RecruitersList({ recruiters, onRemove }) {
  const classes = useStyles();
  const { setResponseDialog } = useApp();

  const removeRecruiterRole = userId => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure_remove_recruiter_role'),
      onConfirm: () => {
        deleteRole(userId, 'recruiter').then(() => onRemove());
      }
    });
  };

  const generateRecruiterQr = userId => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure_generate_qr'),
      onConfirm: () => {
        generateQr(userId).then(() => onRemove());
      }
    });
  };

  return <React.Fragment>
    <Box display="flex">
      <div style={{ flex: 2 }}>
        <TableContainer className={classes.tablecontainer}>
          <Table aria-label="simple table">
            <TableBody>
              {!recruiters.length ?
                <TableRow>
                  <TableCell>{t('no_recruiters')}</TableCell>
                </TableRow>
                :
                recruiters.map(({ _id, name, firstname, email }, index) => {
                  return <TableRow key={index}>
                    <TableCell>
                      {firstname} {name}
                    </TableCell>
                    <TableCell>
                      {email}
                    </TableCell>
                    <TableCell width={30}>
                      <IconButton className={classes.actions} aria-label={t('qr_recruiter')} onClick={() => generateRecruiterQr(_id, index)}>
                        <QrCodeIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell width={30}>
                      <IconButton className={classes.actions} aria-label={t('remove_recruiter_role')} onClick={() => removeRecruiterRole(_id, index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>;
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  </React.Fragment>;
}