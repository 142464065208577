import React from 'react'
import { t } from '../utils/i18n'
import config from '../config'
import moment from 'moment'
import { debounce } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Paper, Button, Chip, TablePagination, IconButton, Typography } from '@material-ui/core'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  AttachFile as AttachFileIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon
} from '@material-ui/icons'

// Context
import { useApp } from "../context/app"

// Services
import TestsService from "../services/tests.service"

// Model components
import TestScoreForm from "./TestScoreForm"

// App components
import AuthenticatedLink from "./AuthenticatedLink"
import SearchFilter from "./SearchFilter"
import MediaFortaDialog from "./MediaFortaDialog"

const useStyles = makeStyles(theme => ({
  test: {
    fontSize: 12,
    padding:10,
    '& h2, & h3, & div > a': { margin: 5, display: 'flex'},
    '& > div > div': { marginTop: 5 },
    '& > div > svg': {
      position: 'absolute',
      right: 10,
      top: 6
    },
    '& a': { 
      position: 'relative',
      '& > svg': { position: 'absolute', fontSize: 20, left: 10 }
    }
  },
  chip: {
    marginTop: -5,
    fontSize: 14,
    marginLeft: 4,
    padding: 15,
    cursor: 'pointer',
    '& :hover': { textDecoration: 'underline' },
  },
  remark: { backgroundColor: '#f3f3f3' },
  score: { 
    flex: 1, 
    marginTop: '2px !important', 
    marginLeft: '5px !important', 
    marginRight: '5px !important', 
    fontWeight: 500, 
    fontSize: 18, 
    color: theme.palette.info.dark 
  },
  actions: {
    display: 'flex',
    marginTop: 15,
    '& svg': { fontSize: 18 }
  },
  add: {
    fontWeight: 500,
    margin: '10px 5px',
    color: '#606060',
    cursor: 'pointer',
    textAlign: 'right'
  },
  dialog: { '& .MuiDialog-container > div': { width: 800, maxWidth: 800 } }
}))

const { API_URL } = config.mediaforta

export const fetchTests = async (search, limit, offset, fields, filter) => TestsService.list(search, limit, offset, fields, filter)
export default function TestsList({ test, candidate, filters, fullwidth }) {
  const classes = useStyles()
  const { setResponseDialog } = useApp()

  const [ tests, setTests ] = React.useState({})
  const [ open, setOpen ] = React.useState(false)
  const [ dialog, setDialog ] = React.useState(false)
  const [ filter, setFilter ] = React.useState('')
  const [ searchInput, setSearchInput ] = React.useState(false)
  const [ page, setPage ] = React.useState(0)
  const [ rowsPerPage, setRowsPerPage ] = React.useState(10)
  const [ selectedScore, setSelectedScore ] = React.useState(-1)

  const search = event => debounceSearch(event.target.value)
  const debounceSearch = debounce(q => setSearchInput(q), 300);

  const setError = error => {
    setTests({})
    setResponseDialog({error})
  }

  const toggleOpen = test => {
    tests.records = tests.records.map(t => {
      if (test._id === t._id) t.open = ! t.open
      return t
    })
    setOpen(! open)
  }

  const getFilter = () => {
    let f = filter
    if (candidate) f = `${f ? f + '&' : ''}candidateId=${candidate._id}`
    return f
  }

  React.useEffect(() => {
    fetchTests(searchInput, rowsPerPage, page*rowsPerPage, undefined, getFilter() )
      .then(setTests)
      .catch(setError)
    // eslint-disable-next-line
  }, [ searchInput, rowsPerPage, page, filter, test ])  

  const handleChangePage = ({}, newPage) => setPage(parseInt(newPage))
  const handleChangeRowsPerPage = ({target}) => {
    const rows = parseInt(target.value, 10)
    setRowsPerPage(rows)
    setPage(0)
  }

  const removeScore = (test, index) => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure_remove_score'),
      onConfirm: () => {
        test.scores.pop(index)
        TestsService.patch(test._id, test)
      }
    })
  }

  const removeTest = testId => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure_remove_test'),
      onConfirm: () => TestsService.delete(testId).then(() => {
        tests.records = tests.records.filter(r => r._id !== testId) 
        tests.totalCount = --tests.totalCount
        setTests(tests)
        toggleOpen({_id: testId})
      })
    })
  }
  
  const isSelectedScore = (test, i) => {
    if (! selectedScore) return false
    return selectedScore._id === test._id && selectedScore.index === i
  }

  const renderTestDetails = test => {
    return <Box display="flex">
      <h3>{ moment(test.createdAt).format('DD/MM/YYYY') }</h3>
      { ! test.open && test.scores && !! test.scores.length && <Typography className={classes.score}> Score {(test.scores.reduce((m, s) => m+s.score, 0) / test.scores.length).toFixed(1)} / 10 </Typography>}
      { test.file &&
        <AuthenticatedLink url={`${API_URL}candidates/${candidate._id}/file/${test.file._id}`} filename={test.file.originalname} >
          <AttachFileIcon /> 
          <Chip label={test.file.originalname} className={classes.chip} />
        </AuthenticatedLink>
      }
    </Box>
  }
  
  if (! tests || ! tests.records) return null
  return (
    <React.Fragment>
      <SearchFilter 
        onChange={search} 
        value={searchInput} 
        placeholder={t('search_tests')}
        filter={filter}
        filters={filters}
        setFilter={setFilter}
      />
      <Grid container spacing={2}>
        { tests.records.map(test => {
          return <Grid key={test._id} open={test.open} item xs={fullwidth ? 12 : 6}>
            <Paper variant="outlined" className={classes.test}>
              <Box display="flex" onClick={() => toggleOpen(test) } style={{cursor: 'pointer', position: 'relative'}}>
                <h2>{ test.name }</h2>
                { ! test.open && renderTestDetails(test) }
                { test.open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
              </Box>
              { test.open && renderTestDetails(test) }
              { test.open && test.scores &&
                test.scores.map((score, i) => {
                  return <Paper key={i} display="flex" elevation={2} className={classes.remark}>
                    <div>
                      { isSelectedScore(test, i) ? 
                        <TestScoreForm score={score} data={test} index={i} onClose={() => { setSelectedScore(-1) }}/>
                        :
                        score.remark
                      }
                    </div>
                    { ! isSelectedScore(test, i) &&
                      <Box className={classes.actions}>
                        <Typography className={classes.score}> Score {score.score} / 10 </Typography>
                        <div>
                          <IconButton onClick={() => setSelectedScore({ _id: test._id, index: i })}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => removeScore(test, i)}>
                            <DeleteIcon/>
                          </IconButton>
                        </div>
                      </Box>
                    }
                  </Paper>
                })
              }
              { test.open && 
                <Box display="flex" margin={2} style={{ justifyContent: 'flex-end' }}>
                  <Typography className={classes.add} onClick={() => setDialog(test)}> + Add score </Typography>
                  <Button variant="contained" color="secondary" style={{ marginLeft: 10, padding: '3px 12px' }} onClick={() => removeTest(test._id)}>
                    {t('remove')}
                  </Button>
                </Box> 
              }
            </Paper>
          </Grid>
        })}
      </Grid>
      { !! tests.records.length &&
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tests.totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      }
      { dialog && 
        <MediaFortaDialog data={{
            title: 'Add Score', 
            onClose: () => { setDialog(false) }, 
            component: { Component: TestScoreForm, data: dialog, className: classes.dialog }
          }} 
        />
      }
    </React.Fragment> 
  )
}