import React from 'react'
import { t } from '../utils/i18n'
import { debounce } from 'lodash'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Paper, TablePagination, Typography } from '@material-ui/core'
import { Email as EmailIcon } from '@material-ui/icons'

// Context
import { useApp } from "../context/app"

// Services
import EmailsService from "../services/emails.service"

// Components
import SearchFilter from "./SearchFilter"

const useStyles = makeStyles(theme => ({
  email: {
    '& svg': {
      padding: 6,
      fontSize: 36,
      maxWidth: 36,
      background: theme.palette.mediaforta.superlight,
      borderRadius: 20,
      color: theme.palette.mediaforta.light,
      cursor: 'pointer'
    }
  },
   labels: {
    paddingBottom: 5,
    '& > *': {
      marginRight: 20,
      marginLeft: 5
    }
  },
  message: { '& > div > *': { margin: '5px 0' }},
  header: {
    paddingBottom: 15,
    '& > *': { flex: 1 },
    '& > p': {
      textAlign: 'right',
	    paddingTop: 10,
	    color: theme.palette.mediaforta.light
    },
    '& h2': { margin: '3px 10px' }
  },
  actions: {
    marginTop: 25,
    float: 'right',
    '& > *': {
      padding: 5,
      float: 'left'
    },
    '& svg': { fontSize: 20 }
  },
  subject: {
  	flex: 2,
  	marginRight: 0,
    textAlign: 'right'
  }
}))

export const fetchEmails = async (search, limit, offset, fields, filter) => EmailsService.list(search, limit, offset, fields, filter)
export default function EmailsList({ email, candidate = {}, fullwidth }) {
  const classes = useStyles()
  const { setResponseDialog } = useApp()

  const [ emails, setEmails ] = React.useState({})
  const [ searchInput, setSearchInput ] = React.useState(false)
  const [ page, setPage ] = React.useState(0)
  const [ rowsPerPage, setRowsPerPage ] = React.useState(10)

  const search = event => debounceSearch(event.target.value)
  const debounceSearch = debounce(q => setSearchInput(q), 300);

  const setError = error => {
    setEmails({})
    setResponseDialog({error})
  }

  const getFilter = () => `candidateId=${candidate._id}`
  React.useEffect(() => {
  	if (! email) {
	    fetchEmails(searchInput, rowsPerPage, page*rowsPerPage, undefined, getFilter() )
	      .then(setEmails)
	      .catch(setError)
  	}
    // eslint-disable-next-line
  }, [ searchInput, rowsPerPage, page, email ])  


  const handleChangePage = (event, newPage) => setPage(parseInt(newPage))
  const handleChangeRowsPerPage = (event) => {
    const rows = parseInt(event.target.value, 10)
    setRowsPerPage(rows)
    setPage(0)
  }

  if (! emails || ! emails.records) return null
  return ( 
    <React.Fragment>
      <SearchFilter 
        onChange={search} 
        value={searchInput} 
        placeholder={t("search_emails")}
      />
      <Grid container spacing={2}>
        { emails.records.map(email => {
          return <Grid key={email._id} item xs={fullwidth ? 12 : 6}>
            <Paper variant="outlined" className={classes.email}>
              <Box display="flex" className={classes.header}>
                <EmailIcon/>
                <h2>{t('mail')}</h2>
                <Typography>
                  { email.scheduledAt ? 
                    moment(email.scheduledAt).format('DD.MM.YYYY HH:mm') :
                    moment(email.createdAt).format('DD.MM.YYYY HH:mm')
                  }
                </Typography>
                </Box>
              <Box display="flex" className={classes.labels}>
                <Typography> <label>{t("from")} </label> {email.from.name} </Typography>
                <Typography> <label>{t("to")} </label> {email.to.name} </Typography>
                <Typography className={classes.subject}> <label>Subject </label> {email.subject} </Typography>
              </Box>
              <Box display="flex" className={classes.labels}>
                { email.cc && <Typography> <label>Cc </label> {email.cc[0].name} </Typography> }
                { email.bcc && <Typography> <label>Bcc </label> {email.bcc[0].name} </Typography> }
              </Box>
              <Paper display="flex" elevation={2} className={classes.message}>
                <div dangerouslySetInnerHTML={{ __html: email.message }} />
              </Paper>
            </Paper>
          </Grid>
        })}
      </Grid>
      { !! emails.records.length &&
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={emails.totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      }
    </React.Fragment>    
  )
}