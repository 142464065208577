import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(3) }
}))

export default function Alert(props) {
  const classes = useStyles()
  return <MuiAlert className={classes.root} elevation={6} variant="filled" {...props} />
}