import * as React from 'react';
import { t, getLanguage } from '../utils/i18n';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import {
	Button,
	Box,
	TextField as MuiTextField,
	Paper,
	FormControl,
	InputLabel,
	Chip,
	IconButton
} from '@material-ui/core';

// Context
import { useJob } from "../context/job";
import { useApp } from "../context/app";

// Services
import jobsService from '../services/jobs.service';

// Components
import RichEditor, { RichEditorHelpers } from './RichEditor';
import MediumAvatar from './MediumAvatar';
import ImageUpload from './ImageUpload';


const useStyles = makeStyles(theme => ({
	avatarWrapper: { position: 'relative', width: 120 },
  uploadIcon: { position: 'absolute', zIndex: 9, top: 10 },
	disabled: {
		'& .Mui-disabled': {
			color: theme.palette.mediaforta.light,
			fontWeight: 400
		},
		'& input.Mui-disabled, textarea.Mui-disabled': {
			color: theme.palette.mediaforta.dark,
			fontWeight: 500,
		},
		'& .MuiInput-underline:before': {
			borderBottom: '1px dotted rgba(0, 0, 0, 0.2)',
			marginRight: 30
		},
		'& .MuiPaper-root': {
			marginRight: 30,
			borderTop: 0,
			marginTop: 20,
			padding: '0 0 5px 0'
		},
		'& .MuiAutocomplete-endAdornment': { display: 'none' }
	},
	formControl: {
		width: '100%',
		marginTop: 16,
		'& .RichEditor-Wrapper ': {
			marginTop: 24
		}
	},
	tagForm: {
		'& > div': {
			'& > div': {
				width: 'calc(50% - 8px)',
				minWidth: 'auto'
			},
			'& > label': {
				marginTop: -8,
				marginLeft: 14,
				backgroundColor: '#fff',
				padding: '0 2px',
				zIndex: 1
			}
		}
	},
	tags: {
		margin: '16px 8px',
		'& > div': {
			paddingTop: 25,
			flexDirection: 'row'
		}
	}
}));

const MediumSchema = Yup.object().shape({
	name: Yup.string().required(t('required')),
	street: Yup.string(),
	postal: Yup.string(),
	country: Yup.string(),
  url: Yup.string(),
  startPrice: Yup.string()
});

export default function MediumForm({ onClose, medium = {}, edit, onUpdate }) {
	const classes = useStyles();
	const { setResponseDialog } = useApp();
	const [avatar, setAvatar] = React.useState(medium.avatar);
  
	const uploadAvatar = file => {
    jobsService.upload(medium._id, [file], true).then(response => {
      medium.avatar = response.data.file._id;
			onUpdate(medium, m => setAvatar(m.avatar));
    });
  };

	const [remarks, setRemarks] = React.useState(
		medium && medium.remarks ?
			RichEditorHelpers.convertFromHtml(medium.remarks) :
			RichEditorHelpers.createEmpty()
	);

	const variant = edit ? 'outlined' : 'standard';

	return (
		<>
		<Formik
			initialValues={{
				name: medium.name || '',
				street: medium.street || '',
				postal: medium.postal || '',
				country: medium.country || '',
        url: medium.url || '',
				startPrice: medium.startPrice || '',
			}}
			validateOnChange={false}
			validateOnBlur={false}
			validationSchema={MediumSchema}
			validate={() => {
				const errors = {};

				if (RichEditorHelpers.getPlainText(remarks).length < 2) { errors.remarks = t('min_chars', false, { amount: 2 }); }

				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				const data = {
					remarks: RichEditorHelpers.convertToHtml(remarks),
					...values
				};

				if (medium && medium._id) {
					jobsService.patchMedium(medium._id, data)
						.then(() => onClose())
						.catch(error => setResponseDialog({ error }));
				} else {
					jobsService.postMedium(data)
						.then(() => onClose())
						.catch(error => setResponseDialog({ error }));
				}
				setSubmitting(false);
			}}
		>
			{({ submitForm, isSubmitting, errors, validateForm }) => {
				return (
					<Form className={!edit ? classes.disabled : ''}>
						<Box display="flex" margin={1}>
							{medium && medium._id && 
								<Box style={{position: 'relative',padding: '0 5px', marginRight: '20px'}}>
									<ImageUpload onUpload={uploadAvatar} className={classes.uploadIcon} />
									<MediumAvatar medium={medium} avatarId={medium.avatar}/>
								</Box>
							}
							<Field
								name="name"
								component={TextField}
								label={t("name")}
								variant={variant}
								disabled={!edit}
								style={{ width: '100%', marginTop: '35px' }}
								margin="normal" />
            </Box>
            <Box  display="flex" margin={1}>
							<Field
								name="street"
								component={TextField}
								label={t("street")}
								variant={variant}
								disabled={!edit}
								style={{ width: '50%', marginRight: 10 }}
								margin="normal" />
							<Field
								name="postal"
								component={TextField}
								label={t("postal")}
								variant={variant}
								disabled={!edit}
								style={{ width: '50%', marginRight: 10 }}
								margin="normal" />
              <Field
								name="country"
								component={TextField}
								label={t("country")}
								variant={variant}
								disabled={!edit}
								style={{ width: '50%' }}
								margin="normal" />
            </Box>
            <Box display="flex" margin={1}>
							<Field
								name="startPrice"
								component={TextField}
								label={t("start price")}
								variant={variant}
								disabled={!edit}
								style={{ width: '50%', marginRight: 10 }}
								margin="normal" />

              <Field
								name="url"
								component={TextField}
								label={t("url")}
								variant={variant}
								disabled={!edit}
								style={{ width: '50%' }}
								margin="normal" />

						</Box>
						
						<Box display="flex" margin={1}>
							<FormControl className={classes.formControl}>
								<InputLabel shrink > {t('remarks')} </InputLabel>
								{edit ?
									<Field
										name="remarks"
										component={RichEditor}
										editorState={remarks}
										onChange={setRemarks}
										error={!!errors['remarks']}
										helperText={errors['remarks']}
										variant={variant}
										margin="normal"
									/>
									:
									<Paper elevation={2} className={classes.paper}>
										<div dangerouslySetInnerHTML={{ __html: medium.remarks }} />
									</Paper>
								}
							</FormControl>
						</Box>
						
						{edit &&
							<Box margin={1}>
								<Button
									variant="contained"
									color="primary"
									disabled={isSubmitting}
									onClick={submitForm}
								>
									{t('save')}
								</Button>
							</Box>
						}
					</Form>
				);
			}}
		</Formik>
		</>
	);
}