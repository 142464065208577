import React from 'react'
import { t } from '../utils/i18n'
import { IconButton, Box } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

// App components
import NotesList from './NotesList'
import NoteForm from './NoteForm'

// assets
import '../assets/scss/form-wrapper.scss'

const filters = [
  { label: t('all'), value: ''},
  { label: t('very_good'), value: 'score=3' },
  { label: t('good'), value: 'score=2' },
  { label: t('bad'), value: 'score=1' },
  { label: t('very_bad'), value: 'score=0' }
]

export default function CandidateNotes({ candidate = {}, className }) {
  const [note, setNote] = React.useState(false)

  return ( 
    <div className={className}>
      { ! note ? 
        <IconButton aria-label="add note" onClick={() => setNote({})}> <AddIcon /> {t('new_note')} </IconButton>
        :
        <Box className="form-wrapper">
          <h2> { note._id ? t('edit_note') : t('new_note') } </h2>
          <div>
            <NoteForm candidate={candidate} onClose={() => setNote(false)} note={note}/>
            <IconButton aria-label={t("cancel")} onClick={() => setNote(false)}> x </IconButton>
          </div>
        </Box>
      }
      { candidate._id && <NotesList fullwidth filters={filters} candidate={candidate} note={note} selectNote={note => setNote(note) }/> }
    </div>
  )
}