import React from 'react'
import { t } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import {
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box,
  Typography,
  IconButton
} from '@material-ui/core'
import { Edit as EditIcon, Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'

// Components 
import { InterviewQuestionForm } from '../components/InterviewForm'

// Services
import interviewsService from '../services/interviews.service'

// Context
import { useApp } from "../context/app"

const useStyles = makeStyles(theme => ({
  tab: { width: 400 },
  category: {
    cursor: 'pointer',
    display: 'flex', 
    padding: '30px 20px 0',
    '& h5': { 
      flex: 1,
      '& svg': {
        background: theme.palette.mediaforta.main,
        borderRadius: '50%',
        fontSize: 20,
        fill: '#fff'
      }
    }
  },
  actions: {
    padding: 0,
    margin: '0 0 0 10px',
    '& svg': { fontSize: 20 }
  }
}))

export const removeInterviewTemplates = async templateId => interviewsService.deleteTemplate(templateId)
export const patchInterviewTemplates = async (templateId, template) => interviewsService.patchTemplate(templateId, template)

export default function InterviewTemplatesList({ templates, setRefresh }) {
  const classes = useStyles()
  const { setResponseDialog } = useApp()
  const [ question, setQuestion ] = React.useState(false)
 
  const removeTemplate = templateId => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure_remove_interview'),
      onConfirm: () => {
        removeInterviewTemplates(templateId).then(() => setRefresh(true))
      }
    })
  }

  const removeQuestion = (templateId, index) => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure_remove_question'),
      onConfirm: () => {
        const template = templates.find(template => template._id === templateId)
        template.questions = template.questions.filter((q, i) => i !== index);
        patchInterviewTemplates(templateId, template).then(() => setRefresh(true))
      }
    })
  }

  return <React.Fragment>
      <Box display="flex">
        <div style={{flex: 2}}>
          { templates.map(({_id, questions, type}) => {
            return <div key={_id}>
              <Box>
                <Typography variant='h5'>
                  <b> {type} </b>
                  <IconButton className={classes.actions} aria-label={t('add_template')} onClick={() => setQuestion({ templateId: _id })}>
                    <AddIcon />
                  </IconButton>
                  <IconButton className={classes.actions} aria-label={t('remove_template')} onClick={() => removeTemplate(_id)}>
                    <DeleteIcon />
                  </IconButton>
                </Typography>
              </Box>
                <TableContainer className={classes.tablecontainer}>
                  <Table aria-label="simple table">
                    <TableBody>
                    { ! questions.length ?
                        <TableRow>
                          <TableCell>{t('no_questions')}</TableCell>
                        </TableRow>
                      :
                      questions.map(({question}, index) => {
                        return <TableRow key={index}>
                            <TableCell>
                              {question}
                              <IconButton 
                                className={classes.actions} 
                                aria-label={t('edit_question')} 
                                onClick={() => setQuestion({question, templateId: _id, index})}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton className={classes.actions} aria-label={t('remove_question')} onClick={() => removeQuestion(_id, index)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        })
                    }
                    </TableBody>
                  </Table>
                </TableContainer>  
                <br/>
            </div>
          })
        }
        </div> 
        { question &&
          <Box className="form-wrapper -full">  
            <Typography variant='h2'>
              { question.question ? t('edit_question') : t('new_question') }
            </Typography>
            <div>
              <InterviewQuestionForm 
                onClose={question => {
                  if (question) setRefresh(true)
                  setQuestion(false)
                }} 
                question={question.question || ''}
                template={templates.find(({_id}) => _id === question.templateId)}
                index={ question.index }
              />
              <IconButton aria-label={t('cancel')} onClick={() => setQuestion(false)}> x </IconButton>
            </div>
          </Box> 
        }
      </Box>
		</React.Fragment>    
}