import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    border: '1px solid',
    borderColor: theme.palette.mediaforta.light,
    padding: 0,
    borderRadius: 35,
    marginBottom: 20,
    marginTop: 20,
    display: 'flex',
    '& :last-child': { borderRight: 0, padding: '0 5px', margin: 0 },
    '& li': {
      float: 'left',
      padding: '0',
      textAlign: 'center',
      minHeight: 40,
      borderRight: '1px solid',
      borderRightColor: theme.palette.mediaforta.light,
      '& div span': {
        fontSize: 15,
        fontWeight: 500,
        color: theme.palette.mediaforta.dark
      },
      '& div p': { color: theme.palette.mediaforta.light },
		  '&.Mui-selected': {
		    backgroundColor: theme.palette.mediaforta.dark,
		    '&:hover': { backgroundColor: theme.palette.mediaforta.main },
		    '& *': { color: '#fff !important' }
		  }
    }
  },
  clickable: { '& li': { cursor: 'pointer' } }
}))

export default function TabList(props) {
  const classes = useStyles()
  const items = props.items || []
  const selectedItem = items.find(i => i.selected)
  const [selected, setSelected] = React.useState(selectedItem ? selectedItem.id : '')
  const className = `${classes.root} ${props.onClick ? classes.clickable : ''}`

  const select = item => {
    setSelected(selected === item.id ? '' : item.id)
  	props.onClick(item)
  }

  return ( 
   	<List className={className}>
   		{ items.map(item => {
   			return <ListItem key={item.id} selected={selected === item.id ? true : false} onClick={() => props.onClick ? select(item) : false }>
			    <ListItemText primary={item.primary} secondary={item.secondary} />
			  </ListItem>
   		})}
		</List>
  )
}