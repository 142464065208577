import en from "./en"
import fr from "./fr"
import nl from "./nl"
import { getLanguage } from '../../utils/i18n'

export const getLanguageList = () => {
  const appLang = getLanguage().toLowerCase()
  const languages = appLang === 'nl' ? nl : appLang === 'fr' ? fr : en 
  return Object.keys(languages).map(key => ({value: key, code: key, name: languages[key]}))
}
