import React from 'react'
import { t } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, TextField, Link, SvgIcon, Typography } from '@material-ui/core'
import {
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  GitHub as GitHubIcon,
  LinkedIn as LinkedInIcon,
  Link as LinkIcon,
  HighlightOff as HighlightOffIcon
} from '@material-ui/icons'

// assets
import { ReactComponent as AngellistIcon } from '../assets/svg/angellist.svg'
import { ReactComponent as DribbbleIcon } from '../assets/svg/dribbble.svg'
import { ReactComponent as BehanceIcon } from '../assets/svg/behance.svg'
import { ReactComponent as SkypeIcon } from '../assets/svg/skype.svg'

const useStyles = makeStyles(theme => ({
  root: {
    '& > a, & > div': {
      flex: 1,
      minWidth: '33%',
      margin: '5px 0'
    },
    '& > a *': { cursor:'pointer' },
    '& svg': {
      float: 'left',
      color: '#fff',
      padding: 10,
      fontSize: 45,
      borderRadius: 35,
    },
    '& > * > div': {
      margin: '5px 10px',
      width: 'calc(100% - 65px)'
    }
  },
  github: { backgroundColor:theme.palette.mediaforta.github },
  twitter: { backgroundColor:theme.palette.mediaforta.twitter },
  facebook: { backgroundColor:theme.palette.mediaforta.facebook },
  angellist: { backgroundColor:theme.palette.mediaforta.angellist },
  linkedin: { backgroundColor:theme.palette.mediaforta.linkedin },
  dribbble: { backgroundColor:theme.palette.mediaforta.dribbble },
  behance: { backgroundColor:theme.palette.mediaforta.behance },
  skype: { padding:'0px !important' },
  other: { backgroundColor:theme.palette.mediaforta.light },
  edit: { backgroundColor:theme.palette.mediaforta.light, cursor: 'pointer' }
}))

const CustomIcon = ({className, children}) => {
  const classes = useStyles()
  return <SvgIcon className={classes[className]}> {children} </SvgIcon>
}

const CustomAngelListIcon = () => <CustomIcon className={'angellist'}> <AngellistIcon /> </CustomIcon>
const CustomDribbbleIcon = () => <CustomIcon className={'dribbble'}> <DribbbleIcon /> </CustomIcon>
const CustomBehanceIcon = () => <CustomIcon className={'behance'}> <BehanceIcon /> </CustomIcon>
const CustomSkypeIcon = () => <CustomIcon className={'skype'}> <SkypeIcon /> </CustomIcon>

const links = {
  skype: CustomSkypeIcon,
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  github: GitHubIcon,
  linkedin: LinkedInIcon,
  angellist: CustomAngelListIcon,
  dribbble: CustomDribbbleIcon,
  behance: CustomBehanceIcon,
  other: LinkIcon,
  edit: HighlightOffIcon
}

export default function CandidateLinks({ candidate = {}, edit }) {
  const classes = useStyles()
  const [ refresh, setRefresh ] = React.useState(0)

  const removeLink = link => {
    if (! edit) return
    const links = Object.keys(candidate.links).reduce((memo, key) => {
      if (link !== key) memo[key] = candidate.links[key]
      else memo[key] = false
      return memo
    }, {})
    candidate.links = links
    setRefresh(refresh + 1)
  }

  const renderText = link => {
    const type = edit ? 'edit' : links[link] ? link.toLowerCase() : 'other' 
    return <React.Fragment>
    { React.createElement(links[type], { className: classes[type], onClick: () => {removeLink(link)} }) }
      <TextField 
        name={link} 
        type="text" 
        disabled={! edit }
        value={candidate.links[link]}
        onChange={ event => { 
          candidate.links[link] = event.currentTarget.value
          setRefresh(refresh + 1) 
        } }
        margin="none" />
    </React.Fragment>
  }
  const renderLink = link => {
    if (['website'].includes(link) || ! candidate.links[link]) { return null }
    if (edit) { return <Grid key={link} item> {renderText(link)} </Grid> }
    return <Grid key={link} item component={Link} href={candidate.links[link]} target="_blank"> {renderText(link)} </Grid>
  }
  return (
    <Grid container className={classes.root}>
      { candidate.links ? Object.keys(candidate.links).map(renderLink) : 
        <Typography variant="overline">{t('no_links_assigned')} </Typography>
      } 
    </Grid>
  )
}