// imports the React Javascript Library
import React from "react";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  input: { display: 'none' },
  button: {
    backgroundColor: theme.palette.mediaforta.superlight,
    color: theme.palette.mediaforta.dark,
    boxShadow: 'none',
    margin: 16,
    left: -5
  }
});

class ImageUploadCard extends React.Component {
  renderInitialState() {
    const { classes, className, onUpload } = this.props
    return (
      <React.Fragment>
        <Grid container justify="center" alignItems="center" className={className}>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={event => onUpload(event.target.files[0])}
          />
          <label htmlFor="contained-button-file">
            <Fab component="span" className={classes.button}> <AddPhotoAlternateIcon /> </Fab>
          </label>
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return ( 
      <React.Fragment> {this.renderInitialState()} </React.Fragment> 
    )
  }
}

export default withStyles(styles, { withTheme: true })(ImageUploadCard);
