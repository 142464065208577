import React from 'react'
import { t } from '../utils/i18n'
import { debounce } from 'lodash'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Typography, Box, Paper, TablePagination, IconButton } from '@material-ui/core'
import { Check as CheckIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

// Context
import { useApp } from "../context/app"

// Services
import TasksService from "../services/tasks.service"

// App components
import SearchFilter from "./SearchFilter"
import TabList from "./TabList"

const useStyles = makeStyles(theme => ({
  task: {
    margin: 5,
    '& svg': {
      padding: 6,
      fontSize: 36,
      maxWidth: 36,
      background: theme.palette.mediaforta.superlight,
      borderRadius: 20,
      color: theme.palette.mediaforta.light,
      cursor: 'pointer',
    },
    '&.-completed': {
      '& > div > svg': {
        backgroundColor: theme.palette.success.main,
        color: '#fff'
      },
      '& h2': { textDecoration: 'line-through' }
    }
  },
  header: {
    marginBottom: 10,
    '& h2': { margin: '3px 10px' }
  },
  labels: {
    marginBottom: 10,
    marginLeft: 5,
    '& > *': { marginRight: 20 }
  },
  tablist: {
    marginLeft: 5,
    '& > ul': { width: 650 }
  },
  description: {
    overflowX: 'auto',
    '& > *': { margin: '5px 0' }
  },
  actions: {
    textAlign: 'right',
    '& button': {
      padding: 0,
      '& svg': {
        background: 'transparent',
        fontSize: 20,
        padding: 0,
        margin: 5
      }
    }
  }
}))

export const fetchTasks = async (search, limit, offset, fields, filter) => TasksService.list(search, limit, offset, fields, filter)
export default function TasksList(props) {
  const { task } = props
  const classes = useStyles()
  const { setResponseDialog } = useApp()
  const [ tasks, setTasks ] = React.useState({})
  const [ filter, setFilter ] = React.useState('')
  const [ searchInput, setSearchInput ] = React.useState(false)
  const [ page, setPage ] = React.useState(0)
  const [ rowsPerPage, setRowsPerPage ] = React.useState(10)
  const [ removedTask, setRemovedTask ] = React.useState(0)

  const selectedItem = props.tabFilters && props.tabFilters.find(i => i.selected)
  const [ selected, setSelected ] = React.useState(selectedItem || false)

  const search = event => debounceSearch(event.target.value)
  const debounceSearch = debounce(q => {
    setSearchInput(q)
    setPage(0)
  }, 300);

  const setError = (error) => {
    setTasks({})
    setResponseDialog({error})
  }

  const getFilter = () => {
    let f = filter
    if (props.candidate) f = `${f ? f + '&' : ''}candidateId=${props.candidate._id}`
    if (selected && selected.filter) f = `${f ? f + '&' : ''}${selected.filter}`
    return f
  }

  React.useEffect(() => {
    fetchTasks(searchInput, rowsPerPage, page*rowsPerPage, undefined, getFilter() ).then(setTasks).catch(setError)
  // eslint-disable-next-line
  }, [ searchInput, rowsPerPage, page, selected, filter, task, removedTask ])

  const handleChangePage = (event, newPage) => setPage(parseInt(newPage))
  const handleChangeRowsPerPage = (event) => {
    const rows = parseInt(event.target.value, 10)
    setRowsPerPage(rows)
    setPage(0)
  }

  const completeTask = (task) => {
    TasksService.patch(task._id, { completed: ! task.completed }).then((response) => {
      const records = tasks.records.map(t => {
        if (t._id === task._id) t = { _id: t._id, ...response.data }
        return t
      })
      setTasks({...tasks, records})
    })
  }

  const removeTask = (task) => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure_remove_task'),
      onConfirm: () => TasksService.delete(task._id).then(() => setRemovedTask(task._id))
    })
  }

  const renderTaskItem = task => {
    return (<Paper key={task._id} variant="outlined" className={`${classes.task} ${task.completed ? '-completed' : ''}`}>
      <Box display="flex" className={classes.header}>
        <CheckIcon onClick={() => completeTask(task)}/>
        <h2>{task.title}</h2>
      </Box>
      <Box display="flex" className={classes.labels}>
        {!! task.assigned && !! task.assigned.length &&
          <Typography>
            <label> {t('assigned_to')} </label>
            {task.assigned[0].firstname}  {task.assigned[0].name}
            &nbsp;
          </Typography>
        }
        <Typography>
          <label> {t('type')} </label>
          { task.type } &nbsp;
        </Typography>
        <Typography>
          <label> {t('due_date')} </label>
          { moment(task.deadline).format('DD.MM.YYYY HH:mm') }
        </Typography>
      </Box>
      <Paper elevation={2}>
        <div dangerouslySetInnerHTML={{ __html: task.description }} className={classes.description}/>
      </Paper>
      <Box className={classes.actions}>
        <IconButton onClick={() => props.selectTask(task)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => removeTask(task)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Paper>)
  } 

  if(! tasks || ! tasks.records) return null
  return ( 
  	<React.Fragment>
      <SearchFilter 
        onChange={search} 
        value={searchInput} 
        placeholder={t("search_tasks")}
        filter={filter}
        filters={props.filters}
        setFilter={setFilter}
      />
      { props.tabFilters && 
        <div className={classes.tablist}>
          <TabList items={props.tabFilters} onClick={item => setSelected(item)}/>
        </div>
      } 
      <ResponsiveMasonry columnsCountBreakPoints={{ 700: 1, 1200: 2}}>
        <Masonry> { tasks.records.map(renderTaskItem) } </Masonry>
      </ResponsiveMasonry>
      { !! tasks.records.length &&
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tasks.totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      }
    </React.Fragment>  
  )
}