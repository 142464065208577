import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { t } from "../utils/i18n";
import config from "../config";
import { get, set } from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneDialog } from "material-ui-dropzone";
import {
  Typography,
  Grid,
  Paper,
  Chip,
  TextField,
  Box,
  Collapse,
  Button,
  Link,
  Radio,
  RadioGroup,
  FormControlLabel
} from "@material-ui/core";
import MuiTextField from "@material-ui/core/TextField";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import csvDownload from "json-to-csv-export";

// Services
import CandidatesService from "../services/candidates.service";
import FilesService from "../services/files.service";
import UsersService from "../services/users.service";
import JobsService from "../services/jobs.service";

// Context
import { useApp } from "../context/app";
import { useUser } from "../context/user";

// Candidate components
import CandidateSkills from "./CandidateSkills";
import CandidateIconMenu from "./CandidateIconMenu";
import CandidateLinks from "./CandidateLinks";
import CandidateLinkForm from "./CandidateLinkForm";
import CandidateTags from "./CandidateTags";
import CandidateSource from "./CandidateSource";
import CandidateExperienceList from "./CandidateExperienceList";
import CandidateDiplomaList from "./CandidateDiplomaList";
import CandidateRemoveForm from "./CandidateRemoveForm";

// App components
import RecruiterSelect from "./RecruiterSelect";
import AuthenticatedLink from "./AuthenticatedLink";
import AuthenticatedAvatar from "./AuthenticatedAvatar";
import AddIcon from "./AddIcon";
import Dropdown from "./Dropdown";
import MediaFortaDialog from "./MediaFortaDialog";
import ImageUpload from "./ImageUpload";

import JobApplicationList from "./JobApplicationList";

import countries from "../assets/countries/CountryList";
import { getLanguageList } from "../assets/languages/LanguageList";

// assets
import "../assets/scss/datepicker.scss";
import JobsList from "./JobsList";

const { API_URL } = config.mediaforta;

const useStyles = makeStyles((theme) => ({
  chip: { paddingRight: 15, cursor: "pointer" },
  archived: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: "#fff",
  },
  avatarWrapper: { position: "relative", width: 120 },
  uploadIcon: { position: "absolute", zIndex: 9, top: 10 },
  file: {
    position: "relative",
    marginBottom: 10,
    "& a": {
      "& p": {
        position: "absolute",
        top: 10,
        opacity: 0.4,
        right: 15,
        fontWeight: 500,
        fontSize: 12,
        width: 20,
        textAlign: "center",
        cursor: "pointer",
      },
    },
  },
  header: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": { float: "left" },
  },
  name: {
    fontSize: 22,
    padding: "10px 10px 0",
    float: "left",
    "& > * > span": { fontSize: 17, display: "block" },
  },
  avatar: { width: 120, height: 120 },
  form: {
    "& label": { color: "#b6c1e6 !important", fontWeight: 400, fontSize: 11 },
    "& input": {
      color: theme.palette.primary.dark,
      fontWeight: 500,
      textOverflow: "ellipsis",
    },
    "& .MuiInputBase-root:before": { borderBottom: "1px solid #b6c1e6" },
    "& .Mui-disabled": {
      cursor: "default !important",
      "& .MuiAutocomplete-endAdornment": {
        display: "none",
      },
    },
  },
  personal: {
    "& > *": { width: "100%", paddingRight: 10, marginLeft: 5 },
    "& .Mui-disabled": {
      cursor: "default !important",
      "& .MuiAutocomplete-endAdornment": { display: "none" },
    },
  },
  info: {
    fontSize: 16,
    padding: 0,
    border: 0,
    "& > div": { width: "100%" },
    "& textarea": { color: theme.palette.primary.dark },
    "& fieldset": {
      borderColor: `${theme.palette.mediaforta.light} !important`,
    },
  },
  attachicon: { fontSize: 32, float: "left", marginTop: 10 },
  autocomplete: { minWidth: 400 },
  addIcon: { right: -30, bottom: 15 },
  dropdown: {
    margin: "30px 5px 0",
    padding: 0,
    "& label": { left: -12 },
    "& div .MuiSelect-root": { paddingLeft: 0 },
    "& fieldset": {
      borderStyle: "none",
      borderRadius: 0,
      borderBottomStyle: "solid",
    },
  },
  actions: {
    float: "none",
    flex: 1,
    "& > *": {
      display: "flex",
      flexDirection: "row-reverse",
      flexWrap: "nowrap",
    },
  },
  recruiterActions: {
    marginTop: 40,
    "& > *": { flexDirection: "row" },
  },
  status: {
    float: "right",
    padding: "10px 0 0",
  },
  collapseWrapper: {
    display: "flex",
    "& > *": {
      borderRadius: 3,
      display: "flex",
      position: "relative",
      flex: 1,
      cursor: "pointer",
      backgroundColor: theme.palette.mediaforta.main,
      color: "#fff",
      padding: 12,
      fontWeight: 500,
      "&:first-child": { marginRight: 8 },
      "& > svg": {
        right: 10,
        top: 10,
        position: "absolute",
      },
    },
  },
  autocomplete: {
    "& > div": {
      marginTop: 4,
      "& > input": {
        marginBottom: -10,
      },
    },
  },
  collapsable: {
    padding: 10,
    backgroundColor: "#f6f7fc",
  },
  active: { backgroundColor: theme.palette.mediaforta.dark },
  dates: {
    width: "33% !important",
    marginRight: ".5%",
  },
}));

const statusses = [
  { label: t("status_interview"), value: "" },
  { label: t("phone_interview"), value: "phone interview" },
  { label: t("test"), value: "test" },
  { label: t("live_interview"), value: "live interview" },
  { label: t("offer"), value: "offer" },
  { label: t("active"), value: "active" },
  { label: t("contract"), value: "contract" },
];

const personal = [
  "street",
  "number",
  "box",
  "postal",
  "city",
  "country",
  "phone",
  "email",
  "language",
];

export default function CandidateDetails({
  candidate,
  edit: editMode,
  onUpdate,
  mini,
  filter = false,
  classes: classesOverwrite = {},
}) {
  const styles = useStyles();
  const { setResponseDialog, candidatesFilterResult } = useApp();
  const { isRecruiter, isSuperAdmin } = useUser();
  const classes = { ...styles, ...classesOverwrite };
  const languageList = getLanguageList();
  const history = useHistory();

  const [avatar, setAvatar] = React.useState(candidate.avatar);
  const [publicEnabled, setPublicEnabled] = React.useState(candidate.publicEnabled);
  const [files, setFiles] = React.useState(false);
  const [formDialog, setFormDialog] = React.useState(false);
  const [removeCandidateDialog, setRemoveCandidateDialog] =
    React.useState(false);

  const [dropzoneDialog, setDropzoneDialog] = React.useState(false);
  const [userFunctions, setUserFunctions] = React.useState(null);
  const [userFunction, setUserFunction] = React.useState(candidate.function);
  const [edit, setEdit] = React.useState(editMode);
  const [open, setOpen] = React.useState("");

  // eslint-disable-next-line
  const [candidateId, setCandidateId] = React.useState(candidate._id);

  const {
    street,
    number,
    box,
    postal,
    city,
    country,
    phone,
    email,
    language,
    candidateInfo = { skillSet: {} },
  } = candidate;
  const [personalFields, setPersonalFields] = React.useState({
    street,
    number,
    box,
    postal,
    city,
    country,
    phone,
    email,
    language,
  });
  const [translationSkills, setTranslationSkills] = React.useState(
    candidateInfo.skillSet.translationSkills || []
  );
  const [motherTongue, setMotherTongue] = React.useState(
    candidateInfo.skillSet.motherTongue
  );

  const canTranslate = () =>
    candidate.userfunction &&
    !["contentmarketer", "Designer"].includes(candidate.userfunction[0].name);

  candidateInfo.unavailable = candidateInfo.unavailable || {};
  const [unavailable, setUnavailable] = React.useState(
    candidateInfo.unavailable
  );

  const addDialogs = {
    links: {
      title: t("add_candidate_link"),
      onClose: () => {
        setFormDialog(false);
      },
      component: { Component: CandidateLinkForm, data: candidate },
    },
    source: {
      title: t("candidate_source"),
      onClose: () => {
        setFormDialog(false);
      },
      onConfirm: () => {
        setFormDialog(false);
        if (onUpdate) onUpdate(candidate);
      },
      component: { Component: CandidateSource, data: { candidate } },
    },
    jobs: {
      title: t("job_applications"),
      onClose: () => {
        setFormDialog(false);
      },
      component: {
        Component: JobsList,
        data: {
          mini: true,
          onClick: ({ _id }) => {
            setResponseDialog({
              title: t("are_you_sure"),
              message: t("link_candidate_job"),
              onConfirm: () => {
                JobsService.apply({ jobId: _id, candidateId: candidate._id })
                  .then(() => {
                    setFormDialog(false);
                    if (onUpdate) onUpdate(candidate);
                  })
                  .catch((error) => setResponseDialog({ error }));
              },
            });
          },
        },
      },
    },
  };

  React.useEffect(() => {
    if (!userFunctions) {
      UsersService.listUserFunctions().then((data) =>
        setUserFunctions(
          data.records.map(({ _id: value, name: label }) => ({
            _id: value,
            value,
            label: t(label),
          }))
        )
      );
    }
  });

  React.useEffect(() => {
    if (candidate._id) CandidatesService.files(candidate._id).then(setFiles);
    setCandidateId(candidate._id);
    setUserFunction(candidate.function);
    const { skillSet } = candidate.candidateInfo || { skillSet: {} };
    setMotherTongue(skillSet.motherTongue);
    setTranslationSkills(skillSet.translationSkills);
    // eslint-disable-next-line
  }, [candidate]);

  React.useEffect(() => {
    if (editMode !== edit) setEdit(editMode);
    // eslint-disable-next-line
  }, [editMode]);

  const onRemoveAccount = () => {
    setRemoveCandidateDialog({
      title: t("are_you_sure"),
      onClose: () => {
        setRemoveCandidateDialog(false);
      },
      component: { Component: CandidateRemoveForm, data: candidate },
    });
  };

  const renderPersonal = (field) => {
    const splitted = field.split(".");
    const label = splitted[splitted.length - 1];
    let value = get(candidate, field) || "";
    const list = field === "country" ? countries : languageList;
    const isAutoComplete = ["country", "language"].includes(field);

    if (isAutoComplete)
      value =
        list.find(
          (c) => c.code && c.code.toLowerCase() === value.toLowerCase()
        ) || null;
    const inputValue = mini ? { value } : { defaultValue: value };
    return (
      <Grid key={field} item xs={4} className={classes.personal}>
        {isAutoComplete ? (
          <Autocomplete
            {...inputValue}
            id={field}
            disabled={!edit}
            options={list}
            autoHighlight
            onInputChange={(event, newValue) => {
              const selected = list.find(
                (item) => item.label === newValue || item.name === newValue
              );
              setPersonalFields({
                ...personalFields,
                [field]: (selected && selected.code) || "",
              });
              set(candidate, field, (selected && selected.code) || "");
            }}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => option.label || option.name}
            renderInput={(params) => {
              const { value, ...props } = params.inputProps;
              return (
                <TextField
                  {...params}
                  value={value}
                  name={field}
                  label={t(field)}
                  disabled={!edit}
                  margin="normal"
                  className={classes.autocomplete}
                  inputProps={{
                    ...props,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              );
            }}
          />
        ) : (
          <TextField
            name={field}
            type="text"
            disabled={!edit}
            onChange={({ currentTarget: { value } }) => {
              setPersonalFields({ ...personalFields, [field]: value });
              set(candidate, field, value);
            }}
            label={t(label)}
            margin="normal"
            {...inputValue}
          />
        )}
      </Grid>
    );
  };

  const removeFile = (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    setResponseDialog({
      title: t("are_you_sure"),
      message: t("sure_remove_file"),
      onConfirm: () => {
        FilesService.delete(file._id).then((response) =>
          setFiles(files.filter((f) => f._id !== file._id))
        );
      },
    });
  };

  const uploadAvatar = (file) => {
    CandidatesService.upload(candidate._id, [file], true).then((response) => {
      candidate.avatar = response.data.file._id;
      onUpdate(candidate, (c) => setAvatar(c.avatar));
    });
  };

  const updateStatus = (status) => {
    candidate.status = status;
    onUpdate(candidate);
  };

  const archive = () => {
    if (candidate.archived) {
      candidate.archived = !candidate.archived;
      return onUpdate(candidate);
    }
    setResponseDialog({
      title: t("are_you_sure"),
      message: t("sure_archive_candidate"),
      onConfirm: () => {
        candidate.archived = !candidate.archived;
        onUpdate(candidate);
      },
    });
  };

  const isOpen = (panel) => {
    return open === panel;
  };
  const toggleOpen = (panel) => {
    isOpen(panel) ? setOpen(false) : setOpen(panel);
  };

  const getValueProp = (value) =>
    mini && !edit ? { value } : { defaultValue: value };

  const exportCandidate = () => {
    const { yearsExperience, translationSkills, motherTongue } =
      candidate.candidateInfo.skillSet;
    const {
      workExperience,
      diploma,
      moreAboutYourself,
      freelancer,
      topProject,
      projectPreferences,
      pricePerWord,
      dayPrice,
      unavailable,
    } = candidate.candidateInfo;

    const {
      firstname,
      name,
      language,
      street,
      number,
      box,
      postal,
      city,
      country,
      info,
      phone,
      email,
      candidatesources,
      candidatetags,
      archived,
      createdAt,
      userfunction,
    } = candidate;

    const data = {
      firstname,
      name,
      language,
      street,
      number,
      box,
      postal,
      city,
      country,
      info,
      phone,
      email,
      createdAt,
      userfunction: userfunction.map(({ name }) => name),
      sources: candidatesources.map(({ label }) => label),
      tags: candidatetags.map(({ label }) => label),
      yearsExperience,
      translationSkills,
      motherTongue,
      workExperience,
      diploma,
      moreAboutYourself,
      freelancer,
      topProject,
      projectPreferences,
      topProject,
      pricePerWord,
      dayPrice,
      archived,
      unavailable,
    };

    csvDownload([data]);
  };

  const candidateFilterIndex = candidatesFilterResult.indexOf(candidate._id);
  const showPricePerWord = () =>
    candidate.userfunction &&
    ["copywriter", "translator"].includes(candidate.userfunction[0].name);

  return (
    candidate && (
      <>
        {!mini && isRecruiter() && candidateFilterIndex !== -1 && (
          <>
            {candidateFilterIndex !== 0 && (
              <Link
                onClick={() =>
                  history.push(
                    "/candidates/" +
                      candidatesFilterResult[candidateFilterIndex - 1]
                  )
                }
                style={{
                  position: "absolute",
                  right: 130,
                  top: -50,
                  cursor: "pointer",
                }}
              >
                <span>&#9666; </span>
                previous
              </Link>
            )}
            {candidateFilterIndex + 1 !== candidatesFilterResult.length && (
              <Link
                onClick={() =>
                  history.push(
                    "/candidates/" +
                      candidatesFilterResult[candidateFilterIndex + 1]
                  )
                }
                style={{
                  position: "absolute",
                  right: 50,
                  top: -50,
                  cursor: "pointer",
                }}
              >
                next
                <span> &#9656; </span>
              </Link>
            )}
          </>
        )}
        {isSuperAdmin() && (
          <Button
            variant="contained"
            color="primary"
            onClick={exportCandidate}
            style={{ float: "right", marginRight: 10 }}
          >
            Export candidate
          </Button>
        )}
        <div className={classes.header}>
          <Grid className={classes.avatarWrapper}>
            {edit && !mini && (
              <ImageUpload
                onUpload={uploadAvatar}
                className={classes.uploadIcon}
              />
            )}
            <AuthenticatedAvatar candidate={candidate} avatarId={avatar} />
          </Grid>
          <Grid
            style={{
              width: isRecruiter()
                ? "calc(100% - 120px)"
                : "calc(100% - 300px)",
            }}
          >
            {!edit ? (
              <div className={classes.name}>
                <Typography>
                  <b>{`${candidate.firstname} ${candidate.name}`}</b>
                  <span>
                    {userFunctions
                      ? userFunctions.find(({ _id }) => _id === userFunction)
                          .label
                      : candidate.userfunction[0].name}
                  </span>
                </Typography>
                <CandidateIconMenu candidate={candidate} />
              </div>
            ) : (
              <span className={classes.form} style={{ float: "left" }}>
                <TextField
                  name="firstname"
                  type="text"
                  defaultValue={candidate.firstname}
                  onChange={({ currentTarget: { value } }) => {
                    set(candidate, "firstname", value);
                  }}
                  label={t("firstname")}
                  style={{ margin: "0px 10px 0 6px" }}
                />
                <TextField
                  name="name"
                  type="text"
                  defaultValue={candidate.name}
                  onChange={({ currentTarget: { value } }) => {
                    set(candidate, "name", value);
                  }}
                  label={t("lastname")}
                  style={{ margin: 0 }}
                />
                <br />
                <Dropdown
                  items={userFunctions}
                  name="function"
                  label={t("i_am")}
                  value={userFunction}
                  className={classes.dropdown}
                  onChange={(value) => {
                    setUserFunction(value);
                    set(candidate, "function", value);
                  }}
                />
              </span>
            )}
            {isRecruiter() && (
              <Grid item className={classes.status}>
                <Dropdown
                  label={t("status_interview")}
                  value={candidate.status || ""}
                  items={statusses}
                  onChange={updateStatus}
                />
              </Grid>
            )}
          </Grid>
          {!mini && (
            <Grid
              className={`${classes.actions} ${
                isRecruiter() ? classes.recruiterActions : ""
              }`}
            >
              {edit ? (
                <Grid item>
                  <Chip
                    label={`${
                      isRecruiter()
                        ? t("update_candidate")
                        : t("update_profile")
                    }`}
                    className={classes.chip}
                    style={{
                      marginTop: 0,
                      backgroundColor: "#38ca8d",
                      color: "#333",
                    }}
                    onClick={() =>
                      onUpdate(candidate, () => {
                        if (!isRecruiter()) {
                          setEdit(false);
                          history.replace(history.location.pathname);
                        } else {
                          setEdit(false);
                        }
                      })
                    }
                  />

                  {isRecruiter() && (
                    <Grid item>
                      <Chip
                        label={
                          candidate.archived ? t("archived") : t("archive")
                        }
                        className={
                          candidate.archived
                            ? `${classes.archived} ${classes.chip}`
                            : classes.chip
                        }
                        style={{ marginTop: 0 }}
                        onClick={archive}
                      />
                    </Grid>
                  )}
                </Grid>
              ) : (
                !isRecruiter() && (
                  <Grid item>
                    <Chip
                      label={t("edit_profile")}
                      className={classes.chip}
                      style={{
                        marginTop: 0,
                        backgroundColor: "#38ca8d",
                        color: "#333",
                      }}
                      onClick={() => setEdit(true)}
                    />
                  </Grid>
                )
              )}
            </Grid>
          )}
        </div>

        <Grid container className={classes.form}>
          {" "}
          {personal.map((field) => {
            return renderPersonal(field);
          })}{" "}
        </Grid>

        {(candidate.info || edit) && candidate._id && (
          <Paper variant="outlined" className={classes.info}>
            <TextField
              id="candidate-info"
              label={t("personal_info")}
              disabled={!edit}
              multiline
              rows={5}
              onChange={(event) => (candidate.info = event.currentTarget.value)}
              variant="outlined"
              {...getValueProp(candidate.info)}
            />
          </Paper>
        )}

        {candidate._id && (
          <>
            {(candidateInfo.topProject || edit) && (
              <Paper variant="outlined" className={classes.info}>
                <TextField
                  id="candidate-top-project"
                  label={t("top_project")}
                  multiline
                  disabled={!edit}
                  rows={5}
                  onChange={(event) =>
                    (candidateInfo.topProject = event.currentTarget.value)
                  }
                  variant="outlined"
                  {...getValueProp(candidateInfo.topProject)}
                />
              </Paper>
            )}
            {(candidateInfo.projectPreferences || edit) && (
              <Paper variant="outlined" className={classes.info}>
                <TextField
                  id="candidate-project-preferences"
                  label={t("project_preferences")}
                  multiline
                  disabled={!edit}
                  rows={5}
                  onChange={(event) =>
                    (candidateInfo.projectPreferences =
                      event.currentTarget.value)
                  }
                  variant="outlined"
                  {...getValueProp(candidateInfo.projectPreferences)}
                />
              </Paper>
            )}

            {isRecruiter() && (
              <Paper variant="outlined" className={classes.info}>
                <TextField
                  id="resumeSummary"
                  label={t("resume summary")}
                  multiline
                  disabled={!edit}
                  rows={5}
                  onChange={(event) =>
                    (candidate.resumeSummary = event.currentTarget.value)
                  }
                  variant="outlined"
                  {...getValueProp(candidate.resumeSummary)}
                />
              </Paper>
            )}
            <Paper variant="outlined" className={classes.info}>
              <TextField
                id="createdAt"
                label={t("createdAt")}
                disabled={true}
                rows={1}
                className={classes.dates}
                variant="outlined"
                {...getValueProp(
                  candidate.createdAt
                    ? moment(candidate.createdAt).format("DD-MM-YYYY")
                    : " "
                )}
              />
              <TextField
                id="modifiedAt"
                label={t("modifiedAt")}
                disabled={true}
                rows={1}
                className={classes.dates}
                variant="outlined"
                {...getValueProp(
                  candidate.modifiedAt
                    ? moment(candidate.modifiedAt).format("DD-MM-YYYY")
                    : " "
                )}
              />
              <TextField
                id="modifiedByRecruiterAt"
                label={t("modifiedByRecruiterAt")}
                disabled={true}
                rows={1}
                className={classes.dates}
                style={{ marginRight: 0 }}
                variant="outlined"
                {...getValueProp(
                  candidate.modifiedByRecruiterAt
                    ? moment(candidate.modifiedByRecruiterAt).format(
                        "DD-MM-YYYY"
                      )
                    : " "
                )}
              />
            </Paper>
          </>
        )}

        {isRecruiter() && candidate._id && (
          <>
            <Grid
              open={isOpen("workexperience")}
              item
              xs={12}
              style={{ marginBottom: 30 }}
            >
              <Paper variant="outlined" style={{ padding: 0, border: 0 }}>
                <Box display="flex" className={classes.collapseWrapper}>
                  <Box
                    display="flex"
                    onClick={() => toggleOpen("workexperience")}
                    className={isOpen("workexperience") ? classes.active : ""}
                  >
                    {t("work_experience")}
                    {isOpen("workexperience") ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </Box>
                  <Box
                    display="flex"
                    onClick={() => toggleOpen("diplomas")}
                    className={isOpen("diplomas") ? classes.active : ""}
                  >
                    {t("diplomas")}
                    {isOpen("diplomas") ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </Box>
                </Box>
                <Collapse
                  in={isOpen("workexperience")}
                  timeout={100}
                  unmountOnExit
                  className={classes.collapsable}
                >
                  <CandidateExperienceList candidate={candidate} />
                </Collapse>
                <Collapse
                  in={isOpen("diplomas")}
                  timeout={100}
                  unmountOnExit
                  className={classes.collapsable}
                >
                  <CandidateDiplomaList candidate={candidate} />
                </Collapse>
              </Paper>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5"> {t("skills")} </Typography>
              </Grid>
              <Grid item>
                <CandidateSkills candidate={candidate} edit={edit} />
              </Grid>
            </Grid>
            <Grid container>
              <Typography variant="h5" style={{ width: "100%" }}>
                {" "}
                {t("attachments")}{" "}
              </Typography>
              {files && !files.length && !edit ? (
                <Typography variant="overline">
                  {" "}
                  {t("no_attachments_assigned")}
                </Typography>
              ) : (
                files &&
                files.map((file) => {
                  return (
                    <Grid item key={file._id} className={classes.file}>
                      <AuthenticatedLink
                        url={`${API_URL}candidates/${candidate._id}/file/${file._id}`}
                        filename={file.originalname}
                      >
                        <Chip
                          label={file.originalname}
                          className={classes.chip}
                        />
                        {edit && (
                          <Typography onClick={(e) => removeFile(e, file)}>
                            {" "}
                            X{" "}
                          </Typography>
                        )}
                      </AuthenticatedLink>
                    </Grid>
                  );
                })
              )}
              {!mini && (
                <AddIcon
                  className={classes.addIcon}
                  onClick={() => setDropzoneDialog(true)}
                />
              )}
            </Grid>
          </>
        )}

        <Grid container className={classes.form}>
          <Grid item xs={12}>
            <Typography variant="h5"> {t("unavailable")} </Typography>
          </Grid>
          {!edit && !unavailable.from && !unavailable.to ? (
            <Typography variant="overline"> {t("always_available")}</Typography>
          ) : (
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  name="from"
                  disabled={!edit}
                  ampm={false}
                  label={t("from")}
                  style={{
                    minWidth: 160,
                    maxWidth: 160,
                    marginRight: 10,
                    marginTop: 0,
                  }}
                  variant="outlined"
                  margin="normal"
                  format="DD-MM-YYYY"
                  views={["year", "month", "date"]}
                  value={unavailable.from || null}
                  onChange={(date) => {
                    const u = { ...unavailable, from: date };
                    candidateInfo.unavailable = u;
                    setUnavailable(u);
                  }}
                />
                <DatePicker
                  name="to"
                  disabled={!edit}
                  ampm={false}
                  label={t("untill")}
                  style={{
                    minWidth: 160,
                    maxWidth: 160,
                    marginRight: 10,
                    marginTop: 0,
                  }}
                  variant="outlined"
                  margin="normal"
                  format="DD-MM-YYYY"
                  views={["year", "month", "date"]}
                  value={unavailable.to || null}
                  onChange={(date) => {
                    const u = { ...unavailable, to: date };
                    candidateInfo.unavailable = u;
                    setUnavailable(u);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}
        </Grid>

        <Grid container className={classes.form}>
          <Grid item xs={12}>
            <Typography variant="h5"> {t("rates")} </Typography>
          </Grid>
          {!edit && !candidateInfo.dayPrice && !candidateInfo.pricePerWord ? (
            <Typography variant="overline"> {t("no_price_set")}</Typography>
          ) : (
            <>
              <Grid item style={{ marginTop: 10, marginRight: 10 }}>
                <TextField
                  id="dayPrice"
                  label={t("dayRate")}
                  disabled={!edit}
                  onChange={(event) =>
                    (candidateInfo.dayPrice = event.currentTarget.value)
                  }
                  defaultValue={candidateInfo.dayPrice || ""}
                />
              </Grid>
              <Grid item style={{ marginTop: 10 }}>
                <TextField
                  id="pricePerWord"
                  label={
                    showPricePerWord() ? t("pricePerWord") : t("pricePerHour")
                  }
                  disabled={!edit}
                  onChange={(event) =>
                    (candidateInfo.pricePerWord = event.currentTarget.value)
                  }
                  defaultValue={candidateInfo.pricePerWord || ""}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid container className={classes.form}>
          <Grid item xs={12}>
            <Typography variant="h5"> {t("native_language")} </Typography>
          </Grid>
          {!!motherTongue || !!edit ? (
            <Grid item xs={6} style={{ marginTop: 10 }}>
              <Autocomplete
                options={languageList}
                getOptionLabel={(language) => language.name}
                disabled={!edit}
                value={languageList.find((l) => l.code === motherTongue)}
                getOptionSelected={(option, value) =>
                  option.value === value.code
                }
                disableClearable
                style={{ paddingRight: "3%" }}
                onChange={(event, input) => {
                  setMotherTongue(input.code);
                  set(
                    candidate,
                    "candidateInfo.skillSet.motherTongue",
                    input.code
                  );
                }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={t("native_language")}
                    fullWidth
                  />
                )}
              />
            </Grid>
          ) : (
            <Typography variant="overline"> {t("no_mothertongue")}</Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          {t("public_enabled")}
          <RadioGroup
            aria-label="data"
            value={publicEnabled}
            name="radio-buttons-group"
            disabled={!edit}
          >
            <FormControlLabel
              disabled={!edit}
              onClick={() => {
                if (!! edit ) {
                  setPublicEnabled(true);
                  candidate.publicEnabled = true;
                }
              }}
              value={true}
              control={<Radio />}
              label={t("yes")}
            />
            <FormControlLabel
              disabled={!edit}
              value={false}
              onClick={() => {
                if (!! edit ) {
                  setPublicEnabled(false);
                  candidate.publicEnabled = false;
                }
              }}
              control={<Radio  />}
              label={t("no")}
            />
          </RadioGroup>
        </Grid>

        {canTranslate() && (
          <Grid container className={classes.form}>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ marginTop: 10 }}>
                {" "}
                {t("translations")}{" "}
              </Typography>
            </Grid>
            {(!translationSkills || !translationSkills.length) && !edit ? (
              <Typography variant="overline">
                {" "}
                {t("no_translations")}
              </Typography>
            ) : (
              <Grid item xs={12} style={{ marginTop: 10 }}>
                {translationSkills.map(({ from, to, _id }) => {
                  return (
                    <Grid item xs={12} key={_id}>
                      <Autocomplete
                        options={languageList}
                        disabled={!edit}
                        getOptionLabel={(language) => language.name}
                        style={{
                          marginBottom: 20,
                          width: "48%",
                          marginRight: "2%",
                          float: "left",
                        }}
                        value={
                          languageList.find((l) => l.code === from) || null
                        }
                        getOptionSelected={(option, value) =>
                          option.value === value.code
                        }
                        onChange={(event, input) => {
                          const skills = translationSkills.map((skill) => {
                            if (skill._id === _id)
                              skill.from = input ? input.code : "";
                            return skill;
                          });
                          set(candidate, "skillSet.translationSkills", skills);
                          setTranslationSkills(skills);
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={t("translate_from")}
                            fullWidth
                          />
                        )}
                      />
                      <Autocomplete
                        options={languageList}
                        disabled={!edit}
                        getOptionLabel={(language) => language.name}
                        style={{
                          marginBottom: 20,
                          width: "48%",
                          marginLeft: "2%",
                          float: "left",
                        }}
                        value={languageList.find((l) => l.code === to) || null}
                        getOptionSelected={(option, value) =>
                          option.value === value.code
                        }
                        onChange={(event, input) => {
                          const skills = translationSkills.map((skill) => {
                            if (skill._id === _id)
                              skill.to = input ? input.code : "";
                            return skill;
                          });
                          set(candidate, "skillSet.translationSkills", skills);
                          setTranslationSkills(skills);
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={t("translate_to")}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {edit && (
              <Grid item>
                <Typography
                  style={{
                    marginLeft: 10,
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                  onClick={() => {
                    const list = translationSkills || [];
                    const skills = [
                      ...list,
                      {
                        from: "",
                        to: "",
                        _id: Math.random().toString(16).substr(2, 8),
                      },
                    ];
                    set(
                      candidate,
                      "candidateInfo.skillSet.translationSkills",
                      skills
                    );
                    setTranslationSkills(skills);
                  }}
                >
                  + {t("add_language")}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {isRecruiter() && candidate._id && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5"> {t("assigned_to")} </Typography>
              </Grid>
              <Grid item>
                {!edit && !candidate.assignedTo ? (
                  <Typography variant="overline">
                    {" "}
                    No recruiter assigned{" "}
                  </Typography>
                ) : (
                  !edit &&
                  candidate.assigned[0] && (
                    <Grid item>
                      {" "}
                      <Chip
                        label={`${candidate.assigned[0].firstname} ${candidate.assigned[0].name}`}
                      />{" "}
                    </Grid>
                  )
                )}
                {!!edit && (
                  <RecruiterSelect
                    recruiter={
                      candidate.assigned.map((r) => {
                        return { _id: r._id, name: `${r.firstname} ${r.name}` };
                      })[0]
                    }
                    onChange={(recruiter) =>
                      (candidate.assignedTo = recruiter && recruiter._id)
                    }
                  />
                )}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5"> {t("job_applications")} </Typography>
              </Grid>
              <Grid item>
                <JobApplicationList candidate={candidate} hideFilter={true} />
              </Grid>
              {!mini && (
                <AddIcon
                  className={classes.addIcon}
                  onClick={() => setFormDialog(addDialogs.jobs)}
                />
              )}
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5"> {t("tags")} </Typography>
              </Grid>
              <Grid item>
                <CandidateTags candidate={candidate} edit={edit} />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5"> {t("source")} </Typography>
              </Grid>
              <Grid item>
                <CandidateSource candidate={candidate} edit={edit} />
              </Grid>
            </Grid>

            <Grid container>
              <form className={classes.form}>
                <Grid container className={classes.links}>
                  <Grid item xs={12}>
                    <Typography variant="h5"> Links </Typography>
                  </Grid>
                  <CandidateLinks candidate={candidate} edit={edit} />
                </Grid>
              </form>
              {!mini && (
                <AddIcon
                  className={classes.addIcon}
                  onClick={() => setFormDialog(addDialogs.links)}
                />
              )}
            </Grid>
          </>
        )}

        {!isRecruiter() && (
          <Chip
            label={`${t("remove_account")}`}
            className={classes.chip}
            style={{
              marginTop: 0,
              backgroundColor: "#f44336",
              color: "#fff",
              float: "right",
              padding: "3px 0 0",
              fontSize: 12,
            }}
            onClick={() => onRemoveAccount()}
          />
        )}

        {removeCandidateDialog && (
          <MediaFortaDialog data={removeCandidateDialog} />
        )}

        {formDialog && (
          <MediaFortaDialog
            data={formDialog}
            candidate={candidate}
            edit={true}
          />
        )}

        <DropzoneDialog
          open={dropzoneDialog}
          onSave={(fs) =>
            CandidatesService.upload(candidate._id, fs).then((response) => {
              const data = response.data.files || [response.data.file];
              setFiles([...files, ...data]);
              setDropzoneDialog(false);
            })
          }
          acceptedFiles={[
            "image/jpeg",
            "image/png",
            "application/pdf",
            ".doc",
            ".docx",
          ]}
          showPreviews={true}
          maxFileSize={10000000}
          maxWidth="md"
          className={classes.dialog}
          onClose={() => setDropzoneDialog(false)}
        />
      </>
    )
  );
}
