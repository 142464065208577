
// Services
import ApiService from "../services/api.service"

const model = 'recruiters'

export default class RecruitersService {
  static list(search = false, limit = 10, skip = 0, fields = '', filter = false) {
    if (search && search !== '') search = `{"$or":[{"name": "regex:${search}"},{"firstname":"regex:${search}"}]}`
    return ApiService.list(model, { search, filter, limit, skip, fields}).then(response => response.data)
  }

  static get(recruiterId) {
    return ApiService.get(`${model}/${recruiterId}`).then(response => response.data)
  }
}