// Services
import ApiService from "../services/api.service";
import { getLanguage } from '../utils/i18n';

const model = 'skills';
const candidateModel = 'candidates';

export default class SkillsService {
  static list({ search = false, limit = 100, skip = 0, fields = '', filter = false, sort } = {}) {
    const language = getLanguage();
    return ApiService.website.list(model, { search, filter, limit, skip, fields, sort: `label.${language.toUpperCase()}` }).then(response => response.data);
  }

  static listCandidates({ search = false, userIds = [], limit = 20, skip = 0, fields = '' } = {}) {
    userIds = `userId=${userIds.toString()}`;
    return ApiService.list(candidateModel, { search, filter: userIds, limit, skip, fields }).then(response => response.data);
  }

  static post(data = {}) {
    return ApiService.post(model, data).then(response => response);
  }

  static patch(skillId, patch = {}) {
    return ApiService.patch(`${model}/${skillId}`, patch).then(response => response);
  }

  static addSkill(skillId, patch = {}) {
    return ApiService.patch(`${model}/${skillId}/add`, patch).then(response => response);
  }

  static delete(skillId) {
    return ApiService.delete(`${model}/${skillId}`).then(response => response);
  }

}