import React from 'react'
import { t } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import {  TextField } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import JobsService from '../services/jobs.service';

const useStyles = makeStyles(() => ({ autocomplete: { minWidth: 400 } }))
const OPTIONS_LIMIT = 5;
const campaignFilter = createFilterOptions()

export default function JobCampaigns({ onChange, campaigns = [] }) {
  const classes = useStyles()
  const [campaignList, setCampaignList] = React.useState([])

  React.useEffect(() => {
    if (! campaignList.length) JobsService.listCampaigns().then(data => setCampaignList(data.records))
    // eslint-disable-next-line
  }, [campaignList])

  return (
    <Autocomplete
      multiple
      id="campaigns"
      filterOptions={(options, params) => {
        const filtered = campaignFilter(options, params).slice(0, OPTIONS_LIMIT);
        if (params.inputValue !== '') filtered.push({ _id: params.inputValue, label: params.inputValue });
        return filtered;
      }}
      onChange={(event, values) => onChange && onChange(values) }
      options={campaignList}
      className={classes.autocomplete}
      getOptionLabel={option => option.title}
      defaultValue={campaigns}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={t("add campaigns")}
        />
      )}
    />        
  )
}