import * as React from 'react'
import { t } from '../utils/i18n'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from 'formik-material-ui'
import { Button, Box } from '@material-ui/core'

// Context
import { useApp } from "../context/app"

// Services
import CandidatesService from "../services/candidates.service"

// App components
import DatePicker from './DatePicker'

const useStyles = makeStyles(theme => ({
  description: { '& .DraftEditor-editorContainer > div': { minHeight: 250 } },
  button: { textAlign: 'right' }
}))

const WorkExperienceSchema = Yup.object().shape({
	function: Yup.string()
  	.min(2, t('function_short'))
   	.required(t('required')),
	company: Yup.string()
		.min(2, t('company_short'))
		.required(t('required'))
})

export default function CandidateExperienceForm({ experience = {}, candidateId, onClose }) {
  const classes = useStyles()
	const { setResponseDialog } = useApp()

	const today = moment()
	const [from, setFrom] = React.useState(experience.from ? moment(experience.from) : today)
	const [to, setTo] = React.useState(experience.to ? moment(experience.to) : today)

	return (
  	<Formik
	    initialValues={{
	    	function: experience.function ? experience.function : '',
	    	company: experience.company ? experience.company : ''
	    }}
	    validateOnChange={false}
	    validateOnBlur={false}
	    validationSchema={WorkExperienceSchema}
	    validate={() => {
        const errors =  {}
	    	// if (! assignedTo) { errors.assignedTo = 'Required' }
        return Object.keys(errors).length ? errors : false
	    }}
	    onSubmit={(values, { setSubmitting }) => {
				const data = { from: from.toDate(), to: to.toDate(), candidateId, ...values }
        if (experience._id) {
          CandidatesService.patchExperience(experience._id, data).then(onClose).catch(error => setResponseDialog({error}))
        } else {
					CandidatesService.postExperience(data).then(onClose).catch(error => setResponseDialog({error}))        
        }
	      setSubmitting(false)
	    }}
    >
	    {({ submitForm, isSubmitting, errors, validateField }) => 
	    {
	    	return (
	        <Form
	    			onBlur = {e => {
	    				const fieldName = e.target.name
	    				fieldName && fieldName !== 'image-url' && validateField(fieldName)
				    }}
	        >
	        	<Box display="flex" style={{margin: '20px 10px 30px'}}>
              <Field 
		            name="function" 
		            component={TextField} 
		            label={t("function")}
		            variant="outlined" 
								style={{width: '100%', marginRight: 10}}
		            margin={"normal"}/>
              <Field 
		            name="company" 
		            component={TextField} 
		            label={t("company")}
		            variant="outlined" 
								style={{width: '100%', marginRight: 10}}
		            margin="normal"/>
							<DatePicker 
	          		name="from"
	              disabled={isSubmitting}
								ampm={false}
								disableTime={true}
	              maxDate={today}
	              label={t("from")}
	              style={{minWidth: 160, maxWidth: 160, marginRight: 10}}
	              variant="outlined"
	              margin="normal"
								format="MMMM YYYY"
								views={['year', 'month']}
	              error={!! errors['from']} 
            		helperText={errors['from']} 
	              value={from}
	            	onChange={setFrom}
	          	/>
							<DatePicker 
	          		name="to"
	              disabled={isSubmitting}
								ampm={false}
								disableTime={true}
	              maxDate={today}
								label={t("to")}
								views={['year', 'month']}
	              style={{minWidth: 160, maxWidth: 160}}
	              variant="outlined"
	              margin="normal"
	              format="MMMM YYYY"
	              error={!! errors['to']} 
            		helperText={errors['to']} 
	              value={to}
	            	onChange={setTo}
	          	/>
	          </Box>
	          <Box style={{margin: '30px 10px 20px'}} className={classes.button}>
	            <Button
	              variant="contained"
	              color="primary"
	              disabled={isSubmitting}
	              onClick={submitForm}
	            >
                { experience._id ? t('edit_experience') : t('add_experience') }
	            </Button>
	          </Box>
	        </Form>
	    )}}
  	</Formik>
  )
}