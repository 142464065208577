import React from 'react'
import { t } from '../utils/i18n'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { TextField } from 'formik-material-ui'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid } from '@material-ui/core'

// Context
import { useApp } from "../context/app"

// Services
import UsersService from "../services/users.service"

// App components
import Alert from "./Alert"

const useStyles = makeStyles(theme => ({ 
  form: { textAlign: 'center' } 
}))

const ResetSchema = Yup.object().shape({
  email: Yup.string()
   .required('Verplicht')
})


export default function SendResetPasswordForm() {
  const classes = useStyles()
  const { setResponseDialog } = useApp()
  const [ error, setError ] = React.useState(false)

  const reset = ({email}, { setSubmitting }) => {
    setError(false)
    UsersService.sendResetPassword(email).then(() => {
      setResponseDialog({title: t('email_sent_title'), message: t('email_sent_spam')})
      setSubmitting(false)
    }).catch(() => {
      setError(true)
      setSubmitting(false)
    })    
  }

  return (
   <Formik
    initialValues={{ email: '' }}
    validationSchema={ ResetSchema }
    onSubmit={ reset }
    validateOnChange={false}
	  validateOnBlur={false}
   >
   {({ submitForm, isSubmitting }) => (
     <Form className={classes.form}>
      { error && <Alert severity="error">{t('reset_failed')}</Alert> }
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Field 
            component={TextField} 
            name="email" 
            style={{marginBottom: 20}}
            type="email"
            autoComplete="email"
            variant="outlined"
            label={t("email")}
            onKeyUp={event => event.keyCode === 13 && submitForm() }
            fullWidth />
        </Grid>
      </Grid>
        <br />
       <Button
          variant="contained"
          color="primary"
          style={{backgroundColor: '#0df394', color: '#041e7a', marginRight: 20}}
          size='large'
          disabled={isSubmitting}
          onClick={submitForm}
        >
          {t("email_forgot_password_link")}
        </Button>
     </Form>
    )}
   </Formik>
  )
}