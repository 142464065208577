import React from 'react'
import { t } from '../utils/i18n'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Box, TextField, Select, MenuItem, FormControl, InputLabel, IconButton } from '@material-ui/core'
import { Work as WorkIcon, Delete as DeleteIcon, Edit as EditIcon, Check as CheckIcon, Remove as RemoveIcon } from '@material-ui/icons'

// Context
import { useApp } from "../context/app"

// Services
import candidatesService from '../services/candidates.service'

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    margin: '0 5px 40px',
    '& > *': {
      width: 'fit-content',
      margin: '10px 0',
    }
  },
  experience: {
    '& > div:not(.MuiBox-root)': {
      marginRight: 20,
      width: '100%'
    },
    '& label': {
      color: '#b6c1e6 !important',
      fontWeight: 500 
    },
    '& input': {
      color: theme.palette.primary.dark,
      fontWeight: 500 ,
      textOverflow: 'ellipsis'
    },
    '& .MuiInputBase-root:before': {
      borderBottom: '1px solid #b6c1e6'
    }
  },
  date: {
    maxWidth: 120,
    minWidth: 120
  },
  icon: {
    marginRight: 20,
    '& svg': {
      padding: 15,
      width: 50,
      height: 50,
      borderRadius: '50%',
      background: '#efeffd'
    }
  },
  check: {
    padding: '0 10px 2px 0',
    '& svg': {
      padding: 5,
      width: 25,
      height: 25
    }
  },
  checked: {
    '& svg': {
      background: theme.palette.mediaforta.main,
      color: '#fff'
    }
  },
  select: {
    background: 'transparent',
    width: 235,
    paddingTop: 10,
    color: '#303f9f',
    fontWeight: 500,
    "& .MuiSelect-select:focus": {
      background: 'transparent',
    }
  },
  iconBox: {
    marginTop: 16,
    alignItems: 'center'
  }
}))

const inputProps = { type: 'text', disabled: true, margin: 'normal' }

export const fetchWorkList = async candidateId => candidatesService.listExperience(candidateId)
export default function CandidateExperienceList({ experience, candidate, setExperience }) {
  const classes = useStyles()
  const { setResponseDialog } = useApp()
  
  const { _id: candidateId, candidateInfo } = candidate
  const [ freelancer, setFreelancer ] = React.useState(candidateInfo.freelancer || false)
  const [ yearsExperience, setYearsExperience ] = React.useState(candidateInfo.skillSet.yearsExperience || 0)
  const [ workExperience, setWorkExperience ] = React.useState([])
  const [ removedExperience, setRemovedExperience ] = React.useState(0)

  const setError = (error) => {
    setWorkExperience([])
    setResponseDialog({error})
  }
  
  React.useEffect(() => {
    if (! experience || !! removedExperience) fetchWorkList(candidateId).then(setWorkExperience).catch(setError)
  // eslint-disable-next-line
  }, [ experience, removedExperience ])
  
  const toggleFreelancer = () => {
    candidate.candidateInfo.freelancer = ! freelancer
    candidatesService.patch(candidate._id, candidate).then(() => setFreelancer(! freelancer))
  }

  const updateYearExperience = event => {
    const yearsExperience = event.target.value
    candidate.candidateInfo.skillSet.yearsExperience = yearsExperience
    candidatesService.patch(candidate._id, candidate).then(() => setYearsExperience(yearsExperience))
  }

  const removeExperience = experience => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure_remove_experience'),
      onConfirm: () => candidatesService.deleteExperience(experience._id).then(() => setRemovedExperience(experience._id))
    })
  }

  const renderExperience = experience => {
    return (
      <Box className={classes.experience} key={experience._id} display="flex" style={{margin: '20px 10px 30px'}}>
        <Box className={`${classes.icon} ${classes.iconBox}`} display="flex"> <WorkIcon/> </Box>
        <TextField 
          name="function" 
          label={t("function")} 
          value={experience.function}
          {...inputProps} />
        <TextField 
          name="company" 
          label={t("company")}
          value={experience.company} 
          {...inputProps} />
        <TextField 
          name="from" 
          label={t("from")}
          className={classes.date}
          value={moment(experience.from).format('MMMM YYYY')} 
          {...inputProps} />
        <TextField 
          name="to" 
          label={t("to")}
          className={classes.date}
          value={moment(experience.to).format('MMMM YYYY')} 
          {...inputProps} />
        <Box className={classes.iconBox} display="flex">
          {
            !! setExperience &&
            <IconButton onClick={() => setExperience(experience)}> <EditIcon /> </IconButton>
          }
          <IconButton onClick={() => removeExperience(experience)}> <DeleteIcon /> </IconButton>
        </Box>
      </Box>
    )
  } 

  return ( 
  	<React.Fragment>
      <Box display="flex" className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="yearsExperience"> {t('years_experience')} </InputLabel>
          <Select
            className={classes.select}
            value={yearsExperience}
            onChange={updateYearExperience}
            inputProps={{ name: 'yearsExperience', id: 'yearsExperience' }}
          >
            { 
              [...Array(40 + 1)].map((_, i) => {
                return <MenuItem key={i} value={i}> {i === 0 ? 'none' : i + ' years'}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <Box className={`${classes.iconBox} ${classes.icon}`} display="flex" >
          <IconButton onClick={toggleFreelancer} className={`${classes.check} ${freelancer ? classes.checked : ''}`}> 
            {freelancer ? <CheckIcon /> : <RemoveIcon/>} 
          </IconButton>
          {t('work_as_freelancer')}
        </Box>
      </Box>
     { !! workExperience.length && workExperience.map(renderExperience) }
		</React.Fragment>    
  )
}