import React from 'react'
import { Route, Redirect } from 'react-router-dom'

// Context
import { useUser } from "../context/user"

function CandidateRoute({ component: Component, ...rest }) {
  const { role = {} } = useUser()
  if (role.type !== 'candidate') return null
  return (
    <Route
      {...rest}
      render={props => 
        props.match.path === '/' ? 
          (<Redirect to="/profile" />) : 
          (<Component {...props} />)
      }
    />
  )
}

export default CandidateRoute