import React from 'react'
import { t } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, TextField, RadioGroup, Button, Typography, FormControlLabel, Radio } from '@material-ui/core'

// Services 
import UsersService from "../services/users.service"

// Context
import { useAuth } from "../context/auth"

const useStyles = makeStyles(() => ({
  root: {
    width: '55vw',
    padding: '0 30px',
    '& .MuiTextField-root': {
      width: '100%',
      marginBottom: 10
    }
  },
  text: {
    textAlign: 'left'
  },
  button: {
    textAlign: 'right',
    marginTop: 20
  }
}))

export default function CandidateRemoveForm({ onClose }) {
  const classes = useStyles();
  const { logout } = useAuth();
  const [ why, setWhy ] = React.useState('');
  const [ keepData, setKeepData ] = React.useState(true);

  const removeAccount = () => {
    UsersService.delete({keepData, why}).then(logout) 
  }

  return (
    <form className={classes.root}>
      <br/>
      <Typography className={classes.text} variant="h5" style={{width: "100%"}}>  {t('why_remove_account')} </Typography>
      <Grid>
        <br/>
        <Grid item xs={12}>
          <TextField 
            name='why' 
            type="text"
            multiline
            rows={5}
            variant="outlined"
            defaultValue=''
            onChange={({currentTarget: {value}}) => setWhy(value)}
          />
        </Grid>
        <br/>
        <Grid item xs={12} className={classes.text}>       
          <RadioGroup
            aria-label="data"
            defaultValue="yes"
            name="radio-buttons-group"
          >
            <FormControlLabel value="yes" onClick={() => setKeepData(true)} control={<Radio />} label={ t('keep_data_and_mail') } />
            <FormControlLabel value="no"  onClick={() => setKeepData(false)} control={<Radio />} label={ t('delete_data_and_mail') } />
          </RadioGroup>
        </Grid>
      </Grid>
      <br/>
      <Grid className={classes.button}>
        <Grid item xs={6} style={{float: 'left'}}>
          <Button onClick={() => onClose()}> {t('cancel')} </Button>
        </Grid>
        <Grid item xs={6} style={{float: 'right'}}>
          <Button onClick={() => removeAccount()}  style={{backgroundColor:'#f44336'}}> {t('remove_account')} </Button>
        </Grid>
      </Grid>
    </form>
  )
}