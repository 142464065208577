import React from 'react'
import { t } from '../utils/i18n'
import { IconButton } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

// App components
import CandidateScoreCardList from './CandidateScoreCardList'
import CandidateScoreCardSelect from './CandidateScoreCardSelect'
import MediaFortaDialog from "./MediaFortaDialog"

// services 
import CandidatesService from '../services/candidates.service';

// assets
import '../assets/scss/form-wrapper.scss'

export const addScoreCards = async (candidateId, scoreCards) => CandidatesService.addScoreCards(candidateId, scoreCards);

export default function CandidateScoreCards({ candidate = {}, className }) {
  const [scoreCard, setScoreCard] = React.useState(false);
  const [ refresh, setRefresh ] = React.useState(true);
  
  const onSelected = cards => addScoreCards(candidate._id, cards).then(({candidate: {scoreCards}}) => {
    setScoreCard(false);
    candidate.scoreCards = scoreCards;
    setRefresh(Math.random());
  });

  return ( 
    <div className={className}>
      <IconButton aria-label="add scorecards" onClick={() => setScoreCard({})}> <AddIcon /> {t('add scorecards')} </IconButton>
      {scoreCard && ! scoreCard._id && 
          <MediaFortaDialog data={{
            title: 'Select scorecards', 
            onClose: () => { setScoreCard(false) }, 
            component: { Component: CandidateScoreCardSelect, data: {candidate, onSelected} }
          }} 
        />
      }
      { candidate && <CandidateScoreCardList fullwidth setRefresh={setRefresh} candidate={candidate} scoreCard={scoreCard} setScoreCard={setScoreCard}/> }
    </div>
  )
}