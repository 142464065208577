import React from 'react'
import { t } from '../utils/i18n'

import { Typography, Container, CssBaseline } from '@material-ui/core'

// Model components
import TaskForm from '../components/TaskForm'

export default function AddTask() {
  return (
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
      	{t('new_task')}
      </Typography>
      <TaskForm />   
    </Container>
  )
}