import React from 'react'
import { t } from '../utils/i18n'
import { useLocation, useHistory } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import {
	AppBar, 
  Avatar,
	Toolbar,
	// Typography,
	Tabs, 
	Tab,
	Link as MuiLink,
	InputBase,
	IconButton,
  Menu,
  MenuItem
} from '@material-ui/core'
import { 
	Group as GroupIcon,
	Work as WorkIcon,
	DateRangeRounded as DateRangeRoundedIcon,
	Assignment as AssignmentIcon,
	PieChart as PieChartIcon,
	Search as SearchIcon,
	// Add as AddIcon,
  ExitToApp as ExitToAppIcon,
  Person as PersonIcon,
  Link as LinkIcon,
  School as SchoolIcon,
  Settings as SettingsIcon
} from '@material-ui/icons'

// Context
import { useAuth } from "../context/auth"

// Components
import Link from "./Link"

const useStyles = makeStyles((theme) => ({
  logo: {
    marginLeft: '20px',
    position: 'relative',
    paddingLeft: '30px',
    lineHeight: '1.6em',
    fontSize: '1.2em',
    fontWeight: theme.typography.fontWeightMedium,
    "& div": {
      position: 'absolute',
      width: '24px !important',
      height: '24px !important',
      left: 0
    }
  },
  settings: {
  	position: 'relative',
  	fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: '40px',
    lineHeight: '1.6em',
    padding: '0 20px',
    fontSize: '1em'
  },
  input: {
  	opacity: 1,
    marginLeft: theme.spacing(4),
    color: theme.palette.mediaforta.light,
    flex: 1,
  },
  iconButton: { padding: 5 },
  search: {
  	borderBottom: '1px solid',
    borderBottomColor: theme.palette.mediaforta.light,
  	marginRight: '50px',
  },
  filter: {
  	position: 'relative',
    float: 'right',
    paddingLeft: '40px',
    paddingTop: '5px',
    color: theme.palette.mediaforta.light
  },
  role: { textTransform: 'capitalize' },
  logout: {
    position: 'absolute',
    right: 30,
    top: 10,
    zIndex: 9,
    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  menu: {
    '& .MuiPaper-root': { 
      boxShadow: 'none',
      border: '1px solid #e8e8e8',
      borderRadius: 10 
    } 
  }
}))

const params = new URLSearchParams(window.location.search)
const routes = {
  recruiter: [ '/candidates', '/jobs', '/agenda', '/tasks', '/stats' ],
  candidate: [ '/profile', '/work', '/education', '/links', '/jobapplications' ]
}

export default function NavBar({role = {}}) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { user, logout } = useAuth()
  const [ searchInput, setSearchInput ] = React.useState(params.get('search') || '')
	const [ active, setActive ] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null);

	React.useEffect(() => {
		if (role.type) {
			const index = routes[role.type].indexOf(location.pathname)
      if (active !== index) setActive(index === -1 ? false : index)
    }
    // eslint-disable-next-line
  }, [ location, role ])
  
  const search = event => {
		event.preventDefault()
    window.location = `/candidates?search=${searchInput}`
	}
	
	if (location.pathname === '/complete') return (
    <MuiLink href="#" onClick={logout} className={classes.logout}>
      <div type="title" color="inherit"> <ExitToAppIcon /> {t('logout')} </div>
    </MuiLink>
  )
	
  return (
    <AppBar position="fixed" elevation={0} className={classes.appbar}>
      <Toolbar>
      	<div style={{ flex: 2 }}>
          <div className={classes.logo} type="title" color="inherit">
            <Avatar alt="Mediaforta" src="/logo-blue-32x32.png" />
            <span className={classes.role}>{ role.type || 'Mediaforta' }</span>
          </div>
        </div>
        { ! user ? null 
          : role.type === 'recruiter' ?
          	<React.Fragment>
  		        <div style={{ flex: 3 }}>
  		        	<Tabs  value={active}>
  				        <Tab icon={<GroupIcon />} label={t("candidates")} component={Link} href="/candidates" />
  				        <Tab icon={<WorkIcon />} label={t("jobs")} component={Link} href="/jobs" />
  				        <Tab icon={<DateRangeRoundedIcon />} label={t("agenda")} component={Link} href="/agenda" />
  				        <Tab icon={<AssignmentIcon />} label={t("tasks")} component={Link} href="/tasks" />
  				        <Tab icon={<PieChartIcon />} label={t("stats")} component={Link} href="/settings/stats" />
  				      </Tabs>
  		        </div>
  		        <form className={classes.search} onSubmit={search}>
  				      <IconButton type="submit" className={classes.iconButton} aria-label={t("search")}>
  				        <SearchIcon />
  				      </IconButton>
  				      <InputBase
  				        className={classes.input}
                  defaultValue={searchInput}
                  onChange={event => setSearchInput(event.currentTarget.value)}
  				        placeholder={t("search_candidates")}
  				        inputProps={{ 'aria-label': t("search_candidates") }} />
  				      {/* <Typography className={classes.filter} color="inherit">
  		            <AddIcon /> {t("add_filter")}
  		          </Typography> */}
  				    </form>
              <div>
                <MuiLink href="#" aria-controls="menu-appbar" onClick={handleMenu} aria-haspopup="true">
                  <div className={classes.settings} type="title" color="inherit">
                    <SettingsIcon /> {t('settings')}  
                  </div>
                </MuiLink>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={open}
                  className={classes.menu}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => {
                    history.push('/settings/specialisations')
                    handleClose()
                  }}>{t('specialisations')}</MenuItem>
                  <MenuItem onClick={() => {
                    history.push('/settings/interviews')
                    handleClose()
                  }}>{t('interviews')}</MenuItem>
                  <MenuItem onClick={() => {
                    history.push('/settings/recruiters')
                    handleClose()
                  }}>{t('recruiters')}</MenuItem>
                  <MenuItem onClick={() => {
                    history.push('/settings/scorecards')
                    handleClose()
                  }}>{t('scorecards')}</MenuItem>
                  <MenuItem onClick={() => {
                    history.push('/settings/mediums')
                    handleClose()
                  }}>{t('mediums')}</MenuItem>
                  <MenuItem onClick={() => {
                    history.push('/settings/stats')
                    handleClose()
                  }}>{t('stats')}</MenuItem>
                  <MenuItem onClick={() => {
                    history.push('/settings/folders')
                    handleClose()
                  }}>{t('folders')}</MenuItem>
                </Menu>
              </div>
              <div>
  				      <MuiLink href="#" onClick={logout}>
  					      <div className={classes.settings} type="title" color="inherit"> <ExitToAppIcon /> {t('logout')} </div>
  		          </MuiLink>
  		        </div>
  		      </React.Fragment>
          : role.type === 'candidate' &&
          	<React.Fragment>
  		        <div style={{ flex: 4 }}>
  		        	<Tabs value={active}>
  				        <Tab icon={<PersonIcon />} label={t("profile")} component={Link} href={`/profile`} />
                  <Tab icon={<WorkIcon />} label={t("work")} component={Link} href={`/work`} />
                  <Tab icon={<SchoolIcon />} label={t("education")} component={Link} href={`/education`} />
                  <Tab icon={<LinkIcon />} label={t("links")} component={Link} href={`/links`} />
                  <Tab icon={<WorkIcon />} label={t("job_applications")} component={Link} href={`/jobapplications`} />
  				      </Tabs>
  		        </div>
  		        <div>
  				      <MuiLink href="#" onClick={logout}>
  					      <div className={classes.settings} type="title" color="inherit"> <ExitToAppIcon /> {t('logout')} </div>
  		          </MuiLink>
  		        </div>
  		      </React.Fragment>
        }
      </Toolbar>
    </AppBar>
  )
}
