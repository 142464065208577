import React from 'react';
import { t } from '../utils/i18n';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles'
import {
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  ThemeProvider,
  IconButton,
  Box
} from '@material-ui/core'
import {  Delete as DeleteIcon } from '@material-ui/icons'


// themes
import tableThema from '../themes/table'

// Context
import { useApp } from "../context/app"

// Services
import JobsService from '../services/jobs.service';


import CampaignCard from "./CampaignCard";
import CampaignDetailsOverview from "./CampaignDetailsOverview";

const useStyles = makeStyles(theme => ({
  tab: { width: 400 },
  category: {
    cursor: 'pointer',
    display: 'flex', 
    padding: '30px 20px 0',
    '& h5': { 
      flex: 1,
      '& svg': {
        background: theme.palette.mediaforta.main,
        borderRadius: '50%',
        fontSize: 20,
        fill: '#fff'
      }
    }
  },
  row: {
  	cursor: 'pointer',
  	'& th, & td': {
  		paddingTop: 15,
  		paddingBottom: 15
  	},
  	'& th label, & td label': { cursor: 'pointer' },
  },
  actions: {
    padding: 0,
    margin: '0 0 0 10px',
    '& svg': { fontSize: 20 }
  }
}))

export const deleteCampaign = async (campaignId) => JobsService.deleteCampaign(campaignId)
export default function CampaignList({ campaigns, onRemove, setRefresh }) {
const classes = useStyles();
  const [ campaign, setCampaign ] = React.useState(false)
  const { setResponseDialog } = useApp()
  
  const removeCampaign = campaignId => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure you want to remove this campaign?'),
      onConfirm: () => {
        deleteCampaign(campaignId).then(() => onRemove())
      }
    })
  }

  return <React.Fragment>
    <Box display="flex">
      <div style={{flex: 4}}>
        <ThemeProvider theme={ tableThema }>
          <TableContainer className={classes.tablecontainer}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Budget</TableCell>
                  <TableCell>Start</TableCell>
                  <TableCell>End</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { ! campaigns.length ?
                    <TableRow>
                      <TableCell>{t('no campaigns')}</TableCell>
                    </TableRow>
                  :
                  campaigns.map((campaign, index) => {
                    return <TableRow key={index} className={classes.row} onClick={() => setCampaign(campaign)}>
                        <TableCell>
                          <b>{campaign.title}</b>
                        </TableCell>
                        <TableCell>
                          {campaign.budget}
                        </TableCell>
                        <TableCell>
                          {moment(campaign.start).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell>
                          {moment(campaign.end).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell width={70}>
                          <IconButton className={classes.actions} aria-label={t('remove campaign')} onClick={() => removeCampaign(campaign._id, index)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    })
                  }
              </TableBody>
            </Table>
          </TableContainer>
        </ThemeProvider>
      </div>

      { campaign && campaign._id &&
        <div style={{flex: 2}}>
          <CampaignCard
            campaign={campaign}
            card={{ id: 'details', component: CampaignDetailsOverview }}
            hideTabs={true}
            onClose={() => {
              setCampaign(false)
              setRefresh()
            }}
            onUpdate={() => setRefresh()}
          />
        </div>
      }
    </Box>
		</React.Fragment>    
}