import React from 'react'
import { t } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import { 
  ErrorOutline as ErrorOutlineIcon,
  Info as InfoIcon 
} from '@material-ui/icons'
import { 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: '9999 !important',
    textAlign: 'center',
    '& .MuiDialog-container': {
      '& > div': {
        backgroundColor: 'transparent',
        borderRadius: 10,
        maxWidth: '60vw',
      },
      '& .MuiDialogContent-root, & .MuiDialogActions-root': {
        backgroundColor: '#fff',
        margin: 0,
        padding: 5,
        '& > button': { padding: '5px 35px' },
        '&.MuiDialogContent-root > p': { padding: 10 }
      }
    }
  },
  dialog: {
    fontWeight: 300,
    margin: 0
  },
  close: {
    position: 'absolute',
    top: 5,
    background: 'none',
    margin: 0,
    padding: '0 !important',
    minWidth: 40,
    right: 0,
    textTransform: 'none',
    fontSize: 16
  }
}))

export default function MediaFortaDialog(props) {
  const classes = useStyles()
  const { open = true, data } = props

  let {
    title,
    message,
    component,
    onClose,
    onConfirm,
    setResponseDialog = () => {}
  } = data

  const confirmDialog = data => {
    if (onConfirm) onConfirm(data)
    else if (onClose) onClose(data)
    setResponseDialog(false)
  }

  const closeDialog = data => {
    if (onClose) onClose(data)
    setResponseDialog(false)
  }

  if (data.error) {
    const response = data.error.response
    title = response ? `(${response.status}) ${response.statusText}` : title || t('something_wrong')
    message = response && response.data && response.data.message ? response.data.message : message || t('contact_recruiter')
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog-title"
      open={ open }
      className={ `${classes.root} ${component && component.className ? component.className : ''}` }
    >
      { title &&
        <DialogTitle id="confirmation-dialog-title" className={data.error ? 'error' : 'info'}>
          { data.error ? <ErrorOutlineIcon /> : <InfoIcon /> }
          { title } 
        </DialogTitle>
      }
      <DialogContent dividers>
        { component && 
          React.createElement(component.Component, {
            ...props,
            key: component.id,
            data: component.data,
            onConfirm: component.onConfirm,
            onClose
          }) 
        }
        { message && <Typography gutterBottom className={classes.dialog}> { message } </Typography> }
      </DialogContent>
      <DialogActions className={classes.dialog}>
        { (! component || (component && !! onConfirm)) &&  <Button onClick={() => confirmDialog(data) } color="primary"> ok </Button> }
        <Button onClick={() => closeDialog(data) } color="primary" className={classes.close}> x </Button>
      </DialogActions>
    </Dialog>
  )
}
