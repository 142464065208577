import * as React from 'react'
import { t } from '../utils/i18n'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import RichEditor, { RichEditorHelpers } from './RichEditor'
import { TextField } from 'formik-material-ui'
import { Button, Box } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  message: { '& .DraftEditor-editorContainer > div': { minHeight: 150 } },
  button: {
    float: 'right',
    margin: '20px 10px'
  }
}))

const EmailSchema = Yup.object().shape({
  subject: Yup.string().required(t('required')),
  message: Yup.string()
    .min(2, t('message_to_short'))
   .required(t('required'))
})

export default function JobApplicationMail({ onSend }) {
  const classes = useStyles()
  const [ message, setMessage ] = React.useState(RichEditorHelpers.convertFromHtml(t('jobapplication_mail_body')))

  return (
  	<Formik
	    initialValues={{ subject: t('jobapplication_mail_subject') }}
	    validateOnChange={false}
	    validateOnBlur={false}
	    validate={async values => {
        try { 
          await EmailSchema.validate({message: RichEditorHelpers.getPlainText(message), ...values})
        } catch (err) { 
          return { [err.path.split('[0]')[0].split('.')[0]]: err.message } 
        }
        return {}
      }}
	    onSubmit={(values, { setSubmitting }) => {
        onSend({message: RichEditorHelpers.getPlainText(message), ...values})
        setSubmitting(false)
	    }}
    >
	    {({ submitForm, isSubmitting, errors }) => 
	    {
        return (
	        <Form className={classes.form} >
            <Box display="flex" margin={2}>
              <Field 
                name="subject" 
                component={TextField} 
                label={t("subject")}
                variant="outlined" 
                style={{width: '100%'}} />
            </Box>
	          <Box margin={2} className={classes.message}>
              <Field 
                name="message" 
                component={RichEditor} 
                label={t("message")}
                editorState={message}
                onChange={setMessage}
                variant="outlined" 
                defaultValue={message}
                error={!! errors['message']} 
                helperText={errors['message']}
                style={{width: '100%'}} />
	          </Box>
	          <Box margin={1} className={classes.button}>
	            <Button
	              variant="contained"
	              color="primary"
	              disabled={isSubmitting}
	              onClick={submitForm}
	            >
                {t('send')}
	            </Button>
	          </Box>
	        </Form>
	      )
      }}
  	</Formik>
  )
}