import * as React from 'react'
import { t } from '../utils/i18n'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Box } from '@material-ui/core'

// Context
import { useApp } from "../context/app"

// Services
import NotesService from "../services/notes.service"
import RecruitersService from "../services/recruiters.service"

// Model components
import NoteScores from './NoteScores'

// App components
import RichEditor, { RichEditorHelpers } from './RichEditor'

const useStyles = makeStyles(() => ({
  description: { '& .DraftEditor-editorContainer > div': { minHeight: 150 } },
  scores: { float: 'left' },
  button: {
    float: 'right',
    margin: '20px 10px'
  }
}))

const NoteSchema = Yup.object().shape({
	score: Yup.number()
  	.min(-1, t('score_low'))
  	.max(3, t('score_high'))
   .required(t('required'))
})

export function NoteDescription({ note, errors, onChange }) {
  const [ recruiters, setRecruiters ] = React.useState(false)
  const [ description, setDescription ] = React.useState(
    note && note.description ? 
      RichEditorHelpers.convertFromHtml(note.description) : 
      RichEditorHelpers.createEmpty()
  )

  React.useEffect(() => {
    if (! recruiters) {
      RecruitersService.list()
      .then(data => setRecruiters(data.records.map(r => {
        return { _id: r._id, name: `${r.firstname} ${r.name}` }
      })))
    }
  })

  const updateDescription = state => {
    setDescription(state)
    onChange(state)
  }

  return (
    <Field 
      name="description" 
      component={RichEditor} 
      label={t('description')}
      editorState={description}
      onChange={updateDescription}
      variant="outlined" 
      defaultValue={description}
      mentions={recruiters || []}
      error={!! errors['description']} 
      helperText={errors['description']}
      style={{width: '100%'}}
      margin="normal" />
  )
}


export default function NoteForm({note, onClose = false, candidate = {}}) {
  const classes = useStyles()
	const { setResponseDialog } = useApp()
  let description = false

  const setDialog = message => setResponseDialog({ title: t('great_success'), message, onClose })

  const getMentions = html => {
    const dom = new DOMParser().parseFromString(html, 'text/html')
    const $mentions = dom.getElementsByClassName('mention')
    return [...Array($mentions.length)].map((m, n) => $mentions[n].id).filter((v, i, a) => a.indexOf(v) === i)
  }

	return (
  	<Formik
	    initialValues={{ score: note && note.score ? note.score : 3 }}
	    validateOnChange={false}
	    validateOnBlur={false}
	    validationSchema={NoteSchema}
      validate={() => {
        const errors =  {}
        if (RichEditorHelpers.getPlainText(description).length < 2) { errors.description = t('min_chars', false, {amount: 2}) }
        return errors
      }}
	    onSubmit={(values, { setSubmitting }) => {
        const html = RichEditorHelpers.convertToHtml(description)
	    	const data = { candidateId: candidate._id, description: RichEditorHelpers.convertToHtml(description), mentions: getMentions(html), ...values }
        if (note && note._id) {
          NotesService.patch(note._id, data).then(() => {
            setDialog(t('note_updated'))
          }).catch(error => setResponseDialog({error, onClose }))
        } else {
          NotesService.post(data).then(() => {
            setDialog(t('note_created'))
        	}).catch(error => setResponseDialog({error, onClose }))        
        }
	      setSubmitting(false)
	    }}
    >
	    {({ submitForm, isSubmitting, errors, values, validateField, setFieldValue }) => 
	    {
	    	return (
	        <Form
	    			onBlur = {e => {
	    				const fieldName = e.target.name
	    				fieldName && fieldName !== 'image-url' && validateField(fieldName)
				    }}
	        >
	          <Box margin={1} className={classes.description}>
              <NoteDescription note={note} errors={errors} onChange={state => description = state }/>
	          </Box>
	          <Box margin={1} className={classes.scores}>
		          <Field 
		          	name="score" 
		          	component={NoteScores} 
		          	setFieldValue={setFieldValue} 
		          	values={values} />
	          </Box>
	          <Box margin={1} className={classes.button}>
	            <Button
	              variant="contained"
	              color="primary"
	              disabled={isSubmitting}
	              onClick={submitForm}
	            >
                { note && note._id ? t('edit_note') : t('add_note') }
	            </Button>
	          </Box>
	        </Form>
	    )}}
  	</Formik>
  )
}