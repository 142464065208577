import axios from "axios";
import config from '../config'

// Services
import AuthService from "../services/auth.service"

const { API_URL } = config.mediaforta 

export default class ApiService {
  static getAuthorization() {
    const token = AuthService.getToken()
    if (! token) return false
    return `Bearer ${token}`
  }

  static getQuery ({search = '', limit = 10, skip = 0, fields = '', filter = false, sort = 'createdAt'}) {
    let query = `fields=${fields}&limit=${limit}&skip=${skip}`
    if (search && search !== '') {
      query = `${query}&filter=${search}`
      if (filter && filter !== '') query = `${query}&${filter}`
    } else {
      if (filter && filter !== '') query = `${query}&${filter}`
    }
    if (sort && sort !== '') query = `${query}&sort=${sort}`
    return query
  }

  static list(path, {search = '', limit = 10, skip = 0, fields = '', filter = false, sort = 'createdAt'}) {
    return ApiService.get(path, ApiService.getQuery({search, limit, skip, fields, filter, sort}))
  }

	static get(path, query = '') {
    const Authorization = ApiService.getAuthorization()
    return axios.get(`${API_URL}${path}?${query}`, { headers: { Authorization, Prefer: 'count=exact' } })
  }

  static post(path, data = {}, headers = {}) {
    const Authorization = ApiService.getAuthorization()
    return axios.post(`${API_URL}${path}`, data, { headers: { Authorization, Prefer: 'count=exact', ...headers } })
  }

  static patch(path, patch = {}) {
    const Authorization = ApiService.getAuthorization()
    return axios.patch(`${API_URL}${path}`, patch, { headers: { Authorization, Prefer: 'count=exact' } })
  }

  static delete(path) {
    const Authorization = ApiService.getAuthorization()
    return axios.delete(`${API_URL}${path}`, { headers: { Authorization, Prefer: 'count=exact' } })
  }

  static website = {
    get: (path) => {
      return axios.get(`${API_URL}website/${path}`)
    },
    post: (path, data = {}) => {
      return axios.post(`${API_URL}website/${path}`, data)
    },
    list: (path, {search = '', limit = 10, skip = 0, fields = '', filter = false, sort = 'createdAt'}) => {
      const query = ApiService.getQuery({search, limit, skip, fields, filter, sort})
      return axios.get(`${API_URL}website/${path}?${query}`)  
    }
  }
}
