import React from 'react'
import i18n from "i18next"

import { makeStyles } from '@material-ui/core/styles'
import { SvgIcon } from '@material-ui/core'

import { ReactComponent as DutchIcon } from '../assets/svg/dutch.svg'
import { ReactComponent as EnglishIcon } from '../assets/svg/english.svg'
import { ReactComponent as FrenchIcon } from '../assets/svg/french.svg'

const useStyles = makeStyles(() => ({
  root: {
    listStyle: 'none',
    height: 35,
    overflow: 'hidden',
    position: 'fixed',
    right: 40,
    top: 20,
    margin: 0,
    padding: 0,
    '&:hover': {
      height: 'auto'
    }
  },
  svg: {
    width: 25,
    height: 35,
    cursor: 'pointer'
  }
}))

const CustomIcon = ({language, children}) => {
  const classes = useStyles()
  return <SvgIcon titleAccess={language} className={classes.svg}> {children} </SvgIcon>
}

const CustomDutchIcon = () => <CustomIcon language="Nederlands"> <DutchIcon /> </CustomIcon>
const CustomEnglishIcon = () => <CustomIcon language="English"> <EnglishIcon /> </CustomIcon>
const CustomFrenchIcon = () => <CustomIcon language="Francais"> <FrenchIcon /> </CustomIcon>

export default function LanguageSwitcher() {
  const classes = useStyles()
  const languages = ['nl', 'fr', 'en'].sort(l => l === i18n.language.toLowerCase() ? -1 : 1) 
 
  const updateLanguage = l => window.location = '?lang=' + l

  return ( 
    <ul className={classes.root}>
      {
        languages.map(l => {
          switch(l) {
            case 'en': return <li key={l} onClick={() => updateLanguage(l)}><CustomEnglishIcon /></li>
            case 'fr': return <li key={l} onClick={() => updateLanguage(l)}><CustomFrenchIcon /></li>
            default: return <li key={l} onClick={() => updateLanguage(l)}><CustomDutchIcon /></li>
          }
        })
      }
    </ul>
  )
}