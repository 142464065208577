import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { t } from '../utils/i18n';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, CssBaseline, Typography, TextField, Button } from '@material-ui/core';
import Reaptcha from 'reaptcha';

// components 
import SigninForm from '../components/SigninForm';
import LanguageSwitcher from '../components/LanguageSwitcher';
import JobsService from '../services/jobs.service';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1240,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  paper: {
    margin: 'auto',
    width: 90,
    maxWidth: '90px !important',
    height: 90,
    backgroundImage: 'url(/mediaforta-logo.svg)',
    backgroundSize: 'auto 100%'
  },
  box: {
    margin: '30px 0',
    '& > *': {
      padding: 30,
      margin: 30
    },
    '& .MuiInputBase-root > fieldset': { borderColor: '#ccc' }
  },
  register: {
    backgroundColor: '#f0f3f5',
    '& .MuiFormControl-root': {
      backgroundColor: '#eaedf2',
      marginBottom: 20
    }
  },
  title: {
    textAlign: 'center',
    marginBottom: 30
  }
}));

export default function Home({ match = { params: {} } }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { params: { jobId } } = match;
  const [verified, setVerified] = React.useState(true);
  
  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');

  return (
    <Container component="main" maxWidth={false} className={classes.root}>
      <CssBaseline />
      <LanguageSwitcher />
      <Box className={classes.paper}></Box>
      <Box display="flex" className={classes.box}>
        <Container component="main" maxWidth={false}>
          <Box>
            <Typography className={classes.title} component="h1" variant="h5"> {t('existing_user_title')} </Typography>
            <SigninForm />
          </Box>
        </Container>
        <Container component="main" maxWidth={false} className={classes.register}>
          <Box>
            <Typography className={classes.title} component="h1" variant="h5"> {t('new_user_title')} </Typography>
            <TextField value={firstname} name="firstname" type="text" variant="outlined" label={t('firstname')} onChange={event => setFirstname(event.currentTarget.value)} fullWidth />
            <TextField value={lastname} name="lastname" type="text" variant="outlined" label={t('lastname')} onChange={event => setLastname(event.currentTarget.value)} fullWidth />
            <div style={{ transform: "scale(0.8)", transformOrigin: "0 0" }}>
              <Reaptcha
                sitekey="6LfuNZofAAAAAB2D_DCaLmutOrJneYQPqbXknuPb"
                onVerify={() => setVerified(true)}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#0df394', color: '#041e7a' }}
              size='large'
              disabled={!verified}
              onClick={() => history.push('/register', { jobId, user: { firstname, name: lastname } })}
            >
              {t('create_account')} &gt;
            </Button>
          </Box>
        </Container>
      </Box>
    </Container>
  );
}