import React from 'react';
import { t } from '../utils/i18n';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, IconButton, Link } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative'
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 5,
    zIndex: 9,
    fontSize: 20,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));


export default function CampaignCard({
  campaign = {},
  card = false,
  onClose,
  onChange,
  refresh,
  className,
  onUpdate,
  edit = false
}) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(card);

  return (
    <Card>
      <CardContent className={classes.content}>
        {!!onClose && <IconButton aria-label="close" className={classes.close} onClick={() => onClose()}> x </IconButton>}
        <Link href={`${campaign._id}/jobs`}>&gt; &nbsp; view jobs</Link>
        {selected && selected.component &&
          React.createElement(selected.component, {
            key: selected.id,
            campaign: campaign,
            edit,
            className: className,
            onChange: onChange,
            refresh: refresh,
            onUpdate: onUpdate,
            onClose: onClose
          })
        }
      </CardContent>
    </Card>
  );
}