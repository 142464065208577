import React from 'react'
import { t } from '../utils/i18n'
import TasksList from '../components/TasksList'
import TaskForm from '../components/TaskForm'
import { Add as AddIcon } from '@material-ui/icons'
import {
	Typography,
  Container,
  CssBaseline,
  IconButton,
  Box
} from '@material-ui/core'

import '../assets/scss/form-wrapper.scss'

const filters = [
  { label: t('all'), value: '' },
  { label: t('active'), value: 1 },
  { label: t('inactive'), value: 2 }
]

const tabFilters = [
  { primary: t('unfinished'), id: 'upcoming', filter: `completed!=true` },
  { primary: t('finished'), id: 'finished', filter: 'completed=true' },
  { primary: t('all'), id: 'all', selected: true, filter: '' }
]

export default function Tasks() {
  const [task, setTask] = React.useState(false)  

  return (
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
      	{t('tasks')}
        <IconButton aria-label="add task" onClick={() => setTask({})}> <AddIcon /> </IconButton>
      </Typography>
      { task &&
        <Box className="form-wrapper" style={{width: 'calc(100vw - 130px)'}}>  
          <h2>
            { task && task._id ? t('edit_task'): t('new_task') }
          </h2>
          <div>
            <TaskForm onClose={() => setTask(false)} task={task}/>
            <IconButton aria-label="cancel" onClick={() => setTask(false)}> x </IconButton>
          </div>
        </Box> 
      }
      <TasksList filters={filters} tabFilters={tabFilters} task={task} selectTask={task => setTask(task) }/>   
    </Container>
  )
}