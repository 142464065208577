import React from 'react';
import { useHistory, Redirect } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline } from '@material-ui/core';

// pages 
import RegistrationFeedback from './RegistrationFeedback';

// components 
import Link from '../components/Link';
import UserDetails from '../components/RegistrationFlow/UserDetails';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1605,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  logo: {
    width: 90,
    height: 90,
    maxWidth: 90,
    marginTop: 20,
    marginBottom: 20,
    backgroundImage: 'url(/mediaforta-logo.svg)',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat'
  }
}));

export default function Register() {
  const classes = useStyles();
  const history = useHistory();
  const { jobId, campaignId, user, step = 1 } = history.location.state || {};

  if (!user) return <Redirect to='/' />;

  const renderRegisterSection = () => {
    switch (step) {
      case 1:
        return <UserDetails user={user} jobId={jobId} campaignId={campaignId} />;
      case 2:
        return <RegistrationFeedback email={user.email} user={user} jobId={jobId} campaignId={campaignId} />;
      default:
        return null;
    }
  };
  return (
    <Container component="main" maxWidth={false} className={classes.root}>
      <CssBaseline />
      <Link className={classes.logo} href="/"></Link>
      {renderRegisterSection()}
    </Container>
  );
}