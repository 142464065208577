// Services
import ApiService from "../services/api.service"

const model = 'jobapplications'

export default class JobApplicationsService {
  static list(search = false, limit = 10, skip = 0, fields = '', filter = false, candidateId) {
    if (candidateId) {
      return ApiService.list(`${model}/candidate/${candidateId}`, { search, filter, limit, skip, fields}).then(response => response.data)
    }
    return ApiService.list(`${model}`, { search, filter, limit, skip, fields}).then(response => response.data)
  }

  static patch(jobApplicationId, patch = {}) {
    return ApiService.patch(`${model}/${jobApplicationId}`, patch).then(response => response) 
  }

  static approve(jobApplicationIds) {
    return ApiService.patch(`${model}/status/successful`, { jobApplicationIds }).then(response => response) 
  }

  static decline(jobApplicationIds, email) {
    return ApiService.patch(`${model}/status/rejected`, { jobApplicationIds, email }).then(response => response) 
  }
  
  static delete(jobApplicationId) {
    return ApiService.delete(`${model}/${jobApplicationId}`).then(response => response) 
  }
}