import React from 'react';
import { t, getLanguage } from '../utils/i18n';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, IconButton } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';

// Context 
import { useApp } from "../context/app";

// Services
import ScoreCardsService from '../services/scorecards.service';

const useStyles = makeStyles(theme => ({
  category: {
    cursor: 'pointer',
    display: 'flex', 
    padding: '30px 20px 0',
    '& h5': { 
      flex: 1,
      '& svg': {
        background: theme.palette.mediaforta.main,
        borderRadius: '50%',
        fontSize: 20,
        fill: '#fff'
      }
    }
  },
  actions: {
    width: 40,
    padding: 0,
    '& svg': { fontSize: 20 }
  },
  delete: {
    fill: '#2a7fd7 !important',
    backgroundColor: '#fff !important'
  }
}));

export const deleteScoreCard = async scoreCardId => ScoreCardsService.delete(scoreCardId);
export default function ScoreCardList({scoreCards = [], setScoreCard}) {
  const classes = useStyles();
  const { setResponseDialog } = useApp();
  const language = getLanguage();
  const [ refresh, setRefresh ] = React.useState(false);

  const removeScoreCard = cardId => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('remove_score_card'),
      onConfirm: () => {    
        deleteScoreCard(cardId).then(() => setRefresh(true))
      }
    })
  }

  return <React.Fragment>
      <Box display="flex">
        <div style={{flex: 2}}>
          { scoreCards.map(card => {
            return <div key={card._id}>
              <Box className={classes.category}>
                <Typography variant='h5' >
                  <b> {card.label}</b>
                  <IconButton aria-label={t('edit_score_card')} onClick={() => setScoreCard(card)}>
                    <EditIcon className={classes.delete}/>
                  </IconButton>
                  <IconButton onClick={() => removeScoreCard(card._id)}>
                    <DeleteIcon className={classes.delete}/>
                  </IconButton>
                </Typography>
              </Box>
            </div>
          })
        }
        </div>
      </Box>
		</React.Fragment>    
}