import React from 'react'
import { t } from '../utils/i18n'

import CandidateExperienceList from '../components/CandidateExperienceList'
import CandidateExperienceForm from '../components/CandidateExperienceForm'
import { Add as AddIcon } from '@material-ui/icons'
import {
  Typography,
  Container,
  CssBaseline,
  IconButton,
  Box,
  Grid
} from '@material-ui/core'
import { useCandidate } from '../context/candidate'

export default function Experience() {
  const [experience, setExperience] = React.useState(false)
  const { candidate } = useCandidate()

  return (
    candidate &&
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
      	{t('experience')}
        <IconButton aria-label={t('add_work')}  onClick={() => setExperience({})}>
          <AddIcon />
        </IconButton>
        { experience &&
        <Box className="form-wrapper -full">  
          <h2>
            { experience && experience._id ? t('edit_experience') :  t('new_experience') }
          </h2>
          <div>
            <CandidateExperienceForm candidateId={candidate._id} onClose={() => setExperience(false)} experience={experience}/>
            <IconButton aria-label={t('cancel')} onClick={() => setExperience(false)}> x </IconButton>
          </div>
        </Box> 
      }
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <CandidateExperienceList candidate={candidate} experience={experience} setExperience={setExperience}/>   
        </Grid>
      </Grid>
    </Container>
  )
}