import React from 'react'
import { t } from '../utils/i18n'
import { Typography, Container, CssBaseline } from '@material-ui/core'

// Context
import { useApp } from "../context/app"
import { useUser } from "../context/user"
import { useCandidate } from "../context/candidate"

// Components
import JobApplicationList from '../components/JobApplicationList'

export default function JobApplications({match: {params: {jobId}}}) {
  const { isRecruiter } = useUser()
  const { candidate = {} } = useCandidate() || {}
  const { languages } = useApp()
    
  return (
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
      	{t('job_applications')}
      </Typography>
      {!! candidate._id && <JobApplicationList candidate={candidate} languages={languages}/>}
      {isRecruiter() && <JobApplicationList languages={languages} jobId={jobId}/>}
    </Container>
  )
}