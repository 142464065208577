import React from 'react'
import config from '../config'
import { t } from '../utils/i18n'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography, Grid, Card, CardContent, IconButton, Button } from '@material-ui/core'

import { ReactComponent as CommentIcon } from '../assets/svg/comment.svg'
import { ReactComponent as LocationIcon } from '../assets/svg/location.svg'

const useStyles = makeStyles(theme => ({
	flex: { '& > *': { flex: 1 } },
	light: { 
		color: theme.palette.mediaforta.light,
		textAlign: 'center' 
	},
	green: { 
		color: '#43e68e',
		'& > *': {
			display: 'flex',
			'& > svg': {
			  width: 15,
    		marginRight: 5
			}
		} 
	},
  content: { position: 'relative' },
  close: {
    position: 'absolute',
    top: 0,
    right: 5,
    zIndex: 9,
    fontSize: 20,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))

export default function JobApplicationCard({ jobApplication, candidate, language, job, onClose }) {
  const classes = useStyles()

  if (! job) return null
  return ( 
  	<Card>
      <CardContent className={classes.content}>
        { !! onClose && <IconButton aria-label="close" className={classes.close} onClick={() => onClose()}> x </IconButton> }
		    <Box className={classes.root}>
		   	 	<Grid container>
		        <Grid item xs={12}>
		          <Typography variant="h4"> {job.title} </Typography>
		        </Grid>
		      </Grid>
		      <br/>
		      <Grid container className={classes.flex}>
		        <Grid item flex={1}>
		          <Typography> <b>{job.client} </b></Typography>
		        </Grid>
		        <Grid item>
		          <Typography> <b>{job.function} </b></Typography>
		        </Grid>
		       	<Grid item className={classes.light}>
		          <Typography> <b>{moment(jobApplication.createdAt).format('DD.MM.YYYY')} </b></Typography>
		        </Grid>
		        <Grid item className={classes.light}>
		          <Typography> <b>{jobApplication.status}</b></Typography>
		        </Grid>
		      </Grid>
		      <Grid container className={classes.green}>
		        <Grid item style={{marginRight: 30}}>
		          <CommentIcon/>
		          <Typography> <b>{t(language)}</b> </Typography>
		        </Grid>
		        <Grid item>
		        	<LocationIcon />
		          <Typography> <b>{job.location}</b> </Typography>
		        </Grid>
		      </Grid>
		      <br/><br/>
		      <Grid container>
		        <Grid item xs={12}>
		          <Typography variant="h5"> {t('work_for')} </Typography>
		        </Grid>
		        <Grid item xs={12}>
		        	<div dangerouslySetInnerHTML={{ __html: job.description }}/>
		        </Grid>
		      </Grid>
		      <br/>
		      <Grid container>
		        <Grid item xs={12}>
		          <Typography variant="h5"> {t('look_for')} </Typography>
		        </Grid>
		        <Grid item xs={12}>
		        	<div dangerouslySetInnerHTML={{ __html: job.about }}/>
		        </Grid>
		      </Grid>
		      <br/>
		      <Grid container>
		        <Grid item xs={12}>
		          <Typography variant="h5"> {t('what_offer')} </Typography>
		        </Grid>
		        <Grid item xs={12}>
		        	<div dangerouslySetInnerHTML={{ __html: job.offer }}/>
		        </Grid>
		      </Grid>
		      <br/><br/>
		      <Grid container>
		        <Grid item xs={12}>
				      <Button
		            variant="contained"
		            color="primary"
		            onClick={() => window.open(config.mediaforta.JOBS_URL, '_blank')}
		          >
		            { t('search_for_similar_jobs') }
		          </Button>
		        </Grid>
		      </Grid>
		    </Box>
      </CardContent>
    </Card>
  )
}