import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Add } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    fontSize: 30,
    background: theme.palette.mediaforta.superlight,
    borderRadius: 17,
    position: 'absolute',
    right: 0,
    bottom: 5,
    cursor: 'pointer'
  }
}))

export default function AddIcon(props) {
  const classes = useStyles()
  const { onClick, className = '' } = props
  return ( 
    <Add className={`${classes.root} ${className}`} onClick={() => onClick ? onClick() : false }/> 
  )
}