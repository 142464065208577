import * as React from 'react'
import { t } from '../utils/i18n'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from 'formik-material-ui'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Button, Box, TextField as MUITextField } from '@material-ui/core'
import countries from "../assets/countries/CountryList"

// Services
import CandidatesService from "../services/candidates.service"

// Context
import { useApp } from "../context/app"

// App components
import DatePicker from './DatePicker'

const useStyles = makeStyles(theme => ({
  description: {
    '& .DraftEditor-editorContainer > div': { minHeight: 250 }
  },
  button: { textAlign: 'right' }
}))

const DiplomaSchema = Yup.object().shape({
	degree: Yup.string()
  	.min(2, t('degree_to_short'))
   	.required(t('required')),
	institute: Yup.string()
		.min(2, t('institute_to_short'))
		.required(t('required'))
})

export default function CandidateDiplomaForm({ diploma = {}, candidateId, onClose }) {
  const classes = useStyles()
	const { setResponseDialog } = useApp()

	const today = moment()
	const [from, setFrom] = React.useState(diploma.from ? moment(diploma.from) : today)
	const [to, setTo] = React.useState(diploma.to ? moment(diploma.to) : today)
  const [country, setCountry] = React.useState(diploma.country || null)

  const getCountry = () => countries.find(c => c.code === country) || null

	return (
  	<Formik
	    initialValues={{
	    	degree: diploma.degree || '',
	    	institute: diploma.institute || ''
	    }}
	    validateOnChange={false}
	    validateOnBlur={false}
	    validationSchema={DiplomaSchema}
	    validate={values => {
        const errors =  {}
	    	return Object.keys(errors).length ? errors : false
	    }}
	    onSubmit={(values, { setSubmitting }) => {
				const data = { from: from.toDate(), to: to.toDate(), candidateId, country, ...values }
        if (diploma._id) {
          CandidatesService.patchDiploma(diploma._id, data).then(onClose).catch(error => setResponseDialog({error}))
        } else {
					CandidatesService.postDiploma(data).then(onClose).catch(error => setResponseDialog({error}))        
        }
	      setSubmitting(false)
	    }}
    >
	    {({ submitForm, isSubmitting, errors, validateField }) => 
	    {
	    	return (
	        <Form
	    			onBlur = {e => {
	    				const fieldName = e.target.name
	    				fieldName && fieldName !== 'country' && validateField(fieldName)
				    }}
	        >
	        	<Box display="flex" style={{margin: '20px 10px 30px'}}>
              <Field 
		            name="degree" 
		            component={TextField} 
		            label={t("degree")}
		            variant="outlined" 
								style={{width: '100%', marginRight: 10}}
		            margin={"normal"}/>
              <Field 
		            name="institute" 
		            component={TextField} 
		            label={t("institute")}
		            variant="outlined" 
								style={{width: '100%', marginRight: 10}}
		            margin="normal"/>
							<DatePicker 
	          		name="from"
	              disabled={isSubmitting}
								ampm={false}
								disableTime={true}
	              maxDate={today}
	              label={t("from")}
	              style={{minWidth: 160, maxWidth: 160, marginRight: 10}}
	              variant="outlined"
	              margin="normal"
								format="YYYY"
								views={['year']}
	              error={!! errors['from']} 
            		helperText={errors['from']} 
	              value={from}
	            	onChange={setFrom}
	          	/>
							<DatePicker 
	          		name="to"
	              disabled={isSubmitting}
								ampm={false}
								disableTime={true}
	              maxDate={today}
								label={t("to")}
								views={['year']}
	              style={{minWidth: 160, maxWidth: 160, marginRight: 10}}
	              variant="outlined"
	              margin="normal"
	              format="YYYY"
	              error={!! errors['to']} 
            		helperText={errors['to']} 
	              value={to}
	            	onChange={setTo}
	          	/>
              <Autocomplete
                value={getCountry()}
                id="country"
                options={countries}
                style={{width: '100%'}}
                autoHighlight
                onInputChange={ (event, newValue) => { 
                  const country = countries.find(c => c.label === newValue)
                  setCountry((country && country.code) || '')
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => {
                  const { value, ...props } = params.inputProps
                  return <MUITextField
										{...params}
										value={value}
										name='country'
										variant="outlined"
										label={t("country")}
										margin="normal"
										inputProps={{
											...props,
											autoComplete: 'new-password', // disable autocomplete and autofill
										}}
									/>
                }}
              />
	          </Box>
	          <Box style={{margin: '30px 10px 20px'}} className={classes.button}>
	            <Button
	              variant="contained"
	              color="primary"
	              disabled={isSubmitting}
	              onClick={submitForm}
	            >
                { diploma._id ? t('edit_degree') : t('add_degree') }
	            </Button>
	          </Box>
	        </Form>
	    )}}
  	</Formik>
  )
}