import React from "react";
import { t } from "../utils/i18n";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  IconButton,
  InputBase,
  TextField,
  Typography,
  Card,
  Checkbox,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import {
  Search as SearchIcon,
  Add as AddIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { createBrowserHistory } from "history";
// App components
import Dropdown from "./Dropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: 5,
    marginBottom: 20,
    minHeight: 40,
  },
  form: {
    width: 400,
    marginRight: 20,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.mediaforta.light,
    "& button": { padding: "8px 5px 0" },
  },
  formFilters: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px 10px 4px 0px",
    "& > *": {
      maxWidth: "calc(33.333% - 8px)",
      marginBottom: 8,
      marginRight: 0,
    },
    "& input": { padding: "15px !important" },
  },
  filter: {
    position: "relative",
    float: "right",
    paddingRight: "30px",
    cursor: "pointer",
    width: "100%",
    textAlign: "right",
    "& svg": { position: "relative", top: 6 },
  },
  inputBase: {
    opacity: 1,
    marginLeft: theme.spacing(1),
    zIndex: 9,
    width: "calc(100% - 70px)",
    verticalAlign: "middle",
    "& input": {
      paddingBottom: 0,
      "&::placeholder": { color: "#0A1D5F" },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      height: "fit-content !important",
    },
  },
  autoComplete: {
    "& > label": {
      top: -2,
      "&.MuiFormLabel-filled, &.Mui-focused": { display: "none" },
    },
    "& input": { padding: "6px !important" },
    "& legend": { maxWidth: 0 },
  },
  date: {
    width: "calc(100% - 70px)",
    border: `1px solid ${theme.palette.mediaforta.light}`,
    borderRadius: 6,
    marginTop: 0,
    marginLeft: 8,
    "& > label": {
      top: -8,
      left: 15,
      "&.MuiFormLabel-filled, &.Mui-focused": { display: "none" },
    },
    "& > div": {
      marginTop: 10,
      "&:before": { border: 0 },
    },
    "& input": { padding: "4px 15px 12px !important" },
  },
}));

const history = createBrowserHistory();
export default function SearchFilter({
  filterLabel,
  filter,
  filters,
  setFilter,
  className,
  value,
  onChange,
  placeholder,
  onFilter,
  formFilters = [],
  disableSearch = false,
  fixedFilters = false,
}) {
  const classes = useStyles();
  className = `${classes.form} ${className || ""}`;
  const [showFormFilters, setShowFormFilters] = React.useState(
    fixedFilters || !!window.location.search
  );
  const [formFilter, setFormFilter] = React.useState({});
  const [dateFilter, setDateFilter] = React.useState({});
  const parameters = new URLSearchParams(history.location.search);

  React.useEffect(() => {
    const params = {};
    parameters.forEach((v, k) => (params[k + "="] = v));
    setFormFilter(params);
  }, []);

  React.useEffect(() => {
    formFilters.forEach((f) => {
      const paramValue = parameters.get(
        f.filter.substring(0, f.filter.length - 1)
      );
      if (f.type === "date" && paramValue) {
        formFilter[f.filter] = paramValue;
        setFormFilter(formFilter);
        dateFilter[f.name] = moment(paramValue);
        setDateFilter(dateFilter)
      }
    });
  }, []);

  const submit = () => {
    let filterString = Object.keys(formFilter)
      .map((key) => (formFilter[key] ? `${key}${formFilter[key]}&` : ""))
      .join("");

    filterString =
      filterString.charAt(filterString.length - 1) === "&"
        ? filterString.substring(0, filterString.length - 1)
        : filterString;

    window.history.replaceState(
      window.location.pathname,
      "mediaforta",
      `?${filterString}`
    );

    onFilter(filterString);
  };

  const reset = () => {
    setFormFilter({});
    setDateFilter({});
    onFilter("");
    window.history.pushState(window.location.pathname, "mediaforta", "");
  };

  return (
    <>
      {(!disableSearch || !fixedFilters) && (
        <div className={classes.root}>
          {!disableSearch && (
            <form className={className}>
              <IconButton aria-label="search">
                {" "}
                <SearchIcon />{" "}
              </IconButton>
              <InputBase
                className={classes.inputBase}
                defaultValue={value || ""}
                onChange={(event) => onChange(event)}
                placeholder={placeholder || t("search")}
                inputProps={{ "aria-label": t("search_candidates") }}
              />
            </form>
          )}
          {!!formFilters.length && !fixedFilters && (
            <Typography
              className={classes.filter}
              color="inherit"
              onClick={() => {
                if (showFormFilters) reset();
                setShowFormFilters(!showFormFilters);
              }}
            >
              {!showFormFilters ? (
                <>
                  <AddIcon /> {t("add_filter")}
                </>
              ) : (
                <>
                  <CloseIcon /> {t("close_filter")}
                </>
              )}
            </Typography>
          )}
          {!!filters && (
            <Dropdown
              label={filterLabel || "Add filter"}
              value={filter}
              items={filters}
              onChange={setFilter}
            />
          )}
        </div>
      )}

      {!!formFilters.length && showFormFilters && (
        <Card className={classes.formFilters}>
          {formFilters.map(
            ({
              name,
              filter,
              label,
              type = "text",
              multiple = false,
              options = [],
            }) => {
              switch (type) {
                case "checkbox":
                  return (
                    <FormControlLabel
                      key={name}
                      className={classes.inputBase}
                      control={
                        <Checkbox
                          name={name}
                          variant="outlined"
                          onChange={(event) => {
                            formFilter[filter] = event.currentTarget.checked;
                            setFormFilter(formFilter);
                          }}
                        />
                      }
                      label={t(label)}
                    />
                  );
                case "date":
                  return (
                    <MuiPickersUtilsProvider utils={MomentUtils} key={name}>
                      <DatePicker
                        name={name}
                        ampm={false}
                        label={t(label)}
                        className={classes.date}
                        // style={{minWidth: 160, maxWidth: 160, marginRight: 10, marginTop: 0}}
                        variant="outlined"
                        margin="normal"
                        format="DD-MM-YYYY"
                        views={["year", "month", "date"]}
                        value={dateFilter[name] || parameters.get(
                          filter.substring(0, filter.length - 1)
                        ) || null}
                        onChange={(date) => {
                          formFilter[filter] =
                            moment(date).format("YYYY-MM-DD");
                          setFormFilter(formFilter);
                          setDateFilter({ ...dateFilter, [name]: date });
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  );
                case "autocomplete":
                  return (
                    <Autocomplete
                      key={name}
                      options={options}
                      // defaultValue={!! parameters.get(name) ? parameters.get(name) : ''}
                      multiple={multiple}
                      autoHighlight
                      className={classes.inputBase}
                      onInputChange={(event, newValue) => {
                        const selected = options.find(
                          (item) =>
                            item.label === newValue || item.name === newValue
                        );
                        formFilter[filter] = !newValue
                          ? ""
                          : selected && selected.code
                          ? selected.code
                          : "";

                        setFormFilter(formFilter);
                      }}
                      onChange={(event, values) => {
                        if (multiple) {
                          formFilter[filter] = values.map((v) => v.code).join();
                          setFormFilter(formFilter);
                        }
                      }}
                      getOptionLabel={(option) => option.label || option.name}
                      renderInput={(params) => {
                        let { value, ...props } = params.inputProps;
                        // value = value || !! parameters.get(name) ? parameters.get(name) : undefined;
                        const filterObj = formFilters.find(
                          (f) => f.name === name
                        );
                        const qParam = parameters.get(
                          filterObj.filter.substring(
                            0,
                            filterObj.filter.length - 1
                          )
                        );
                        if (!value && !!qParam) {
                          const o = options.find((item) => {
                            return item.code === qParam;
                          });
                          value = o.name || o.label;
                        }

                        return (
                          <TextField
                            {...params}
                            value={value}
                            name={name}
                            label={t(label)}
                            variant="outlined"
                            className={classes.autoComplete}
                            inputProps={{
                              ...props,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        );
                      }}
                    />
                  );
                default:
                  return (
                    <TextField
                      key={name}
                      type={type}
                      defaultValue={
                        !!parameters.get(name)
                          ? parameters
                              .get(name)
                              .replace("/^", "")
                              .replace("/i", "")
                          : ""
                      }
                      className={classes.inputBase}
                      variant="outlined"
                      placeholder={t(label)}
                      onChange={(event) => {
                        formFilter[filter] = `/^${event.currentTarget.value}/i`;
                        setFormFilter(formFilter);
                      }}
                      inputProps={{ "aria-label": t(label) }}
                    />
                  );
              }
            }
          )}
          <div style={{ maxWidth: "100%", width: "100%", textAlign: "right" }}>
            <Button variant="contained" color="primary" onClick={submit}>
              {t("filter")}
            </Button>
          </div>
        </Card>
      )}
    </>
  );
}
