import React from 'react'
import { t } from '../utils/i18n'
import CandidatesService from "../services/candidates.service"
import { makeStyles } from '@material-ui/core/styles'
import {  TextField } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(() => ({ autocomplete: { minWidth: 400 } }))
const OPTIONS_LIMIT = 5;
const tagFilter = createFilterOptions()

export default function Tags({ onChange, tags = [] }) {
  const classes = useStyles()
  const [tagList, setTagList] = React.useState([])

  React.useEffect(() => {
    if (! tagList.length) CandidatesService.listTags().then(data => setTagList(data.records))
    // eslint-disable-next-line
  }, [])

  return (
    <Autocomplete
      multiple
      id="tags"
      filterOptions={(options, params) => {
        const filtered = tagFilter(options, params).slice(0, OPTIONS_LIMIT);
        if (params.inputValue !== '') filtered.push({ _id: params.inputValue, label: params.inputValue });
        return filtered;
      }}
      onChange={(event, values) => onChange && onChange(values) }
      options={tagList}
      className={classes.autocomplete}
      getOptionLabel={option => option.label}
      defaultValue={tags}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={t("add_tag")}
        />
      )}
    />        
  )
}