import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { t } from '../utils/i18n';
import { Add as AddIcon } from '@material-ui/icons';

import { Container, CssBaseline, Box, Typography, IconButton } from '@material-ui/core';

// Context
import { useUser } from '../context/user';
import FoldersList from '../components/FoldersList';
import FolderForm from '../components/FolderForm';

const useStyles = makeStyles((theme) => ({
  stats: {
    width: '80%',
    margin: '40px auto'
  }
}));


export default function Folders() {
  const { isRecruiter } = useUser();
  const classes = useStyles();
  const [folder, setFolder] = React.useState(null);
  const [refresh, setRefresh] = React.useState(1);
  
  return (
    isRecruiter() &&
    <Container component="main" maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5">
        {t('Folders')}
        <IconButton aria-label={t('add_folder')} onClick={() => setFolder({})}>
          <AddIcon />
        </IconButton>
        {folder &&
          <Box className="form-wrapper -full">
            <h2>
              {t('Folder')}
            </h2>
            <div>
              <FolderForm onClose={folderId => {
                setFolder(null);
                setRefresh(refresh+1)
              }}
                folder={folder}
              />
              <IconButton aria-label={t('cancel')} onClick={() => setFolder(false)}> x </IconButton>
            </div>
          </Box>
        }
      </Typography>
      <Box className={classes.stats}>
        {refresh && <FoldersList refresh={refresh} setRefresh={setRefresh}/>}
      </Box>
    </Container>
  );
}