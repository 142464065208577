import * as React from 'react';
import { t, getLanguage } from '../utils/i18n';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import {
	Button,
	Box,
	TextField as MuiTextField,
	Paper,
	FormControl,
	InputLabel,
	Chip,
	IconButton
} from '@material-ui/core';

// Context
import { useJob } from "../context/job";
import { useApp } from "../context/app";

// Services
import jobsService from '../services/jobs.service';

// Components
import RichEditor, { RichEditorHelpers } from './RichEditor';
import Tags from "./Tags";
import DatePicker from './DatePicker';
import JobCampaigns from './JobCampaigns';

const useStyles = makeStyles(theme => ({
	disabled: {
		'& .Mui-disabled': {
			color: theme.palette.mediaforta.light,
			fontWeight: 400
		},
		'& input.Mui-disabled, textarea.Mui-disabled': {
			color: theme.palette.mediaforta.dark,
			fontWeight: 500,
		},
		'& .MuiInput-underline:before': {
			borderBottom: '1px dotted rgba(0, 0, 0, 0.2)',
			marginRight: 30
		},
		'& .MuiPaper-root': {
			marginRight: 30,
			borderTop: 0,
			marginTop: 20,
			padding: '0 0 5px 0'
		},
		'& .MuiAutocomplete-endAdornment': { display: 'none' }
	},
	formControl: {
		width: '100%',
		marginTop: 16,
		'& .RichEditor-Wrapper ': {
			marginTop: 24
		}
	},
	tagForm: {
		'& > div': {
			'& > div': {
				width: 'calc(50% - 8px)',
				minWidth: 'auto'
			},
			'& .MuiChip-root': {
				padding: 0
			},
			'& > label': {
				marginTop: -8,
				marginLeft: 14,
				backgroundColor: '#fff',
				padding: '0 2px',
				zIndex: 1
			}
		}
	},
	tags: {
		margin: '16px 8px',
		'& > div': {
			paddingTop: 25,
			flexDirection: 'row'
		}
	}
}));

const JobSchema = Yup.object().shape({
	title: Yup.string().required(t('required')),
	client: Yup.string().required(t('required')),
	function: Yup.string().required(t('required')),
	salary: Yup.string().required(t('required'))
});

export default function JobForm({ onClose, job = {}, edit }) {
	const classes = useStyles();
	const { employmentTypes, applicantLevels, languages } = useJob();
	const { setResponseDialog } = useApp();
	const userLanguage = getLanguage();

	const [employmentType, setEmploymentType] = React.useState(
		job.employmentTypeId && employmentTypes ? employmentTypes.records.find(type => type._id === job.employmentTypeId) :
			null
	);
	const [applicantLevel, setApplicantLevel] = React.useState(
		job.applicantLevelId && applicantLevels ? applicantLevels.records.find(level => level._id === job.applicantLevelId) :
			null
	);

	const [language, setLanguage] = React.useState(
		job.languageId && languages ? languages.records.find(lang => lang._id === job.languageId) :
			null
	);

	const [tags, setTags] = React.useState(job.jobtags || []);
	const [campaigns, setCampaigns] = React.useState(job.jobcampaigns || []);

	const [introduction, setIntroduction] = React.useState(
		job && job.introduction ?
			RichEditorHelpers.convertFromHtml(job.introduction) :
			RichEditorHelpers.createEmpty()
	);

	const [description, setDescription] = React.useState(
		job && job.description ?
			RichEditorHelpers.convertFromHtml(job.description) :
			RichEditorHelpers.createEmpty()
	);

	const [about, setAbout] = React.useState(
		job && job.about ?
			RichEditorHelpers.convertFromHtml(job.about) :
			RichEditorHelpers.createEmpty()
	);

	const [offer, setOffer] = React.useState(
		job && job.offer ?
			RichEditorHelpers.convertFromHtml(job.offer) :
			RichEditorHelpers.createEmpty()
	);

	const today = moment();
	const [from, setFrom] = React.useState(moment(job.from) || today);
	const [to, setTo] = React.useState(moment(job.to) || moment().add(1, 'day'));

	const variant = edit ? 'outlined' : 'standard';

	return (
		<Formik
			initialValues={{
				title: job.title || '',
				client: job.client || '',
				function: job.function || '',
				salary: job.salary || '',
				location: job.location || '',
				time: job.time || ''
			}}
			validateOnChange={false}
			validateOnBlur={false}
			validationSchema={JobSchema}
			validate={() => {
				const errors = {};

				if (RichEditorHelpers.getPlainText(introduction).length < 2) { errors.introduction = t('min_chars', false, { amount: 2 }); }
				if (RichEditorHelpers.getPlainText(description).length < 2) { errors.description = t('min_chars', false, { amount: 2 }); }
				if (RichEditorHelpers.getPlainText(about).length < 2) { errors.about = t('min_chars', false, { amount: 2 }); }
				if (RichEditorHelpers.getPlainText(offer).length < 2) { errors.offer = t('min_chars', false, { amount: 2 }); }

				if (!employmentType) { errors.type = t('required'); }
				if (!applicantLevel) { errors.level = t('required'); }

				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				const data = {
					from: moment(from.format('YYYY-MM-DD')).toDate(),
					to: moment(to.format('YYYY-MM-DD')).toDate(),
					employmentTypeId: employmentType._id,
					applicantLevelId: applicantLevel._id,
					languageId: language._id,
					introduction: RichEditorHelpers.convertToHtml(introduction),
					description: RichEditorHelpers.convertToHtml(description),
					about: RichEditorHelpers.convertToHtml(about),
					offer: RichEditorHelpers.convertToHtml(offer),
					tags: tags.map(tags => tags._id),
					campaigns: campaigns.map(campaign => campaign._id),
					...values
				};

				if (job && job._id) {
					jobsService.patch(job._id, data)
						.then(onClose)
						.catch(error => setResponseDialog({ error }));
				} else {
					jobsService.post(data)
						.then(onClose)
						.catch(error => setResponseDialog({ error }));
				}
				setSubmitting(false);
			}}
		>
			{({ submitForm, isSubmitting, errors, validateForm }) => {
				return (
					<Form className={!edit ? classes.disabled : ''}>
						<Box display="flex" margin={1}>
							<Field
								name="title"
								component={TextField}
								label={t("job_title")}
								variant={variant}
								disabled={!edit}
								style={{ width: '100%', marginRight: 10 }}
								margin="normal" />
							<Field
								name="client"
								component={TextField}
								label={t("client")}
								variant={variant}
								disabled={!edit}
								style={{ width: '50%', marginRight: 10 }}
								margin="normal" />
							<Field
								name="function"
								component={TextField}
								label={t("function")}
								variant={variant}
								disabled={!edit}
								style={{ width: '50%' }}
								margin="normal" />
						</Box>
						<Box display="flex" margin={1}>
							<DatePicker
								name="from"
								ampm={false}
								minDate={today}
								label={t("start_date")}
								disableTime={true}
								style={{ width: '100%', marginRight: 10 }}
								variant={variant}
								margin="normal"
								disabled={!edit || isSubmitting}
								format="DD.MM.YYYY"
								error={!!errors.from}
								helperText={errors.from}
								value={from}
								onChange={setFrom}
							/>
							<DatePicker
								name="to"
								ampm={false}
								minDate={today}
								label={t("end_date")}
								disableTime={true}
								style={{ width: '100%', marginRight: 10 }}
								variant={variant}
								disabled={!edit || isSubmitting}
								margin="normal"
								format="DD.MM.YYYY"
								error={!!errors.to}
								helperText={errors.to}
								value={to}
								onChange={setTo}
							/>
							<Field
								name="language"
								component={Autocomplete}
								value={language}
								disabled={!edit}
								options={languages && languages.records ? languages.records : []}
								getOptionLabel={option => option.label ? t(option.label) : ''}
								style={{ width: '100%', marginRight: 10 }}
								onChange={(event, value) => setLanguage(value)}
								renderInput={(params) => (
									<Field
										{...params}
										margin="normal"
										component={MuiTextField}
										error={!!errors.language}
										helperText={errors.language}
										label={t("language")}
										variant={variant}
										name="language" />
								)} />
							<Field
								name="salary"
								disabled={!edit}
								component={TextField}
								label={t("salary")}
								variant={variant}
								style={{ width: '100%' }}
								margin="normal" />
						</Box>
						<Box display="flex" margin={1}>
							<Field
								name="location"
								disabled={!edit}
								component={TextField}
								label={t("location")}
								variant={variant}
								style={{ width: '100%', marginRight: 10 }}
								margin="normal" />
							<Field
								name="type"
								component={Autocomplete}
								disabled={!edit}
								value={employmentType}
								options={employmentTypes && employmentTypes.records ? employmentTypes.records : []}
								getOptionLabel={option => option.label || ''}
								style={{ width: '100%', marginRight: 10 }}
								onChange={(event, value) => setEmploymentType(value)}
								renderInput={(params) => (
									<Field
										{...params}
										margin="normal"
										component={MuiTextField}
										error={!!errors.type}
										helperText={errors.type}
										label={t("type_of_employment")}
										variant={variant}
										name="type" />
								)} />
							<Field
								name="level"
								component={Autocomplete}
								disabled={!edit}
								value={applicantLevel}
								options={applicantLevels && applicantLevels.records ? applicantLevels.records : []}
								style={{ width: '100%', marginRight: 10 }}
								getOptionLabel={option => option.label || ''}
								onChange={(event, value) => setApplicantLevel(value)}
								renderInput={(params) => (
									<Field
										{...params}
										margin="normal"
										component={MuiTextField}
										error={!!errors.level}
										helperText={errors.level}
										label={t("level_of_applicant")}
										variant={variant}
										name="level" />
								)} />
							<Field
								name="time"
								disabled={!edit}
								component={TextField}
								label={t("time")}
								variant={variant}
								style={{ width: '100%' }}
								margin="normal" />
						</Box>
						{(edit || !!job.jobtags.length) &&
							<Box display="flex" margin={1} className={edit ? classes.tagForm : classes.tags}>
								<FormControl className={edit ? classes.formControl : ''}>
									<InputLabel shrink > {t('tags')} </InputLabel>
									{edit ?
										<Tags tags={job.jobtags || []} onChange={setTags} />
										:
										tags.map(({ label }) => { return <Chip label={label} key={label} />; })
									}
								</FormControl>
							</Box>
						}

						{(edit || !!job.jobtags.length) &&
							<Box display="flex" margin={1} className={edit ? classes.tagForm : classes.tags}>
								<FormControl className={edit ? classes.formControl : ''}>
									<InputLabel shrink > {t('campaigns')} </InputLabel>
									{edit ?
										<JobCampaigns campaigns={job.jobcampaigns || []} onChange={setCampaigns} />
										:
										campaigns.map(({ title }) => { return <Chip label={title} key={title} />; })
									}
								</FormControl>
							</Box>
						}

						<Box display="flex" margin={1}>
							<FormControl className={classes.formControl}>
								<InputLabel shrink > {t('job_introduction')} </InputLabel>
								{edit ?
									<Field
										name="introduction"
										component={RichEditor}
										editorState={introduction}
										onChange={setIntroduction}
										error={!!errors['introduction']}
										helperText={errors['introduction']}
										variant={variant}
										margin="normal"
									/>
									:
									<Paper elevation={2} className={classes.paper}>
										<div dangerouslySetInnerHTML={{ __html: job.introduction }} />
									</Paper>
								}
							</FormControl>
						</Box>

						<Box display="flex" margin={1}>
							<FormControl className={classes.formControl}>
								<InputLabel shrink > {t('work_for')} </InputLabel>
								{edit ?
									<Field
										name="description"
										component={RichEditor}
										editorState={description}
										onChange={setDescription}
										error={!!errors['description']}
										helperText={errors['description']}
										variant={variant}
										margin="normal"
									/>
									:
									<Paper elevation={2} className={classes.paper}>
										<div dangerouslySetInnerHTML={{ __html: job.description }} />
									</Paper>
								}
							</FormControl>
						</Box>
						<Box display="flex" margin={1}>
							<FormControl className={classes.formControl}>
								<InputLabel shrink> {t('look_for')} </InputLabel>
								{edit ?
									<Field
										name="about"
										component={RichEditor}
										editorState={about}
										onChange={setAbout}
										error={!!errors['about']}
										helperText={errors['about']}
										variant={variant}
										margin="normal"
									/>
									:
									<Paper elevation={2} className={classes.paper}>
										<div dangerouslySetInnerHTML={{ __html: job.about }} />
									</Paper>
								}
							</FormControl>
						</Box>
						<Box display="flex" margin={1}>
							<FormControl className={classes.formControl}>
								<InputLabel shrink> {t('what_offer')} </InputLabel>
								{edit ?
									<Field
										name="offer"
										component={RichEditor}
										editorState={offer}
										onChange={setOffer}
										error={!!errors['offer']}
										helperText={errors['offer']}
										variant={variant}
										margin="normal"
									/>
									:
									<Paper elevation={2} className={classes.paper}>
										<div dangerouslySetInnerHTML={{ __html: job.offer }} />
									</Paper>
								}
							</FormControl>
						</Box>
						{edit &&
							<Box margin={1}>
								<Button
									variant="contained"
									color="primary"
									disabled={isSubmitting}
									onClick={submitForm}
								>
									{t('save')}
								</Button>
							</Box>
						}
					</Form>
				);
			}}
		</Formik>
	);
}