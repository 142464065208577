import React from "react";
import { t } from "../../utils/i18n";
import "./Resume.scss";
import { makeStyles } from "@material-ui/core/styles";
import Reaptcha from "reaptcha";
import AppService from "../../services/app.service";
import moment from "moment";
import {
  Grid,
  Typography,
  Modal,
  Button,
  TextField,
  Box,
} from "@material-ui/core";
import CostomTimeline, {
  CostomTimelineSeparator,
} from "../Candidate/Timeline/Timeline";
import WorkIcon from "@material-ui/icons/Work";
import SchoolIcon from "@material-ui/icons/School";
import AuthenticatedAvatar from "../AuthenticatedAvatar";

import CandidateSkills from "../CandidateSkills";
import { TimelineContent, TimelineItem } from "@material-ui/lab";
import countries from "../../assets/countries/CountryList";
import { getLanguageList } from "../../assets/languages/LanguageList";
import CandidatesService from "../../services/candidates.service";
import PortfolioImage from "../PortfolioImage";
// Context
import { useApp } from "../../context/app";

const dateFormat = "MM/YYYY";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1605,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
  },
  modal: {
    margin: "auto",
    background: "#fff",
    width: "50vw",
    height: "fit-content",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    position: "absolute",
    backdropFilter: "none",
    borderRadius: 10,
    paddingBottom: 60,
    "&:focus": {
      outline: "none",
    },
  },
  modalheader: {
    background: "#041e7a",
    padding: 10,
    paddingTop: 20,
    marginBottom: 50,
    color: "#fff",
    textAlign: "center",
    "& > h2": {
      lineHeight: "1.6em",
    },
  },
  modalbody: {
    margin: "auto",
    width: "70%",
    "& > *": {
      marginBottom: 20,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#919191",
    },
    "& > button": {
      width: "70%",
      margin: "auto",
      marginTop: 30,
    },
  },
}));

const hireCandidate = async (id, contact) =>
  CandidatesService.hire(id, contact);

const Resume = ({ candidate }) => {
  const classes = useStyles();
  const { setResponseDialog } = useApp();
  const [verified, setVerified] = React.useState(false);
  const [hireModal, setHireModal] = React.useState(false);
  const [selectedPortfolioItem, setSelectedPortfolioItem] =
    React.useState(false);
  const [contact, setContact] = React.useState({});
  const [files, setFiles] = React.useState(candidate.candidatefiles || []);
  const [languages, setLanguages] = React.useState(null);

  React.useEffect(() => {
    if (!languages) {
      AppService.languages().then(setLanguages);
    }
    // eslint-disable-next-line
  }, []);

  const workExperience = candidate.candidateworkexperiences.sort((a) =>
    new Date(a.from).getTime()
  );
  const diplomas = candidate.candidatediplomas.sort((a) =>
    new Date(a.from).getTime()
  );

  const unavailable = candidate.candidateInfo?.unavailable;
  const available =
    !unavailable || !unavailable.to
      ? "available"
      : `available after ${unavailable.to}`;

  const getCountry = (countryCode) =>
    countries.find((c) => c.code === countryCode).label;
  const getLanguage = (motherTongue = "NL") =>
    getLanguageList().find((l) => l.code === motherTongue)?.name ||
    "Nederlands";

  const isDesigner = () =>
    candidate.userfunction &&
    !!["Designer", "copywriter", "translator"].includes(
      candidate.userfunction[0].name
    );

  const hireMe = () => {
    // if (verified)
    hireCandidate(candidate._id, contact).then((response) => {
      setResponseDialog({
        title: t("great_success"),
        message: t("registration_completed_sub"),
        onClose: setHireModal(false),
      });
    });
  };

  return (
    <>
      {/* About me*/}
      <Grid
        container
        className="section pt_45"
        style={{ paddingLeft: "10px !important" }}
      >
        <AuthenticatedAvatar
          candidate={candidate}
          avatarId={candidate.avatar}
        />
        <Grid item xs={12} className={"aboutme_grid"}>
          <Typography variant={"h6"}>{candidate.firstname}</Typography>
          <Typography variant={"subtitle1"}>
            {candidate.userfunction[0].name}
          </Typography>
          <Typography variant={"body2"} className={"aboutme_text"}>
            {candidate.resumeSummary}
          </Typography>
          <Grid item xs={12} className={"aboutme_skills"}>
            <CandidateSkills candidate={candidate} edit={false} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="section hire">
        <Typography variant={"h6"}>
          {candidate.firstname} is {available}{" "}
          {available ? (
            <a
              style={{ color: "#16cc94" }}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setHireModal(candidate._id);
              }}
            >
              hire me
            </a>
          ) : (
            ""
          )}
        </Typography>
      </Grid>

      <Grid container className="section pb_20">
        <Grid item sm={12} md={6}>
          <Grid container sm={12} md={12}>
            <Grid item xs={12} md={3}>
              <Grid className="section_title mb-20">
                <span></span>
                <h6 className={"section_title_text"}>Current location</h6>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant={"body2"}>
                  {getCountry(candidate.country)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}>
              <Grid className="section_title mb-20">
                <span></span>
                <h6 className={"section_title_text"}>Mother tongue </h6>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant={"body2"}>
                  {getLanguage(candidate.language)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid className="section_title mb-20">
                <span></span>
                <h6 className={"section_title_text"}>Years of experience</h6>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant={"body2"}>
                  {candidate.language.skillSet &&
                    candidate.candidateInfo.skillSet.yearsExperience}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid className="section_title mb-20">
              <span></span>
              <h6 className={"section_title_text"}>About myself</h6>
            </Grid>
            <Grid item xs={12} className={"aboutme_skills"}>
              <Typography variant={"body2"} style={{ paddingRight: 40 }}>
                {candidate.info}
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12}>
            <CostomTimeline title={"Education"} icon={<SchoolIcon />}>
              {diplomas.map((Education) => (
                <TimelineItem key={Education._id}>
                  <CostomTimelineSeparator />
                  <TimelineContent className={"timeline_content"}>
                    <Typography className={"timeline_title"}>
                      {Education.degree}
                    </Typography>
                    <Typography variant={"caption"} className={"timeline_date"}>
                      {moment(Education.from).format(dateFormat)}
                      &nbsp;-&nbsp;
                      {moment(Education.to).format(dateFormat)}
                    </Typography>
                    <Typography
                      variant={"body2"}
                      className={"timeline_description"}
                    >
                      {Education.institute}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </CostomTimeline>
          </Grid>
        </Grid>

        <Grid item sm={12} md={6}>
          <Grid item xs={12} md={12}>
            <Grid className="section_title mb-20">
              <span></span>
              <h6 className={"section_title_text"}>Preferred project</h6>
            </Grid>
            <Grid item xs={12} className={"aboutme_skills"}>
              <Typography variant={"body2"}>
                {candidate.candidateInfo.projectPreferences}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid className="section_title mb-20">
              <span></span>
              <h6 className={"section_title_text"}>Top project</h6>
            </Grid>
            <Grid item xs={12} className={"aboutme_skills"}>
              <Typography variant={"body2"}>
                {candidate.candidateInfo.topProject}
              </Typography>
            </Grid>
          </Grid>

          <Grid item sm={12} md={12}>
            <CostomTimeline title={"Work Experiences"} icon={<WorkIcon />}>
              {workExperience.map((Experiences) => (
                <TimelineItem key={Experiences._id}>
                  <CostomTimelineSeparator />
                  <TimelineContent className={"timeline_content"}>
                    <Typography className={"timeline_title"}>
                      {Experiences.function}
                    </Typography>
                    <Typography variant={"caption"} className={"timeline_date"}>
                      {moment(Experiences.from).format(dateFormat)}
                      &nbsp;-&nbsp;
                      {moment(Experiences.to).format(dateFormat)}
                    </Typography>
                    <Typography
                      variant={"body2"}
                      className={"timeline_description"}
                    >
                      {Experiences.company}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </CostomTimeline>
          </Grid>
        </Grid>

        {!!isDesigner() && (
          <Grid container>
            <Grid container className="section hire">
              <Typography variant={"h6"}>portfolio</Typography>
            </Grid>
            <Grid container style={{ margin: 12, paddingLeft: 15 }}>
              {files
                .filter((file) => !!file.portfolio)
                .map((file) => {
                  return (
                    <div onClick={() => setSelectedPortfolioItem(file)}>
                      <PortfolioImage fileId={file._id} candidate={candidate} />
                    </div>
                  );
                })}
            </Grid>
          </Grid>
        )}

        <Modal
          open={!!hireModal}
          onClose={() => setHireModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box flexDirection="column" className={classes.modal}>
            <Box className={classes.modalheader}>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Thank you for your intrest. <br />
                Please leave your personal details and we'll get in touch!
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={classes.modalbody}
            >
              <TextField
                variant="outlined"
                name="name"
                type="text"
                onChange={(e) => (contact.name = e.currentTarget.value)}
                label="Name"
              />
              <TextField
                variant="outlined"
                name="email"
                type="email"
                onChange={(e) => (contact.email = e.currentTarget.value)}
                label="Email"
              />
              <TextField
                variant="outlined"
                name="phone"
                onChange={(e) => (contact.phone = e.currentTarget.value)}
                type="text"
                label="Phone"
              />
              <Reaptcha
                sitekey="6LfuNZofAAAAAB2D_DCaLmutOrJneYQPqbXknuPb"
                onVerify={() => setVerified(true)}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#e91e63", color: "#fff" }}
                size="large"
                disabled={!verified}
                onClick={hireMe}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={!!selectedPortfolioItem}
          onClose={() => setSelectedPortfolioItem(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box flexDirection="column" className={classes.modal}>
            <Box className={classes.modalheader} style={{ marginBottom: 0 }}>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                {selectedPortfolioItem.title
                  ? selectedPortfolioItem.title
                  : "portfolio"}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={classes.modalbody}
            >
              <PortfolioImage
                fileId={selectedPortfolioItem._id}
                candidate={candidate}
                style={{
                  height: "60vh",
                  maxHeight: "80vh",
                  width: "auto",
                }}
              />
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h5"
                style={{ textAlign: "center", fontSize: 12 }}
              >
                {!!selectedPortfolioItem.summary &&
                  selectedPortfolioItem.summary}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#e91e63", color: "#fff" }}
                onClick={() => setSelectedPortfolioItem(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </Grid>
    </>
  );
};

export default Resume;
