import React from "react";
import { t } from "../../utils/i18n";
import { useHistory } from "react-router-dom";
import config from "../../config";
import { debounce } from "lodash";

import * as Yup from "yup";
import PortfolioImage from "../PortfolioImage";

import countries from "../../assets/countries/CountryList";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { DropzoneDialog } from "material-ui-dropzone";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Button,
  Chip,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiTextField from "@material-ui/core/TextField";

// Services
import CandidatesService from "../../services/candidates.service";
import FilesService from "../../services/files.service";
import AuthService from "../../services/auth.service";
import JobsService from "../../services/jobs.service";

import { useAuth } from "../../context/auth";

// App components
import DatePicker from "../DatePicker";
import AuthenticatedLink from "../AuthenticatedLink";

const { API_URL } = config.mediaforta;

const useStyles = makeStyles(() => ({
  chip: {
    paddingRight: 15,
    cursor: "pointer",
  },
  avatarWrapper: { position: "relative" },
  uploadIcon: {
    position: "absolute",
    zIndex: 9,
    top: 10,
    width: 120,
  },
  header: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": { float: "left" },
  },
  name: {
    fontSize: 22,
    padding: "10px 10px 0",
    width: "calc(100% - 250px)",
    "& > * > span": {
      fontSize: 17,
      display: "block",
    },
  },
  avatar: {
    width: 120,
    height: 120,
  },
  form: {
    width: "100%",
    backgroundColor: "#f0f3f5",
    "& .MuiFormControl-root": {
      backgroundColor: "#ebeced",
      marginBottom: 20,
    },
    "& .Mui-error": {
      marginBottom: 0,
      backgroundColor: "#f0f3f5",
    },
  },
  container: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: 30,
    },
  },
  sections: {
    marginTop: 30,
    "& > *": {
      marginLeft: "30px !important",
      color: "#ccc",
      fontWeight: 500,
      "&.-active": {
        color: "#2a7fd7",
        borderBottom: "3px solid #2a7fd7",
        paddingBottom: 5,
      },
    },
  },
  file: {
    position: "relative",
    margin: "0px 10px 20px",
    float: "left",
    "& > a > p": {
      fontSize: 12,
      position: "absolute",
      bottom: 9,
      left: 9,
      fontWeight: 500,
      cursor: "pointer",
    },
  },
  dropdown: {
    backgroundColor: "transparent !important",
    width: "100%",
    "& > .MuiFormLabel-root": { top: 0, left: 0 },
    "& .MuiInputBase-formControl, & div .MuiSelect-root": {
      backgroundColor: "#ebeced !important",
      borderRadius: 0,
      padding: "10px 5px 7px",
    },
    "& .MuiInputBase-root > fieldset": { borderRadius: "4px" },
  },
  phone: { "& .MuiPhoneNumber-flagButton": { backgroundColor: "transparent" } },
  error: {
    width: "100%",
    background: "#ef5350",
    color: "#fff",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
    padding: "10px 50px",
    margin: 0,
    "& li > span": {
      fontSize: "15px",
      textTransform: "uppercase",
      fontWeight: 600,
      marginRight: "5px",
    },
  },
}));

const PersonalSchema = Yup.object().shape({
  info: Yup.string().required(t("required")),
});

export default function CandidateProfessional({ candidate = {} }) {
  const classes = useStyles();
  const history = useHistory();
  const {
    user: {
      user: { jobId, campaignId, newsletter },
    },
  } = useAuth();
  const [files, setFiles] = React.useState(candidate.candidatefiles || []);
  const [dropzoneDialog, setDropzoneDialog] = React.useState(false);
  const [portfolioDropzoneDialog, setPortfolioDropzoneDialog] =
    React.useState(false);
  const canUploadPortfolio = () =>
    candidate.userfunction &&
    !!["Designer", "copywriter", "translator"].includes(
      candidate.userfunction[0].name
    );
  const today = new Date();
  const {
    diploma = [
      {
        from: today,
        to: today,
        institute: "",
        degree: "",
        country: "",
        _id: Math.random().toString(16).substr(2, 8),
      },
    ],
    workExperience = [
      {
        from: today,
        to: today,
        company: "",
        function: "",
        _id: Math.random().toString(16).substr(2, 8),
      },
    ],
  } = candidate;

  const removeFile = (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    FilesService.delete(file._id).then(() =>
      setFiles(files.filter((f) => f._id !== file._id))
    );
  };

  const updateFile = (fileId, update) => {
    FilesService.patch(fileId, update);
  };

  const debounceUpdateFile = debounce((fileId, update) => {
    updateFile(fileId, update);
  }, 300);

  const showPricePerWord = () =>
    candidate.userfunction &&
    ["copywriter", "translator"].includes(candidate.userfunction[0].name);

  return (
    candidate && (
      <>
        <Formik
          initialValues={{
            dayRate: "",
            pricePerWord: "",
            diploma,
            workExperience,
            info: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={PersonalSchema}
          onSubmit={(
            { dayRate, pricePerWord, diploma, workExperience, info },
            { setSubmitting }
          ) => {
            diploma = diploma.filter(({ degree }) => !!degree);
            workExperience = workExperience.filter(
              ({ company, function: func }) => !!company && !!func
            );
            const candidateInfo = candidate.candidateInfo || {};

            const patch = {
              ...candidate,
              candidateInfo: {
                dayRate,
                pricePerWord,
                diploma,
                workExperience,
                ...candidateInfo,
              },
              info,
            };
            CandidatesService.patch(candidate._id, patch)
              .then(() => {
                if (jobId) {
                  JobsService.apply({
                    jobId,
                    campaignId,
                    candidateId: candidate._id,
                  }).then(AuthService.removeJobId);
                }
                history.push("/complete", { candidate: patch, step: 3 });
              })
              .catch((error) => console.log(error));
          }}
          validate={({ dayRate, pricePerWord }) => {
            const errors = {};
            if (!files.length) errors.files = t("required");
            if (!dayRate && !pricePerWord)
              errors.price = t("at_least_one_price_required");
            const hasErrors = Object.keys(errors).length;
            if (hasErrors) {
              setTimeout(
                () =>
                  document.body.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  }),
                400
              );
            }
            return hasErrors ? errors : false;
          }}
        >
          {({ submitForm, values, isSubmitting, setFieldValue, errors }) => (
            <>
              {!!Object.keys(errors).length && (
                <ul className={classes.error}>
                  {Object.keys(errors).map((key) => (
                    <li key={key}>
                      <span>{t(key)}:</span> {errors[key]}
                    </li>
                  ))}
                </ul>
              )}
              <Form className={classes.form}>
                <Grid container className={classes.sections}>
                  <Typography> {t("personal_details")} </Typography>
                  <Typography className={"-active"} style={{ flex: "inherit" }}>
                    {" "}
                    {t("professional_details")}{" "}
                  </Typography>
                </Grid>
                <Grid
                  container
                  className={`${classes.container} upload-wrapper`}
                  style={{
                    display: "block",
                    paddingLeft: 30,
                    overflow: "auto",
                  }}
                >
                  <Grid container style={{ margin: "20px 0px 0px" }}>
                    <Typography variant="h5" style={{ marginTop: 25 }}>
                      {" "}
                      {t("cv_resume")}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        margin: 20,
                        backgroundColor: errors.files ? "red" : "",
                      }}
                      size="large"
                      onClick={() => setDropzoneDialog(true)}
                    >
                      {t("choose_file")}
                    </Button>
                    <p
                      className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                      style={{ lineHeight: 6 }}
                    >
                      {errors.files}
                    </p>
                  </Grid>
                  {files &&
                    files
                      .filter((file) => !file.hidden && !file.portfolio)
                      .map((file) => {
                        return (
                          <Grid
                            item
                            key={file._id}
                            className={`file ${classes.file}`}
                          >
                            <AuthenticatedLink
                              url={`${API_URL}candidates/${candidate._id}/file/${file._id}`}
                              filename={file.originalname}
                            >
                              <Chip
                                label={file.originalname}
                                className={classes.chip}
                              />
                              <Typography onClick={(e) => removeFile(e, file)}>
                                {" "}
                                X{" "}
                              </Typography>
                            </AuthenticatedLink>
                          </Grid>
                        );
                      })}
                </Grid>
                {!!canUploadPortfolio() && (
                  <Grid
                    container
                    className={`${classes.container} upload-wrapper`}
                    style={{
                      display: "block",
                      paddingLeft: 30,
                      overflow: "auto",
                    }}
                  >
                    <Grid container style={{ margin: "20px 0px 0px" }}>
                      <Typography variant="h5" style={{ marginTop: 25 }}>
                        {" "}
                        {t("portfolio")}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          margin: 20,
                          backgroundColor: errors.files ? "red" : "",
                        }}
                        size="large"
                        onClick={() => setPortfolioDropzoneDialog(true)}
                      >
                        {t("choose_file")}
                      </Button>
                      <p
                        className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                        style={{ lineHeight: 6 }}
                      >
                        {errors.files}
                      </p>
                    </Grid>
                    {files &&
                      files
                        .filter((file) => !file.hidden && !!file.portfolio)
                        .map((file) => {
                          return (
                            <Grid
                              item
                              key={file._id}
                              className={`file ${classes.file}`}
                            >
                              <Grid container>
                                <Typography
                                  variant="h5"
                                  style={{ marginBottom: 20 }}
                                >
                                  <AuthenticatedLink
                                    url={`${API_URL}candidates/${candidate._id}/file/${file._id}`}
                                    filename={file.originalname}
                                  >
                                    {/* <PortfolioImage fileId={file._id} candidate={candidate} /> */}

                                    <Chip
                                      label={file.originalname}
                                      className={classes.chip}
                                    />
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => removeFile(e, file)}
                                    >
                                      {" "}
                                      X{" "}
                                    </span>
                                  </AuthenticatedLink>
                                </Typography>
                                <Field
                                  name="title"
                                  component={MuiTextField}
                                  label={t("Title")}
                                  variant="outlined"
                                  onChange={({ currentTarget: { value } }) =>
                                    debounceUpdateFile(file._id, {
                                      title: value,
                                    })
                                  }
                                  style={{ width: "100%", marginRight: 10 }}
                                />
                                <Field
                                  name="summary"
                                  component={MuiTextField}
                                  multiline
                                  rows={10}
                                  label={t("Summary")}
                                  variant="outlined"
                                  onChange={({ currentTarget: { value } }) =>
                                    debounceUpdateFile(file._id, {
                                      summary: value,
                                    })
                                  }
                                  style={{ width: "100%", marginRight: 10 }}
                                />
                              </Grid>
                            </Grid>
                          );
                        })}
                  </Grid>
                )}
                <Grid container className={classes.container}>
                  <Grid container>
                    <Typography variant="h5" style={{ marginBottom: 20 }}>
                      {" "}
                      {t("rates")}{" "}
                    </Typography>
                    <Field
                      name="dayRate"
                      component={MuiTextField}
                      error={!!errors["price"]}
                      helperText={errors["price"]}
                      label={t("dayRate")}
                      variant="outlined"
                      onChange={({ currentTarget: { value } }) =>
                        setFieldValue("dayRate", value)
                      }
                      style={{ width: "100%", marginRight: 10 }}
                    />
                    <Field
                      name="pricePerWord"
                      component={MuiTextField}
                      error={!!errors["price"]}
                      helperText={errors["price"]}
                      label={
                        showPricePerWord()
                          ? t("pricePerWord")
                          : t("pricePerHour")
                      }
                      variant="outlined"
                      onChange={({ currentTarget: { value } }) =>
                        setFieldValue("pricePerWord", value)
                      }
                      style={{ width: "100%", marginRight: 10 }}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.container}>
                  <Grid>
                    <Typography variant="h5" style={{ marginBottom: 20 }}>
                      {" "}
                      {t("diplomas")}{" "}
                    </Typography>
                    {values.diploma.map(
                      ({ from, to, degree, institute, country, _id }) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            key={_id}
                            style={{ marginBottom: 20 }}
                          >
                            <Field
                              name="degree"
                              component={MuiTextField}
                              label={t("degree")}
                              variant="outlined"
                              value={degree}
                              onChange={({ currentTarget: { value } }) => {
                                setFieldValue(
                                  "diploma",
                                  values.diploma.map((diploma) => {
                                    if (diploma._id === _id)
                                      diploma.degree = value;
                                    return diploma;
                                  })
                                );
                              }}
                              style={{ width: "100%", marginRight: 10 }}
                            />
                            <Field
                              name="institute"
                              component={MuiTextField}
                              label={t("institute")}
                              variant="outlined"
                              value={institute}
                              onChange={({ currentTarget: { value } }) => {
                                setFieldValue(
                                  "diploma",
                                  values.diploma.map((diploma) => {
                                    if (diploma._id === _id)
                                      diploma.institute = value;
                                    return diploma;
                                  })
                                );
                              }}
                              style={{ width: "100%", marginRight: 10 }}
                            />
                            <Autocomplete
                              value={
                                countries.find((c) => c.code === country) ||
                                null
                              }
                              id="country"
                              options={countries}
                              getOptionSelected={(option, value) =>
                                option.code === value.code
                              }
                              onChange={(event, input) => {
                                setFieldValue(
                                  "diploma",
                                  values.diploma.map((diploma) => {
                                    if (diploma._id === _id)
                                      diploma.country = input ? input.code : "";
                                    return diploma;
                                  })
                                );
                              }}
                              getOptionLabel={(option) => option.label}
                              renderInput={(params) => {
                                return (
                                  <MuiTextField
                                    {...params}
                                    name="country"
                                    variant="outlined"
                                    label={t("country")}
                                  />
                                );
                              }}
                            />
                            <DatePicker
                              name="from"
                              disabled={isSubmitting}
                              ampm={false}
                              disableTime={true}
                              label={t("from")}
                              variant="outlined"
                              format="YYYY"
                              views={["year"]}
                              value={from}
                              style={{ width: "100%" }}
                              onChange={(date) => {
                                setFieldValue(
                                  "diploma",
                                  values.diploma.map((diploma) => {
                                    if (diploma._id === _id)
                                      diploma.from = date.toDate();
                                    return diploma;
                                  })
                                );
                              }}
                            />
                            <DatePicker
                              name="to"
                              disabled={isSubmitting}
                              ampm={false}
                              disableTime={true}
                              label={t("to")}
                              views={["year"]}
                              variant="outlined"
                              format="YYYY"
                              value={to}
                              style={{ width: "100%" }}
                              onChange={(date) => {
                                setFieldValue(
                                  "diploma",
                                  values.diploma.map((diploma) => {
                                    if (diploma._id === _id)
                                      diploma.to = date.toDate();
                                    return diploma;
                                  })
                                );
                              }}
                            />
                          </Grid>
                        );
                      }
                    )}
                    <Grid item>
                      <Typography
                        style={{
                          marginLeft: 10,
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        onClick={() =>
                          setFieldValue("diploma", [
                            ...values.diploma,
                            {
                              from: today,
                              to: today,
                              country: "",
                              institute: "",
                              degree: "",
                              _id: Math.random().toString(16).substr(2, 8),
                            },
                          ])
                        }
                      >
                        + {t("add_diploma")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container style={{ display: "block" }}>
                    <Typography variant="h5" style={{ marginBottom: 20 }}>
                      {" "}
                      {t("info")}{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        fullWidth
                        multiline
                        rows={10}
                        style={{ marginBottom: 20 }}
                        variant="outlined"
                        name="info"
                        type="text"
                        label={t("personal_info")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={classes.container}>
                  <Grid container>
                    <Typography variant="h5" style={{ marginBottom: 20 }}>
                      {" "}
                      {t("work_experience")}{" "}
                    </Typography>
                    {values.workExperience.map(
                      ({ from, to, company, function: func, _id }) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            key={_id}
                            style={{ marginBottom: 20 }}
                          >
                            <Field
                              name="company"
                              component={MuiTextField}
                              label={t("company")}
                              variant="outlined"
                              value={company}
                              onChange={({ currentTarget: { value } }) => {
                                setFieldValue(
                                  "workExperience",
                                  values.workExperience.map((experience) => {
                                    if (experience._id === _id)
                                      experience.company = value;
                                    return experience;
                                  })
                                );
                              }}
                              style={{ width: "100%", marginRight: 10 }}
                            />
                            <Field
                              name="function"
                              component={MuiTextField}
                              label={t("function")}
                              variant="outlined"
                              value={func}
                              onChange={({ currentTarget: { value } }) => {
                                setFieldValue(
                                  "workExperience",
                                  values.workExperience.map((experience) => {
                                    if (experience._id === _id)
                                      experience.function = value;
                                    return experience;
                                  })
                                );
                              }}
                              style={{ width: "100%", marginRight: 10 }}
                            />
                            <div style={{ display: "flex" }}>
                              <DatePicker
                                name="from"
                                disabled={isSubmitting}
                                ampm={false}
                                disableTime={true}
                                label={t("from")}
                                style={{
                                  minWidth: 160,
                                  flex: 1,
                                  marginRight: 10,
                                }}
                                variant="outlined"
                                format="DD.MM.YYYY"
                                value={from}
                                onChange={(date) => {
                                  setFieldValue(
                                    "workExperience",
                                    values.workExperience.map((experience) => {
                                      if (experience._id === _id)
                                        experience.from = date.toDate();
                                      return experience;
                                    })
                                  );
                                }}
                              />
                              <DatePicker
                                name="to"
                                disabled={isSubmitting}
                                ampm={false}
                                disableTime={true}
                                label={t("to")}
                                style={{ minWidth: 160, flex: 1 }}
                                variant="outlined"
                                format="DD.MM.YYYY"
                                value={to}
                                onChange={(date) => {
                                  setFieldValue(
                                    "workExperience",
                                    values.workExperience.map((experience) => {
                                      if (experience._id === _id)
                                        experience.to = date.toDate();
                                      return experience;
                                    })
                                  );
                                }}
                              />
                            </div>
                          </Grid>
                        );
                      }
                    )}
                    <Grid item>
                      <Typography
                        style={{
                          marginLeft: 10,
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        onClick={() =>
                          setFieldValue("workExperience", [
                            ...values.workExperience,
                            {
                              from: today,
                              to: today,
                              company: "",
                              function: "",
                              _id: Math.random().toString(16).substr(2, 8),
                            },
                          ])
                        }
                      >
                        + {t("add_experience")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid>
                    {!newsletter && (
                      <FormControlLabel
                        // className={!! errors['newsletter'] ? classes.errorLabel : classes.checkboxControl}
                        control={
                          <Checkbox
                            name="newsletter"
                            variant="outlined"
                            onChange={({ currentTarget }) =>
                              setFieldValue("newsletter", currentTarget.checked)
                            }
                          />
                        }
                        label={t("newsletter_check", true)}
                      />
                    )}
                  </Grid>
                </Grid>

                <br />
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#e0e0e0",
                    color: "#606060",
                    float: "left",
                    margin: 20,
                  }}
                  size="large"
                  disabled={isSubmitting}
                  onClick={() => {
                    history.push("/complete", { candidate, step: 1 });
                  }}
                >
                  &lt; {t("previous")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#0df394",
                    color: "#041e7a",
                    float: "right",
                    margin: 20,
                  }}
                  size="large"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  {t("save")} &gt;
                </Button>
              </Form>
            </>
          )}
        </Formik>
        <DropzoneDialog
          open={dropzoneDialog}
          onSave={(fs) =>
            CandidatesService.upload(candidate._id, fs).then((response) => {
              const data = response.data.files || [response.data.file];
              setFiles([...files, ...data]);
              setDropzoneDialog(false);
              history.push("/complete", {
                candidate: { ...candidate, files: [...files, ...data] },
                step: 2,
              });
            })
          }
          acceptedFiles={[
            "image/jpeg",
            "image/png",
            "application/pdf",
            ".docx",
          ]}
          showPreviews={true}
          maxFileSize={10000000}
          maxWidth="md"
          className={classes.dialog}
          onClose={() => setDropzoneDialog(false)}
        />
        {!!canUploadPortfolio() && (
          <DropzoneDialog
            open={portfolioDropzoneDialog}
            onSave={(fs) => {
              CandidatesService.uploadPortfolio(candidate._id, fs).then(
                (response) => {
                  if (response.data.files) {
                    response.data.files.map((file) => {
                      file.portfolio = true;
                      return file;
                    });
                  }
                  const data = response.data.files || [
                    { ...response.data.file, portfolio: true },
                  ];
                  setFiles([...files, ...data]);
                  setPortfolioDropzoneDialog(false);
                  history.push("/complete", {
                    candidate: { ...candidate, files: [...files, ...data] },
                    step: 2,
                  });
                }
              );
            }}
            acceptedFiles={[
              "image/jpeg",
              "image/png",
              "application/pdf",
              ".docx",
            ]}
            showPreviews={true}
            maxFileSize={10000000}
            maxWidth="md"
            className={classes.dialog}
            onClose={() => setPortfolioDropzoneDialog(false)}
          />
        )}
      </>
    )
  );
}
