const LanguageListNL = {
  aa: 'Afar',
  ab: 'Abchazisch',
  ae: 'Avestan',
  af: 'Afrikaans',
  ak: 'Akan',
  am: 'Amhaars',
  an: 'Aragonees',
  ar: 'Arabisch',
  as: 'Assamees',
  av: 'Avar',
  ay: 'Aymara',
  az: 'Azerbeidzjaans',
  ba: 'Bashkir',
  be: 'Wit-Russisch',
  bg: 'Bulgaars',
  bh: 'Bihari',
  bi: 'Bislama',
  bm: 'Bambara',
  bn: 'Bengaals',
  bo: 'Tibetaans',
  br: 'Breton',
  bs: 'Bosnisch',
  ca: 'Catalaans',
  ce: 'Tsjetsjeens',
  ch: 'Chamorro',
  co: 'Corsicaans',
  cr: 'Cree',
  cs: 'Tsjechisch',
  cu: 'Oudkerkslavisch / Oud Bulgaars',
  cv: 'Tsjoevasj',
  cy: 'Welsh',
  da: 'Deens',
  de: 'Duits',
  dv: 'Duikhi',
  dz: 'Dzongkha',
  ee: 'Ewe',
  el: 'Grieks',
  en: 'Engels',
  eo: 'Esperanto',
  es: 'Spaans',
  et: 'Ests',
  eu: 'Baskisch',
  fa: 'Perzisch',
  ff: 'Peul',
  fi: 'Fins',
  fj: 'Fiji',
  fo: 'Faeröers',
  fr: 'Frans',
  fy: 'West-Fries',
  ga: 'Iers',
  gd: 'Schots-Gaelisch',
  gl: 'Galicisch',
  gn: 'Guarani',
  gu: 'Gujarati',
  gv: 'Manx',
  ha: 'Hausa',
  he: 'Hebreeuws',
  hi: 'Hindi',
  ho: 'Hiri Motu',
  hr: 'Kroatisch',
  ht: 'Haïtiaans',
  hu: 'Hongaars',
  hy: 'Armeens',
  hz: 'Herero',
  ia: 'Interlingua',
  id: 'Indonesisch',
  ie: 'Interlingue',
  ig: 'Igbo',
  ii: 'Sichuan Yi',
  ik: 'Inupiak',
  io: 'Ido',
  is: 'IJslands',
  it: 'Italiaans',
  iu: 'Inuktitut',
  ja: 'Japans',
  jv: 'Javaans',
  ka: 'Georgisch',
  kg: 'Kongo',
  ki: 'Kikuyu',
  kj: 'Kuanyama',
  kk: 'Kazachs',
  kl: 'Groenlands',
  km: 'Cambodjaans',
  kn: 'Kannada',
  ko: 'Koreaans',
  kr: 'Kanuri',
  ks: 'Kashmiri',
  ku: 'Koerdisch',
  kv: 'Komi',
  kw: 'Cornish',
  ky: 'Kirghiz',
  la: 'Latijns',
  lb: 'Luxemburgs',
  lg: 'Ganda',
  li: 'Limburgs',
  ln: 'Lingala',
  lo: 'Laotiaans',
  lt: 'Litouws',
  lu: 'Luba-Katanga',
  lv: 'Lets',
  mg: 'Madagaskar',
  mh: 'Marshallees',
  mi: 'Maori',
  mk: 'Macedonisch',
  ml: 'Malayalam',
  mn: 'Mongools',
  mo: 'Moldovan',
  mr: 'Marathi',
  ms: 'Maleis',
  mt: 'Maltees',
  my: 'Birmees',
  na: 'Nauruan',
  nb: 'Noors Bokmål',
  nd: 'Noord Ndebele',
  ne: 'Nepalees',
  ng: 'Ndonga',
  nl: 'Nederlands',
  nn: 'Noors Nynorsk',
  no: 'Noors',
  nr: 'Zuid Ndebele',
  nv: 'Navajo',
  ny: 'Chichewa',
  oc: 'Occitan',
  oj: 'Ojibwa',
  om: 'Oromo',
  or: 'Oriya',
  os: 'Ossetian / Ossetic',
  pa: 'Panjabi / Punjabi',
  pi: 'Pali',
  pl: 'Pools',
  ps: 'Pashto',
  pt: 'Portugees',
  qu: 'Quechua',
  rm: 'Raeto Romance',
  rn: 'Kirundi',
  ro: 'Roemeense',
  ru: 'Russisch',
  rw: 'Rwanda',
  sa: 'Sanskrit',
  sc: 'Sardinisch',
  sd: 'Sindhi',
  se: 'Noord Sami',
  sg: 'Sango',
  sh: 'Servo-Kroatisch',
  si: 'Sinhalese',
  sk: 'Slowaaks',
  sl: 'Sloveens',
  sm: 'Samoan',
  sn: 'Shona',
  so: 'Somalia',
  sq: 'Albanees',
  sr: 'Servisch',
  ss: 'Swati',
  st: 'Zuid Sotho',
  su: 'Soendanees',
  sv: 'Zweeds',
  sw: 'Swahili',
  ta: 'Tamil',
  te: 'Telugu',
  tg: 'Tajik',
  th: 'Thais',
  ti: 'Tigrinya',
  tk: 'Turkmeens',
  tl: 'Tagalog',
  tn: 'Tswana',
  to: 'Tonga',
  tr: 'Turks',
  ts: 'Tsonga',
  tt: 'Tatar',
  tw: 'Twi',
  ty: 'Tahitiaans',
  ug: 'Uyghur',
  uk: 'Oekraïens',
  ur: 'Urdu',
  uz: 'Oezbeeks',
  ve: 'Venda',
  vi: 'Vietnamees',
  vo: 'Volapük',
  wa: 'Waals',
  wo: 'Wolof',
  xh: 'Xhosa',
  yi: 'Yiddish',
  yo: 'Yoruba',
  za: 'Zhuang',
  zh: 'Chinees',
  zu: 'Zulu',
  /**
   * Extended List
   */
  'en-GB': 'Engels (VK)',
  'es-MX': 'Spaans (Mexico)',
  'pt-BR': 'Braziliaans Portugees',
  'zh-TW': 'Traditioneel Chinees',
}

export default LanguageListNL