import React from 'react'
import { t } from '../utils/i18n'

import CandidatesList from '../components/CandidatesList'
import {
	Typography,
  Container,
  CssBaseline,
  IconButton
} from '@material-ui/core'

import { Add as AddIcon } from '@material-ui/icons'

export default function Candidates() {
  const [ candidate, setCandidate ] = React.useState(false)

  return (
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
      	{t('candidates')}
        <IconButton aria-label={t('add_job')} onClick={() => setCandidate({})}> 
          <AddIcon /> 
        </IconButton>
      </Typography>
      <CandidatesList candidate={candidate} />
    </Container>
  )
}