import React from "react"
import i18n from "../translations/i18n"

export const t = (key, constainsHtml, props = {}) => {
	if (constainsHtml) return <div dangerouslySetInnerHTML={{__html: i18n.t(key, props)}} />
	return i18n.t(key, props)
}

export const getLanguage = () => {
  return i18n.language
}