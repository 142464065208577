import React, { useState } from "react";
import { Router, Switch } from 'react-router-dom';
import i18n from "i18next";
import { createBrowserHistory } from 'history';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// Context
import { useAuth } from "../context/auth";
import { UserContext } from "../context/user";

// Services
import UsersService from "../services/users.service";

// Routes
import RecruiterRoute from './recruiter';
import CandidateRoute from './candidate';
import CandidateContextRoute from './candidatecontext';
import AnonymousRoute from './anonymous';

// Pages
import Home from '../pages/Home';
import Register from '../pages/Register';
import Candidates from '../pages/Candidates';
import Candidate from '../pages/Candidate';
import Jobs from '../pages/Jobs';
import Agenda from '../pages/Agenda';
import Tasks from '../pages/Tasks';
import AddTask from '../pages/AddTask';
import Experience from '../pages/Experience';
import Diploma from '../pages/Diploma';
import Links from '../pages/Links';
import ResetPassword from '../pages/ResetPassword';
import Activation from '../pages/Activation';
import Profile from '../pages/Profile';
import JobApply from '../pages/JobApply';
import JobApplications from '../pages/JobApplications';
import Specialisations from '../pages/Specialisations';
import InterviewTemplates from '../pages/InterviewTemplates';
import Recruiters from '../pages/Recruiters';
import ScoreCards from '../pages/ScoreCards';
import Mediums from '../pages/Mediums';
import Campaigns from '../pages/Campaigns';
import CampaignJobs from '../pages/CampaignJobs';
import CompleteProfile from '../pages/CompleteProfile';
import DataStats from '../pages/DataStats';
import CompleteRegistration from '../pages/CompleteRegistration';
import CampaignJobHome from '../pages/CampaignJobHome';
import Folders from '../pages/Folders';



// App components
import Navbar from '../components/Navbar';
import PublicCandidate from "../pages/PublicCandidate";

const history = createBrowserHistory();
const styles = () => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    minWidth: 0
  },
  wrapper: {
    height: 'calc(100% - 102px)',
    paddingTop: 70,
    '& > div': {
      height: 'calc(100% + 70px)',
      maxWidth: '100%',
      backgroundColor: '#fff',
      borderRadius: '12px',
      margin: 'auto'
    }
  },
  anonymous: {
    height: 'calc(100% - 102px)',
    alignItems: 'center'
  }
});

const Routes = ({ classes, match }) => {
  const { user, logout } = useAuth();
  const [role, setRole] = useState(false);

  if (!user && role) setRole(false);
  if (user && !role) {
    UsersService.getRole().then(setRole);
  }

  if (user && user.user && user.user.language) {
    const userLang = ['nl', 'fr', 'en'].includes(user.user.language.toLowerCase()) ? user.user.language : 'en';
    if (userLang !== i18n.language.toLowerCase()) i18n.changeLanguage(userLang.toUpperCase());
  }

  if (user && window.location.pathname === '/complete-registration') {
    window.location = '/';
  }

  const parameters = new URLSearchParams(history.location.search);
  const lang = parameters.get('lang');
  if (lang) i18n.changeLanguage(lang.toUpperCase());

  const isRecruiter = () => role && role.type === 'recruiter';
  const isSuperAdmin = () => user && user.user.roles.includes('super-admin');
  const isCandidate = () => role && role.type === 'candidate';

  const isJobApplication = window.location.pathname.includes('/apply/'); 

  return (
    <Grid container className={!!user || isJobApplication ? classes.wrapper : classes.anonymous}>
      <Router history={history}>
        {!!user && <Navbar role={role} />}
        <Grid item xs={role && role.type === 'candidate' ? 9 : 12}>
          <div className={classes.root}>
            <main className={`${classes.content} route-content`}>
              {role && role.type &&
                <UserContext.Provider value={{ role, isRecruiter, isSuperAdmin, isCandidate }}>
                  <CandidateContextRoute>
                    <CandidateRoute exact path="/complete" component={CompleteProfile} />
                    <CandidateRoute exact path="/profile" component={Profile} />
                    <CandidateRoute exact path="/work" component={Experience} />
                    <CandidateRoute exact path="/education" component={Diploma} />
                    <CandidateRoute exact path="/jobapplications" component={JobApplications} />
                    <CandidateRoute exact path="/links" component={Links} />
                    <CandidateRoute exact path="/" component={Candidate} />
                    <CandidateRoute exact path="/jobs/apply/:jobId" component={JobApply} />
                    <CandidateRoute exact path="/apply/:campaignId/:jobId" component={JobApply} />
                    <CandidateRoute exact path="/public/candidate/:candidateId" component={PublicCandidate} />
                    <RecruiterRoute exact path='/candidates/:id' component={Candidate} />
                    <RecruiterRoute exact path="/agenda/:candidateId" component={Agenda} />
                  </CandidateContextRoute>
                  <RecruiterRoute exact path="/" component={Candidates} />
                  <RecruiterRoute exact path="/public/candidate/:candidateId" component={PublicCandidate} />
                  <RecruiterRoute exact path="/candidates" component={Candidates} />
                  <RecruiterRoute exact path="/jobs" component={Jobs} />
                  <RecruiterRoute exact path="/jobs/:jobId" component={Jobs} />
                  <RecruiterRoute exact path="/jobs/:jobId/applications" component={JobApplications} />
                  <RecruiterRoute exact path="/agenda" component={Agenda} />
                  <RecruiterRoute exact path="/tasks" component={Tasks} />
                  <RecruiterRoute exact path="/tasks/add" component={AddTask} />
                  <RecruiterRoute exact path="/settings/specialisations" component={Specialisations} />
                  <RecruiterRoute exact path="/settings/interviews" component={InterviewTemplates} />
                  <RecruiterRoute exact path="/settings/recruiters" component={Recruiters} />
                  <RecruiterRoute exact path="/settings/scorecards" component={ScoreCards} />
                  <RecruiterRoute exact path="/settings/mediums" component={Mediums} />
                  <RecruiterRoute exact path="/settings/:mediumId/campaigns" component={Campaigns} />
                  <RecruiterRoute exact path="/settings/:mediumId/:campaignId/jobs" component={CampaignJobs} />
                  <RecruiterRoute exact path="/settings/stats" component={DataStats} />
                  <RecruiterRoute exact path="/settings/folders" component={Folders} />
                </UserContext.Provider>
              }
              {!user &&
                <Switch>
                  <AnonymousRoute exact path="/public/candidate/:candidateId" component={PublicCandidate} />
                  <AnonymousRoute exact path="/activate/:email/:token" component={Activation} />
                  <AnonymousRoute exact path="/register/:jobId?" component={Register} />
                  <AnonymousRoute exact path="/reset-password" component={ResetPassword} />
                  <AnonymousRoute exact path="/reset-password/:email/:token" component={ResetPassword} />
                  <AnonymousRoute exact path="/jobs/apply/:jobId" component={Home} />
                  <AnonymousRoute exact path="/apply/:campaignId/:jobId" component={CampaignJobHome} />
                  <AnonymousRoute exact path="/complete-registration" component={CompleteRegistration} />
                  <AnonymousRoute exact path="/*" component={Home} />
                </Switch>
              }
            </main>
          </div>
        </Grid>
      </Router>
    </Grid>
  );
};

Routes.propTypes = { classes: PropTypes.object.isRequired };

export default withStyles(styles)(Routes);
