import { useCandidate } from "../context/candidate"

// Services
import JobsService from "../services/jobs.service"

const applyJob = async ({jobId, campaignId}, candidateId) => JobsService.apply({jobId, campaignId, candidateId})
export default function JobApply({ match: { params: { jobId, campaignId } }, location: { search } }) {
  const { candidate: { _id } } = useCandidate()

  if (! _id || ! jobId) return null
  
  const go = () => window.location = '/jobapplications'
  applyJob({jobId, campaignId}, _id).then(go).catch(go)

  return null
}