import React from 'react'
import CandidateDetails from "../components/CandidateDetails"
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Container, CssBaseline } from '@material-ui/core'

// Context
import { useUser } from '../context/user'
import { useCandidate } from "../context/candidate"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4)
  },
  candidate: {
    paddingRight: 40,
    '& > *': {
      marginTop: 20,
      marginBottom: 20,
      position: 'relative'
    }
  },
  card: {
    paddingLeft: 40,
    marginBottom: 20,
    '& > div': {
      height: '100%'
    }
  },
  back: {
    fontSize: 16,
    marginLeft: 15,
    marginBottom: 15,
    '& > span': {
      fontSize: 22,
      marginRight: 5
    }
  }
}))

export default function Profile() {
  const classes = useStyles()
  const { isRecruiter } = useUser()
  const { candidate, updateCandidate, profileIncomplete } = useCandidate()
  
  return (
    <Container component="main" className={classes.root} maxWidth='lg'>
      <CssBaseline />
      <Grid container className={classes.grid}>
        <Grid item xs={12} className={classes.candidate}>
          { candidate && candidate._id && 
            <CandidateDetails 
              candidate={candidate} 
              isRecruiter={isRecruiter} 
              edit={profileIncomplete()}
              onUpdate={(candidate, cb) => updateCandidate(candidate, cb)}
            />
          }
        </Grid>
      </Grid>
    </Container>
  )
}