import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
// import { useStyles } from "./utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    position: 'relative',
    paddingLeft: 2,
    minWidth: 250,
    marginRight: 10,
    '& label': {
	    color: '#999fae',
	    left: 1,
	    top: -7
    },
    '& div': {
    	backgroundColor: '#fff !important',
	    borderColor: theme.palette.mediaforta.light,
    	borderRadius: 17,
    	'& .MuiSelect-root': {
	    	backgroundColor: '#fff !important',
	    	padding: 10,
        paddingLeft: 14,
        color: theme.palette.mediaforta.dark,
	    },
	    '& fieldset': { borderColor: theme.palette.mediaforta.light }
    },
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

function MultiSelect({options, className, label, onChange = false, values = []}) {
  const classes = useStyles();
  const [selected, setSelected] = useState(values);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = event => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options.map(({value}) => value));
      return;
    }
    setSelected(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <FormControl className={`${classes.formControl} ${className}`} variant="outlined" >
      <InputLabel htmlFor="outlined-filter-native-simple">{label}</InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={() => options.filter(({value}) => selected.includes(value)).map(({label}) => label).join(", ")}
        MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : ""
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              color="primary"
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <ListItemIcon>
              <Checkbox color="primary" checked={selected.includes(option.value)} />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelect;
