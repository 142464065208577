// Services
import ApiService from "./api.service";

const model = "candidates";
const fileModel = "files";

export default class CandidatesService {
  static get(candidateId) {
    return ApiService.get(`${model}/${candidateId}`).then(
      (response) => response.data
    );
  }

  static post(data) {
    return ApiService.website
      .post(model, data)
      .then((response) => response.data);
  }

  static getPublic(candidateId) {
    return ApiService.website
      .get(`${model}/${candidateId}`)
      .then((response) => response.data);
  }

  static patch(candidateId, { _id, ...patch }) {
    return ApiService.patch(`${model}/${candidateId}`, patch).then(
      (response) => response
    );
  }

  static hire(candidateId, contact) {
    return ApiService.post(`${model}/${candidateId}/hire`, contact).then(
      (response) => response
    );
  }

  static delete(candidateId) {
    return ApiService.delete(`${model}/${candidateId}`).then(
      (response) => response
    );
  }

  static list(
    search = false,
    limit = 10,
    skip = 0,
    fields = "",
    filter = false,
    sort = "createdAt"
  ) {
    if (search && search !== "")
      search = `{"$or":[{"name": "regex:${search}"},{"firstname":"regex:${search}"}]}`;
    return ApiService.list(model, {
      search,
      filter,
      limit,
      skip,
      fields,
      sort,
    }).then((response) => response.data);
  }

  static registrations(filter = false) {
    return ApiService.list(`${model}/registrations`, { filter }).then(
      (response) => response.data
    );
  }

  // Files
  static upload(candidateId, files = [], avatar = false) {
    if (!files.length) return;

    let formData = new FormData();
    if (files.length > 1)
      files.forEach((file) => formData.append("files", file));
    else formData.append("file", files[0]);

    formData.append("candidateId", candidateId);

    const path = avatar ? "avatar" : files.length > 1 ? "files" : "file";
    return ApiService.post(`${model}/${candidateId}/${path}`, formData, {
      "Content-Type": "multipart/form-data",
    }).then((response) => response);
  }

  static uploadPortfolio(candidateId, files = []) {
    if (!files.length) return;

    let formData = new FormData();
    if (files.length > 1)
      files.forEach((file) => formData.append("files", file));
    else formData.append("file", files[0]);

    formData.append("candidateId", candidateId);

    const path = files.length > 1 ? "files" : "file";
    return ApiService.post(`${model}/${candidateId}/${path}/portfolio`, formData, {
      "Content-Type": "multipart/form-data",
    }).then((response) => response);
  }

  static files(candidateId) {
    return ApiService.get(
      fileModel,
      `filter={"candidateId":"${candidateId}","hidden":false}`
    ).then((response) => response.data);
  }

  static portfolio(candidateId) {
    return ApiService.get(
      fileModel,
      `filter={"candidateId":"${candidateId}","hidden":false, "portfolio":true}`
    ).then((response) => response.data);
  }

  // Experiences
  static listExperience(candidateId) {
    return ApiService.get(
      `${model}/experience`,
      `filter={"candidateId":"${candidateId}"}`
    ).then((response) => response.data);
  }

  static patchExperience(experienceId, { _id, ...patch }) {
    return ApiService.patch(`${model}/experience/${experienceId}`, patch).then(
      (response) => response
    );
  }

  static postExperience(data) {
    return ApiService.post(`${model}/experience`, data).then(
      (response) => response
    );
  }

  static deleteExperience(experienceId) {
    return ApiService.delete(`${model}/experience/${experienceId}`).then(
      (response) => response
    );
  }

  // Diplomas
  static listDiplomas(candidateId) {
    return ApiService.get(
      `${model}/diplomas`,
      `filter={"candidateId":"${candidateId}"}`
    ).then((response) => response.data);
  }

  static patchDiploma(diplomaId, { _id, ...patch }) {
    return ApiService.patch(`${model}/diplomas/${diplomaId}`, patch).then(
      (response) => response
    );
  }

  static postDiploma(data) {
    return ApiService.post(`${model}/diplomas`, data).then(
      (response) => response
    );
  }

  static deleteDiploma(diplomaId) {
    return ApiService.delete(`${model}/diplomas/${diplomaId}`).then(
      (response) => response
    );
  }

  // Folders
  static listFolders() {
    return ApiService.get(`${model}/folders`).then((response) => response.data);
  }

  static postFolder(data) {
    return ApiService.post(`${model}/folders`, data).then(
      (response) => response.data
    );
  }

  static addCandidatesToFolders(data) {
    return ApiService.post(`${model}/folders/candidates`, data).then(
      (response) => response.data
    );
  }

  static removeCandidateFromFolder(folderId, candidateId) {
    return ApiService.delete(
      `${model}/folders/${folderId}/${candidateId}`
    ).then((response) => response);
  }

  static removeFolder(folderId) {
    return ApiService.delete(`${model}/folders/${folderId}`).then(
      (response) => response
    );
  }

  // Sources
  static listSources() {
    return ApiService.get(`${model}/sources`).then((response) => response.data);
  }

  // Tags
  static listTags() {
    return ApiService.list(`${model}/tags`, { limit: 400 }).then(
      (response) => response.data
    );
  }

  // ScoreCards
  static listScoreCards() {
    return ApiService.list(`${model}/scorecards`, { limit: 100 }).then(
      (response) => response.data
    );
  }

  static addScoreCards(candidateId, scoreCards = []) {
    return ApiService.post(
      `${model}/${candidateId}/scorecards`,
      scoreCards
    ).then((response) => response.data);
  }

  static updateScoreCards(candidateId, scoreCards = []) {
    return ApiService.patch(
      `${model}/${candidateId}/scorecards`,
      scoreCards
    ).then((response) => response.data);
  }
}
