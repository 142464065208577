import React from "react";
import { t } from "../utils/i18n";
import { makeStyles } from "@material-ui/core/styles";
import { Delete as DeleteIcon, Check as CheckIcon } from "@material-ui/icons";
import {
  Box,
  TextField,
  IconButton,
  Button,
  MenuList,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

// Context
import { useApp } from "../context/app";

// Services
import ScoreCardsService from "../services/scorecards.service";

const border = "1px solid #dadff3";
const useStyles = makeStyles(() => ({
  root: {
    "& label": {
      color: "#606060",
      fontSize: 20,
      display: "block",
    },
  },
  scoreCard: {
    minWidth: "50%",
    marginLeft: 5,
    marginBottom: 10,
    "& input": {
      fontWeight: 500,
    },
  },
  categoryWrapper: {
    flexWrap: "wrap",
    marginBottom: 20,
    "& > *": {
      flex: 1,
      minWidth: "25%",
      maxWidth: "25%",
      border: "5px solid transparent",
      borderBottom: "50px solid transparent",
    },
  },
  addWrapper: { padding: "10px 10px 5px !important" },
  categoryLabel: {
    width: "calc(100% - 90px)",
    marginBottom: 10,
  },
  addItem: {
    width: "calc(100% - 50px)",
    marginRight: 4,
    "& input": {
      backgroundColor: "#fff",
      padding: 10,
    },
  },
  items: {
    flexDirection: "column",
    marginTop: 5,
    borderLeft: border,
    borderBottom: border,
    borderRight: border,
    borderRadius: 5,
    backgroundColor: "#fafafa",
    "& > label": {
      fontSize: 16,
      fontWeight: 400,
      borderTop: border,
      padding: "15px 10px",
    },
  },
  chooseItem: {
    backgroundColor: "#ebebeb",
    fontWeight: "500 !important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ddd",
    },
  },
  button: {
    float: "right",
    minWidth: 200,
    minHeight: 40,
  },
  addCategory: {
    float: "left",
    minWidth: 200,
    minHeight: 40,
    backgroundColor: "#606060",
  },
  overlayWrapper: {
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, .4)",
    height: "calc(100% - 53px)",
    position: "absolute",
    left: 0,
    zIndex: 9,
    bottom: 0,
    "& > *": {
      width: "fit-content",
      minWidth: "50%",
      height: "fit-content",
      maxHeight: "90%",
      backgroundColor: "#fff",
      margin: "auto",
      top: 0,
      bottom: 0,
      position: "absolute",
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      "& > label": {
        background: "#606060",
        color: "#fff",
        marginBottom: 10,
        padding: "12px !important",
      },
      "& > div": { margin: 10 },
      "& ul": {
        height: "calc(100% - 115px)",
        overflow: "auto",
        borderTop: "1px solid #ccc",
        paddingTop: 0,
        marginTop: 10,
        marginBottom: 70,
        "& li": {
          borderBottom: "1px solid #ccc",
        },
      },
    },
  },
  search: {
    width: "95%",
    margin: "auto",
  },
  actions: {
    position: "absolute",
    bottom: 0,
    right: 0,
    "& > *": {
      width: 200,
      margin: 15,
      fontSize: 16,
      height: 40,
      lineHeight: "45px",
      textAlign: "right",
    },
  },
}));

const categoryItemFilter = createFilterOptions();
const addScoreCardCategoryItem = async (categoryId, item) =>
  ScoreCardsService.addCategoryItem(categoryId, item);
const CategoryItemSelector = ({ category, cancel, update }) => {
  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(true);
  const [items, setItems] = React.useState(category.items);
  const [scoreCardCategoryItem, setScoreCardCategoryItem] =
    React.useState(false);

  const mapItems = (items) =>
    items.map((item) => ({ ...item, label: item.label, value: item._id }));
  const isChecked = (id) =>
    category.selectedItems.map((i) => i._id).includes(id);

  const toggleItem = (id) => {
    if (isChecked(id)) {
      category.selectedItems = category.selectedItems.filter(
        (item) => item._id !== id
      );
    } else {
      category.selectedItems.push(
        category.items.find((item) => item._id === id)
      );
    }
    setRefresh(Math.random());
  };

  const updateItems = (category) => {
    if (
      !!scoreCardCategoryItem &&
      !search(scoreCardCategoryItem, true).length
    ) {
      addScoreCardCategoryItem(category._id, scoreCardCategoryItem).then(
        ({ data }) => {
          category.selectedItems.push(data);
          category.items.push(data);
          update(category);
        }
      );
    } else {
      update(category);
    }
  };

  const search = (search, exact = false) =>
    category.items.filter((item) => {
      return !exact
        ? item.label.toLowerCase().includes(search.toLowerCase())
        : item.label.toLowerCase() === search.toLowerCase();
    });

  return (
    <Box className={classes.overlayWrapper}>
      <Box>
        <label className={classes.addWrapper}>
          {`${t("manage")} ${category.label}`}
        </label>

        <Autocomplete
          name="categoryItemId"
          id="categoryItemId"
          options={items.map((i) => i.label)}
          margin="normal"
          className={classes.search}
          filterOptions={(options, params) => {
            const filtered = categoryItemFilter(options, params);
            if (params.inputValue !== "") filtered.push(params.inputValue);
            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="item"
              type="text"
              placeholder={t("search_or_add")}
              variant="outlined"
              onChange={({ currentTarget }) =>
                setItems(search(currentTarget.value))
              }
              onBlur={(e) => setScoreCardCategoryItem(e.currentTarget.value)}
            />
          )}
        />
        <MenuList>
          {!!refresh &&
            mapItems(items).map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                onClick={() => toggleItem(option.value)}
              >
                <ListItemIcon>
                  <Checkbox color="primary" checked={isChecked(option.value)} />
                </ListItemIcon>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
        </MenuList>
        <Box display="flex" className={classes.actions}>
          <a href="#" onClick={cancel}>
            {" "}
            {t("cancel")}{" "}
          </a>
          <Button onClick={() => updateItems(category)}> {t("save")} </Button>
        </Box>
      </Box>
    </Box>
  );
};

const categoryFilter = createFilterOptions();
const fetchScoreCardCategories = async () => ScoreCardsService.listCategories();
const addScoreCardCategory = async (category) =>
  ScoreCardsService.addCategory(category);
const patchCategory = async (categoryId, patch) =>
  ScoreCardsService.patchCategory(categoryId, patch);
const patchCardItemWeight = async (id, itemId, weight) =>
  ScoreCardsService.patchCardItemWeight(id, itemId, weight);

export default function ScoreCardModal({ scoreCard, update }) {
  const classes = useStyles();

  const { setResponseDialog } = useApp();
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [addCategoryModal, setAddCategoryModal] = React.useState(false);
  const [scoreCardCategories, setScoreCardCategories] = React.useState([]);
  const [scoreCardCategory, setScoreCardCategory] = React.useState(null);
  const [selectedCategoryLabel, setSelectedCategoryLabel] =
    React.useState(null);

  React.useEffect(() => {
    fetchScoreCardCategories().then(({ records }) => {
      setScoreCardCategories(records);
    });
    // eslint-disable-next-line
  }, []);

  const updateScoreCardCategory = (category) => {
    scoreCard.selectedCategories.map((cat) => {
      if (cat._id === category._id) return category;
      return cat;
    });
    setSelectedCategory(null);
  };

  const removeCategory = (_id) => {
    setResponseDialog({
      title: t("are_you_sure"),
      message: t("remove_category"),
      onConfirm: () => {
        scoreCard.selectedCategories = scoreCard.selectedCategories.filter(
          (category) => category._id !== _id
        );
      },
    });
  };

  const submitScoreCardCategories = () => {
    scoreCard.selectedCategories.map(({ _id, selectedItems }) => ({
      _id,
      items: selectedItems.map((i) => i._id),
    }));
    update(scoreCard);
  };

  const addCategory = () => {
    const category = scoreCardCategories.find(
      (c) => c.label === scoreCardCategory
    );
    if (category) {
      category.selectedItems = [];
      scoreCard.selectedCategories.push(category);
      setScoreCardCategory(null);
      setAddCategoryModal(false);
    } else {
      const newCategory = {
        label: scoreCardCategory,
        items: [],
      };
      addScoreCardCategory(newCategory).then(({ data }) => {
        scoreCard.selectedCategories.push({
          ...newCategory,
          _id: data._id,
          selectedItems: [],
        });
        setScoreCardCategory(null);
        setAddCategoryModal(false);
      });
    }
  };

  const updateCardLabel = (label) => (scoreCard.label = label);
  const updateCategoryLabel = (categoryId, label) => {
    scoreCard.selectedCategories = scoreCard.selectedCategories.map((cat) => {
      if (cat._id === categoryId) {
        cat.label = label;
      }
      return cat;
    });
  };

  const updateCategory = (_id, category) => {
    const { selectedItems, currentIndex, ...patch } = category;
    patchCategory(_id, patch);
  };

  const setScoreCardItemWeight = (_id, itemId, weight) => {
    patchCardItemWeight(_id, itemId, weight);
  };

  return (
    <Box className={classes.root} style={{ overflow: "auto" }}>
      <TextField
        className={classes.scoreCard}
        defaultValue={scoreCard.label}
        name="label"
        placeholder="label"
        type="text"
        variant="outlined"
        onChange={({ currentTarget }) => updateCardLabel(currentTarget.value)}
      />
      <Box display="flex" className={classes.categoryWrapper}>
        {scoreCard.selectedCategories.map((category) => {
          return (
            <Box key={category._id}>
              <TextField
                className={classes.categoryLabel}
                defaultValue={category.label}
                name="categorylabel"
                placeholder="label"
                type="text"
                variant="outlined"
                onFocus={() => setSelectedCategoryLabel(category._id)}
                onChange={({ currentTarget }) =>
                  updateCategoryLabel(category._id, currentTarget.value)
                }
              />
              <IconButton onClick={() => removeCategory(category._id)}>
                {" "}
                <DeleteIcon />{" "}
              </IconButton>
              {selectedCategoryLabel &&
                selectedCategoryLabel === category._id && (
                  <IconButton
                    onClick={() => updateCategory(category._id, category)}
                  >
                    {" "}
                    <CheckIcon />{" "}
                  </IconButton>
                )}
              <Box display="flex" className={classes.items}>
                {!!category.selectedItems &&
                  category.selectedItems.map((item) => {
                    return (
                      <>
                        <label key={item._id}>{item.label}</label>
                        <TextField
                          name="weight"
                          type="number"
                          placeholder="weight"
                          variant="outlined"
                          defaultValue={item.weight}
                          style={{
                            background: "#fff",
                            margin: "0px 10px 10px",
                          }}
                          onBlur={(e) =>
                            setScoreCardItemWeight(
                              category._id,
                              item._id,
                              e.currentTarget.value
                            )
                          }
                        />
                      </>
                    );
                  })}
                <label
                  className={classes.chooseItem}
                  onClick={() => setSelectedCategory(category)}
                >
                  {t("manage_items")}
                </label>
              </Box>
            </Box>
          );
        })}
      </Box>
      {!!selectedCategory && (
        <CategoryItemSelector
          category={selectedCategory}
          update={updateScoreCardCategory}
          cancel={() => setSelectedCategory(null)}
        />
      )}
      {!!addCategoryModal && (
        <Box className={classes.overlayWrapper}>
          <Box>
            <label> {t("add_category")} </label>
            <Autocomplete
              name="categoryId"
              id="categoryId"
              options={scoreCardCategories
                .filter(
                  (r) =>
                    !scoreCard.selectedCategories
                      .map((c) => c._id)
                      .includes(r._id)
                )
                .map((c) => c.label)}
              margin="normal"
              value={scoreCardCategory}
              filterOptions={(options, params) => {
                const filtered = categoryFilter(options, params);
                if (params.inputValue !== "") filtered.push(params.inputValue);
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="category"
                  type="text"
                  variant="outlined"
                  onBlur={(e) => setScoreCardCategory(e.currentTarget.value)}
                />
              )}
            />
            <Box
              display="flex"
              className={classes.actions}
              style={{ position: "relative", margin: "10px 0 0 auto" }}
            >
              <a href="#" onClick={() => setAddCategoryModal(false)}>
                {" "}
                {t("cancel")}{" "}
              </a>
              <Button onClick={() => addCategory()}> {t("add")} </Button>
            </Box>
          </Box>
        </Box>
      )}
      <Button
        onClick={() => setAddCategoryModal(true)}
        className={classes.addCategory}
      >
        + {t("add_category")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={submitScoreCardCategories}
      >
        {t("save")}
      </Button>
    </Box>
  );
}
