import React from 'react'
import { t } from '../utils/i18n'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { TextField } from 'formik-material-ui'
import { Box, Button, Typography } from '@material-ui/core'

// Services
import CandidatesService from '../services/candidates.service'

const FolderSchema = Yup.object().shape({
	name: Yup.string().required(t('required'))
})

export default function FolderForm({onClose, refresh}) {
  return <Formik
    initialValues={{ name: '' }}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={FolderSchema}
    onSubmit={(values, { setSubmitting }) => {
      CandidatesService.postFolder({...values}).then(({data = {}}) => onClose(data._id))
      setSubmitting(false)
    }}
  >
    {({ submitForm, isSubmitting }) => 
    {
      return (
        <Form>
          <Box margin={1}>
            <Typography variant="h5"> {t('name')} </Typography>
            <Field
              component={TextField}
              fullWidth
              style={{marginBottom: 20}}
              variant="outlined"
              name="name"
              type="text"
              label={t('name')} />
          </Box>
          <Box margin={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              { t('save') }
            </Button>
          </Box>
        </Form>
    )}}
  </Formik>
}
