import React from 'react';
import { t } from '../utils/i18n';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  ThemeProvider,
  IconButton,
  Box
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';


// themes
import tableThema from '../themes/table';

// Context
import { useApp } from "../context/app";

// Services
import JobsService from '../services/jobs.service';


import CampaignCard from "./CampaignCard";
import CampaignDetailsOverview from "./CampaignDetailsOverview";

const useStyles = makeStyles(theme => ({
  tab: { width: 400 },
  category: {
    cursor: 'pointer',
    display: 'flex',
    padding: '30px 20px 0',
    '& h5': {
      flex: 1,
      '& svg': {
        background: theme.palette.mediaforta.main,
        borderRadius: '50%',
        fontSize: 20,
        fill: '#fff'
      }
    }
  },
  row: {
    '& th, & td': {
      paddingTop: 15,
      paddingBottom: 15
    },
  },
  actions: {
    padding: 0,
    margin: '0 0 0 10px',
    '& svg': { fontSize: 20 }
  }
}));

export default function CampaignJobList({ jobs = [], match }) {
  const { campaignId } = match.params;
  const classes = useStyles();
  return <React.Fragment>
    <Box display="flex">
      <div style={{ flex: 4 }}>
        <ThemeProvider theme={tableThema}>
          <TableContainer className={classes.tablecontainer}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Client</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Job Apply URL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!jobs.length ?
                  <TableRow>
                    <TableCell>{t('no jobs')}</TableCell>
                  </TableRow>
                  :
                  jobs.map((job, index) => {
                    return <TableRow key={index} className={classes.row} >
                      <TableCell>
                        <b>{job.client}</b>
                      </TableCell>
                      <TableCell>
                        {job.title}
                      </TableCell>
                      <TableCell>
                        <a href={`${window.location.origin}/apply/${campaignId}/${job._id}`} target='_blank'>
                          {window.location.origin}/apply/{campaignId}/{job._id}
                        </a>
                      </TableCell>
                    </TableRow>;
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </ThemeProvider>
      </div>
    </Box>
  </React.Fragment>;
}