import React from 'react'
import { Route } from 'react-router-dom'

// Context
import { useUser } from "../context/user"

function RecruiterRoute({ component: Component, ...rest }) {
  const { role } = useUser()
  if (! role || role.type !== 'recruiter') return null
  return (
    <Route {...rest} render={props => (<Component {...props} />) }/>
  )
}

export default RecruiterRoute