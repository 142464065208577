import React from 'react'
import { t } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Container, CssBaseline, IconButton, Grid, Box, FormControl, InputLabel, TextField } from '@material-ui/core'
import { Add as AddIcon, Edit as EditIcon, Check as CheckIcon } from '@material-ui/icons'

// Context
import { useCandidate } from '../context/candidate'

// Components
import CandidateLinks from "../components/CandidateLinks"
import CandidateLinkForm from "../components/CandidateLinkForm"
import MediaFortaDialog from "../components/MediaFortaDialog"


const useStyles = makeStyles(() => ({
  link: {
    '& .MuiLink-root, & .MuiGrid-item': {
      minWidth: '25%',
      flex: 'initial'
    }
  },
  formControl: {
    width: 300,
    marginBottom: 20,
    marginLeft: 5
  }
}))

export default function Links() {
  const classes = useStyles()
  const { candidate, updateCandidate } = useCandidate()
  const [ edit, setEdit ] = React.useState(false)
  const [ formDialog, setFormDialog ] = React.useState(false)

  const [ personalWebsite, setPersonalWebsite ] = React.useState(
    !! candidate && candidate.links && candidate.links.website ? candidate.links.website : ''
  )
  
  const { website, ...candidateLinks} = candidate.links || {}

  return (
    candidate &&
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
      	Links
        <IconButton aria-label={t('add_link')} 
          onClick={() => setFormDialog({
            title: t('add_link'), 
            onClose: () => setFormDialog(false), 
            component: { Component: CandidateLinkForm, data: candidate }
          })}
        >
          <AddIcon />
        </IconButton>
        {
            candidateLinks && Object.values(candidateLinks).find(ref => !! ref) && 
            <IconButton aria-label={t('add_link')} 
              onClick={() => {
                if (edit) { updateCandidate(candidate) }
                setEdit(! edit)
              }}
            >
              { edit ? <CheckIcon /> : <EditIcon /> }
            </IconButton> 
          }
      </Typography>
      <Box display="flex" className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="website"> {t('personal_website')} </InputLabel>
          <TextField
            value={personalWebsite}
            name='website'
            margin="normal"
            placeholder={t('fill_personal_website')}
            onChange={event => setPersonalWebsite(event.currentTarget.value)}
            onBlur={() => {
              candidate.links = candidate.links || {}
              candidate.links.website = personalWebsite 
              updateCandidate(candidate)
            }}
          />
        </FormControl>
      </Box>
      <Grid container>
        <Grid item xs={12} className={classes.link}>
          <CandidateLinks candidate={candidate} edit={edit}/>  
        </Grid>
      </Grid>
      {formDialog && <MediaFortaDialog data={formDialog} candidate={candidate} edit={true} />}
    </Container>
  )
}