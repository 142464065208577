import * as React from 'react';
import MomentUtils from '@date-io/moment';
import { Field } from 'formik'
import { DateTimePicker, DatePicker as DateOnlyPicker, MuiPickersUtilsProvider } from "@material-ui/pickers"

// assets
import '../assets/scss/datepicker.scss'

export default function DatePicker({disableTime, views = ['date'], ...props}) {
	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
     	 <Field
     	 		{ ...props }
          component={disableTime ? DateOnlyPicker : DateTimePicker}
          views={views}
          className={props.variant !== 'standard' ? "DatePicker" : ''} 
        />
    </MuiPickersUtilsProvider>
  )
}