import React from 'react'
import { useHistory } from "react-router-dom"
import { Link as MuiLink } from '@material-ui/core'

const Link = React.forwardRef(({children, href, onClick, ...props}, ref) => {
  const history = useHistory()
  return (
    <MuiLink href="#" onClick={event => {
      event.preventDefault()
      history.push(href)
    }} ref={ref} {...props}> {children} </MuiLink>
  )
});

export default Link