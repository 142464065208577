import React from 'react'
import { t } from '../utils/i18n'
import { useHistory } from "react-router-dom"
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { TextField } from 'formik-material-ui'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid } from '@material-ui/core'

// Services
import UsersService from "../services/users.service"

// Components
import Alert from "./Alert"

const useStyles = makeStyles(theme => ({ form: { textAlign: 'center' } }))

const ResetSchema = Yup.object().shape({
  password: Yup.string()
    .required(t('required')),
  confirmpassword: Yup.string()
    .required(t('required'))
})

export default function ResetPasswordForm({email, token}) {
  const classes = useStyles()
  const history = useHistory()
  const [ error, setError ] = React.useState(false)
  
  const reset = ({password}, { setSubmitting }) => {
    setError(false)
    UsersService.resetPassword({email, token, password}).then(() => {
      history.push('/')
      setSubmitting(false)
    }).catch(() => {
      setError(true)
      setSubmitting(false)
    })    
  }

  return (
   <Formik
    initialValues={{ password: '', confirmpassword: '' }}
    validationSchema={ ResetSchema }
    onSubmit={ reset }
    validateOnChange={true}
	  validateOnBlur={false}
    validate={({password, confirmpassword}) => {
      const errors =  {}
      if (password !== confirmpassword) { errors.confirmpassword = t('passwords_no_match') }
      return Object.keys(errors).length ? errors : false
    }}
   >
   {({ submitForm, isSubmitting }) => (
     <Form className={classes.form}>
      { error && <Alert severity="error">{error.message}</Alert> }
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Field
            component={TextField}
            fullWidth
            style={{marginBottom: 20}}
            variant="outlined"
            name="password"
            type="password"
            label={t("password")}
            InputProps={{ autoComplete: 'new-password' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            fullWidth
            style={{marginBottom: 20}}
            variant="outlined"
            name="confirmpassword"
            type="password"
            label={t("confirm_password")}
            InputProps={{ autoComplete: 'new-password' }}
          />
        </Grid>
      </Grid>
        <br />
       <Button
          variant="contained"
          color="primary"
          style={{backgroundColor: '#0df394', color: '#041e7a', marginRight: 20}}
          size='large'
          disabled={isSubmitting}
          onClick={submitForm}
        >
          {t("save_password")}
        </Button>
     </Form>
    )}
   </Formik>
  )
}