import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, CssBaseline } from '@material-ui/core';

// Components
import Resume from "../components/Resume/Resume";
import CandidatesService from "../services/candidates.service";

const useStyles = makeStyles(theme => ({
  root: { 
    // marginTop: theme.spacing(4) 
    maxWidth: '80% !important',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  candidate: {
    width: '100%',
    paddingRight: 40,
    position: 'relative',
    '& > *': {
      marginTop: 20,
      marginBottom: 20,
      position: 'relative'
    }
  },
  card: {
    paddingLeft: 40,
    marginBottom: 20,
    '& > div': {
      height: '100%'
    }
  },
  back: {
    fontSize: 16,
    marginLeft: 15,
    marginBottom: 15,
    '& > span': {
      fontSize: 22,
      marginRight: 5
    }
  }
}));

export const fetchCandidate = async id => CandidatesService.getPublic(id);
export default function PublicCandidate({ match }) {
  const classes = useStyles();
  const [candidate, setCandidate] = React.useState(null);
  const { candidateId } = match.params;


  React.useEffect(() => {
    if (!candidate && candidateId) {
      fetchCandidate(candidateId).then(({ candidate }) => setCandidate(candidate));
    }
  }, [candidate]);

  return (
    <Container component="main" className={`${classes.root} resume_wrapper`} maxWidth='lg'>
      <CssBaseline />
      <Grid container className={classes.grid}>
        <Grid item className={classes.candidate}>
          {candidate && candidate.publicEnabled ?
            <Resume
              candidate={candidate}
            />
            :
            <Grid style={{textAlign: 'center'}}>Not found</Grid>
          }
        </Grid>
      </Grid>
    </Container>
  );
}