import axios from "axios"
import config from '../config'
import ApiService from "./api.service"

const { USER_URL, API_URL } = config.mediaforta 

export default class UsersService {
  static post(user) {
    return axios.post(`${USER_URL}`, user).then(response => response.data)
  }

  static delete({why, keepData}) {
    const Authorization = ApiService.getAuthorization();
    return axios.delete(`${USER_URL}?keepData=${keepData}&why=${why}`, { headers: { Authorization, Prefer: 'count=exact' }}).then(response => response.data)
  }

  static validateUser(user) {
    return axios.post(`${USER_URL}validate`, user).then(response => response.data)
  }

  static resendToken(email) {
    return axios.post(`${USER_URL}resendToken/${email}`).then(response => response.data)
  }

  static sendResetPassword(email) {
    return axios.post(`${USER_URL}resetPassword/${email}`).then(response => response.data)
  }

  static resetPassword({email, token, password}) {
    return axios.post(`${USER_URL}resetPassword`, {email, token, password}).then(response => response.data)
  }

  static validate(email, token) {
    return axios.post(`${USER_URL}validate/${email}/${token}`).then(response => response.data)
  }

  static getRole() {
    const Authorization = ApiService.getAuthorization()
    return axios.get(`${USER_URL}role`, { headers: { Authorization, Prefer: 'count=exact' }}).then(response => response.data || {} )
  }

  static listUserFunctions() {
    return axios.get(`${API_URL}userfunctions`).then(response => response.data)
  }

  static listRecruiters() {
    const Authorization = ApiService.getAuthorization()
    return axios.get(`${USER_URL}recruiters`, { headers: { Authorization, Prefer: 'count=exact' }}).then(response => response.data)
  }

  static patchRole(patch) {
    const Authorization = ApiService.getAuthorization()
    return axios.patch(`${USER_URL}role`, patch, { headers: { Authorization, Prefer: 'count=exact' }}).then(response => response.data)
  }

  static deleteRole(userId, role) {
    const Authorization = ApiService.getAuthorization()
    return axios.delete(`${USER_URL}role?userId=${userId}&role=${role}`, { headers: { Authorization, Prefer: 'count=exact' }}).then(response => response.data)
  }

  static generateQr(userId) {
    return axios.post(`${USER_URL}generate/qr/${userId}`).then(response => response.data)
  }
}