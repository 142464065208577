import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_NL } from "./nl/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_FR } from "./fr/translations";
 
i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     NL: { translation: TRANSLATIONS_NL },
     EN: { translation: TRANSLATIONS_EN },
     FR: { translation: TRANSLATIONS_FR }
   },
   lng: "NL"
 });


export default i18n