import React from 'react'
import { t } from '../utils/i18n'
import moment from 'moment'
import { useHistory } from "react-router-dom"
import { debounce } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'
import {
	TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TablePagination,
  Link,
  ThemeProvider
} from '@material-ui/core'

// Context
import { useApp } from "../context/app"

// Services
import JobsService from "../services/jobs.service"

// Components
import SearchFilter from "../components/SearchFilter"

// themes
import tableThema from '../themes/table'

const useStyles = makeStyles(() => ({
  candidates: {
    textAlign: 'center',
    width: 175,
  },
  actions: {
    width: 80,
    '& svg': {
      width: 20,
      margin: '0 5px'
    }
  },
  row: {
  	cursor: 'pointer',
  	'& th, & td': {
  		paddingTop: 15,
  		paddingBottom: 15
  	},
  	'& th label, & td label': { cursor: 'pointer' },
  }
}))

const dateFormat = 'DD.MM.YYYY'
const renderStatus = (job, today) => {
	const { from, to } = job
	switch (true) {
		case today.isAfter(moment(to)):
			return t('finished')
		case today.isBefore(moment(from)):
			return t('future')
		case today.isAfter(moment(from)) && today.isSameOrBefore(moment(to)):
			return t('ongoing')
		default:
			return ''
	}
}

export const fetchJobs = async (search, limit, offset, fields, filter) => JobsService.list(search, limit, offset, fields, filter)
export default function JobsList({ job, setJob, removeJob, data: { mini = false, onClick = false } = {} }) {
	const classes = useStyles()
	const history = useHistory()
	const { setResponseDialog } = useApp()
	const today = moment(new Date(), dateFormat)

	const filters = mini ? false : [
		{ label: t('none'), value: '' },
		{ label: t('future'), value: `from>=${today.format('YYYY-MM-DD')}` },
		{ label: t('finished'), value: `to<${today.format('YYYY-MM-DD')}` },
		{ label: t('ongoing'), value: `from<=${today.format('YYYY-MM-DD')}&to>=${today.format('YYYY-MM-DD')}` }
	]

	const [ jobs, setJobs ] = React.useState({})
	const [ removedJob, setRemovedJob ] = React.useState(0)
  const [ filter, setFilter ] = React.useState('')
  const [ searchInput, setSearchInput ] = React.useState(false)
  const [ page, setPage ] = React.useState(0)
  const [ rowsPerPage, setRowsPerPage ] = React.useState(10)
  
  const setError = (error) => {
    setJobs({})
    setResponseDialog({error})
  }

  React.useEffect(() => {
		if (! job) {
			fetchJobs(searchInput, rowsPerPage, page*rowsPerPage, false, filter)
				.then(setJobs)
				.catch(setError)
		}
    // eslint-disable-next-line
  }, [ searchInput, rowsPerPage, page, filter, job, removedJob ])


  const search = event => debounceSearch(event.target.value)
  const debounceSearch = debounce(q => {
  	setSearchInput(q)
  	setPage(0)
  }, 300);

	const handleChangePage = (event, newPage) => setPage(parseInt(newPage))
	const handleChangeRowsPerPage = (event) => {
		const rows = parseInt(event.target.value, 10)
		setRowsPerPage(rows)
    setPage(0)
  }

  return ( 
  	<React.Fragment>
			{
				(searchInput || !! filter || (jobs.records && !! jobs.records.length)) &&
				<SearchFilter 
					onChange={search} 
					value={searchInput} 
					placeholder={t("search_jobs")}
					filterLabel={t("filter_status")}
					filter={filter}
					filters={filters}
					setFilter={setFilter}
				/>
			}
  		<ThemeProvider theme={ tableThema }>
        <TableContainer className={classes.tablecontainer}>
  	    	{ jobs.records && !! jobs.records.length && 
  		    		<React.Fragment>
  						  <Table aria-label="simple table">
  						    <TableHead>
  						      <TableRow>
  						        <TableCell>Customer</TableCell>
  						        <TableCell>Title</TableCell>
  						        <TableCell>Duration</TableCell>
  						        <TableCell>Status</TableCell>
											<TableCell>Candidates</TableCell>
											{! mini &&
												<>
													<TableCell></TableCell>
													<TableCell></TableCell>
												</>
											}
  						      </TableRow>
  						    </TableHead>
  						    <TableBody>
						      {jobs.records && jobs.records.map(job => (
						        <TableRow key={job._id} className={classes.row} onClick={() => onClick ? onClick(job) : history.push(`/jobs/${job._id}`)}>
						          <TableCell scope="row" component='th'> {job.client} </TableCell>
						          <TableCell>{job.title}</TableCell>
						          <TableCell>
						          	{job.from && moment(job.from).format(dateFormat)}--
						          	{job.to && moment(job.to).format(dateFormat)} 
						          </TableCell>
											<TableCell>{renderStatus(job, today)}</TableCell>
						          <TableCell>{job.jobapplications.length}</TableCell>
											{	! mini && 
												<>
													<TableCell className={classes.candidates}>
														<Link onClick={
															event => {
																event.stopPropagation()
																history.push(`/jobs/${job._id}/applications`)
															}
														}>{t('see_candidates')}</Link>
													</TableCell>
													<TableCell className={classes.actions}>
														<EditIcon onClick={event => {
															event.stopPropagation()
															setJob(job)
														}}/>
														<DeleteIcon onClick={event => {
															event.stopPropagation()
															removeJob(job, () => setRemovedJob(removedJob + 1))
														}}/>
													</TableCell>
												</>
											}
						        </TableRow>
						      ))}
						    </TableBody>
						  </Table>
					    <TablePagination
			          rowsPerPageOptions={[5, 10, 25]}
			          component="div"
			          count={jobs.totalCount || 0}
			          rowsPerPage={rowsPerPage}
			          page={page}
			          onChangePage={handleChangePage}
			          onChangeRowsPerPage={handleChangeRowsPerPage}
  			       />
  		      </React.Fragment>
  		    }
  			</TableContainer>  
      </ThemeProvider>
		</React.Fragment>    
  )
}