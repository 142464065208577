import React from 'react';
import { useHistory, Redirect } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline } from '@material-ui/core';

// Context
import { useAuth } from "../context/auth";
import { useCandidate } from "../context/candidate";

// components 
import Link from '../components/Link';
import CandidateDetails from '../components/RegistrationFlow/CandidateDetails';
import CandidateProfessional from '../components/RegistrationFlow/CandidateProfessional';
import CandidateCompleted from '../components/RegistrationFlow/CandidateCompleted';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  logo: {
    width: 90,
    height: 90,
    maxWidth: 90,
    marginTop: 20,
    marginBottom: 20,
    backgroundImage: 'url(/mediaforta-logo.svg)',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat'
  }
}));

export default function CompleteProfile() {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuth();
  const { candidate, updateCandidate } = useCandidate();

  const { step = 1, candidate: state } = history.location.state || {};

  if (!user) return <Redirect to='/' />;

  const renderRegisterSection = () => {
    switch (step) {
      case 1:
        return <CandidateDetails candidate={{ ...candidate, ...state }} />;
      case 2:
        return <CandidateProfessional candidate={{ ...candidate, ...state }} updateCandidate={updateCandidate} />;
      case 3:
        return <CandidateCompleted candidate={{ ...candidate, ...state }} />;
      default:
        return null;
    }
  };

  if (!candidate) return null;

  return (
    <Container component="main" maxWidth={false} className={`register-wrapper ${classes.root}`}>
      <CssBaseline />
      <Link className={classes.logo} href="/"></Link>
      {renderRegisterSection()}
    </Container>
  );
}