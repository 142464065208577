import React from 'react'
import { t } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Box, CssBaseline, Typography } from '@material-ui/core'

// components 
import SendResetPasswordForm from '../components/SendResetPasswordForm'
import ResetPasswordForm from '../components/ResetPasswordForm'
import Link from '../components/Link'

const useStyles = makeStyles(theme => ({
  root: { 
    maxWidth: 1240,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center' 
  },
  logo: {
    margin: 'auto',
    width: 90,
    height: 90,
    backgroundImage: 'url(/mediaforta-logo.svg)',
    backgroundSize: 'auto 100%'
  },
  box: {
    width: 500,
    margin: '30px 0',
    '& .MuiInputBase-root > fieldset': { borderColor: '#ccc' }
  },
  title: { 
    textAlign:'center',
    marginBottom: 30 
  }
}))

export default function ResetPassword({match}) {
  const classes = useStyles()
  const { email, token } = match.params || {} 

  return (
    <Container component="main" maxWidth={false} className={classes.root}>
      <CssBaseline />
      <Link className={classes.logo} href="/"></Link>
      <Box display="flex" className={classes.box}>
        <Container component="main" maxWidth={false}>
          {! email ?
            <Box>
              <Typography className={classes.title} component="h1" variant="h5"> {t('forgot_password')} </Typography>
              <SendResetPasswordForm />
            </Box>
            :
            <Box>
              <Typography className={classes.title} component="h1" variant="h5"> {t('choose_password')} </Typography>
              <ResetPasswordForm email={email} token={token}/>
            </Box>
          }
        </Container>
      </Box>
    </Container>
  )
}