import React from 'react';
import { t } from '../utils/i18n';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, CssBaseline } from '@material-ui/core';

// Context
import { useUser } from "../context/user";
import { useCandidate } from "../context/candidate";

// Components
import Dropdown from "../components/Dropdown";
import Link from "../components/Link";
import CandidateCard from "../components/CandidateCard";
import CandidateDetails from "../components/CandidateDetails";

const useStyles = makeStyles(theme => ({
  root: { marginTop: theme.spacing(4) },
  candidate: {
    paddingRight: 40,
    position: 'relative',
    '& > *': {
      marginTop: 20,
      marginBottom: 20,
      position: 'relative'
    }
  },
  card: {
    paddingLeft: 40,
    marginBottom: 20,
    '& > div': {
      height: '100%'
    }
  },
  back: {
    fontSize: 16,
    marginLeft: 15,
    marginBottom: 15,
    '& > span': {
      fontSize: 22,
      marginRight: 5
    }
  }
}));

export default function Candidate({ match }) {
  const classes = useStyles();
  const { role, isRecruiter } = useUser();
  const { candidate, updateCandidate, deleteCandidate } = useCandidate();
  const [action, setAction] = React.useState('');

  const actions = {
    recruiter: [
      { label: t('none'), value: '' },
      { label: t('edit_candidate'), value: 'edit' },
      { label: t('delete_candidate'), value: 'delete' }
    ],
    candidate: [
      { label: t('none'), value: '' },
      { label: t('edit_profile'), value: 'edit' }
    ]
  };

  React.useEffect(() => {
    if (action === 'delete') deleteCandidate();
    // eslint-disable-next-line
  }, [action]);

  const update = (candidate, cb) => {
    updateCandidate(candidate, response => {
      setAction('');
      if (cb) cb(response);
    });
  };

  const { id } = match.params;
  return (
    <Container component="main" className={classes.root} maxWidth='lg'>
      <CssBaseline />
      {
        isRecruiter() &&
        <div className={classes.back}>
          <span>&#9666;</span>
          <Link href={'/candidates'+window.location.search}> {t('back_candidates')} </Link>
        </div>
      }
      <Grid container className={classes.grid}>
        <Grid item xs={isRecruiter() ? 6 : 12} className={classes.candidate}>
          <Dropdown label={t('actions')} value={action} items={actions[role.type]} onChange={setAction} />
          {candidate && candidate._id && (!id || id === candidate._id) &&
            <CandidateDetails
              candidate={candidate}
              isRecruiter={isRecruiter}
              edit={action === 'edit'}
              onUpdate={(candidate, cb) => update(candidate, cb)}
            />
          }
        </Grid>
        {
          isRecruiter() &&
          <Grid item xs={6} className={classes.card}>
            {candidate && candidate._id && <CandidateCard candidate={candidate} />}
          </Grid>
        }
      </Grid>
    </Container>
  );
}