import React from 'react'
import { Route, Redirect } from 'react-router-dom'

// Context
import { useAuth } from "../context/auth"
import { useApp } from "../context/app"

function AnonymousRoute({ component: Component, ...rest }) {
	const { user } = useAuth()
  const { redirect } = useApp()
  return (
    <Route
      {...rest}
      render={props =>
        ! user ? ( <Component {...props} />) : 
        ( <Redirect to={redirect ? redirect : '/candidates' } />)
      }
    />
  )
}

export default AnonymousRoute