import React from 'react';
import { t } from '../utils/i18n';
import CandidatesService from "../services/candidates.service";

import {
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box,
  Typography,
  IconButton
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

// Context
import { useApp } from "../context/app";

export default function FoldersList({ refresh, setRefresh }) {
  const [folders, setFolders] = React.useState(null);
  const { setResponseDialog } = useApp()
  const removeFolder = (fId) => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('are you sure you want to delete this folder?'),
      onConfirm: () => {
        CandidatesService.removeFolder(fId).then(({ records }) => {
          setRefresh(refresh+1);
        })
      }
    })
   
  };

  const remove = (fId, cId) => {
    CandidatesService.removeCandidateFromFolder(fId, cId).then(({ records }) => {
      setRefresh(refresh+1);
    })
  };

  React.useEffect(() => {
    CandidatesService.listFolders().then(({ records }) => {
      setFolders(records);
    });
  }, [refresh]);

  return <React.Fragment>
    <Box display="flex">
      <div style={{ flex: 2 }}>
        {folders && folders.map(({ candidatelist, name, _id }) => {
          return <div key={_id}>
            <Box>
              <Typography variant='h5'>
                <b> {name} </b>
                  <IconButton  aria-label={t('remove_folder')} onClick={() => removeFolder(_id)}>
                    <DeleteIcon />
                  </IconButton>
              </Typography>
            </Box>
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  {!candidatelist.length ?
                    <TableRow>
                      <TableCell>{t('no candidates')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    :
                    candidatelist.map(({ firstname, name, email, _id: cId }, index) => {
                      return <TableRow key={index}>
                        <TableCell>
                          {firstname} {name} ({email})

                        </TableCell><TableCell style={{ width: 50 }}>

                          <IconButton aria-label={t('remove candidate from folder')} onClick={() => remove(_id, cId)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>;
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <br />
          </div>;
        })
        }
      </div>
    </Box>
  </React.Fragment>;
}