import React from 'react'
import { t } from '../utils/i18n'
import { useHistory } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Container, CssBaseline, IconButton, Box } from '@material-ui/core'
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

// Context 
import { JobContext } from "../context/job"
import { useApp } from "../context/app"

// Services
import JobsService from "../services/jobs.service"
import AppService from "../services/app.service"

// App Components
import Link from '../components/Link'

// Model Components
import JobsList from '../components/JobsList'
import JobForm from '../components/JobForm'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '75%',
    right: '12%',
    '&:before': {
      right: '-16%'
    }
  },
  back: {
    fontSize: 16,
    marginLeft: 5,
    marginTop: 30,
    marginBottom: -30,
    '& > span': {
      fontSize: 22,
      marginRight: 5
    }
  }
}))

export default function Jobs({match}) {
  const classes = useStyles()
  const history = useHistory()
  const { setResponseDialog } = useApp()
  const { jobId } = match.params
  const [edit, setEdit] = React.useState(false)
  const [job, setJob] = React.useState(false)
  const [applicantLevels, setApplicantLevels] = React.useState(false)
  const [employmentTypes, setEmploymentTypes] = React.useState(false)
  const [languages, setLanguages] = React.useState(false)

  React.useEffect(() => { 
    if (! languages && ! employmentTypes && ! applicantLevels) {
      AppService.languages().then(setLanguages)
      JobsService.listEmploymentTypes().then(setEmploymentTypes)
      JobsService.listApplicantLevels().then(setApplicantLevels)
    } 
    // eslint-disable-next-line
  }, [])

  const removeJob = (job, onRemoved) => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('confirm_remove_job'),
      onConfirm: () => {
        JobsService.delete(job._id).then(onRemoved)
      }
    })
  }

  const getJob = () => JobsService.get(jobId).then(setJob)

  React.useEffect(() => {
    if (jobId && ! job) getJob()
    // eslint-disable-next-line
  }, [])

  return ( applicantLevels && employmentTypes && languages &&
    <JobContext.Provider value={{ applicantLevels, employmentTypes, languages }}>
      <Container component="main"  maxWidth='lg'>
        <CssBaseline />
        {
          jobId ?
            job &&
            <>
              <div className={classes.back}>
                <span>&#9666;</span> 
                <Link href='/jobs'> Back to all jobs </Link>
              </div>
              <Typography component="h1" variant="h5"> 
                {job.title}
                <IconButton aria-label={t('edit_job')} onClick={() => setEdit(true)}> 
                  <EditIcon /> 
                </IconButton>
                <IconButton aria-label={t('remove_job')} onClick={() => removeJob(job, () => history.push('/jobs'))} variant="error">
                  <DeleteIcon />
                </IconButton>
              </Typography>
              <JobForm 
                onClose={() => {
                  getJob()
                  setEdit(false)
                }} 
                job={job} 
                edit={edit}
              />     
            </>     
          :
            <>
              <Typography component="h1" variant="h5"> 
                Jobs
                <IconButton aria-label={t('add_job')} onClick={() => setJob({})}> 
                  <AddIcon /> 
                </IconButton>
              </Typography>
              { job &&
                <Box className={classes.wrapper + ' form-wrapper'}>  
                  <h2> { job && job._id ? t('edit_job'): t('new_job') } </h2>
                  <div>
                    <JobForm onClose={() => setJob(false)} job={job} edit={true}/>   
                    <IconButton aria-label={t('cancel')} onClick={() => setJob(false)}> x </IconButton>
                  </div>
                </Box> 
              }
              <JobsList job={job} setJob={setJob} removeJob={(job, cb) => removeJob(job, cb)}/>  
            </> 
        }
      </Container>
    </JobContext.Provider>
  )
}