import React from 'react'
import { t } from '../utils/i18n'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { Add as AddIcon } from '@material-ui/icons'
import { Typography, Container, CssBaseline, IconButton, Box, Grid } from '@material-ui/core'

// Context
import { useUser } from '../context/user'

// Components
import CampaignList from '../components/CampaignList';

// Services
import JobsService from '../services/jobs.service';
import CampaignForm from '../components/CampaignForm';
import { Link } from 'react-router-dom';

export const fetchCampaigns = async (mediumId) => JobsService.listCampaigns(mediumId);
export const getMedium = async (mediumId) => JobsService.getMedium(mediumId);

const useStyles = makeStyles(theme => ({
  back: {
    fontSize: 16,
    marginTop: 30,
    marginLeft: 10
  }
}));

export default function Campaigns({match}) {
  const history = useHistory();
  const classes = useStyles();
  const { isRecruiter } = useUser();
  const { mediumId } = match.params;
  const [ medium, setMedium ] = React.useState(false)
  const [ campaign, setCampaign ] = React.useState(false)
  const [ campaigns, setCampaigns ] = React.useState([])
  const [ refresh, setRefresh ] = React.useState(false)
  
  if (! medium) {
    getMedium(mediumId).then((data) => {
      setMedium(data);
      // if(refresh) setRefresh(false)
    })
  }
  
  React.useEffect(() => {
    if (! campaigns.length || refresh){
      fetchCampaigns(mediumId).then(({records}) => {
        setCampaigns(records);
        if(refresh) setRefresh(false)
      })
    } 
    // eslint-disable-next-line
  }, [refresh])
  
  return (
    isRecruiter() &&
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <div className={classes.back}>
        <Link onClick={() => history.goBack()}> &lt; back to mediums </Link>
      </div>
      {medium && 
      <>
        <Typography component="h1" variant="h5"> 
          {medium.name} {t('campaigns')}
          <IconButton aria-label={t('add_campaign')} onClick={() => setCampaign({})}>
            <AddIcon />
          </IconButton>
          { campaign &&
            <Box className="form-wrapper -full">  
              <h2> { t('new campaign') } </h2>
              <div>
                <CampaignForm edit={true} onClose={() => {
                  setCampaign(false);
                  setRefresh(Math.random());
                }} mediumId={mediumId}/>
                <IconButton aria-label={t('cancel')} onClick={() => setCampaign(false)}> x </IconButton>
              </div>
            </Box> 
          }
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <CampaignList campaigns={campaigns} setRefresh={() => setRefresh(Math.random())} onRemove={() => setRefresh(true)} />
          </Grid>
        </Grid>
      </>
      }
    </Container>
  )
}