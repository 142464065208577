import React from 'react';
import { t } from '../utils/i18n';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles'
import {
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  ThemeProvider,
  IconButton,
  Box
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'


// themes
import tableThema from '../themes/table'

// Context
import { useApp } from "../context/app"

// Services
import JobsService from '../services/jobs.service';


import MediumCard from "./MediumCard";
import MediumDetailsOverview from "./MediumDetailsOverview";

const useStyles = makeStyles(theme => ({
  tab: { width: 400 },
  category: {
    cursor: 'pointer',
    display: 'flex', 
    padding: '30px 20px 0',
    '& h5': { 
      flex: 1,
      '& svg': {
        background: theme.palette.mediaforta.main,
        borderRadius: '50%',
        fontSize: 20,
        fill: '#fff'
      }
    }
  },
  row: {
  	cursor: 'pointer',
  	'& th, & td': {
  		paddingTop: 15,
  		paddingBottom: 15
  	},
  	'& th label, & td label': { cursor: 'pointer' },
  },
  actions: {
    padding: 0,
    margin: '0 0 0 10px',
    '& svg': { fontSize: 20 }
  }
}))

export const deleteMedium = async (mediumId) => JobsService.deleteMedium(mediumId);
export const patchMedium = async (mediumId, patch) => JobsService.patchMedium(mediumId, patch);

export default function MediumsList({ mediums, onRemove, setRefresh }) {
  const classes = useStyles();
  const [ medium, setMedium ] = React.useState(false);
  const { setResponseDialog } = useApp();
  
  const removeMedium = mediumId => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure you want to remove this medium?'),
      onConfirm: () => {
        deleteMedium(mediumId).then(() => onRemove())
      }
    })
  }

  const updateMedium = () => {
    const {_id, ...patch} = medium;
    patchMedium(_id, patch);
    setRefresh(Math.random());
  }

  return <React.Fragment>
    <Box display="flex">
      <div style={{flex: 4}}>
        <ThemeProvider theme={ tableThema }>
          <TableContainer className={classes.tablecontainer}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell>Advertise start price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { ! mediums.length ?
                    <TableRow>
                      <TableCell>{t('no mediums')}</TableCell>
                    </TableRow>
                  :
                  mediums.map((medium, index) => {
                    return <TableRow key={index} className={classes.row} onClick={() => setMedium(medium)}>
                        <TableCell>
                          <b>{medium.name}</b>
                        </TableCell>
                        <TableCell>
                          {medium.country}
                        </TableCell>
                        <TableCell>
                          <a 
                            href='#'
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              window.open(medium.url);
                            }
                          }
                        >{medium.url}</a>
                        </TableCell>
                        <TableCell>
                          {medium.startPrice}
                        </TableCell>
                        <TableCell width={70}>
                          <IconButton className={classes.actions} aria-label={t('remove medium')} onClick={() => removeMedium(medium._id, index)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    })
                  }
              </TableBody>
            </Table>
          </TableContainer>
        </ThemeProvider>
      </div>

      { medium && medium._id &&
        <div style={{flex: 2}}>
          <MediumCard
            medium={medium}
            card={{ id: 'details', component: MediumDetailsOverview }}
            hideTabs={true}
            onClose={() => {
              setMedium(false);
              setRefresh(Math.random())
            }}
            onUpdate={updateMedium}
          />
        </div>
      }
    </Box>
		</React.Fragment>    
}