import React from 'react';
import { t } from '../utils/i18n';

import { Add as AddIcon } from '@material-ui/icons';
import { Typography, Container, CssBaseline, IconButton, Box, Grid } from '@material-ui/core';

// Context
import { useUser } from '../context/user';

// Components
import ScoreCardList from '../components/ScoreCardList';
import ScoreCardModal from '../components/ScoreCardModal';

// Services
import ScoreCardsService from '../services/scorecards.service';

export const fetchScoreCards = async () => ScoreCardsService.list();
export const patchScoreCard = async (id, patch) => ScoreCardsService.patch(id, patch);
export const postScoreCard = async data => ScoreCardsService.post(data);

export default function ScoreCards() {
  const { isRecruiter } = useUser();
  const [ scoreCard, setScoreCard ] = React.useState(false);
  const [ scoreCards, setScoreCards ] = React.useState([]);
  const [ refresh, setRefresh ] = React.useState(false);
  
  React.useEffect(() => {
    if (! scoreCards.length || refresh) fetchScoreCards().then(({records}) => {
      setScoreCards(records);
      if(refresh) setRefresh(false)
    })
    // eslint-disable-next-line
  }, [refresh]);
  
  const updateScoreCard = card => {
    if (card) {
      const data = {
        label: card.label,
        categories: card.selectedCategories.map(c => ({
          _id: c._id,
          items: c.selectedItems.map(i => i._id)
        }))
      }
      if (! card._id) {
        postScoreCard(data).then(() => {
          setScoreCard(false);
          setRefresh(Math.random());
        })
      } else {
        patchScoreCard(card._id, data).then(() => setScoreCard(false));
      }
    }
  };

  return (
    isRecruiter() &&
    <Container component="main"  maxWidth='lg'>
      <CssBaseline />
      <Typography component="h1" variant="h5"> 
      	{t('Score cards')}
        <IconButton aria-label={t('add_scorecard')} onClick={() => setScoreCard({label: '', selectedCategories: []})}>
          <AddIcon />
        </IconButton>
        { scoreCard &&
          <Box className="form-wrapper -full -wide">  
            <h2> { t('scorecard') } </h2>
            <div>
              <ScoreCardModal scoreCard={scoreCard} update={updateScoreCard}/>
              <IconButton aria-label={t('cancel')} onClick={() => {
                setScoreCard(false);
                setRefresh(Math.random());
              }}> x </IconButton>
            </div>
          </Box> 
        }
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <ScoreCardList scoreCards={scoreCards} setScoreCard={setScoreCard}/>  
        </Grid>
      </Grid>
    </Container>
  )
}