import * as React from 'react'
import * as Yup from 'yup'
import { t } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Box,
  Paper,
  TextField,
  Typography,
  IconButton
} from '@material-ui/core'
import { RemoveCircleOutline as DeleteIcon } from '@material-ui/icons'

// Context
import { useApp } from "../context/app"
import { useCandidate } from "../context/candidate"

// Services
import InterviewsService from "../services/interviews.service"

const useStyles = makeStyles(theme => ({
  textfield: {
    marginBottom: 10,
    width: '100%',
    '& > div': { backgroundColor: '#f7f7f7' }
  },
  question: {
    boxShadow: 'none',
    marginBottom: 20,
    '& > p': {
      color: theme.palette.mediaforta.light,
      fontWeight: 500,
      display: 'inline-block',
      paddingTop: 15,
      paddingBottom: 15
    }
  },
  add: { cursor: 'pointer' },
  actions: {
    textAlign: 'right',
    marginTop: 10,
  },
  remove: {
    float: 'left',
    margin: '13px 3px 10px 0px',
    padding: 5,
    color: theme.palette.mediaforta.dark,
    '& svg': { fontSize: 15 }
  }
}))

const InterviewSchema = Yup.object().shape({
  questions: Yup.array().of(
    Yup.object().shape({
      question: Yup.string().required(),
      answer: Yup.string().required(),
    })
  )
})

function CandidateInterviewFormQuestion({ question, index, errors, onChange }) {
  const classes = useStyles()

  const [ q, setQ ] = React.useState('')
  const [ a, setA ] = React.useState('')

  React.useEffect(() => {
   setQ(question.question)
   setA(question.answer)
    // eslint-disable-next-line
  }, [question])


  React.useEffect(() => {
    if (onChange) onChange({ question: q, answer: a })
    // eslint-disable-next-line
  }, [q, a])  

  return (
    <Paper key={index} display="flex" className={classes.question}>
      <Typography> {'Question ' + (index + 1) + ': '} </Typography> 
      <TextField 
        className={classes.textfield}
        variant="outlined" 
        error={!! errors[`questions[${index}].question`]} 
        helperText={errors[`questions[${index}].question`]}
        name="question" 
        label={t("question")}
        value={q}
        onChange={event => { setQ(event.currentTarget.value) }}
      />
      <Box>
        <TextField 
          multiline={true} 
          className={classes.textfield}
          rows={4}
          name="answer"
          error={!! errors[`questions[${index}].answer`]} 
          helperText={errors[`questions[${index}].answer`]}
          label={t("answer")}
          variant="outlined" 
          style={{width: '100%'}} 
          value={a}
          onChange={event => { setA(event.currentTarget.value) }} 
        />
    </Box>
    </Paper>
  )
}

export default function CandidateInterviewForm({ candidate, type, interview, close, ...props }) {
  const classes = useStyles()
  const { setResponseDialog } = useApp()
  const { refreshCandidate } = useCandidate()

  const [ questions, setQuestions ] = React.useState([])
  const [ interviewQuestions, setInterviewQuestions ] = React.useState([])
  
  const [ errors, setErrors ] = React.useState({})

  const setDialog = message => {
    setResponseDialog({
      title: t('great_success'),
      message,
      onClose: props.onClose || false 
    })
    return refreshCandidate()
  }

  React.useEffect(() => {
    setInterviewQuestions(interview.questions)
    // eslint-disable-next-line
  }, [type])

  const removeInterview = () => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure_remove_interview'),
      onConfirm: () => InterviewsService.delete(interview._id).then(() => setDialog(t('interview_removed')))
    })
  }  

  const submit = async event => {
    setErrors({})
    const formData = new FormData(event.target);
    event.preventDefault()
    let i = 0
    let questions = []
    for (let [key, value] of formData.entries()) {
      questions[i] = {...questions[i], ...{[key]: value}}
      if (key === 'answer') { ++i }
    }
    try {
      await InterviewSchema.validate({ questions })
      if (interview.templateId && interview.candidateId) {
        InterviewsService.patch(interview._id, { questions })
          .then(() => { 
            if (close) close()
            setDialog(t('interview_updated')) 
          })
      } else {
        InterviewsService.post({
          templateId: interview._id,
          candidateId: candidate._id,
          questions
        }).then(() => { 
          if (close) close()
          setDialog(t('interview_created')) 
        })
      }
    } catch(e) {
      setErrors({[e.path]: t('required')})
    }
  }

	return (
    <React.Fragment>
      <form style={{margin: 5}} onSubmit={submit}>
        {!! interviewQuestions && 
          interviewQuestions.map((question, index) => { 
            return <React.Fragment key={index}>
              <IconButton 
                className={classes.remove} 
                aria-label={t("remove_question")} 
                onClick={() => setInterviewQuestions(interviewQuestions.slice(0, index).concat(interviewQuestions.slice(index+1, interviewQuestions.length)))}> 
                  <DeleteIcon /> 
              </IconButton> 
              <CandidateInterviewFormQuestion question={question} index={index} errors={errors} /> 
            </React.Fragment>
          })
        }
        {questions.map((question, index) => {
          return <React.Fragment key={(interviewQuestions.length + index)}>
            <IconButton 
              className={classes.remove} 
              aria-label={t("remove_question")} 
              onClick={() => setQuestions(questions.slice(0, index).concat(questions.slice(index+1, questions.length)))}> 
                <DeleteIcon /> 
            </IconButton> 
            <CandidateInterviewFormQuestion question={questions[index]} index={(interviewQuestions.length + index)} errors={errors} onChange={question => questions[index] = question}/> 
          </React.Fragment>
        })}
        <Typography className={classes.add} onClick={() => { setQuestions([...questions, {question: '', answer: ''}])}}> + {t('add_question')} </Typography>
        <Box className={classes.actions}>
          <Button variant="contained" color="primary" type="submit"> {t('save')} </Button>
          {!! interview.templateId && 
            <Button variant="contained" color="secondary" style={{ marginLeft: 5 }} onClick={removeInterview}>
              {t('remove')}
            </Button>
          }
        </Box>
  	 </form>    
    </React.Fragment>
  )
}