import { createMuiTheme } from '@material-ui/core/styles'
import 'fontsource-roboto';
import 'fontsource-poppins';

const palette =  {
  hover: '#f4f6fb',
	superlight: '#dadff3',
  light: '#b5bede',
  main: '#2a7fd7',
  dark: '#041e7a',
  border: '#d8ddf1',
  disabled: '#d9def2',
  success: '#32b10a',
  error: '#eb313c',
  github: '#1b1f23',
  twitter: '#2ca1f2',
  facebook: '#206ef2',
  angellist: '#000',
  linkedin: '#125293',
  dribbble: '#f14a8c',
  behance: '#145bf2',
  label: '#9ba1af'
}

const theme = createMuiTheme({
  palette: {
    mediaforta: palette,
    success: { main: palette.success },
    error: { main: palette.error }
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: '#031e82 !important',
        height: 70,
        "& a": {
          minWidth: 'auto',
          color: '#fff',
          padding: '20px 5px 15px 40px',
          marginRight: '20px',
          opacity: 1,
          fontWeight: 400,
          textTransform: 'none'
        },
        "& a:hover": { textDecoration: 'none' },
        "& svg": {
          position: 'absolute',
          left: '5px',
          padding: '2px'
        }
      },
    },
    MuiCard: {
      root: {
        boxShadow: '0px 2px 20px 0px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        borderRadius: 20,
      }
    },
    MuiCardContent: {
      root: {
        '& > div': {
          '& > button': {
            color: '#fff',
            backgroundColor: palette.dark,
            borderRadius: 17,
            fontSize: 14,
            marginLeft: 40,
            float: 'right',
            padding: '7px 18px',
            '&:hover': { backgroundColor: palette.main }
          },
          '& > ul': {
            border: 0,
            borderBottom: '1px solid',
            borderTop: '1px solid',
            borderBottomColor: palette.light,
            borderTopColor: palette.light,
            borderRadius: 0,
            width: '100%',
          }
        }
      }
    },
    MuiGrid: {
      item: { paddingBottom: '0 !important' }
    },
    MuiPaper: {
      root: { color: palette.dark },
      rounded: { borderRadius: 2 },
      outlined: {
        borderColor: palette.border,
        padding: 15,
        '& h2': { fontWeight: 500 }
      },
      elevation2: {
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderBottomColor: palette.superlight,
        borderTopColor: palette.superlight,
        marginBottom: 2,
        marginTop: 5,
        padding: 10,
        boxShadow: 'none',
        borderRadius: 0
      }
    },
    MuiPopover: {
      'paper': {
        borderRadius: 10,
        boxShadow: '0px 4px 10px 2px #ccc'
      }
    },
    MuiContainer: {
      root: {
        color: palette.dark,
        '& label': { color: palette.label },
        "& > h1 > button": {
          backgroundColor: palette.main,
          color: '#fff',
          marginLeft: 10,
          width: 30,
          height: 30,
          padding: 0,
          "& svg": {
            fontSize: 15,
            width: '100%'
          },
          "&:hover": { backgroundColor: palette.dark }
        }
      }
    },
    MuiDropzoneArea: {
      root: {
        borderWidth: 2,
        marginTop: 10
      },
      text: {
        fontWeight: 300,
        fontSize: 18
      },
      icon: {
        color: palette.main,
        width: 45,
        height: 45
      }
    },
    MuiDropzonePreviewList: {
      root: {
        margin: 'auto',
        width: '100%'
      },
      imageContainer: {
        flexBasis: '10%',
        maxWidth: '10%',
        padding: '0 !important',
        '& p': { fontSize: 14 }
      },
      image:{
        height: 50,
        padding: 10,
        marginTop: 10
      }
    },
    MuiAvatar: {
      colorDefault: {
        color: '#fff',
        backgroundColor: palette.main
      }
    },
    MuiLink: {
      root: {
        color: palette.dark,
        fontWeight: 500
      }
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        minWidth: 550,
        backgroundColor: palette.main, 
        '& h2': {
          fontSize: 18,
          fontWeight: 400,
          margin: 10,
          textAlign: 'center',
          color: '#fff',
          borderRadius: '10px 10px 0 0',
          width: 'calc(100% - 50px)',
          '& svg': {
            fontSize: 25,
            position: 'relative',
            top: 6,
            marginRight: 10
          }
        },
        '&.error': { backgroundColor: palette.error }
      }
    },
    MuiTypography: {
      h5: {
        fontSize: '1.4rem',
        marginBottom: 10
      },
      overline: {
        fontStyle: 'italic',
        color: '#333',
        paddingLeft: 10
      }
    },
    MuiButton: {
      root: {
        backgroundColor: palette.main,
        color: '#fff',
        borderRadius: 17,
        '&:hover': { backgroundColor: palette.dark },
        '&.Mui-disabled': {
          color: '#fff',
          opacity: .5
        }
      },
      containedPrimary: { boxShadow: 'none' },
      textPrimary:{
        color: '#fff',
        '&:hover': { backgroundColor: palette.dark }
      },
      text: { padding: '8px 20px' }
    },
    MuiChip: {
      root: {
        padding: '20px 10px',
        borderRadius: 17,
        marginRight: 10,
        backgroundColor: palette.superlight,
        color: palette.dark,
        fontWeight: 500,
        fontSize: 14,
        '&.MuiLink-underlineHover': {
          cursor: 'pointer'
        }
      }
    },
    MuiCircularProgress: {
      root: {
        margin: 'auto',
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        position: 'absolute'
      }
    },
    MuiAutocomplete: {
      root: { 
        '&:not(.Mui-focused) *': { cursor: 'pointer' },
      },
      inputRoot: {
        height: 45,
        padding: '5px !important'
      },
      popper: { zIndex: 99999 }
    },
    MuiIconButton: {
      root: {
        color: palette.light,
        padding: 10,
        '&[variant="error"]': { backgroundColor: palette.error }
      },
    },
    MuiInputLabel: {
      root: {
        color: palette.dark,
        fontWeight: 400
      }
    },
    MuiSelect: {
      icon: { color: palette.light }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 15px) scale(1)',
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: palette.superlight,
        borderBottomColor: palette.light,
      },
      input: {
        padding: '12.5px',
      },
      root: {
        '&:hover:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.main
        }
      }
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent !important',
          '& *': { color: '#fff' }
        }
      }
    }
  }
})

export default theme