export const TRANSLATIONS_FR = {
  existing_user_title: "Avez-vous déjà un compte?",
  existing_user_title_apply:
    "Avez-vous déjà un compte? Veuillez vous connecter pour postuler à l`emploi.",
  new_user_title: "Vous êtes nouveau?",
  new_user_title_apply: "Vous êtes nouveau?",
  edit_task: "modifier une tâche",
  new_task: "nouvelle tâche",
  all: "tous",
  active: "actif",
  unfinished: "pas encore terminé",
  finished: "prêt",
  forgot_password: "Mot de passe oublié ?",
  choose_password: "Choisissez votre nouveau mot de passe.",
  email_sent_title: "Nous vous avons envoyé un e-mail.",
  email_sent_info:
    "Nous vous envoyons un e-mail grâce auquel vous pouvez confirmer votre inscription.<br/>Vous devez confirmer votre adresse e-mail pour continuer.",
  email_sent_spam:
    "N’hésitez pas à vérifier votre courrier indésirable ou votre dossier spam.",
  email_not_received: "Vous n’avez pas reçu d’e-mail ?",
  resend: "renvoyer",
  create_account: "créez un compte",
  hello: "Bonjour",
  no_price_set: "No rates set",
  join_us:
    "Rejoignez-nous et augmentez vos chances de travailler avec de grandes entreprises <br/>à la recherche d’un contenu de qualité.",
  account_details: "Données de compte",
  testimonial_century21:
    "« Mediaforta crée un contenu de qualité pour notre groupe cible B2B international. » <label>Kim R. COO <br/>Century21</label>",
  add_link: "ajoutez un lien",
  personal_website: "site Web personnel",
  fill_personal_website: "saisissez votre site Web personnel",
  are_you_sure: "Êtes-vous sûr(e) ?",
  confirm_remove_job: "Êtes-vous sûr(e) de vouloir supprimer cet emploi ?",
  edit_job: "modifier un emploi",
  remove_job: "supprimer un emploi",
  add_job: "ajouter un emploi",
  new_job: "nouvel emploi",
  cancel: "annuler",
  experience: "expérience",
  add_work: "ajouter un emploi",
  edit_experience: "modifier l’expérience",
  new_experience: "nouvelle expérience",
  education: "formation",
  add_education: "ajouter une formation",
  edit_education: "modifier un diplôme",
  new_education: "nouveau diplôme",
  diplomas: "diplômes",
  add_diploma: "ajouter un diplôme",
  candidates: "candidats",
  personal_info: "Parlez un peu de vous.",
  work_experience: "expérience professionnelle",
  none: "aucune",
  edit_candidate: "modifier un candidat",
  delete_candidate: "supprimer un candidat",
  edit_profile: "modifier votre profil",
  back_candidates: "retour à tous les candidats",
  actions: "actions",
  missing_field: "champ manquant",
  field_required: "{{veld}} est obligatoire",
  required: "obligatoire",
  welcome_back: "Bon retour.",
  activation_info:
    "Il nous manque encore des informations vous concernant pour pouvoir vous associer aux bons clients.",
  complete_info:
    "Afin que nous puissions éventuellement vous proposer des offres d’emploi, merci de bien vouloir finaliser votre inscription.",
  get_started: "au travail",
  confirm_remove_calendar_item:
    "Êtes-vous sûr(e) de vouloir supprimer cet élément du calendrier ?",
  great_success: "Bonne chance !",
  calendar_item_removed: "élément du calendrier supprimé",
  calendar_item_added: "élément du calendrier ajouté",
  calendar_item_updated: "élément du calendrier mis à jour",
  sure_remove_score: "Êtes-vous sûr(e) de vouloir supprimer ce score ?",
  sure_remove_test: "Êtes-vous sûr(e) de vouloir supprimer ce test ?",
  sure_generate_qr: "Are you sure you want to regenarate 2fa code",
  search_tests: "tests de recherche",
  remark_required: "une remarque est requise",
  add_score: "ajouter un score",
  edit_score: "modifier un score",
  test_updated: "test mis à jour",
  test_created: "Test créé",
  createdAt: "créé",
  createdAt_from: "créé de",
  createdAt_to: "créé a",
  modifiedAt: "modifier",
  unavailable: "indisponible",
  modifiedByRecruiterAt: "modifier par recruteur",
  name: "nom",
  firstname: "prénom",
  lastname: "nom de famille",
  add_file: "ajouter un fichier",
  edit_test: "modifier un test",
  new_test: "nouveau test",
  add_test: "ajouter un test",
  sure_remove_task: "Êtes-vous sûr(e) de vouloir supprimer cette tâche ?",
  type: "type",
  link_candidate_job: "Lier le candidat à l`emploi",
  due_date: "date d’échéance",
  assigned_to: "attribué à",
  tasks: "tâches",
  search_tasks: "rechercher des tâches",
  todo: "à faire",
  call: "appeler",
  title_short: "titre trop court",
  min_chars: "Veuillez saisir au moins {{aantal}} caractères.",
  task_updated: "tâche mise à jour",
  task_created: "tâche créée",
  title: "titre",
  assign_to: "attribuer",
  description: "description",
  add_task: "ajouter une tâche",
  add_tag: "ajouter une balise",
  email: "e-mail",
  password: "mot de passe",
  login: "se connecter",
  email_forgot_password_link:
    "Mot de passe oublié ? Recevez un lien par e-mail.",
  reset_failed: "Utilisateur non trouvé.",
  search: "recherche",
  search_candidates: "rechercher des candidats",
  invalid_image_url: "Veuillez indiquer une URL d`image valable.",
  image_url: "URL d`image",
  passwords_no_match: "les mots de passe ne correspondent pas",
  confirm_password: "répéter le mot de passe",
  save_password: "enregistrer le mot de passe",
  dutch: "Néerlandais",
  french: "Français",
  english: "Anglais",
  i_am: "Je suis",
  my_lang: "langue",
  lets_go: "au travail",
  recruiter: "recruteur",
  sure_remove_note: "Êtes-vous sûr(e) de vouloir supprimer cette note ?",
  search_notes: "rechercher des notes",
  score_low: "score trop faible",
  score_high: "score trop élevé",
  edit_note: "modifier une note",
  new_note: "nouvelle note",
  add_note: "ajouter une note",
  note_created: "note créée",
  note_updated: "note mise à jour",
  jobs: "emplois",
  agenda: "agenda",
  stats: "statistiques",
  add_filter: "ajouter un filtre",
  close_filter: "fermer le filtre",
  logout: "se déconnecter",
  profile: "profil",
  work: "travail",
  links: "liens",
  something_wrong: "Une erreur s’est produite.",
  contact_recruiter:
    "Veuillez nous contacter à l’adresse e-mail recruiter@mediaforta.be.",
  future: "futur",
  ongoing: "en cours",
  search_jobs: "rechercher des emplois",
  filter_status: "filtre statut",
  job_title: "Titre de fonction",
  client: "client",
  select_future_date: "Veuillez sélectionner une date dans le futur.",
  function: "fonction",
  start_date: "date de début",
  end_date: "date de fin",
  language: "langue",
  salary: "salaire",
  location: "localité",
  type_of_employment: "type de contrat",
  level_of_applicant: "niveau du candidat",
  time: "heure",
  work_for: "Pour qui allez-vous travailler ?",
  job_introduction: "présentation de l`emploi",
  look_for: "Qui recherchons-nous ?",
  what_offer: "Qu’offrons-nous ?",
  save: "enregistrer",
  tags: "balises",
  add: "ajouter",
  past_image_url: "copiez l’URL d`image",
  search_emails: "rechercher des e-mails",
  from: "de",
  to: "à",
  mail: "e-mail",
  message_to_short: "message trop court",
  no_candidate_found: "Aucun candidat trouvé",
  scheduled_send: "envoi programmé",
  subject: "objet",
  message: "message",
  send: "envoyé",
  invalid_email: "e-mail invalide",
  no_tags_assigned: "aucune balise attribuée",
  no_source_assigned: "aucune source attribuée",
  add_source: "ajouter une source",
  new_applicant: "nouveau candidat",
  test: "test",
  live_interview: "entretien en personne",
  offer: "offre",
  archived: "archivé",
  archive: "archive",
  status: "statut",
  specialisation: "spécialisation",
  specialisations: "spécialisations",
  evaluation: "évaluation",
  add_skill: "ajouter une compétence",
  remove_skill: "Supprimer compétence",
  remove_skill_item: "Supprimer compétence",
  very_good: "très bien",
  good: "bien",
  bad: "mauvais",
  very_bad: "très mauvais",
  new_interview: "Nouvel entretien",
  interview_created: "entretien créé",
  interview_updated: "entretien mis à jour",
  interview_removed: "entretien supprimé",
  no_links_assigned: "aucun lien attribué",
  search_interviews: "rechercher des entretiens",
  start_interview: "commencer un entretien",
  interview_by: "entretien par",
  interview: "entretien",
  sure_remove_interview:
    "Êtes-vous sûr(e) de vouloir supprimer cet entretien ?",
  question: "question",
  new_question: "Nouvelle question",
  edit_question: "Modifier une question",
  untill: "jusqu`à",
  available_from: "disponible depuis",
  available_to: "disponible jusqu`à",
  sure_remove_question: "Êtes-vous sûr(e) de vouloir supprimer cette question?",
  answer: "réponse",
  remove_question: "supprimer une question",
  remove: "supprimer",
  add_question: "ajouter une question",
  sure_remove_experience:
    "Êtes-vous sûr(e) de vouloir supprimer cette expérience ?",
  company: "entreprise",
  worked_as_freelancer: "travailler comme indépendant",
  function_short: "fonction trop courte",
  company_short: "entreprise trop courte",
  add_experience: "ajouter une expérience",
  new_email: "nouvel e-mail",
  view_email: "consulter un e-mail",
  add_email: "ajouter un e-mail",
  sure_remove_degree: "Êtes-vous sûr(e) de vouloir supprimer ce grade ?",
  degree: "grade",
  university: "université",
  country: "pays",
  institute_to_short: "établissement trop court",
  degree_to_short: "grade trop court",
  edit_degree: "modifier un grade",
  add_degree: "ajouter un grade",
  full_profile: "profil complet",
  status_interview: "statut de l’entretien",
  phone_interview: "entretien téléphonique",
  add_candidate_link: "ajouter un lien candidat",
  candidate_source: "source candidat",
  street: "rue",
  number: "numéro",
  box: "boîte postale",
  postal: "code postal",
  city: "ville",
  phone: "téléphone",
  skype: "Skype",
  sure_remove_file: "Êtes-vous sûr(e) de vouloir supprimer ce fichier ?",
  sure_archive_candidate: "Êtes-vous sûr(e) de vouloir archiver ce candidat ?",
  update_candidate: "mettre à jour un candidat",
  update_profile: "mettre à jour un profil",
  attachments: "annexes",
  no_attachments_assigned: "aucune annexe attribuée",
  no_job_applications_assigned: "aucune candidature attribuée",
  source: "source",
  Info: "info",
  sure_remove_candidate: "Êtes-vous sûr(e) de vouloir supprimer ce candidat ?",
  job_applications: "candidatures",
  pending: "en attente",
  rejected: "rejetée",
  successful: "acceptée",
  search_job_applications: "rechercher des candidatures",
  employer: "employeur",
  application_date: "date de candidature",
  search_for_similar_jobs: "rechercher des emplois similaires",
  projects: "projets",
  preferred_projects: "Quels projets vous intéressent ?",
  add_skills: "ajouter vos spécialisations",
  skills: "spécialisations",
  skills_categories: "catégorie de spécialisations",
  which_skills: "Quelles sont vos spécialisations ?",
  max_5_skills:
    'Choisissez au maximum 5 compétences qui vous correspondent le mieux et cliquez sur "Suivant".',
  which_writer_skills: "Sur quels sujets pouvez-vous bien écrire ?",
  max_5_writer_skills:
    "Choisissez jusqu'à cinq sujets dans lesquels vous avez de l'expérience en écriture et de l'expertise, puis cliquez sur 'Suivant'.",
  find_skill: "Vous ne trouvez pas ce que vous recherchez ?",
  add_new_skill: "Ajoutez une nouvelle spécialisation.",
  next: "suivant",
  add_remark: "ajouter une remarque",
  remark: "remarque",
  selected_skills: "Vos spécialisations sélectionnées:",
  manage_skills: "modifiez vos spécialisations",
  existing: "existant",
  for_validation: "pour approbation",
  add_specialisation: "ajouter une spécialisation",
  edit_specialisation: "modifier une spécialisation",
  new_specialisation: "nouvelle spécialisation",
  edit_specialisation_category: "modifier la catégorie de spécialisation",
  new_specialisation_category: "nouvelle catégorie de spécialisation",
  submitted_by: "soumis par",
  submitted_on: "soumis le",
  validation: "approbation",
  category: "catégorie",
  label: "dénomination",
  no_pending_skills: "aucune spécialisation en attente",
  see_candidates: "voir les candidats",
  decline: "rejeter",
  approve: "approuver",
  remove_job_application:
    "Êtes-vous sûr(e) de vouloir supprimer ce candidature",
  jobapplication_mail_body:
    "message standard pour e-mail de candidature rejetée",
  jobapplication_mail_subject:
    "objet standard pour e-mail de candidature rejetée",
  jobapplication_popup_title: "message pour e-mail de candidature rejetée",
  no_jobs_found: "aucun emploi trouvé",
  fill_in_user_create_fields:
    "Veuillez remplir tous les champs et vous assurer que l’adresse e-mail est unique.",
  personal_data: "données",
  contact: "contact",
  project_preferences: "Quels projets vous intéressent ?",
  top_project: "De quel projet êtes-vous le plus fier ?",
  work_as_freelancer: "Travaillez-vous comme indépendant ?",
  public_enabled:
    "Mediaforta peut partager mon profil avec des prospects et des clients de Mediaforta",
  yes: "oui",
  no: "non",
  avatar: "photo de profil",
  personal_details: "coordonnées personnelles",
  professional_details: "coordonnées professionnelles",
  years_experience: "années d’expérience",
  native_language: "langue maternelle",
  translations: "traductions",
  translate_from: "traduire du",
  translate_to: "traduire vers",
  add_language: "ajouter une langue",
  profile_picture: "photo de profil",
  choose_file: "sélectionner un fichier",
  rates: "Prix",
  dayRate: "Prix à la journée (en euros)",
  pricePerWord: "Prix par mot (en euros)",
  pricePerHour: "Prix par heure (en euros)",
  cv_resume: "CV",
  portfolio: "Portfolio",
  registration_completed_title: "Merci de votre inscription !",
  registration_completed_sub:
    "Nous vous contactons dans les plus brefs délais <br/> pour passer aux étapes suivantes.",
  vacancies: "offres d’emploi",
  goto_vacancies: "consulter les offres d’emploi",
  blogposts: "articles de blog",
  goto_blogposts: "lire les articles de blog",
  your_profile: "votre profil",
  goto_your_profile: "consulter votre profil",
  no_translations: "aucune traduction",
  read_more: "en savoir plus",
  please_select_one_skill: "Veuillez sélectionner au moins 1 spécialisation.",
  no_mothertongue: "aucune langue maternelle sélectionnée",
  no_skillset: "aucune spécialisation sélectionnée",
  how_does_it_work: "Comment ça fonctionne?",
  how_does_it_work_info:
    "Mediaforta possède un réseau exclusif des meilleurs talents en matière de creation de contenu. Nous offrons un accès aux meilleures entreprises, à une communauté d’experts et à des ressources qui peuvent booster votre carrière.",
  activate_question: "Procédure de sélection",
  activate_question_info:
    "Privilégiant la qualité, Mediaforta sélectionne les meilleurs créateurs de contenu parmi les candidats. Notre procédure de sélection efficace permet à nos clients de collaborer les meilleurs créateurs de contenu et des content marketers indépendants.",
  activate_meet: "Recevez des missions",
  activate_meet_info:
    "Si vous avez réussi notre procédure de sélection, vous faites désormais partie de notre reseau. Lorsqu’un client recherche un créateur de conentenu avec vos spécialités, nous lui soumettons votre profil. C’est vous qui décidez si vous acceptez le projet ou non. ",
  activate_match: "Paiment juste",
  activate_match_info:
    "Mediaforta ne joue pas sur les prix. Nous offrons une rémunération correcte et équitable à nos créateurs de conentnu freelance.",
  testimonial_ludovic:
    '"Mediaforta est un chouette partenaire pour un freelancer. Les accords sont clairs, la communication est directe et vous avez la possibilité de travailler avec de grandes entreprises prometteuses." <label>Ludovic <br/>Freelance copywriter</label>',
  privacy_check:
    'En vous inscrivant, vous confirmez que vous avez lu et accepté nos <a href="https://www.mediaforta.com/algemene-voorwaarden/" target="_blank">conditions générales</a>, la <a href="https://www.mediaforta.com/fr/declaration-de-confidentialite-mediaforta/" target="_blank">politique de confidentialité</a> et la <a href="https://www.mediaforta.com/fr/cookie-policy/" target="_blank">politique de cookies</a>.',
  newsletter_check:
    "Ne ratez rien. Tenez-moi informé par e-mail des emplois intéressants et des contenus inspirants.",
  trusted_by: "Ils nous font confiance:",
  copywriter: "Copywriter",
  translator: "Traducteur",
  contentmarketer: "Marketeur de contentu",
  sure_remove_recruiter_role:
    "Êtes-vous sûr de vouloir supprimer le rôle de recruteur?",
  new_recruiter: "Ajouter un nouveau recruteur",
  remove_account: "remove my account",
  why_remove_account:
    "Pouvez-vous indiquer pourquoi vous souhaitez arrêter Mediaforta",
  keep_data_and_mail:
    "Mediaforta peut toujours conserver mes données et m`envoyer des e-mails",
  delete_data_and_mail: "Mediaforta n`est plus autorisé à stocker mes données",
  choose_more: "Choose more",
  add_category: "Add category",
  change: "Change",
  remove_category:
    "Are you sure you want to remove this category from current scorecard?",
  manage_items: "Manage items",
  manage: "Manage",
  search_or_add: "Search or add new",
  remove_score_card: "Are you sure you want to remove this scorecard?",
  at_least_one_price_required:
    "Veuillez entrer un prix journalier ou un prix au mot",
  always_available: "Always available",
  privacy:
    '<a target="_blank" href="https://www.mediaforta.com/fr/declaration-de-confidentialite-mediaforta/">Déclaration de confidentialité</a>',
  cookie_policy:
    '<a target="_blank" href="https://www.mediaforta.com/fr/cookie-policy/">Cookie Policy</a>',
  terms_and_conditions:
    '<a target="_blank" href="https://www.mediaforta.com/fr/conditions-generales/">Termes et conditions</a>',
  Designer: "Graphiste",
  skills_stars:
    "En cliquant sur une étoile, vous indiquez votre degré de compétence dans ce domaine.",
  digitalmarketer: "Marketeur digital",
  socialmediacontentcreator: "Créateur de contenu sur les réseaux sociaux",
};
