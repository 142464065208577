import React from 'react'
import { t } from '../utils/i18n'
import { debounce } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Paper, TablePagination, IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

// Context
import { useApp } from "../context/app"

// Services
import NotesService from "../services/notes.service"

// Model components
import NoteScores from "./NoteScores"

// App components
import SearchFilter from "./SearchFilter"

const useStyles = makeStyles(() => ({
  description: { '& > div > *': { margin: '5px 0' } },
  header: {
    marginTop: 4,
    '& > *': { flex: 1 },
    '& h2': { margin: '5px 10px' }
  },
  actions: {
    marginTop: 5,
    float: 'right',
    '& > *': {
      padding: 5,
      float: 'left'
    },
    '& svg': { fontSize: 20 }
  }
}))

export const fetchNotes = async (search, limit, offset, fields, filter) => NotesService.list(search, limit, offset, fields, filter)
export default function NotesList({ note, candidate, fullwidth, filters, selectNote}) {
  const classes = useStyles()
  const { setResponseDialog } = useApp()

  const [ notes, setNotes ] = React.useState({})
  const [ filter, setFilter ] = React.useState('')
  const [ searchInput, setSearchInput ] = React.useState(false)
  const [ page, setPage ] = React.useState(0)
  const [ rowsPerPage, setRowsPerPage ] = React.useState(10)
  const [ removedNote, setRemovedNote ] = React.useState(0)

  const search = event => debounceSearch(event.target.value)
  const debounceSearch = debounce(q => setSearchInput(q), 300);

  const setError = (error) => {
    setNotes({})
    setResponseDialog({error})
  }

  const getFilter = () => {
    let f = filter
    if (candidate) f = `${f ? f + '&' : ''}candidateId=${candidate._id}`
    return f
  }

  React.useEffect(() => {
    fetchNotes(searchInput, rowsPerPage, page*rowsPerPage, undefined, getFilter() )
      .then(setNotes)
      .catch(setError)
    // eslint-disable-next-line
  }, [ searchInput, rowsPerPage, page, filter, note, removedNote ])  


  const handleChangePage = (event, newPage) => setPage(parseInt(newPage))
  const handleChangeRowsPerPage = (event) => {
    const rows = parseInt(event.target.value, 10)
    setRowsPerPage(rows)
    setPage(0)
  }

  const removeNote = note => {
    setResponseDialog({
      title: t('are_you_sure'),
      message: t('sure_remove_note'),
      onConfirm: () => {
        NotesService.delete(note._id).then(() => setRemovedNote(note._id))
      }
    })
  }

  if (! notes || ! notes.records) return null

  return ( 
    <React.Fragment>
      <SearchFilter 
        onChange={search} 
        value={searchInput} 
        placeholder={t("search_notes")}
        filter={filter}
        filters={filters}
        setFilter={setFilter}
      />
      <Grid container spacing={2}>
        { notes.records.map(note => {
          return <Grid key={note._id} item xs={fullwidth ? 12 : 6}>
            <Paper variant="outlined" className={classes.note}>
              <Box display="flex" className={classes.header}>
                <h2>Recruiter {note.recruiter[0].firstname} {note.recruiter[0].name}</h2>
                <h3 style={{textAlign: 'right'}}>{new Date(note.createdAt).toLocaleDateString('nl-BE')}</h3>
              </Box>
              <Paper display="flex" elevation={2} className={classes.description}>
                <div dangerouslySetInnerHTML={{ __html: note.description }} />
              </Paper>
              <NoteScores note={note} />
              <Box className={classes.actions}>
                <IconButton onClick={() => selectNote(note)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => removeNote(note)}>
                  <DeleteIcon/>
                </IconButton>
              </Box>
            </Paper>
          </Grid>
        })}
      </Grid>
      { !! notes.records.length &&
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={notes.totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      }
    </React.Fragment>    
  )
}