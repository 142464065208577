import React from 'react'
import { t } from '../utils/i18n'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from "react-router-dom"
import { Box, Grid, Button } from '@material-ui/core'


import CampaignForm from "./CampaignForm";

// Components
import Alert from "./Alert"

const useStyles = makeStyles(theme => ({
  root: {
    '& > .MuiGrid-root': {
      position: 'relative',
      paddingBottom: 25,
      minHeight: 40
    },
    '& ul.MuiList-padding': { padding: '5px 0' },
    '& .MuiGrid-root.MuiTypography-root': { minWidth: '40%' },
    '& h5': { fontSize: 18 }
  },
  button: {
    backgroundColor: theme.palette.mediaforta.main,
    textTransform: 'none'
  },
  save: { 
    backgroundColor: theme.palette.mediaforta.success,
    textTransform: 'none',
    marginLeft: 10
  },
  avatar: {
    width: 60,
    height: 60,
    float: 'left'
  },
  name: {
    fontSize: 24,
    fontWeight: 500,
    padding: '2px 10px 0',
    '& > * > span': {
      fontSize: 14,
      fontWeight: 300,
      display: 'block'
    }
  },
  list: { paddingBottom: 15 },
  info: { 
    fontSize: 16,
    marginBottom: 30,
    padding: 0,
    border: 0,
    '& fieldset': { borderColor: `${theme.palette.mediaforta.light} !important` },
    '& textarea': { color: theme.palette.primary.dark },
    '& > div': { width: '100%' }
  },
  status: {
    position: 'absolute',
    top: 17,
    right: 25
  }
}))

export default function CampaignDetailsOverview({campaign = {}, edit, onUpdate, onClose}) {
  const classes = useStyles()
  const [ isEdit, setIsEdit ] = React.useState(edit)
  const [ error, setError ] = React.useState(false)
  
  React.useEffect(() => {
    setError(false)
    if (campaign._id) setIsEdit(false)
    else setIsEdit(true)
    // eslint-disable-next-line
  }, [campaign])

  return ( 
    <Box className={classes.root}>
      { error && <Alert severity="error">{t('fill_in_user_create_fields')}</Alert> }
      <CampaignForm edit={true} onClose={() => onClose()} campaign={campaign} onUpdate={() => onUpdate()}/>
    </Box>
  )
}