// Services
import ApiService from "../services/api.service"

const model = 'emails'

export default class EmailsService {
	static list(search = false, limit = 10, skip = 0, fields = '', filter = false) {
    if (search && search !== '') search = `{"description": "regex:${search}"}`  
    return ApiService.list(model, { search, filter, limit, skip, fields}).then(response => response.data)
  }

  static post(data = {}) {
    return ApiService.post(model, data).then(response => response)
  }
}
