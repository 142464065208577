import React from 'react';
import { t } from '../utils/i18n';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, CssBaseline, Typography, Link as MuiLink } from '@material-ui/core';

// Context
import { useApp } from "../context/app";

// Services
import UsersService from "../services/users.service";

// Pages 
import Home from './Home';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1605,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  logo: {
    width: 90,
    maxWidth: 90,
    height: 90,
    marginBottom: 30,
    backgroundImage: 'url(/mediaforta-logo.svg)',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat'
  },
  box: {
    width: 800,
    flexDirection: 'column',
    '& > *': {
      padding: 30,
      margin: 10,
    }
  },
  register: { backgroundColor: '#f0f3f5' },
  title: {
    textAlign: 'center',
    marginBottom: 15,
    color: '#17285d'
  },
  link: {
    display: 'block',
    textAlign: 'center',
    color: '#504fff'
  }
}));

export default function RegistrationFeedback({ user, email }) {
  const classes = useStyles();
  const { setResponseDialog } = useApp();
  const { firstname, name } = user || {};

  if (!firstname || !name || !email) return <Home />;
  return (
    <Container component="main" maxWidth={false} className={classes.root}>
      <CssBaseline />
      <Box display="flex" className={classes.box}>
        <Container component="main" maxWidth={false} className={classes.register}>
          <Box>
            <Typography className={classes.title} component="h1" variant="h2"> {t('email_sent_title')} </Typography>
          </Box>
        </Container>
        <Container component="main" maxWidth={false} >
          <Box>
            <Typography className={classes.title} component="h1" variant="h5">
              {t('email_sent_info', true)}
            </Typography>
          </Box>
        </Container>
        <Container component="main" maxWidth={false} >
          <Box>
            <Typography className={classes.title} component="div">
              {t('email_not_received')}
            </Typography>
            <MuiLink
              className={classes.link}
              href="#"
              onClick={event => {
                event.preventDefault();
                UsersService.resendToken(email)
                  .then(() => setResponseDialog({ title: t('email_sent_title'), message: t('email_sent_spam') }))
                  .catch(error => setResponseDialog({ error }));
              }}
            >
              {t('resend')} &gt;
            </MuiLink>
          </Box>
        </Container>
      </Box>
    </Container>
  );
}