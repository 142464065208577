import React from 'react';
import { t } from '../utils/i18n';

import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { Container, CssBaseline, Typography, Link, Grid } from '@material-ui/core';

// Context
import { useUser } from '../context/user';

// Services
import JobsService from '../services/jobs.service';
import CampaignJobList from '../components/CampaignJobList';

export const listCampaignJobs = async (campaignId) => JobsService.listCampaignJobs(campaignId);


const useStyles = makeStyles(theme => ({
  back: {
    fontSize: 16,
    marginTop: 30,
    marginLeft: 10,
    cursor: 'pointer'
  }
}));

export default function CampaignJobs({ match }) {
  const history = useHistory();
  const classes = useStyles();

  const { isRecruiter } = useUser();
  const { campaignId } = match.params;
  const [jobs, setJobs] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    if (!jobs.length || refresh) {
      listCampaignJobs(campaignId).then((jobs) => {
        setJobs(jobs);
        if (refresh) setRefresh(false);
      });
    }
    // eslint-disable-next-line
  }, [refresh]);

  return (
    isRecruiter() &&
    <Container component="main" maxWidth='lg'>
      <CssBaseline />
      <div className={classes.back}>
        <Link onClick={() => history.goBack()}> &lt; back to campaign </Link>
      </div>
      <Typography component="h1" variant="h5">
        {t('Campaign Jobs')}
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <CampaignJobList jobs={jobs} setRefresh={() => setRefresh(Math.random())} onRemove={() => setRefresh(true)} match={match} />
        </Grid>
      </Grid>
    </Container>
  );
}