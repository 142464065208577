import React from 'react'
import { t } from '../utils/i18n'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { TextField } from 'formik-material-ui'
import { Box, Button, Typography } from '@material-ui/core'

// Services
import InterviewService from '../services/interviews.service'

const InterviewSchema = Yup.object().shape({
	type: Yup.string().required(t('required')),
  questions: Yup.array()
})

export function InterviewTemplateForm({onClose}) {
  return <Formik
    initialValues={{ type: '' }}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={InterviewSchema}
    onSubmit={(values, { setSubmitting }) => {
      InterviewService.postTemplate({...values, questions: []}).then(({data = {}}) => onClose(data._id))
      setSubmitting(false)
    }}
  >
    {({ submitForm, isSubmitting }) => 
    {
      return (
        <Form>
          <Box margin={1}>
            <Typography variant="h5"> {t('type')} </Typography>
            <Field
              component={TextField}
              fullWidth
              style={{marginBottom: 20}}
              variant="outlined"
              name="type"
              type="text"
              label={t('type')} />
          </Box>
          <Box margin={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              { t('save') }
            </Button>
          </Box>
        </Form>
    )}}
  </Formik>
}

const QuestionSchema = Yup.object().shape({
	question: Yup.string().required(t('required'))
})

export function InterviewQuestionForm({ onClose, question, template, index }) {
  return <Formik
    initialValues={{ question }}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={QuestionSchema}
    onSubmit={({question}, { setSubmitting }) => {
      if (index >= 0) {
        template.questions = template.questions.map((q, i) => i === index ? { question } : q);
        InterviewService.patchTemplate(template._id, template).then(({data = {}}) => onClose(data));
      } else {
        InterviewService.addQuestion(template._id, {question}).then(({data = {}}) => onClose(data));
      }
      setSubmitting(false)
    }}
  >
    {({ submitForm, isSubmitting }) => 
    {
      return (
        <Form>
          <Box margin={1}>
            <Typography variant="h5"> {t('question')} </Typography>
            <Field
              component={TextField}
              fullWidth
              style={{marginBottom: 20}}
              variant="outlined"
              name="question"
              type="text"
              label={t('question')} />
          </Box>
          <Box margin={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              { t('save') }
            </Button>
          </Box>
        </Form>
    )}}
  </Formik>
}