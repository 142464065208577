import React from 'react'
import { t } from '../utils/i18n'
import { IconButton, Box } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

// Model components
import EmailsList from './EmailsList'
import EmailForm from './EmailForm'

// assets
import '../assets/scss/form-wrapper.scss'

export default function CandidateEmails({candidate = {}, className}) {
  const [email, setEmail] = React.useState(false)

  return ( 
    <div className={className}>
      { ! email ?
        <IconButton aria-label={t("add_email")} onClick={() => setEmail({})}> <AddIcon /> {t('new_email')} </IconButton>
        :
        <Box className="form-wrapper">
          <h2> { email._id ? t('view_email'):  t('new_email') } </h2>
          <div>
            <EmailForm candidate={candidate} onClose={() => setEmail(false)} email={email}/>
            <IconButton aria-label={t("cancel")} onClick={() => setEmail(false)}> x </IconButton>
          </div>
        </Box>
      }
      { candidate._id && <EmailsList fullwidth candidate={candidate} email={email} selectEmail={email => setEmail(email) }/> }
    </div>
  )
}