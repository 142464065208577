const dev = {
  mediaforta: {
    AUTH_URL: 'https://mediaforta-api.aranealabs.com/auth/',
    USER_URL: 'https://mediaforta-api.aranealabs.com/users/',
    API_URL: 'https://mediaforta-api.aranealabs.com/api/v1/'
  }
}

const prod = {
  mediaforta: {
    AUTH_URL: 'https://jobs-api.mediaforta.com/auth/',
    USER_URL: 'https://jobs-api.mediaforta.com/users/',
    API_URL: 'https://jobs-api.mediaforta.com/api/v1/',
    JOBS_URL: 'http://mediaforta.com/jobs'
  }
}

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev

export default config
