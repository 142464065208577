import React from 'react';
import { t } from '../../utils/i18n';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Avatar } from '@material-ui/core';

// components 
import RegisterForm from '../RegisterForm';

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
    '& > *': {
      padding: 30,
      margin: 30,
      flex: 1.5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    '& .MuiInputBase-root > fieldset': { borderColor: '#ccc' }
  },
  register: {
    flex: 1,
    backgroundColor: '#f0f3f5',
    '& .MuiFormControl-root': {
      backgroundColor: '#ebeced',
      marginBottom: 20
    }
  },
  title: {
    textAlign: 'center',
    marginBottom: 15
  },
  companyTitle: {
    margin: '0 0 15px 0 !important',
    fontSize: '25px !important',
    fontWeight: '400 !important'
  },
  name: { color: '#5150ff' },
  companies: {
    flexDirection: 'row !important',
    width: '70%',
    flexWrap: 'wrap',
    opacity: .5,
    '& > img': {
      width: '33%',
      padding: '5px 20px'
    }
  },
  testimonial: {
    alignItems: 'center',
    marginTop: 20,
    '& h1': {
      textAlign: 'left',
      color: '#636363',
      margin: 0,
      '& label': {
        display: 'block',
        fontSize: '14px',
        marginTop: 10,
        color: '#16cc94'
      }
    }
  },
  avatar: {
    width: 345,
    height: 245,
    backgroundImage: 'url(http://www.mediaforta.com/wp-content/themes/mfagency/library/images/quote-bg-green.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '& > img': {
      borderRadius: '50%',
      width: 200,
      height: 200
    }
  }
}));

export default function UserDetails({ user, jobId, campaignId }) {
  const classes = useStyles();
  const { firstname, name } = user || {};
  return (
    <>
      <Box margin={2}>
        <Typography className={classes.title} component="h1" variant="h2">
          {t('hello')} <span className={classes.name}>{firstname} {name}</span>!
        </Typography>
        <Typography className={classes.title} component="h1" variant="h5">
          {t('join_us', true)}
        </Typography>
      </Box>
      <Box display="flex" className={classes.box}>
        <Container component="main" maxWidth={false} className={classes.register} flex={1}>
          <Box>
            <Typography className={classes.title} component="h1" variant="h5"> {t('account_details')} </Typography>
            <RegisterForm user={user} jobId={jobId} campaignId={campaignId} />
          </Box>
        </Container>
        <Container component="main" maxWidth={false} flex={2}>
          <Typography className={classes.companyTitle} component="h1" variant="h5"> {t('trusted_by')} </Typography>
          <Box display='flex' className={classes.companies}>
            <img src="/Securitas_logo2.png" alt="securitas logo" />
            <img src="/ing-logo.png" alt="ing logo" />
            <img src="/LUMINUS_logo.png" alt="luminus logo" />
            <img src="/Belfius.png" alt="belfius logo" />
            <img src="/velux-logo.png" alt="velux logo" />
            <img src="/Proximus.png" alt="proximus logo" />
            <img src="/notify-logo.png" alt="notify logo" />
          </Box>
          <Box display='flex' className={classes.testimonial}>
            <Avatar src="/1547586797414.jpeg" className={classes.avatar} />
            <Typography className={classes.title} component="h1" variant="h5">
              {t('testimonial_ludovic', true)}
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}